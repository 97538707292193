import * as actionTypes from '@/store/actions/types/gifts';

const initialState = {
  totalChart: [],
  totalStatisticCards: null,
  errors: {},
  success: {},
  loading: {
    totalChart: true,
    totalStatisticCards: true,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GIFTS_CARDS_START:
      return { ...state, loading: { ...state.loading, totalStatisticCards: true } };
    case actionTypes.GIFTS_CARDS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, totalStatisticCards: false },
        totalStatisticCards: action.payload.data,
        errors: { ...state.errors, totalStatisticCards: null },
      };
    case actionTypes.GIFTS_CARDS_FAIL:
      return {
        ...state,
        loading: false,
        errors: {
          totalStatisticCards: action.payload.errorMessage,
        },
      };
    case actionTypes.GIFTS_CHART_START:
      return { ...state, loading: { ...state.loading, totalChart: true } };
    case actionTypes.GIFTS_CHART_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, totalChart: false },
        totalChart: action.payload.data,
        errors: { ...state.errors, totalChart: null },
      };
    case actionTypes.GIFTS_CHART_FAIL:
      return {
        ...state,
        loading: false,
        errors: {
          totalChart: action.payload.errorMessage,
        },
      };
    default:
      return state;
  }
};

export default reducer;
