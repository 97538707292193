import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/locations';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
// import { AUTH_UPDATE_CHECK_INFO } from '@/store/actions/types/auth';
import { getMe } from '@/store/saga/auth';
import { getEmployeesListSaga } from '@/store/saga/employees';

export function* addEmployeesToLocationSaga(action) {
  yield put({ type: actionTypes.LOCATIONS_START });

  const { locations, organizationId, locationId, getList, params, ...employees } = action.payload;
  let location_id;

  try {
    if (locationId) {
      location_id = locationId;
    } else {
      const urlLocations = getLink(endpoints.ADD_LOCATIONS, { id: organizationId });
      const res = yield axios.post(urlLocations, { locations });
      const [first] = res.data;
      location_id = first.organizationId;
    }
    const urlEmp = getLink(endpoints.ADD_EMPLOYEES_TO_LOCATION, {
      id: organizationId,
      locationId: location_id,
    });
    const { data } = yield axios.post(urlEmp, { ...employees });

    if (getList) {
      yield call(getEmployeesListSaga, {
        payload: { organizationId, locationId: location_id, ...params },
      });
    }
    if (locationId) {
      yield put({
        type: PUSH_NOTIFY,
        payload: {
          message: t('notification.addEmployeeSuccess'),
          options: {
            variant: 'success',
          },
        },
      });
    } else {
      yield put({
        type: PUSH_NOTIFY,
        payload: {
          message: t('notification.addLocationAndEmployeeSuccess'),
          options: {
            variant: 'success',
          },
        },
      });
    }
    yield put({
      type: actionTypes.LOCATIONS_ADD_EMPLOYEES_SUCCESS,
      payload: data,
    });
    yield call(getMe);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.LOCATIONS_STOP,
    });
  }
}

export function* getLocationsListSaga(action) {
  yield put({ type: actionTypes.LOCATIONS_START });

  const { organizId, ...params } = action.payload;
  const url = getLink(endpoints.GET_ORGANIZATION_LOCATIONS, { id: organizId });

  try {
    const res = yield axios.get(url, { params });
    const resList = res.data.data;

    const list = Object.keys(resList).map(key => {
      return {
        city: key,
        locations: resList[key],
      };
    });

    const data = {
      ...res.data,
      data: list,
    };

    yield put({
      type: actionTypes.LOCATIONS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.LOCATIONS_STOP,
    });
  }
}

export function* addLocationSaga(action) {
  yield put({ type: actionTypes.LOCATIONS_START });

  const { locations, organizId } = action.payload;
  const url = getLink(endpoints.ADD_LOCATIONS, { id: organizId });

  try {
    yield axios.post(url, { locations });
    yield call(getLocationsListSaga, { payload: { organizId } });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.addLocationsSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.LOCATIONS_STOP,
    });
  }
}

export function* deleteLocationSaga(action) {
  yield put({ type: actionTypes.LOCATIONS_START });

  const { organizId, locationId } = action.payload;
  const url = getLink(endpoints.DELETE_ORGANIZATION_LOCATION, { id: organizId, locationId });

  try {
    yield axios.delete(url);
    yield call(getLocationsListSaga, { payload: { organizId } });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.deleteLocationsSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.LOCATIONS_STOP,
    });
  }
}

export function* editLocationSaga(action) {
  yield put({ type: actionTypes.LOCATIONS_START });

  const { location, organizId, locationId } = action.payload;
  const url = getLink(endpoints.EDIT_LOCATIONS, { id: organizId, locationId });

  try {
    yield axios.put(url, { location });
    yield call(getLocationsListSaga, { payload: { organizId } });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.editLocationsSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.LOCATIONS_STOP,
    });
  }
}
