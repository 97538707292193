import styled from 'styled-components';
import { getCurrentLang } from '@/locale/i18n';
import colors from '@/constants/colors';
import { Typography } from '@material-ui/core';

const { stripeConnect } = colors;
export const StripeText = styled.p`
  display: block;
  font-size: 16px;
  color: ${stripeConnect.button.color};
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 3px;
`;

export const StripeFirstSymbol = styled.p`
  display: block;
  font-size: 26px;
  color: ${stripeConnect.button.color};
  font-weight: bold;
  border-right: 2px solid ${stripeConnect.button.border};
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 5px;
  margin-top: 3px;
`;

export const StripeBtn = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  margin: 0;
  border: 2px solid ${stripeConnect.button.border};
  background-color: ${stripeConnect.button.bg};
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: ${getCurrentLang() === 'en' ? 175 : 210}px;
`;

export const ButtonContainers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export const CreateNewAccount = styled(Typography)`
  margin-bottom: 0;
`;
