import * as Yup from 'yup';
import { t } from '@/locale/i18n';

export default data => {
  return {
    country: Yup.string().required(t('validation.countryRequired')),
    to_ids: Yup.array().required(t('validation.usersRequired')),
    amount: Yup.number()
      .required(t('validation.amountRequired'))
      .min(data.min, t('validation.amountMin', { min: data.min }))
      .max(data.max, t('validation.amountMax', { max: data.max })),
  };
};
