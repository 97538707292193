import axios from 'axios';

import { getCurrentLang } from '../locale/i18n';

export const setDefaultAxios = () => {
  const lang = getCurrentLang();

  axios.defaults.baseURL = process.env.REACT_APP_BASE_API;

  if (lang) {
    axios.defaults.headers = {
      ...axios.defaults.headers,
      'X-localization': lang,
      'Accept-Language': `${lang}-US,${lang}`,
    };
  }
};

export const deleteAuthorizationToken = () => {
  delete axios.defaults.headers.Authorization;
};

export const updateHeadersLang = lng => {
  axios.defaults.headers['X-localization'] = lng;
  axios.defaults.headers['Accept-Language'] = `${lng}-US,${lng}`;
};

export const setAuthorizationToken = (token, tokenType) => {
  const lang = getCurrentLang();

  if (token) {
    axios.defaults.headers = {
      Authorization: `${tokenType} ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-localization': lang,
      'Accept-Language': `${lang}-US,${lang}`,
    };
  } else {
    deleteAuthorizationToken();
  }
};

export const createAxiosResponseInterceptor = () => {
  axios.interceptors.request.use(config => {
    config.retry = config.retry || 0;
    return config;
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      try {
        const errorResponse = error.response;
        if (errorResponse?.status === 408 && (!error.config.retry || error.config.retry < 3)) {
          error.config.retry = (error.config.retry || 1) + 1;
          return axios(error.config);
        }

        if (error.message === 'Network Error') {
          const connected = window.navigator.onLine;

          // Application.ReduxStorage.store.dispatch({
          //   type: appActionTypes.SET_CONNECTION,
          //   payload: { connected },
          // });

          if (connected && (!error.config.retry || error.config.retry < 3)) {
            error.config.retry = (error.config.retry || 1) + 1;
            return axios(error.config);
          }
        }

        // Reject promise if usual error
        return Promise.reject(error);
      } catch (e) {
        return Promise.reject(error);
      }
    },
  );
};
