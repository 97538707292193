import * as actionTypes from './types/surveys';

export const getSurveysList = params => {
  return {
    type: actionTypes.GET_SURVEYS_LIST,
    payload: params,
  };
};

export const getSurveyDetail = params => {
  return {
    type: actionTypes.GET_DETAIL_SURVEY,
    payload: params,
  };
};

export const changeStatusSurvey = params => {
  return {
    type: actionTypes.CHANGE_STATUS_SURVEY,
    payload: params,
  };
};

export const createSurvey = data => {
  return {
    type: actionTypes.CREATE_SURVEY,
    payload: data,
  };
};

export const editSurvey = data => {
  return {
    type: actionTypes.EDIT_SURVEY,
    payload: data,
  };
};
