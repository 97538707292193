import React from 'react';
import { compose } from 'redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import StripeConnect from '@/components/StripeConnect/StripeConnect';
import { Divider } from './styles';
import { ROLE_MEXICO } from '@/constants/roles';
import { Link } from '@material-ui/core';
import { t } from '@/locale/i18n';
import { Container, Content } from '@/containers/MyProfile/styles';
import { StripeWrapper } from '@/containers/MyProfile/tabs/Billing/styles';
import { isSwitchAvailable } from '@/utility/switchAccountDialog';
import EXTERNAL_SERVICE from '@/constants/externalService';

const PaymentConnect = ({
  external_service,
  check_info,
  country,
  // squareDisplay,
  stripeDisplay,
  isMobile,
  // plan_status,
  setSwitchDialogIsOpened,
  isDesktop,
  organization_stripe_account,
  organization_square_account,
}) => {
  // const isFreePlan = plan_status && Boolean(plan_status.is_free);
  const isNGC = external_service === EXTERNAL_SERVICE;
  const isMexico = country === ROLE_MEXICO;

  // const squareCountryConnect = (
  //   <Content>
  //     <Container>
  //       <SquareWrapper>
  //         <SquareConnect />
  //         {isSwitchAvailable(check_info.square_connect_accounts, check_info.square_connect) ? (
  //           <div>
  //             <Link
  //               onClick={() => {
  //                 setSwitchDialogIsOpened({ display: true, paymentType: 'square' });
  //               }}
  //               id="stripe-switch-account"
  //               component={RouterLink}
  //               color="secondary"
  //             >
  //               {t('switchAccountDialog.switchSquareAccount')}
  //             </Link>
  //           </div>
  //         ) : null}
  //       </SquareWrapper>
  //     </Container>
  //   </Content>
  // );

  const stripeCountryConnect = (
    <Content>
      <Container>
        <StripeWrapper>
          <StripeConnect />
          {isSwitchAvailable(check_info.stripe_connect_accounts, check_info.stripe_connect) ? (
            <div>
              <Link
                onClick={() => {
                  setSwitchDialogIsOpened({ display: true, paymentType: 'stripe' });
                }}
                id="stripe-switch-account"
                color="secondary"
              >
                {t('switchAccountDialog.switchStripeAccount')}
              </Link>
            </div>
          ) : null}
        </StripeWrapper>
      </Container>
    </Content>
  );

  const connectedStripe = (
    <>
      <StripeConnect />
      {isSwitchAvailable(check_info.stripe_connect_accounts, check_info.stripe_connect) ? (
        <div>
          <Link
            onClick={() => {
              setSwitchDialogIsOpened({
                display: true,
                paymentType: 'stripe',
                accountId: organization_stripe_account,
              });
            }}
            id="stripe-switch-account"
            component={RouterLink}
            color="secondary"
          >
            {t('switchAccountDialog.switchStripeAccount')}
          </Link>
        </div>
      ) : null}
    </>
  );

  const connectedSquare = (
    <>
      {isSwitchAvailable(check_info.square_connect_accounts, check_info.square_connect) ? (
        <div>
          <Link
            onClick={() => {
              setSwitchDialogIsOpened({
                display: true,
                paymentType: 'square',
                accountId: organization_square_account,
              });
            }}
            id="stripe-switch-account"
            component={RouterLink}
            color="secondary"
          >
            {t('switchAccountDialog.switchSquareAccount')}
          </Link>
        </div>
      ) : null}
    </>
  );

  // const squareConnect = squareDisplay && connectedSquare;
  const stripeConnect = stripeDisplay && connectedStripe;

  const checkPaymentsDesktop = isMexico ? (
    stripeConnect
  ) : (
    <>
      {/* {squareConnect} */}
      {!isNGC && <Divider />}
      {!isNGC && stripeConnect}
    </>
  );

  const checkPaymentsMobile = isMexico ? (
    connectedStripe
  ) : (
    <>
      {connectedSquare}
      {!isNGC && <Divider />}
      {!isNGC && stripeConnect}
    </>
  );

  if (isMobile || isDesktop) {
    if (!check_info.stripe_connect && !check_info.square_connect) {
      return isMobile ? checkPaymentsMobile : checkPaymentsDesktop;
    }
    if (check_info.stripe_connect) {
      return connectedStripe;
    }
    return connectedSquare;
  }

  return isMexico ? (
    stripeCountryConnect
  ) : (
    <>
      {!isNGC ? stripeCountryConnect : null}
      {/* {squareCountryConnect} */}
    </>
  );
};

PaymentConnect.propTypes = {
  external_service: PropTypes.string,
  country: PropTypes.string,
  check_info: PropTypes.object,
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  plan_status: PropTypes.object,
  squareDisplay: PropTypes.bool,
  stripeDisplay: PropTypes.bool,
  setSwitchDialogIsOpened: PropTypes.func.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  organization_stripe_account: PropTypes.string.isRequired,
  organization_square_account: PropTypes.string.isRequired,
};

const mapStateToProps = ({ auth }) => ({
  external_service: auth.external_service,
  check_info: auth.check_info,
  country: auth.organization_country,
  plan_status: auth.plan_status,
  organization_stripe_account: auth.organization_stripe_account,
  organization_square_account: auth.organization_square_account,
});

export default compose(withRouter, connect(mapStateToProps, null))(PaymentConnect);
