import * as actionTypes from './types/locations';

export const addLocation = params => {
  return {
    type: actionTypes.LOCATIONS_ADD,
    payload: params,
  };
};

export const addEmployeesToLocation = params => {
  return {
    type: actionTypes.LOCATIONS_ADD_EMPLOYEES,
    payload: params,
  };
};

export const getLocationsList = params => {
  return {
    type: actionTypes.LOCATIONS_LIST,
    payload: params,
  };
};

export const deleteLocation = params => {
  return {
    type: actionTypes.LOCATIONS_DELETE,
    payload: params,
  };
};

export const editLocation = params => {
  return {
    type: actionTypes.LOCATIONS_EDIT,
    payload: params,
  };
};
