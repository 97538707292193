import styled from 'styled-components';
import { Grid, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import colors from '@/constants/colors';
import completeProfilePicture from '@/assets/images/completeProfileBg.png';

export const LeftSide = styled(Grid)`
  background: url(${completeProfilePicture}) bottom right no-repeat ${colors.leftSideBg};
  @media (max-width: 960px) {
    background: ${colors.leftSideBg};
  }
`;

export const RightSide = styled(Grid)`
  .close-button {
    position: absolute;
    top: 15px;
    right: 24px;
  }
`;

export const MainTitle = styled(DialogTitle)`
  && {
    padding: 83px 64px 100px;
    h3 {
      display: inline-block;
    }
    @media (max-width: 960px) {
      padding: 24px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    @media (max-width: 600px) {
      & h3 {
        font-size: 2.4rem;
        line-height: 2.4rem;
      }
    }
    .close-button {
      display: inline-block;
      font-size: 2.4rem;
      color: ${colors.primaryBtnBg};
    }
  }
`;

export const LeftSideFooter = styled.div`
  padding: 250px 38px 20px;
`;

export const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const WrapSteps = styled.div`
  margin: 80px 60px 40px;
  width: 100%;
  max-width: 424px;

  @media (max-width: 960px) {
    margin: 60px 0;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const StripeForm = styled.form`
  .stripeInput {
    border: 1px solid ${colors.inputBorder};
    padding: 14px 37px 14px 18px;
    font-size: 16px;
    margin-top: 5px;
    min-width: 0;
    background: none;
    box-sizing: border-box;
    font-weight: 400;
    line-height: 25px;
    border-radius: 4px;
    &:active,
    &:focus {
      border: 1px solid ${colors.inputBorderFocused};
    }
  }
  & input:placeholder {
    color: red;
  }
  .cancel-btn {
    margin-left: 10px;
  }
  & .buttons-field {
    display: flex;
    align-items: center;
  }
`;

export const ExpansionPanel = withStyles({
  root: {
    width: '100%',
    padding: 0,
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    '&$disabled': {
      backgroundColor: colors.leftSideBg,
    },
  },
  expanded: {},
  disabled: {
    backgroundColor: colors.leftSideBg,
  },
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: colors.leftSideBg,
    border: 'none',
    color: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  disabled: {
    opacity: '1 !important',
    color: colors.customStepperTextComplete,
    '& .stepper-number': {
      background: colors.customStepperNumberCompleteBg,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {
    backgroundColor: colors.customStepperActiveBg,
  },
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);
