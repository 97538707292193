import * as actionTypes from '@/store/actions/types/redemptions';

const initialState = {
  redemptionData: null,
  isRedeemed: false,
  loading: {
    data: false,
    download: false,
    secretSymbolsList: false,
    secretSymbol: false,
    list: false,
    code: false,
  },
  history: [],
  secretSymbols: [],
  transaction: {},
  redemptionList: [],
  redemptionsCodes: [],
  totalRedeemed: 0,
  meta: {
    current_page: 0,
    per_page: 25,
    total: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REDEMPTIONS_CODE_INFO_START:
      return {
        ...state,
        loading: { ...state.loading, code: true },
      };
    case actionTypes.REDEMPTIONS_CODE_INFO_SUCCESS:
      return {
        ...state,
        redemptionsCodes: action.payload.data,
        totalRedeemed: action.payload.total_redeemed,
        loading: { ...state.loading, code: false },
      };
    case actionTypes.REDEMPTIONS_CODE_INFO_FAIL:
      return {
        redemptionsCodes: [],
        loading: { ...state.loading, code: false },
      };
    case actionTypes.REDEMPTIONS_START:
      return {
        ...state,
        loading: { ...state.loading, data: true },
        history: [],
        transaction: {},
      };
    case actionTypes.REDEMPTIONS_INFO_SUCCESS:
      return {
        ...state,
        redemptionData: action.payload.data,
        loading: { ...state.loading, data: false },
      };
    case actionTypes.REDEMPTIONS_SUBMIT_SUCCESS:
      return {
        ...state,
        redemptionData: {
          ...state.redemptionData,
          amount: (state.redemptionData.amount - action.payload).toFixed(2),
        },
        isRedeemed: true,
        loading: { ...state.loading, data: false },
      };
    case actionTypes.REDEMPTIONS_REDEEM_OTHER:
      return {
        ...initialState,
      };
    case actionTypes.REDEMPTIONS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, data: false },
      };
    case actionTypes.REDEMPTIONS_GET_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload.history,
        transaction: action.payload,
        loading: { ...state.loading, data: false },
      };
    case actionTypes.REDEMPTIONS_REFUND_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, data: false },
      };

    case actionTypes.REDEMPTION_DOWNLOAD_QR_CODE_START:
      return {
        ...state,
        loading: { ...state.loading, download: true },
      };

    case actionTypes.REDEMPTION_DOWNLOAD_QR_CODE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, download: false },
      };

    case actionTypes.REDEMPTION_DOWNLOAD_QR_CODE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, download: false },
      };

    case actionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS_START:
      return {
        ...state,
        loading: { ...state.loading, secretSymbolsList: true },
      };

    case actionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS_SUCCESS:
      return {
        ...state,
        secretSymbols: action.payload.data,
        loading: { ...state.loading, secretSymbolsList: false },
      };

    case actionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, secretSymbolsList: false },
      };

    case actionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL_START:
      return {
        ...state,
        loading: { ...state.loading, secretSymbol: true },
      };

    case actionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, secretSymbol: false },
      };

    case actionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL_FAIL:
      return {
        ...state,
        loading: { ...state.loading, secretSymbol: false },
      };

    case actionTypes.REDEMPTION_DOWNLOAD_COSTER_START:
      return {
        ...state,
        loading: { ...state.loading, download: true },
      };

    case actionTypes.REDEMPTION_DOWNLOAD_COSTER_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, download: false },
      };

    case actionTypes.REDEMPTION_DOWNLOAD_COSTER_FAIL:
      return {
        ...state,
        loading: { ...state.loading, download: false },
      };

    case actionTypes.GET_REDEMPTIONS_START:
      return {
        ...state,
        loading: { ...state.loading, list: true },
      };

    case actionTypes.GET_REDEMPTIONS_SUCCESS: {
      const customers = action.payload.customers.map(user => ({
        id: user.id,
        label: user.username,
        value: user.id,
      }));
      return {
        ...state,
        redemptionList: action.payload.data,
        meta: {
          ...action.payload.meta,
          current_page: action.payload.meta.current_page - 1,
        },
        customers: action.payload.customers?.length ? customers : [],
        loading: { ...state.loading, list: false },
      };
    }
    case actionTypes.GET_REDEMPTIONS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, list: false },
      };

    case actionTypes.REDEMPTIONS_SET_INIT_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
