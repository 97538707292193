import * as actionTypes from './types/adminSettings';

export const getNGCStatus = () => {
  return {
    type: actionTypes.GET_NGC_STATUS,
  };
};

export const archiveAllNGC = params => {
  return {
    type: actionTypes.ARCHIVE_ALL_NGC,
    payload: params,
  };
};

export const getAdminSettings = () => {
  return {
    type: actionTypes.GET_ADMINS_SETTINGS,
  };
};

export const updateAdminSettings = params => {
  return {
    type: actionTypes.UPDATE_ADMINS_SETTINGS,
    payload: params,
  };
};

export const updateToCurrentUsers = params => {
  return {
    type: actionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS,
    payload: params,
  };
};

export const getAdminNotification = () => {
  return {
    type: actionTypes.GET_ADMIN_NOTIFICATIONS,
  };
};

export const updateAdminNotification = params => {
  return {
    type: actionTypes.UPDATE_ADMIN_NOTIFICATIONS,
    payload: params,
  };
};

export const reindexAlgolia = params => {
  return {
    type: actionTypes.REINDEX_ALGOLIA,
    payload: params,
  };
};
