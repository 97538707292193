import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const Menu = ({ selectProps, innerProps, children }) => {
  return (
    <Paper square className={`${selectProps.classes.paper} autocomplete-menu`} {...innerProps}>
      {children}
    </Paper>
  );
};

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

export default Menu;
