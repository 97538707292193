import React from 'react';
import PropTypes from 'prop-types';
import { Typography, NoSsr } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { SelectStyled, ErrorWrapper, LabelWrapper, LabelAddText, useStyles } from './styles';
import components from './components';

const IntegrationReactSelect = ({
  inputId,
  inputStyles,
  containerStyles,
  label,
  isMulti,
  options,
  className,
  isDisabled,
  maxLength,
  labelUnit,
  error,
  helperText,
  value,
  ...rest
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMaxLength = maxLength > 0;
  let borderColor = 'rgba(206,214,243,1)';
  let classNameRender = className;

  if (isDisabled) {
    borderColor = 'rgba(39,49,120,0.1)';
  }

  if (error) {
    borderColor = '#FF4D42';
    classNameRender = 'm-b-0';
  }

  if (!value) {
    classNameRender = 'select-empty';
  }

  const renderLabel = (
    <LabelWrapper>
      <span>{label}</span>
      {isMaxLength ? (
        <LabelAddText>
          {value === null ? 0 : value.length}/{maxLength}
          {labelUnit ? ` ${labelUnit}` : null}
        </LabelAddText>
      ) : null}
    </LabelWrapper>
  );

  const selectStyles = {
    input: base => ({
      ...base,
      ...inputStyles,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    container: base => ({
      ...base,
      ...containerStyles,
      '.m-b-0 + .autocomplete-menu': {
        marginTop: 0,
      },
      '.MuiInputBase-input': {
        backgroundColor: isDisabled ? 'rgba(39,49,120,0.05)' : 'inherit',
        borderColor,
        color: isDisabled ? 'rgba(39,49,120,0.4)' : 'inherit',
      },
    }),
  };

  return (
    <NoSsr>
      <SelectStyled
        {...rest}
        value={value}
        isDisabled={isDisabled}
        classes={classes}
        styles={selectStyles}
        inputId={inputId}
        TextFieldProps={{
          label: label ? renderLabel : null,
          className: classNameRender,
          InputLabelProps: {
            htmlFor: inputId,
            shrink: true,
          },
          inputProps: {
            style: {
              position: 'fixed',
            },
          },
        }}
        options={options}
        components={components}
        isMulti={isMulti}
      />
      {error && (
        <ErrorWrapper>
          <Typography variant="body2">{helperText}</Typography>
        </ErrorWrapper>
      )}
    </NoSsr>
  );
};
IntegrationReactSelect.defaultProps = {
  maxLength: 0,
  className: '',
};

IntegrationReactSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  labelUnit: PropTypes.number,
  maxLength: PropTypes.number,
  isDisabled: PropTypes.bool,
  inputId: PropTypes.string,
  inputStyles: PropTypes.object,
  containerStyles: PropTypes.object,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  options: PropTypes.array,
  className: PropTypes.string,
};

export default IntegrationReactSelect;
