import * as Yup from 'yup';

export default t => {
  return {
    giftDetails: {
      cover_image: Yup.array().required(t('validation.coverRequired')),
    },
    overview: {},
  };
};
