import { t } from '@/locale/i18n';
import { ROUTE_MY_PROFILE, ROUTE_ORGANIZATION } from '@/constants/routes';
import { ROLE_MERCHANDISER } from '@/constants/roles';
import { checkIsPaymentVerified } from '@/utility/orgonizations/organization';

const conditionals = [
  { stripe: true, stripe_connect: true, stripe_connect_is_active: 1 },
  { square_connect: true },
];

export const checkFilledInfo = info => {
  let isFilled = false;

  if (info) {
    conditionals.forEach(condData => {
      const conditionPass = [];
      const keys = Object.keys(condData);
      keys.forEach(key => {
        if (condData[key] === info[key]) {
          conditionPass.push(true);
        }
      });
      if (conditionPass.length === keys.length) {
        isFilled = true;
      }
    });
  }

  return isFilled;
};

export default checkFilledInfo;

/**
 * Parse JWT token and return object with expiration date and etc.
 *
 * @param {string} token
 *
 */
export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

export const checkProgressProfile = profile => {
  const total = Object.keys(profile).length;
  const curProgress = Object.keys(profile).filter(item => profile[item] !== null).length;

  return (curProgress / total) * 100;
};

export const getInfoProfile = data => {
  const { check_info, organization, role } = data;
  const isConnectPayment = check_info.stripe_connect || check_info.square_connect;
  const isPaymentVerified = checkIsPaymentVerified(check_info, organization.status);

  const profile = {
    name: organization.name,
    email: organization.email || null,
    cover: organization.cover,
    logo: organization.logo,
    payment: isConnectPayment && isPaymentVerified ? 'connected' : null,
  };
  const profileProgress = checkProgressProfile(profile);

  const isWarning =
    Object.keys(organization).length && profileProgress !== 100 && role === ROLE_MERCHANDISER;

  return { profile, isWarning };
};

export const profileLabel = item => {
  let name;
  let route;

  switch (item) {
    case 'name':
      name = t('warning.profile.info.name');
      route = ROUTE_ORGANIZATION;
      break;
    case 'email':
      name = t('warning.profile.info.email');
      route = ROUTE_ORGANIZATION;
      break;
    case 'cover':
      name = t('warning.profile.info.cover');
      route = ROUTE_ORGANIZATION;
      break;
    case 'logo':
      name = t('warning.profile.info.logo');
      route = ROUTE_ORGANIZATION;
      break;
    case 'payment':
      name = t('warning.profile.info.payment');
      route = `${ROUTE_MY_PROFILE}#billing`;
      break;
    default:
      name = '';
      break;
  }

  return { name, route };
};
