import moment from 'moment';
import * as highchartsConst from '@/constants/highcharts';

export function parsingEventsCountData(data) {
  const emptyEvents = {
    user_engagement: 0,
    login: 0,
    notification_foreground: 0,
    view_item: 0,
    begin_checkout: 0,
    firebase_campaign: 0,
    notification_receive: 0,
    notification_dismiss: 0,
    app_update: 0,
    screen_view: 0,
    purchase: 0,
    categories: 0,
    notification_open: 0,
    contact_view: 0,
    sign_up: 0,
    share: 0,
    share_link: 0,
    dynamic_link_first_open: 0,
    first_open: 0,
    businesses: 0,
    app_remove: 0,
    profileGift_view: 0,
    gifts_list: 0,
    session_start: 0,
    search: 0,
    logout: 0,
    add_emotion: 0,
    add_thank_emotion: 0,
    dynamic_link_app_open: 0,
    os_update: 0,
  };
  if (!data) {
    return emptyEvents;
  }
  const response = emptyEvents;

  Object.values(data).forEach(yearData => {
    yearData.forEach(event => {
      response[event.event_name] = response[event.event_name]
        ? response[event.event_name] + event.count
        : event.count;
    });
  });

  return response;
}

export function formatScreenData(data) {
  return Object.keys(data)
    .map(screen => ({ title: screen, views: data[screen] }))
    .sort((a, b) => b.views - a.views);
}

export function parsingScreenViewData(data) {
  if (!data) {
    return [];
  }
  const response = {};

  Object.values(data).forEach(yearData => {
    yearData.forEach(event => {
      if (event.firebase_screen !== null) {
        response[event.firebase_screen] = response[event.firebase_screen]
          ? response[event.firebase_screen] + event.count
          : event.count;
      }
    });
  });

  return formatScreenData(response);
}

export function parsingScreenTimeData(data) {
  if (!data) {
    return [];
  }
  const response = {};

  Object.values(data).forEach(yearData => {
    yearData.forEach(event => {
      if (event.firebase_screen !== null) {
        response[event.firebase_screen] = response[event.firebase_screen]
          ? (response[event.firebase_screen] + event.average_engagement_time_msec) / 2
          : event.average_engagement_time_msec;
      }
    });
  });

  return formatScreenData(response);
}

function parsingTopEventsList(data, props) {
  if (!data) {
    return [];
  }
  const response = {};

  Object.values(data).forEach(yearData => {
    yearData.forEach(event => {
      const eventId = event[props.id];
      response[eventId] = response[eventId]
        ? { ...response[eventId], views: response[eventId].views + event[props.views] }
        : { name: event[props.name], views: event[props.views], id: event[props.id] };
    });
  });

  return Object.keys(response)
    .map(id => response[id])
    .sort((a, b) => b.views - a.views);
}

function pastePropsToObj(idField, nameField, viewsField) {
  return {
    name: nameField,
    views: viewsField,
    id: idField,
  };
}

export function parsingTopEventsData(data, event) {
  if (!data) {
    return [];
  }
  let props = {};

  switch (event) {
    case 'businesses':
      props = pastePropsToObj('business_id', 'business_name', 'count');
      break;
    case 'categories':
      props = pastePropsToObj('categories_id', 'categories_name', 'count');
      break;
    case 'contact_view':
      props = pastePropsToObj('user_id', 'username', 'count');
      break;
    case 'view_item':
    default:
      props = pastePropsToObj('item_id', 'item_name', 'views');
      break;
  }

  return parsingTopEventsList(data, props);
}

export function parsingSearchesData(data) {
  if (!data) {
    return [];
  }
  const response = {};

  Object.values(data).forEach(yearData => {
    yearData.forEach(event => {
      response[event.search_term] = response[event.search_term]
        ? (response[event.search_term] + event.count) / 2
        : event.count;
    });
  });

  return Object.keys(response).map(text => ({ text, value: response[text] }));
}

export function parsingEmotionsData(sentData, thankData) {
  if (!sentData && !thankData) {
    return { audio: 0, photo: 0, text: 0, video: 0 };
  }
  const response = { audio: 0, photo: 0, text: 0, video: 0 };

  Object.values(sentData).forEach(yearData => {
    yearData.forEach(event => {
      response[event.type] = response[event.type]
        ? response[event.type] + event.count
        : event.count;
    });
  });

  Object.values(thankData).forEach(yearData => {
    yearData.forEach(event => {
      response[event.type] = response[event.type]
        ? response[event.type] + event.count
        : event.count;
    });
  });

  return response;
}

export function parsingActiveUsersData(data) {
  if (!data) {
    return 0;
  }
  let count = 0;

  Object.values(data).forEach(yearData => {
    yearData.forEach(event => {
      count += event.count;
    });
  });

  return count;
}

export function parsingBusinessRegistrations(data) {
  if (!data?.length) {
    return [];
  }

  return data.map(value => {
    let period = value.period;
    if (value.period.length === 4) {
      period = `${period}0101`;
    }

    if (value.period.length === 6) {
      period = `${period}01`;
    }

    return {
      date: moment(period),
      value: value.count,
    };
  });
}

export const parsingPeriod = ({ params, displayYears = true, displayMonth = true }) => {
  const start = moment(params.to, 'YYYY-MM-DD');
  const end = moment(params.from, 'YYYY-MM-DD');

  const days = +moment.duration(start.diff(end)).asDays();

  if (days >= 30 && displayYears) {
    return { ...params, period: highchartsConst.STATISTIC_DATE_RANGE_YEAR };
  }
  if (days >= 7 && displayMonth) {
    return { ...params, period: highchartsConst.STATISTIC_DATE_RANGE_MONTH };
  }
  return { ...params, period: highchartsConst.STATISTIC_DATE_RANGE_DAY };
};
