import React from 'react';
import PropTypes from 'prop-types';

import { t } from '@/locale/i18n';
import { InfoOutlined } from '@material-ui/icons';

import { LinkStyled, Progress, TypographyStyled, WarningContainer } from './styles';

export const stripeAccountProblem = info => {
  switch (info.stripe_connect_disabled_reason) {
    case 'rejected.fraud': {
      return t('warning.paymentSystem.accountFraud');
    }
    case 'under_review': {
      return t('warning.paymentSystem.underReview');
    }
    case 'fields_needed': {
      return t('warning.paymentSystem.fieldsNeeded');
    }
    case 'listed': {
      return t('warning.paymentSystem.listed');
    }
    case 'rejected.other': {
      return t('warning.paymentSystem.other');
    }
    case 'rejected.listed': {
      return t('warning.paymentSystem.rejectedListed');
    }
    case 'rejected.terms_of_service': {
      return t('warning.paymentSystem.rejectedTermsOfService');
    }
    case 'requirements.pending_verification': {
      return t('warning.paymentSystem.pendingVerification');
    }
    case 'requirements.past_due': {
      return t('warning.paymentSystem.pastDuo');
    }
    case 'account.application.deauthorized': {
      return t('warning.paymentSystem.deauthorized');
    }
    default:
      return '';
  }
};

const paymentAccountWarning = props => {
  const { info } = props;

  return (
    <WarningContainer>
      <TypographyStyled variant="body2">
        {stripeAccountProblem(info)}
        <LinkStyled
          id="profile-link"
          target="_blank"
          color="secondary"
          href="https://dashboard.stripe.com/login"
        >
          {t('warning.paymentSystem.paymentDashboard')}
        </LinkStyled>
      </TypographyStyled>
      <Progress>
        <InfoOutlined />
      </Progress>
    </WarningContainer>
  );
};

paymentAccountWarning.propTypes = {
  info: PropTypes.object,
};

export default paymentAccountWarning;
