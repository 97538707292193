import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

// CONSTANTS
import { ROUTE_OVERVIEW, ROUTE_REDEMPTIONS } from '@/constants/routes';
import { ROLE_MERCHANDISER } from '@/constants/roles';

const PublicRoute = ({ component, type, isAuth, role, ...rest }) => {
  const redirectLink = role === ROLE_MERCHANDISER ? ROUTE_REDEMPTIONS : ROUTE_OVERVIEW;

  return (
    <Route
      {...rest}
      render={props => {
        return isAuth && type === 'auth' ? (
          <Redirect
            to={{
              pathname: redirectLink,
              state: { from: props.location },
            }}
          />
        ) : (
          <>{component}</>
        );
      }}
    />
  );
};

PublicRoute.propTypes = {
  type: PropTypes.string,
  component: PropTypes.node,
  isAuth: PropTypes.bool,
  location: PropTypes.object,
  role: PropTypes.string,
};

export default PublicRoute;
