import styled from 'styled-components';

// MATERIAL UI
import { Typography, Link } from '@material-ui/core';

// COMMON
import colors, { festival } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

export const LinkStyled = styled(Link)`
  && {
    padding-left: 3px;
    font-size: 1.4rem;
  }
`;

export const WarningContainer = styled.div`
  background: ${colors.warningBg};

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: solid 1px ${festival};
  padding: 8px 20px;

  top: 90px;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: ${breakpoints.sm}) {
    top: 65px;
  }

  @media (max-width: ${breakpoints.xs}) {
    flex-direction: column;

    top: 60px;
  }
`;

export const TypographyStyled = styled(Typography)`
  @media (max-width: ${breakpoints.xs}) {
    text-align: center;
    padding-bottom: 5px;
  }
`;

export const Progress = styled.div`
  display: flex;
  align-items: center;
  & svg {
    margin-left: 5px;
  }
`;
