import * as actionTypes from '@/store/actions/types/transactions';

const initialState = {
  list: [],
  lastTransactions: [],
  detailsTransaction: null,
  error: null,
  loading: false,
  current_page: 0,
  per_page: 25,
  total: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRANSACTIONS_START:
      return { ...state, loading: true, error: false };
    case actionTypes.TRANSACTIONS_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.TRANSACTIONS_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case actionTypes.TRANSACTIONS_GET_LAST_SUCCESS:
      return {
        ...state,
        loading: false,
        lastTransactions: action.payload.lastTransactions,
      };
    case actionTypes.GET_DETAIL_TRANSACTION_START:
      return { ...state, loading: true };
    case actionTypes.GET_DETAIL_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        detailsTransaction: action.payload.data,
      };
    case actionTypes.GET_DETAIL_TRANSACTION_FAIL:
      return { ...state, loading: false };
    case actionTypes.UPDATE_STATUS_TRANSACTION_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_STATUS_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.UPDATE_STATUS_TRANSACTION_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
