import * as actionTypes from '@/store/actions/types/admins';

export const getAdminPermissions = () => {
  return {
    type: actionTypes.ADMINS_GET_PERMISSIONS,
  };
};

export const getCountries = () => {
  return {
    type: actionTypes.ADMINS_GET_COUNTRIES,
  };
};

export const createEditAdmin = params => {
  return {
    type: actionTypes.ADMINS_CREATE_EDIT,
    payload: params,
  };
};

export const getAdminsList = params => {
  return {
    type: actionTypes.ADMINS_GET_LIST,
    payload: params,
  };
};

export const getAdminProfile = params => {
  return {
    type: actionTypes.ADMINS_GET_PROFILE,
    payload: params,
  };
};

export const deleteProfile = params => {
  return {
    type: actionTypes.ADMINS_DELETE_PROFILE,
    payload: params,
  };
};

export const changeStatusAdmin = params => {
  return {
    type: actionTypes.ADMINS_CHANGE_STATUS,
    payload: params,
  };
};
