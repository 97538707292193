import * as Yup from 'yup';

import * as validationConst from '@/constants/validation';

export default t => {
  return {
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid')),
    password: Yup.string()
      .required(t('validation.passwordRequired'))
      .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength')),
  };
};
