import * as actionTypes from '@/store/actions/types/auth';
import * as actionTypesOrganization from '@/store/actions/types/organizations';

// to avoid redirect to root route before api/me response
// interceptor will check token relevance
const checkToken = () => !!localStorage?.getItem('token');
const userRole = () => localStorage?.getItem('role');
const loginAsOrganization =
  localStorage && localStorage.getItem('login_as_organization') !== 'null'
    ? localStorage.getItem('login_as_organization')
    : null;

// TODO: rework all organization params to organization object
const initialState = {
  user: {},
  role: userRole(),
  isAuth: checkToken(),
  errors: {},
  success: {},
  loading: false,
  loadingLogo: false,
  organization_id: null,
  organization_logo: null,
  organization_name: null,
  organization_currency: 'usd',
  organization_country: '',
  organization_status: '',
  organization_privacy_policy: '',
  organization_terms_of_use: '',
  organization_tips_accepted: false,
  organization_stripe_account: '',
  organization_square_account: '',
  organization_stripe_account_name: '',
  plan_status: {},
  check_info: {},
  login_as_organization: loginAsOrganization,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return { ...state, loading: true };
    case actionTypes.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: true,
        role: action.payload.role,
        user: action.payload.user,
        external_service: action.payload.organization.external_service,
        organization_id: action.payload.organization.id,
        organization_logo: action.payload.organization.logo,
        organization_name: action.payload.organization.name,
        organization_status: action.payload.organization.status,
        organization_currency: action.payload.organization.currency,
        organization_country: action.payload.organization.country,
        organization_privacy_policy: action.payload.organization.privacy_policy,
        organization_terms_of_use: action.payload.organization.terms_of_use,
        organization_tips_accepted: Boolean(action.payload.organization.tips_accept),
        organization_stripe_account: action.payload.organization.stripe?.stripe_user_id || '',
        organization_stripe_account_name: action.payload.organization.stripe?.name || '',
        organization_square_account: action.payload.organization.square?.square_user_id || '',
        organization_square_account_name: action.payload.organization.square?.name || '',
        plan_status: action.payload.organization.plan_status,
        check_info: action.payload.check_info,
        errors: {},
      };
    case actionTypes.AUTH_FAIL:
      return {
        ...state,
        errors: {
          auth: action.payload.errorMessage,
        },
        loading: false,
      };
    case actionTypes.AUTH_LOGOUT:
      return {
        ...state,
        loading: false,
        isAuth: false,
        role: '',
        user: null,
        plan_status: null,
        login_as_organization: null,
        errors: {},
        success: {},
      };
    case actionTypes.REGISTER_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          register: false,
        },
        success: {
          ...state.success,
          register: true,
        },
      };
    case actionTypes.REGISTER_BUSINESS_FAIL:
      return {
        ...state,
        errors: {
          ...state.errors,
          register: action.payload.errorMessage,
        },
        success: {
          ...state.success,
          register: false,
        },
        loading: false,
      };
    case actionTypes.BUSINESS_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          verify: false,
        },
        success: {
          ...state.success,
          verify: true,
        },
      };
    case actionTypes.BUSINESS_VERIFY_FAIL:
      return {
        ...state,
        errors: {
          ...state.errors,
          verify: action.payload.errorMessage,
        },
        success: {
          ...state.success,
          verify: false,
        },
        loading: false,
      };
    case actionTypes.AUTH_RESOLVE:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.AUTH_UPDATE_FIRST_LOGIN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          first_login: false,
        },
      };
    case actionTypes.AUTH_UPDATE_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case actionTypes.AUTH_UPDATE_CHECK_INFO:
      return {
        ...state,
        check_info: {
          ...state.check_info,
          [action.payload.key]: true,
        },
      };
    case actionTypes.AUTH_UPDATE_ORG_LOGO:
      return {
        ...state,
        organization_logo: action.payload.logo,
      };
    case actionTypes.AUTH_UPDATE_PLAN_STATUS:
      return {
        ...state,
        plan_status: action.payload.plan_status,
      };

    case actionTypes.AUTH_LOGIN_AS_ORGANIZATION_SUCCESS:
      return {
        ...state,
        login_as_organization: action.payload,
      };

    case actionTypes.AUTH_LOGIN_AS_ADMIN_SUCCESS:
      return {
        ...state,
        login_as_organization: null,
      };

    case actionTypes.AUTH_RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.AUTH_RESEND_EMAIL_FAIL:
      return {
        ...state,
        errors: {
          ...state.errors,
          resendEmail: true,
        },
      };

    case actionTypes.ADMIN_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuth: true,
        user: action.payload.user,
        organization_id: action.payload.organization.id,
        organization_logo: action.payload.organization.logo,
        organization_name: action.payload.organization.name,
        organization_currency: action.payload.organization.currency,
        organization_country: action.payload.organization.country,
        plan_status: action.payload.organization.plan_status,
        check_info: action.payload.check_info,
        success: {
          ...state.success,
          verifyAdmin: true,
        },
        errors: {
          ...state.errors,
        },
      };

    case actionTypes.ADMIN_VERIFY_FAIL:
      return {
        ...state,
        errors: {
          ...state.errors,
          verifyAdmin: action.payload,
        },
        success: {
          ...state.success,
          verifyAdmin: false,
        },
        loading: false,
      };

    case actionTypesOrganization.UPLOAD_BUSINESS_LOGO_START:
      return { ...state, loadingLogo: true };
    case actionTypesOrganization.UPLOAD_BUSINESS_LOGO_SUCCESS:
      return {
        ...state,
        loadingLogo: false,
        organization_logo: action.payload.data.logo,
      };
    case actionTypesOrganization.UPLOAD_BUSINESS_LOGO_FAIL:
      return {
        ...state,
        loadingLogo: false,
      };

    default:
      return state;
  }
};

export default reducer;
