import * as actionTypes from '@/store/actions/types/privacyAndTerms';

const initialState = {
  privacy: '',
  terms: '',
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRIVACY_START:
      return { ...state, loading: true };
    case actionTypes.GET_PRIVACY_SUCCESS:
      return {
        ...state,
        privacy: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_PRIVACY_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.GET_TERMS_START:
      return { ...state, loading: true };
    case actionTypes.GET_TERMS_SUCCESS:
      return {
        ...state,
        terms: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_TERMS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SEND_PRIVACY_START:
      return { ...state, loading: true };
    case actionTypes.SEND_PRIVACY_SUCCESS:
      return {
        ...state,
        privacy: action.payload.data,
        loading: false,
      };
    case actionTypes.SEND_PRIVACY_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.SEND_TERMS_START:
      return { ...state, loading: true };
    case actionTypes.SEND_TERMS_SUCCESS:
      return {
        ...state,
        terms: action.payload.data,
        loading: false,
      };
    case actionTypes.SEND_TERMS_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
