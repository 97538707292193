import * as actionTypes from '@/store/actions/types/plans';

const initialState = {
  plans: [],
  loading: false,
  changePlanLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PLANS_START:
      return { ...state, loading: true };
    case actionTypes.GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_PLANS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CHANGE_PLAN_START:
      return { ...state, changePlanLoading: true };
    case actionTypes.CHANGE_PLAN_SUCCESS:
      return {
        ...state,
        changePlanLoading: false,
      };
    case actionTypes.CHANGE_PLAN_FAIL:
      return {
        ...state,
        changePlanLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
