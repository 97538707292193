import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import { TYPE_CATEGORY } from '@/constants/categories';
import getErrorMessage from '@/utility/common/errors';
import history from '@/utility/common/history';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/categories';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* getCategoriesSaga(action) {
  yield put({ type: actionTypes.CATEGORIES_START });

  const url = endpoints.GET_CATEGORIES;

  try {
    const { data } = yield axios.get(url, { params: action.payload });

    yield put({
      type: actionTypes.GET_CATEGORIES_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_CATEGORIES_FAIL,
    });
  }
}

export function* getCategoriesListSaga(action) {
  yield put({ type: actionTypes.CATEGORIES_GET_LIST_START });

  const { id, search, typeCategory, ...params } = action.payload;
  const url = search
    ? getLink(endpoints.GET_CATEGORIES_SEARCH, { id, search })
    : endpoints.GET_CATEGORIES_LIST;
  const type =
    typeCategory === 'select'
      ? actionTypes.CATEGORIES_GET_SELECT_SUCCESS
      : actionTypes.CATEGORIES_GET_LIST_SUCCESS;

  try {
    const res = yield axios.get(url, { params });
    const { data } = res;

    yield put({
      type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CATEGORIES_GET_LIST_FAIL,
    });
  }
}

export function* getPromoGroupsListSaga(action) {
  yield put({ type: actionTypes.PROMO_GROUPS_GET_LIST_START });

  const params = action.payload;
  const url = endpoints.GET_PROMO_GROUPS;

  try {
    const res = yield axios.get(url, { params });
    const { data } = res;

    yield put({
      type: actionTypes.PROMO_GROUPS_GET_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.PROMO_GROUPS_GET_LIST_FAIL,
    });
  }
}

export function* getPromoGroupSaga(action) {
  yield put({ type: actionTypes.GET_PROMO_GROUP_DETAIL_START });

  const { promoGroupId } = action.payload;
  const url = getLink(endpoints.GET_PROMO_GROUP_DETAILS, { promoGroupId });

  try {
    const res = yield axios.get(url);
    const { data } = res;

    yield put({
      type: actionTypes.GET_PROMO_GROUP_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_PROMO_GROUP_DETAIL_FAIL,
    });
  }
}

export function* createPromoGroupSaga(action) {
  yield put({ type: actionTypes.CREATE_PROMO_GROUP_START });

  const { requestData, promoGroupId } = action.payload;
  const isUpdate = !!promoGroupId;
  const url = isUpdate
    ? getLink(endpoints.UPDATE_PROMO_GROUP_DETAILS, { promoGroupId })
    : endpoints.CREATE_PROMO_GROUPS;
  const catImageFormData = new FormData();
  const uploadedFiles = {};
  let isNeedUpload = false;
  let images = [];
  let imageNumber = 0;

  try {
    for (let i = 0; i < requestData.categories.length; i++) {
      const category = requestData.categories[i];
      if (!category.image[0].id) {
        uploadedFiles[category.value] = imageNumber;
        imageNumber++;
        catImageFormData.append('images[]', category.image[0]);
        isNeedUpload = true;
      }
    }
    catImageFormData.append('type', TYPE_CATEGORY);

    if (isNeedUpload) {
      const { data } = yield axios.post(endpoints.UPLOAD_IMAGES, catImageFormData, {
        'Content-Type': 'multipart/form-data',
      });
      images = data.images;
    }

    requestData.categories = requestData.categories.map(category => {
      const logo_image_id =
        uploadedFiles[category.value] !== undefined
          ? images[uploadedFiles[category.value]].id
          : category.image[0].id;

      return {
        category_id: category.value,
        logo_image_id,
      };
    });

    const response = yield isUpdate ? axios.put(url, requestData) : axios.post(url, requestData);
    const { data } = response;

    yield put({
      type: actionTypes.CREATE_PROMO_GROUP_SUCCESS,
      payload: data,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: isUpdate ? t('notification.editPromoGroup') : t('notification.promoGroup'),
        options: {
          variant: 'success',
        },
      },
    });

    yield call(history.push, '/categories#promoGroups');
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CREATE_PROMO_GROUP_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* deletePromoGroupSaga(action) {
  yield put({ type: actionTypes.DELETE_PROMO_GROUP_START });

  const { promoGroupId } = action.payload;
  const url = getLink(endpoints.DELETE_PROMO_GROUP, { promoGroupId });

  try {
    yield axios.delete(url);
    yield put({
      type: actionTypes.DELETE_CATEGORY_SUCCESS,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.deletePromoGroupSuccess'),
        options: {
          variant: 'success',
        },
      },
    });

    yield call(history.push, '/categories#groups');
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.DELETE_PROMO_GROUP_FAIL,
    });
  }
}

export function* createCategorySaga(action) {
  yield put({ type: actionTypes.CREATE_CATEGORY_START });

  const { requestData, categoryId, type } = action.payload;
  const isCoverImage = requestData.cover_image_id !== null;
  const isCategoryIcon = requestData.logo_image_id !== null;

  let coverFormData;
  let iconFormData;

  const url = endpoints.CREATE_CATEGORY;

  if (isCoverImage) {
    coverFormData = new FormData();
    coverFormData.append('images[]', requestData.cover_image_id[0]);
    coverFormData.append('type', TYPE_CATEGORY);
  }

  if (isCategoryIcon) {
    iconFormData = new FormData();
    iconFormData.append('images[]', requestData.logo_image_id[0]);
    iconFormData.append('type', TYPE_CATEGORY);
  }

  try {
    if (isCoverImage) {
      const {
        data: { images },
      } = yield axios.post(endpoints.UPLOAD_IMAGES, coverFormData, {
        'Content-Type': 'multipart/form-data',
      });

      requestData.cover_image_id = yield images[0].id;
    }

    if (isCategoryIcon) {
      const {
        data: { images },
      } = yield axios.post(endpoints.UPLOAD_IMAGES, iconFormData, {
        'Content-Type': 'multipart/form-data',
      });

      requestData.logo_image_id = yield images[0].id;
    }

    const response = yield axios.post(url, requestData);
    const { data } = response;
    let message;
    let path;

    if (type === TYPE_CATEGORY) {
      message = t('notification.createCategory');
      path = '/categories';
    } else {
      message = t('notification.createSubCategory');
      path = `/categories/${categoryId}`;
    }

    yield put({
      type: actionTypes.CREATE_CATEGORY_SUCCESS,
      payload: data,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message,
        options: {
          variant: 'success',
        },
      },
    });

    yield call(history.push, path);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CREATE_CATEGORY_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* updateCategorySaga(action) {
  yield put({ type: actionTypes.UPDATE_CATEGORY_START });

  const { requestData, categoryId, type } = action.payload;
  const url = getLink(endpoints.EDIT_CATEGORY, { categoryId });
  const isCoverImage = requestData.cover_image_id !== null;
  const isCategoryIcon = requestData.logo_image_id !== null;
  let coverFormData;
  let iconFormData;

  if (isCoverImage) {
    if (requestData.cover_image_id[0].type) {
      coverFormData = new FormData();
      coverFormData.append('images[]', requestData.cover_image_id[0]);
      coverFormData.append('type', TYPE_CATEGORY);
    } else {
      requestData.cover_image_id = requestData.cover_image_id[0].id;
    }
  }

  if (isCategoryIcon) {
    if (requestData.logo_image_id[0].type) {
      iconFormData = new FormData();
      iconFormData.append('images[]', requestData.logo_image_id[0]);
      iconFormData.append('type', TYPE_CATEGORY);
    } else {
      requestData.logo_image_id = requestData.logo_image_id[0].id;
    }
  }

  try {
    if (coverFormData) {
      const {
        data: { images },
      } = yield axios.post(endpoints.UPLOAD_IMAGES, coverFormData, {
        'Content-Type': 'multipart/form-data',
      });

      requestData.cover_image_id = yield images[0].id;
    }

    if (iconFormData) {
      const {
        data: { images },
      } = yield axios.post(endpoints.UPLOAD_IMAGES, iconFormData, {
        'Content-Type': 'multipart/form-data',
      });

      requestData.logo_image_id = yield images[0].id;
    }

    const response = yield axios.put(url, requestData);
    const { data } = response;
    let message;
    let path;

    if (type === TYPE_CATEGORY) {
      message = t('notification.editCategory');
      path = '/categories';
    } else {
      message = t('notification.editSubCategory');
      path = `/categories/${requestData.parent_id}`;
    }

    yield put({
      type: actionTypes.UPDATE_CATEGORY_SUCCESS,
      payload: data,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message,
        options: {
          variant: 'success',
        },
      },
    });

    yield call(history.push, path);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_CATEGORY_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* getCategoryDetailSaga(action) {
  yield put({ type: actionTypes.GET_CATEGORY_DETAIL_START });

  const { categoryId } = action.payload;
  const url = getLink(endpoints.GET_CATEGORY, { categoryId });

  try {
    const res = yield axios.get(url, categoryId);
    const { data } = res;

    yield put({
      type: actionTypes.GET_CATEGORY_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_CATEGORY_DETAIL_FAIL,
    });
  }
}

export function* deleteCategorySaga(action) {
  yield put({ type: actionTypes.DELETE_CATEGORY_START });

  const { categoryId, parentId, type } = action.payload;
  const url = getLink(endpoints.DELETE_CATEGORY, { categoryId });

  try {
    yield axios.delete(url);
    yield put({
      type: actionTypes.DELETE_CATEGORY_SUCCESS,
    });

    let message;
    let path;

    if (type === TYPE_CATEGORY) {
      message = t('notification.deleteCategorySuccess');
      path = '/categories';
    } else {
      message = t('notification.deleteSubCategorySuccess');
      path = `/categories/${parentId}`;
    }

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message,
        options: {
          variant: 'success',
        },
      },
    });

    yield call(history.push, path);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.DELETE_CATEGORY_FAIL,
    });
  }
}

export function* getSubCategoriesListSaga(action) {
  yield put({ type: actionTypes.SUBCATEGORIES_GET_LIST_START });

  const { categoryId, search, ...params } = action.payload;
  const url = search
    ? getLink(endpoints.GET_SUBCATEGORIES_SEARCH, { categoryId, search })
    : getLink(endpoints.GET_SUBCATEGORIES_LIST, { categoryId });

  try {
    const res = yield axios.get(url, { params });
    const { data } = res;

    yield put({
      type: actionTypes.SUBCATEGORIES_GET_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.SUBCATEGORIES_GET_LIST_FAIL,
    });
  }
}
