export const ADMINS_GET_PERMISSIONS_START = 'ADMINS_GET_PERMISSIONS_START';
export const ADMINS_GET_PERMISSIONS = 'ADMINS_GET_PERMISSIONS';
export const ADMINS_GET_PERMISSIONS_SUCCESS = 'ADMINS_GET_PERMISSIONS_SUCCESS';
export const ADMINS_GET_PERMISSIONS_FAIL = 'ADMINS_GET_PERMISSIONS_FAIL';

export const ADMINS_CREATE_EDIT_START = 'ADMINS_CREATE_EDIT_START';
export const ADMINS_CREATE_EDIT = 'ADMINS_CREATE_EDIT';
export const ADMINS_CREATE_EDIT_SUCCESS = 'ADMINS_CREATE_EDIT_SUCCESS';
export const ADMINS_CREATE_EDIT_FAIL = 'ADMINS_CREATE_EDIT_FAIL';

export const ADMINS_GET_LIST_START = 'ADMINS_GET_LIST_START';
export const ADMINS_GET_LIST = 'ADMINS_GET_LIST';
export const ADMINS_GET_LIST_SUCCESS = 'ADMINS_GET_LIST_SUCCESS';
export const ADMINS_GET_LIST_FAIL = 'ADMINS_GET_LIST_FAIL';

export const ADMINS_GET_PROFILE_START = 'ADMINS_GET_PROFILE_START';
export const ADMINS_GET_PROFILE = 'ADMINS_GET_PROFILE';
export const ADMINS_GET_PROFILE_SUCCESS = 'ADMINS_GET_PROFILE_SUCCESS';
export const ADMINS_GET_PROFILE_FAIL = 'ADMINS_GET_PROFILE_FAIL';

export const ADMINS_DELETE_PROFILE_START = 'ADMINS_DELETE_PROFILE_START';
export const ADMINS_DELETE_PROFILE = 'ADMINS_DELETE_PROFILE';
export const ADMINS_DELETE_PROFILE_SUCCESS = 'ADMINS_DELETE_PROFILE_SUCCESS';
export const ADMINS_DELETE_PROFILE_FAIL = 'ADMINS_DELETE_PROFILE_FAIL';

export const ADMINS_CHANGE_STATUS_START = 'ADMINS_CHANGE_STATUS_START';
export const ADMINS_CHANGE_STATUS = 'ADMINS_CHANGE_STATUS';
export const ADMINS_CHANGE_STATUS_SUCCESS = 'ADMINS_CHANGE_STATUS_SUCCESS';
export const ADMINS_CHANGE_STATUS_FAIL = 'ADMINS_CHANGE_STATUS_FAIL';

export const ADMINS_GET_COUNTRIES_START = 'ADMINS_GET_COUNTRIES_START';
export const ADMINS_GET_COUNTRIES = 'ADMINS_GET_COUNTRIES';
export const ADMINS_GET_COUNTRIES_SUCCESS = 'ADMINS_GET_COUNTRIES_SUCCESS';
export const ADMINS_GET_COUNTRIES_FAIL = 'ADMINS_GET_COUNTRIES_FAIL';
