import { components as baseComponents } from 'react-select';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import colors from '@/constants/colors';
import React from 'react';

const DropdownIndicator = props => {
  return (
    <baseComponents.DropdownIndicator {...props}>
      <ArrowDropDown style={{ fontSize: 26, color: colors.primary }} />
    </baseComponents.DropdownIndicator>
  );
};

export default DropdownIndicator;
