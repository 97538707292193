// GIFTS CARDS actions
export const GIFTS_CARDS = 'GIFTS_CARDS';
export const GIFTS_CARDS_START = 'GIFTS_START';
export const GIFTS_CARDS_SUCCESS = 'GIFTS_CARDS_SUCCESS';
export const GIFTS_CARDS_FAIL = 'GIFTS_CARDS_FAIL';

// GIFTS CHART actions
export const GIFTS_CHART = 'GIFTS_CHART';
export const GIFTS_CHART_START = 'GIFTS_CHART_START';
export const GIFTS_CHART_SUCCESS = 'GIFTS_CHART_SUCCESS';
export const GIFTS_CHART_FAIL = 'GIFTS_CHART_FAIL';
