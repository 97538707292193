import * as actionTypes from './types/analytics';

export const getBusinessDetailsAnalytics = params => {
  return {
    type: actionTypes.ANALYTICS_GET_BUSINESS_DETAILS,
    payload: {
      params,
    },
  };
};

export const getGeneralAnalytics = params => {
  return {
    type: actionTypes.ANALYTICS_GENERAL,
    payload: {
      params,
    },
  };
};

export const getUsersAnalytics = params => {
  return {
    type: actionTypes.ANALYTICS_USERS,
    payload: {
      params,
    },
  };
};

export const getGiftsAnalytics = params => {
  return {
    type: actionTypes.ANALYTICS_GIFTS,
    payload: {
      params,
    },
  };
};

export const getGiftSum = params => {
  return {
    type: actionTypes.ANALYTICS_GET_SUM,
    payload: {
      params,
    },
  };
};

export const getBusinessBrands = params => {
  return {
    type: actionTypes.ANALYTICS_GET_BRANDS,
    payload: {
      params,
    },
  };
};

export const getTopBrands = params => {
  return {
    type: actionTypes.GET_TOP_BRANDS,
    payload: {
      params,
    },
  };
};
