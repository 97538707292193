import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { t } from '@/locale/i18n';
import { MAX_IMAGE_SIZE } from '@/constants/validation';
import DropContainer from './styles';

const dropZone = props => {
  const {
    onDropAccepted,
    children,
    name,
    rootProps,
    inputProps,
    onFileDialogCancel,
    multiple,
    accept,
    limit,
    error,
    maxSize,
    width,
    height,
  } = props;
  const inputName = name ? { name } : {};
  const { rejectedFiles, getRootProps, getInputProps } = useDropzone({
    multiple,
    maxSize: maxSize || MAX_IMAGE_SIZE,
    accept,
    onFileDialogCancel,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > limit) {
        acceptedFiles.splice(limit);
      }
      onDropAccepted(
        acceptedFiles.map(file => {
          return Object.assign(file, {
            src: URL.createObjectURL(file),
          });
        }),
      );
    },
  });

  return (
    <DropContainer
      {...getRootProps({
        className: `dropzone ${rejectedFiles.length || !!error ? 'dropzone--error' : ''}`,
        ...rootProps,
      })}
      width={width}
      height={height}
    >
      <input {...getInputProps({ ...inputName, ...inputProps })} />
      {children}
      {rejectedFiles.length ? (
        <p className="dropzone__error">{t('validation.maxUploadImageSize')}</p>
      ) : error ? (
        <p className="dropzone__error">{error}</p>
      ) : null}
    </DropContainer>
  );
};

dropZone.defaultProps = {
  accept: 'image/*',
  name: '',
  error: '',
  rootProps: {},
  inputProps: {},
  multiple: true,
  limit: 5,
};

dropZone.propTypes = {
  error: PropTypes.string,
  onFileDialogCancel: PropTypes.func,
  accept: PropTypes.string,
  name: PropTypes.string,
  rootProps: PropTypes.object,
  inputProps: PropTypes.object,
  multiple: PropTypes.bool,
  limit: PropTypes.number,
  maxSize: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onDropAccepted: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default dropZone;
