import * as actionTypes from './types/gifts';

// organizationId, dateRange, type, role, country
export const getTotalChart = params => {
  return {
    type: actionTypes.GIFTS_CHART,
    payload: params,
  };
};

export const getTotalStatisticCards = (organizationId, date_range, role) => {
  return {
    type: actionTypes.GIFTS_CARDS,
    payload: {
      organizationId,
      date_range,
      role,
    },
  };
};
