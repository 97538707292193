import * as actionTypes from './types/directGift';

export const sendDirectGift = params => {
  return {
    type: actionTypes.DIRECT_GIFT_SEND,
    payload: params,
  };
};

export default sendDirectGift;
