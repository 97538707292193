import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/employees';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { t } from '@/locale/i18n';

export function* getEmployeesListSaga(action) {
  yield put({ type: actionTypes.EMPLOYEES_START });

  const { organizationId: organizId, locationId, ...params } = action.payload;
  const url = getLink(endpoints.GET_EMPLOYEES_LIST, { id: organizId, locationId });

  try {
    const res = yield axios.get(url, { params });
    const { data } = res;
    yield put({
      type: actionTypes.EMPLOYEES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.EMPLOYEES_STOP,
    });
  }
}

export function* deleteEmployeeSaga(action) {
  yield put({ type: actionTypes.EMPLOYEES_START });

  const { organizationId, locationId, user_id } = action.payload;
  const url = getLink(endpoints.DELETE_EMPLOYEES, { id: organizationId, locationId, user_id });

  try {
    const res = yield axios.delete(url);
    yield call(getEmployeesListSaga, {
      payload: { organizationId, locationId, page: 0, per_page: 5 },
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: res.data.message,
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.EMPLOYEES_STOP,
    });
  }
}

export function* editEmployeeSaga(action) {
  yield put({ type: actionTypes.EMPLOYEES_START });

  const { employee, organizationId, locationId, user_id, params } = action.payload;
  const url = getLink(endpoints.EDIT_EMPLOYEES, { id: organizationId, locationId, user_id });

  try {
    yield axios.put(url, { ...employee });
    yield call(getEmployeesListSaga, {
      payload: { organizationId, locationId, ...params },
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.editEmployeeSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.EMPLOYEES_STOP,
    });
  }
}
