// Users actions

export const USERS_GET_LIST_START = 'USERS_GET_LIST_START';
export const USERS_GET_LIST = 'USERS_GET_LIST';
export const USERS_GET_LIST_SUCCESS = 'USERS_GET_LIST_SUCCESS';
export const USERS_GET_LIST_FAIL = 'USERS_GET_LIST_FAIL';

export const USERS_GET_DETAIL_START = 'USERS_GET_DETAIL_START';
export const USERS_GET_DETAIL = 'USERS_GET_DETAIL';
export const USERS_GET_DETAIL_SUCCESS = 'USERS_GET_DETAIL_SUCCESS';
export const USERS_GET_DETAIL_FAIL = 'USERS_GET_DETAIL_FAIL';

export const USERS_GET_INVITED_LIST_START = 'USERS_GET_INVITED_LIST_START';
export const USERS_GET_INVITED_LIST = 'USERS_GET_INVITED_LIST';
export const USERS_GET_INVITED_LIST_SUCCESS = 'USERS_GET_INVITED_LIST_SUCCESS';
export const USERS_GET_INVITED_LIST_FAIL = 'USERS_GET_INVITED_LIST_FAIL';

export const GET_BLOCK = 'GET_BLOCK';
export const GET_BLOCK_START = 'GET_BLOCK_START';
export const GET_BLOCK_SUCCESS = 'GET_BLOCK_SUCCESS';
export const GET_BLOCK_FAIL = 'GET_BLOCK_FAIL';

export const USER_DOWNLOAD_QRCODE = 'USER_DOWNLOAD_QRCODE';
export const USER_DOWNLOAD_QRCODE_START = 'USER_DOWNLOAD_QRCODE_START';
export const USER_DOWNLOAD_QRCODE_SUCCESS = 'USER_DOWNLOAD_QRCODE_SUCCESS';
export const USER_DOWNLOAD_QRCODE_FAIL = 'USER_DOWNLOAD_QRCODE_FAIL';

export const USER_GENERATE_QRCODE = 'USER_GENERATE_QRCODE';
export const USER_GENERATE_QRCODE_START = 'USER_GENERATE_QRCODE_START';
export const USER_GENERATE_QRCODE_SUCCESS = 'USER_GENERATE_QRCODE_SUCCESS';
export const USER_GENERATE_QRCODE_FAIL = 'USER_GENERATE_QRCODE_FAIL';

export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_START = 'VERIFY_USER_START';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAIL = 'VERIFY_USER_FAIL';

export const GET_ONLINE_USERS_COUNT = 'GET_ONLINE_USERS_COUNT';
export const GET_ONLINE_USERS_COUNT_START = 'GET_ONLINE_USERS_COUNT_START';
export const GET_ONLINE_USERS_COUNT_SUCCESS = 'GET_ONLINE_USERS_COUNT_SUCCESS';
export const GET_ONLINE_USERS_COUNT_FAIL = 'GET_ONLINE_USERS_COUNT_FAIL';

export const CHANGE_ALTRUUS_BALANCE_STATUS = 'CHANGE_ALTRUUS_BALANCE_STATUS';
export const CHANGE_ALTRUUS_BALANCE_STATUS_START = 'CHANGE_ALTRUUS_BALANCE_STATUS_START';
export const CHANGE_ALTRUUS_BALANCE_STATUS_SUCCESS = 'CHANGE_ALTRUUS_BALANCE_STATUS_SUCCESS';
export const CHANGE_ALTRUUS_BALANCE_STATUS_FAIL = 'CHANGE_ALTRUUS_BALANCE_STATUS_FAIL';

export const NOTIFY_USERS = 'NOTIFY_USERS';
export const NOTIFY_USERS_START = 'NOTIFY_USERS_START';
export const NOTIFY_USERS_SUCCESS = 'NOTIFY_USERS_SUCCESS';
export const NOTIFY_USERS_FAIL = 'NOTIFY_USERS_FAIL';
