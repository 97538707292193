import * as Yup from 'yup';

import * as validationConst from '@/constants/validation';
import { phoneRegEx } from '@/constants/regexp';

export default (t, data) => {
  const Scheme = {
    name: Yup.string()
      .required(t('validation.fullNameRequired'))
      .min(validationConst.MIN_NAME_LENGTH, t('validation.nameLength')),
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid')),
    phone: Yup.string()
      .required(t('validation.phoneRequired'))
      .matches(phoneRegEx, {
        excludeEmptyString: true,
        message: t('validation.phoneNotValid'),
      }),
  };

  if (data.location) {
    Scheme.location = Yup.string().required(t('validation.locationRequired'));
  }

  return Scheme;
};
