export const ANALYTICS_GET_BUSINESS_DETAILS = 'ANALYTICS_GET_BUSINESS_DETAILS';
export const ANALYTICS_GET_BUSINESS_DETAILS_START = 'ANALYTICS_GET_BUSINESS_DETAILS_START';
export const ANALYTICS_GET_BUSINESS_DETAILS_SUCCESS = 'ANALYTICS_GET_BUSINESS_DETAILS_SUCCESS';
export const ANALYTICS_GET_BUSINESS_DETAILS_FAIL = 'ANALYTICS_GET_BUSINESS_DETAILS_FAIL';

export const GET_TOP_BRANDS = 'GET_TOP_BRANDS';
export const GET_TOP_BRANDS_START = 'GET_TOP_BRANDS_START';
export const GET_TOP_BRANDS_SUCCESS = 'GET_TOP_BRANDS_SUCCESS';
export const GET_TOP_BRANDS_FAIL = 'GET_TOP_BRANDS_FAIL';

export const ANALYTICS_GENERAL = 'ANALYTICS_GENERAL';
export const ANALYTICS_GENERAL_START = 'ANALYTICS_GENERAL_START';
export const ANALYTICS_GENERAL_SUCCESS = 'ANALYTICS_GENERAL_SUCCESS';
export const ANALYTICS_GENERAL_FAIL = 'ANALYTICS_GENERAL_FAIL';

export const ANALYTICS_USERS = 'ANALYTICS_USERS';
export const ANALYTICS_USERS_START = 'ANALYTICS_USERS_START';
export const ANALYTICS_USERS_SUCCESS = 'ANALYTICS_USERS_SUCCESS';
export const ANALYTICS_USERS_FAIL = 'ANALYTICS_USERS_FAIL';

export const ANALYTICS_GIFTS = 'ANALYTICS_GIFTS';
export const ANALYTICS_GIFTS_START = 'ANALYTICS_GIFTS_START';
export const ANALYTICS_GIFTS_SUCCESS = 'ANALYTICS_GIFTS_SUCCESS';
export const ANALYTICS_GIFTS_FAIL = 'ANALYTICS_GIFTS_FAIL';

export const ANALYTICS_GET_SUM = 'ANALYTICS_GET_SUM';
export const ANALYTICS_GET_SUM_START = 'ANALYTICS_GET_SUM_START';
export const ANALYTICS_GET_SUM_SUCCESS = 'ANALYTICS_GET_SUM_SUCCESS';
export const ANALYTICS_GET_SUM_FAIL = 'ANALYTICS_GET_SUM_FAIL';

export const ANALYTICS_GET_BRANDS = 'ANALYTICS_GET_BRANDS';
export const ANALYTICS_GET_BRANDS_START = 'ANALYTICS_GET_BRANDS_START';
export const ANALYTICS_GET_BRANDS_SUCCESS = 'ANALYTICS_GET_BRANDS_SUCCESS';
export const ANALYTICS_GET_BRANDS_FAIL = 'ANALYTICS_GET_BRANDS_FAIL';
