import * as actionTypes from '@/store/actions/types/organizations';
import * as actionTypesRedemptions from '@/store/actions/types/redemptions';

const initialState = {
  // Organizations admin
  templatesList: [],
  organizationsList: [],
  current_page: 0,
  per_page: 25,
  total: 0,
  // User organization
  userOrganizationId: null,
  // Organization
  data: {},
  privacy_policy: '',
  terms_of_use: '',
  balanceChart: [],
  totalProfitCards: null,
  errors: {},
  loading: {
    redemptions: true,
    templates: true,
    admin: false,
    user: true,
    data: false,
    update: false,
    balanceChart: true,
    totalProfitCards: true,
    organization: false,
    businessLogo: false,
  },
};

const getOrganizationsEmails = organization => {
  const email = organization?.email;
  if (!email) {
    return [];
  }

  return email.replace(/\s+/g, '').split(',');
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ORGANIZATIONS_GET_START:
      return {
        ...state,
        loading: { ...state.loading, data: true },
        errors: { ...state.errors, data: null },
      };
    case actionTypes.ORGANIZATIONS_GET_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        data: { ...action.payload.data, emails: getOrganizationsEmails(action.payload.data) },
      };
    case actionTypes.ORGANIZATIONS_GET_FAIL:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        errors: {
          ...state.errors,
          data: action.payload.errorMessage,
        },
      };
    case actionTypes.ORGANIZATIONS_PUT_START:
      return {
        ...state,
        loading: { ...state.loading, update: true },
        errors: { ...state.errors, update: null },
      };
    case actionTypes.ORGANIZATIONS_PUT_INFO_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, update: false },
        data: { ...action.payload.data, emails: getOrganizationsEmails(action.payload.data) },
      };
    case actionTypes.ORGANIZATIONS_PUT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, update: false },
        errors: {
          ...state.errors,
          update: action.payload.errorMessage,
        },
      };
    case actionTypes.ORGANIZATIONS_BALANCE_CHART_START:
      return {
        ...state,
        loading: { ...state.loading, balanceChart: true },
        errors: { ...state.errors, balanceChart: null },
      };
    case actionTypes.ORGANIZATIONS_BALANCE_CHART_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, balanceChart: false },
        balanceChart: action.payload.data,
      };
    case actionTypes.ORGANIZATIONS_BALANCE_CHART_FAIL:
      return {
        ...state,
        loading: {
          ...state.loading,
          balanceChart: false,
        },
        errors: {
          ...state.errors,
          balanceChart: action.payload.errorMessage,
        },
      };
    case actionTypes.ORGANIZATIONS_BALANCE_CARDS_START:
      return {
        ...state,
        loading: { ...state.loading, totalProfitCards: true },
        errors: { ...state.errors, totalProfitCards: null },
      };
    case actionTypes.ORGANIZATIONS_BALANCE_CARDS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, totalProfitCards: false },
        totalProfitCards: action.payload.data,
      };
    case actionTypes.ORGANIZATIONS_BALANCE_CARDS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, totalProfitCards: false },
        errors: {
          ...state.errors,
          totalProfitCards: action.payload.errorMessage,
        },
      };
    // Organizations admin
    case actionTypes.GET_ORGANIZATIONS_START:
      return { ...state, loading: { ...state.loading, admin: true }, data: {} };
    case actionTypes.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, admin: false },
        organizationsList: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case actionTypes.GET_ORGANIZATIONS_SELECT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, admin: false },
        organizationsList: action.payload,
      };
    case actionTypes.GET_ORGANIZATIONS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, admin: false },
      };
    case actionTypes.GET_ARCHIVE_START:
      return {
        ...state,
        loading: { ...state.loading, data: true },
      };
    case actionTypes.GET_ARCHIVE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        data: { ...action.payload },
      };
    case actionTypes.GET_ARCHIVE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, data: false },
      };
    case actionTypes.CREATE_ORGANIZATION_START:
      return { ...state, loading: { ...state.loading, user: true } };
    case actionTypes.CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        userOrganizationId: action.payload,
        loading: { ...state.loading, user: false },
      };
    case actionTypes.CREATE_ORGANIZATION_FAIL:
      return { ...state, loading: { ...state.loading, user: false } };
    case actionTypes.SEND_INVITATION_START:
      return { ...state, loading: { ...state.loading, organization: true } };
    case actionTypes.SEND_INVITATION_SUCCESS:
      return {
        ...state,
        userOrganizationId: action.payload,
        loading: { ...state.loading, organization: false },
      };
    case actionTypes.SEND_INVITATION_FAIL:
      return { ...state, loading: { ...state.loading, organization: false } };
    case actionTypes.GET_TEMPLATES_START:
      return {
        ...state,
        loading: { ...state.loading, templates: true },
      };
    case actionTypes.GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, templates: false },
        templatesList: action.payload,
      };
    case actionTypes.GET_TEMPLATES_FAIL:
      return {
        ...state,
        loading: { ...state.loading, templates: false },
      };

    case actionTypesRedemptions.GENERATE_REDEMPTION_ORGANIZ_CODE_START:
      return { ...state, loading: { ...state.loading, redemptions: true } };
    case actionTypesRedemptions.GENERATE_REDEMPTION_ORGANIZ_CODE_SUCCESS:
      return {
        ...state,
        data: { ...state.data, redemption_code: action.payload },
        loading: { ...state.loading, redemptions: false },
      };
    case actionTypesRedemptions.GENERATE_REDEMPTION_ORGANIZ_CODE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, redemptions: false },
      };

    default:
      return state;
  }
};

export default reducer;
