import * as Yup from 'yup';
import * as validationConst from '@/constants/validation';

export default t => {
  return {
    password: Yup.string()
      .required(t('validation.passwordRequired'))
      .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength')),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validation.passwordDontMatch'))
      .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength'))
      .required(t('validation.passwordRequired')),
  };
};
