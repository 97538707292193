import * as actionTypes from './types/categories';

export const getCategories = params => {
  return {
    type: actionTypes.GET_CATEGORIES,
    payload: params,
  };
};

export const getCategoriesList = params => {
  return {
    type: actionTypes.CATEGORIES_GET_LIST,
    payload: params,
  };
};

export const createCategory = data => {
  return {
    type: actionTypes.CREATE_CATEGORY,
    payload: data,
  };
};

export const updateCategory = params => {
  return {
    type: actionTypes.UPDATE_CATEGORY,
    payload: params,
  };
};

export const getCategory = params => {
  return {
    type: actionTypes.GET_CATEGORY_DETAIL,
    payload: params,
  };
};

export const deleteCategory = params => {
  return {
    type: actionTypes.DELETE_CATEGORY,
    payload: params,
  };
};

export const getSubCategoriesList = params => {
  return {
    type: actionTypes.SUBCATEGORIES_GET_LIST,
    payload: params,
  };
};

export const createPromoGroup = data => {
  return {
    type: actionTypes.CREATE_PROMO_GROUP,
    payload: data,
  };
};

export const updatePromoGroup = params => {
  return {
    type: actionTypes.UPDATE_PROMO_GROUP,
    payload: params,
  };
};

export const getPromoGroup = params => {
  return {
    type: actionTypes.GET_PROMO_GROUP_DETAIL,
    payload: params,
  };
};

export const deletePromoGroup = params => {
  return {
    type: actionTypes.DELETE_PROMO_GROUP,
    payload: params,
  };
};

export const getPromoGroupsList = params => {
  return {
    type: actionTypes.PROMO_GROUPS_GET_LIST,
    payload: params,
  };
};
