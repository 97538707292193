import * as actionTypes from './types/support';

export const sendSupport = (data, formik) => {
  return {
    type: actionTypes.SUPPORT_SEND,
    payload: { data, formik },
  };
};

export default sendSupport;
