import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/language';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* setLanguageSaga(action) {
  const { userId, language } = action.payload;
  yield put({ type: actionTypes.SET_LANGUAGE_START });
  const url = getLink(endpoints.SET_LANGUAGE, { userId });

  try {
    yield axios.post(url, { language });

    yield put({
      type: actionTypes.SET_LANGUAGE_SUCCESS,
    });

    window.location.reload();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });

    yield put({
      type: actionTypes.SET_LANGUAGE_FAIL,
    });
  }
}

export function* getEmailLanguageSaga(action) {
  const { organizationId } = action.payload;
  yield put({ type: actionTypes.GET_EMAIL_LANG_START });
  const url = getLink(endpoints.GET_EMAIL_LANGUAGE, { organizationId });

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_EMAIL_LANG_SUCCESS,
      payload: {
        email_lang: data.email_lang,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });

    yield put({
      type: actionTypes.GET_EMAIL_LANG_FAIL,
    });
  }
}
