import * as Yup from 'yup';

export default t => {
  return {
    organization_email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid')),
    business_name: Yup.string().required(t('validation.businessNameRequired')),
    location: Yup.string().required(t('validation.locationRequired')),
  };
};
