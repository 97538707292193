import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

// MATERIAL UI
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';

// COMMON
import { t } from '@/locale/i18n';
import colors from '@/constants/colors';
import * as actions from '@/store/actions';

const styles = createStyles({
  btn: {
    color: colors.outlinedPrimaryBtnText,
    fontSize: '1.2rem',
    lineHeight: '2rem',
    padding: '1px 5px',
  },
});

class SnackbarNotify extends Component {
  displayed = [];

  shouldComponentUpdate({ notifications: newSnacks = [] }) {
    const { notifications: currentSnacks } = this.props;
    let notExists = false;

    for (let i = 0; i < newSnacks.length; i += 1) {
      /* eslint-disable */
      if (notExists) continue;
      /* eslint-enable */
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [], enqueueSnackbar, popNotification } = this.props;

    notifications.forEach(notification => {
      if (this.displayed.includes(notification.key)) return;
      enqueueSnackbar(notification.message, {
        ...notification.options,
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        action: this.closeAction,
      });
      this.storeDisplayed(notification.key);
      popNotification(notification.key);
    });
  }

  closeAction = key => {
    const { closeSnackbar, classes } = this.props;
    return (
      <Button
        classes={{ root: classes.btn }}
        color="primary"
        variant="text"
        onClick={() => closeSnackbar(key)}
      >
        {t('buttons.close')}
      </Button>
    );
  };

  storeDisplayed = id => {
    this.displayed = [...this.displayed, id];
  };

  render() {
    return <></>;
  }
}

SnackbarNotify.propTypes = {
  notifications: PropTypes.array,
  enqueueSnackbar: PropTypes.func,
  popNotification: PropTypes.func,
  closeSnackbar: PropTypes.func,
  classes: PropTypes.object,
};

const mapStateToProps = ({ notifications }) => ({
  notifications: notifications.list,
});

const mapDispatchToProps = dispatch => {
  return {
    popNotification: data => dispatch(actions.popNotification(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(withStyles(styles)(SnackbarNotify)));
