import axios from 'axios';
import { all, put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import * as actionTypes from '@/store/actions/types/analytics';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import {
  parsingActiveUsersData,
  parsingBusinessRegistrations,
  parsingEmotionsData,
  parsingEventsCountData,
  parsingPeriod,
  parsingScreenTimeData,
  parsingScreenViewData,
  parsingSearchesData,
  parsingTopEventsData,
} from '@/utility/analytics';
import moment from 'moment';
import * as highchartsConst from '@/constants/highcharts';

function* handleError(error, action) {
  yield put({
    type: PUSH_NOTIFY,
    payload: {
      message: getErrorMessage(error),
      options: {
        variant: 'error',
      },
    },
  });
  yield put({
    type: action,
  });
}

function* getCountOfEvents(params) {
  try {
    const allCounts = yield axios.get(endpoints.GET_ANALYTIC_COUNT, {
      params,
    });
    return parsingEventsCountData(allCounts.data);
  } catch (e) {
    return {};
  }
}

export function* getGiftsBrandListSaga(action) {
  const { params } = action.payload;
  yield put({ type: actionTypes.ANALYTICS_GET_BRANDS_START });
  try {
    const { data } = yield axios.get(endpoints.GET_GIFT_BRAND_LIST, {
      params,
    });
    yield put({
      type: actionTypes.ANALYTICS_GET_BRANDS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({ type: actionTypes.ANALYTICS_GET_BRANDS_FAIL });
  }
}

export function* getGiftSumListSaga(action) {
  const { params } = action.payload;
  yield put({ type: actionTypes.ANALYTICS_GET_SUM_START });

  const updateParams = yield parsingPeriod({ params });

  try {
    const { data } = yield axios.get(endpoints.GET_GIFT_SUM_LIST, {
      params: updateParams,
    });
    yield put({
      type: actionTypes.ANALYTICS_GET_SUM_SUCCESS,
      payload: data,
    });
    return data;
  } catch (e) {
    yield put({ type: actionTypes.ANALYTICS_GET_SUM_FAIL });
    return {};
  }
}

function* getUsersCount(params) {
  try {
    const { data } = yield axios.get(endpoints.COUNT_USERS, {
      params,
    });
    return data;
  } catch (e) {
    return {};
  }
}

function* getMostViewedScreens(params) {
  try {
    const mostViewed = yield axios.get(endpoints.GET_ANALYTIC_MOST_VIEWED, {
      params: { ...params, event: 'screen_view', limit: 20, period: 'year' },
    });
    return parsingScreenViewData(mostViewed.data);
  } catch (e) {
    return [];
  }
}

function* getMostViewedSearches(params) {
  try {
    const searchData = yield axios.get(endpoints.GET_ANALYTIC_MOST_VIEWED, {
      params: { ...params, event: 'search', limit: 20, period: 'year' },
    });
    return parsingSearchesData(searchData.data);
  } catch (e) {
    return [];
  }
}

function* getActiveUsers(params) {
  try {
    const activeUsersData = yield axios.get(endpoints.GET_ANALYTIC_ACTIVE_USERS, {
      params,
    });
    return parsingActiveUsersData(activeUsersData.data);
  } catch (e) {
    return [];
  }
}

function* getAverageSession(params) {
  try {
    const { data } = yield axios.get(endpoints.GET_ANALYTIC_AVERAGE_SESSION, {
      params,
    });

    if (data?.[0]?.average_session_minutes_length) {
      const time = data[0].average_session_minutes_length;
      const notFormatSeconds = time.toFixed(2) - Math.floor(time);
      const minutes = time.toFixed(2) - notFormatSeconds;
      const seconds = (notFormatSeconds * 60).toFixed(0);

      return `${minutes}m ${seconds}s`;
    }
    return '-';
  } catch (e) {
    return [];
  }
}

function* getMostEngagementScreen(params) {
  try {
    const averageScreen = yield axios.get(endpoints.GET_ANALYTIC_AVERAGE, {
      params: { ...params, period: 'year', event: 'user_engagement', limit: 20 },
    });
    return parsingScreenTimeData(averageScreen.data);
  } catch (e) {
    return [];
  }
}

function* getTopEvents(params, event) {
  try {
    const { data } = yield axios.get(endpoints.GET_ANALYTIC_MOST_VIEWED, {
      params: { ...params, event, limit: 20, period: 'year' },
    });
    return parsingTopEventsData(data, event);
  } catch (e) {
    return [];
  }
}

function* getTopUsers(params) {
  try {
    const { data } = yield axios.get(endpoints.GET_ANALYTIC_MOST_VIEWED, {
      params: { ...params, event: 'contact_view', limit: 20, period: 'year' },
    });
    return data.map(user => ({
      id: user.user_id,
      name: user.username,
      views: user.count,
    }));
  } catch (e) {
    return [];
  }
}

function* getEmotionsAnalytics(params) {
  try {
    const sendEmotion = yield axios.get(endpoints.GET_ANALYTIC_MOST_VIEWED, {
      params: { ...params, event: 'add_emotion', period: 'year' },
    });
    const thankEmotions = yield axios.get(endpoints.GET_ANALYTIC_MOST_VIEWED, {
      params: { ...params, event: 'add_thank_emotion', period: 'year' },
    });
    return parsingEmotionsData(sendEmotion.data, thankEmotions.data);
  } catch (e) {
    return {};
  }
}

export function* getGeneralAnalyticsSaga(action) {
  yield put({ type: actionTypes.ANALYTICS_GENERAL_START });
  const { params } = action.payload;
  const updateParams = yield parsingPeriod({ params });

  try {
    const [eventCounts, viewData, avgTimeData, topCategories, search, emotions] = yield all([
      getCountOfEvents(updateParams),
      getMostViewedScreens(params),
      getMostEngagementScreen(params),
      getTopEvents(updateParams, 'categories'),
      getMostViewedSearches(updateParams),
      getEmotionsAnalytics(updateParams),
    ]);
    const screens = {
      viewData,
      avgTimeData,
    };
    const tops = {
      categories: topCategories,
    };
    const counts = { ...eventCounts, ...emotions };

    yield put({
      type: actionTypes.ANALYTICS_GENERAL_SUCCESS,
      payload: { counts, screens, tops, search },
    });
  } catch (error) {
    yield handleError(error, actionTypes.ANALYTICS_GENERAL_FAIL);
  }
}

export function* getUsersAnalyticsSaga(action) {
  yield put({ type: actionTypes.ANALYTICS_USERS_START });
  const { params } = action.payload;
  const updateParams = yield parsingPeriod({ params });
  try {
    const [eventCounts, topUsers, activeUsers, usersCount, averageSession] = yield all([
      getCountOfEvents(updateParams),
      getTopUsers(params),
      getActiveUsers(updateParams),
      getUsersCount(updateParams),
      getAverageSession(updateParams),
    ]);

    const tops = {
      users: topUsers,
    };

    const counts = { ...eventCounts, activeUsers, usersCount, averageSession };
    yield put({
      type: actionTypes.ANALYTICS_USERS_SUCCESS,
      payload: { counts, tops },
    });
  } catch (error) {
    yield handleError(error, actionTypes.ANALYTICS_USERS_FAIL);
  }
}

export function* getGiftsAnalyticsSaga(action) {
  yield put({ type: actionTypes.ANALYTICS_GIFTS_START });
  const { params } = action.payload;
  try {
    const [eventCounts, topGifts] = yield all([
      getCountOfEvents(params),
      getTopEvents({ ...params, period: 'year' }, 'view_item'),
    ]);

    const tops = {
      gifts: topGifts,
    };
    const counts = eventCounts;

    yield put({
      type: actionTypes.ANALYTICS_GIFTS_SUCCESS,
      payload: { counts, tops },
    });
  } catch (error) {
    yield handleError(error, actionTypes.ANALYTICS_GIFTS_FAIL);
  }
}

export function* getBusinessDetailsSaga(action) {
  yield put({ type: actionTypes.ANALYTICS_GET_BUSINESS_DETAILS_START });
  const { params } = action.payload;
  const updateParams = () => {
    const start = moment(params.to, 'YYYY-MM-DD');
    const end = moment(params.from, 'YYYY-MM-DD');

    const days = +moment.duration(start.diff(end)).asDays();

    if (days >= 60) {
      return { ...params, period: highchartsConst.STATISTIC_DATE_RANGE_MONTH };
    }
    return { ...params, period: highchartsConst.STATISTIC_DATE_RANGE_DAY };
  };
  try {
    const { data } = yield axios.get(endpoints.GET_ANALYTIC_BUSINESS_REGISTRATIONS, {
      params: updateParams(),
    });
    const registrations = yield parsingBusinessRegistrations(data);
    yield put({
      type: actionTypes.ANALYTICS_GET_BUSINESS_DETAILS_SUCCESS,
      payload: { registrations },
    });
  } catch (error) {
    yield handleError(error, actionTypes.ANALYTICS_GET_BUSINESS_DETAILS_FAIL);
  }
}

export function* getTopBrandsSaga(action) {
  yield put({ type: actionTypes.GET_TOP_BRANDS_START });
  const { params } = action.payload;

  try {
    const { data } = yield axios.get(endpoints.GET_ANALYTIC_TOP_BUSINESSES, {
      params: { ...params, period: 'year' },
    });

    yield put({
      type: actionTypes.GET_TOP_BRANDS_SUCCESS,
      payload: { data: data.data, meta: data.meta },
    });
  } catch (error) {
    yield handleError(error, actionTypes.GET_TOP_BRANDS_FAIL);
  }
}
