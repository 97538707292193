import styled from 'styled-components';
import { Typography, Divider } from '@material-ui/core';

import colors from '@/constants/colors';

const TitleContainer = styled.div`
  margin-bottom: 42px;

  h1 {
    margin-bottom: 10px;
  }

  h4 + .MuiTypography-subtitle2 {
    margin-top: 18px;
    margin-bottom: 40px;
  }
`;

export const PinOnTheMapText = styled(Typography)`
  && {
    margin-top: 20px;
  }

  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const FieldsetTitle = styled.p`
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  padding-bottom: 5px;
  border-bottom: 1px solid ${colors.defaultDividerBg};
  margin-top: 36px;
  margin-bottom: 20px;
`;

export const StyledDivider = styled(Divider)`
  && {
    margin-bottom: 30px;
  }
`;

export default TitleContainer;
