import { t } from '@/locale/i18n';

export const ROUTE_ROOT = '/';
export const ROUTE_SUPPORT = '/support-form';
export const ROUTE_SHARING = '/sharing';
export const ROUTE_SIGN_IN = '/sign-in';
export const ROUTE_SIGN_UP = '/sign-up';
export const ROUTE_RESET_PASSWORD = '/reset-password';
export const ROUTE_UPDATE_PASSWORD = '/reset-password/:token';
export const ROUTE_CREATE_PASSWORD = '/create-password/:token';
export const ROUTE_COMMUNICATIONS = '/communications';
export const ROUTE_PRIVACY_AND_TERMS = '/organization/:organizationId/:type';
export const ROUTE_TERMS = '/terms';
export const ROUTE_TERMS_PR = '/terms-pernod-ricard';
export const ROUTE_ABOUT = '/about';
export const ROUTE_HOW_IT_WORKS = '/how-it-works';
export const ROUTE_MERCH_AGREEMENT = '/merchant-agreement';
export const ROUTE_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_PRIVACY_POLICY_PR = '/privacy-policy-pernod-ricard';
export const ROUTE_OVERVIEW = '/overview';
export const ROUTE_GIFTS = '/gifts';
export const ROUTE_CREATE_GIFT = `${ROUTE_GIFTS}/create-gift`;
export const ROUTE_EDIT_GIFT = `${ROUTE_GIFTS}/:giftId/edit-gift`;
export const ROUTE_EDIT_GIFT_CARD = `${ROUTE_GIFTS}/:giftId/edit-gift-card`;
export const ROUTE_DETAIL_GIFT = `${ROUTE_GIFTS}/:giftId`;
export const ROUTE_CHANGE_QUANTITY = `${ROUTE_GIFTS}/:giftId/change-quantity`;
export const ROUTE_BALANCE = '/balance';
export const ROUTE_TRANSACTIONS_HISTORY = `${ROUTE_BALANCE}/transactions-history`;
export const ROUTE_CUSTOMERS = '/customers';
export const ROUTE_DETAILS_CUSTOMER = `${ROUTE_CUSTOMERS}/:customerId`;
export const ROUTE_USERS = '/users';
export const ROUTE_DETAILS_USER = `${ROUTE_USERS}/:userId`;
export const ROUTE_DIRECT_GIFTS = `${ROUTE_CUSTOMERS}/:customerId/:key`;
export const ROUTE_DETAIL_DIRECT_GIFT = `${ROUTE_CUSTOMERS}/:customerId/:key/:giftId`;
export const ROUTE_ORGANIZATION = '/organization';
export const ROUTE_ORGANIZATIONS = '/organizations';
export const ROUTE_DETAIL_ORGANIZATION = `${ROUTE_ORGANIZATIONS}/:organizationId`;
export const ROUTE_CREATE_ORGANIZATION = `${ROUTE_ORGANIZATIONS}/create`;
export const ROUTE_CREATE_MY_ORGANIZATION = `${ROUTE_ORGANIZATIONS}/create-new`;
export const ROUTE_DRAFT = `${ROUTE_ORGANIZATIONS}/draft`;
export const ROUTE_DRAFT_ORGANIZATION = `${ROUTE_DRAFT}/:organizationId`;
export const ROUTE_LOCATION_DETAIL = `${ROUTE_ORGANIZATION}/:locationId`;
export const ROUTE_ORGANIZATION_LOCATION_DETAIL = `${ROUTE_DETAIL_ORGANIZATION}/:locationId`;
export const ROUTE_LOCATIONS_IMPORT_CSV = `${ROUTE_ORGANIZATION}/locations-import-csv`;
export const ROUTE_LOCATIONS_CSV = `${ROUTE_DETAIL_ORGANIZATION}/locations-import-csv`;
export const ROUTE_EMPLOYEES_IMPORT_CSV = `${ROUTE_LOCATION_DETAIL}/employees-import-csv`;
export const ROUTE_EMPLOYEES_CSV = `${ROUTE_ORGANIZATION_LOCATION_DETAIL}/locations-import-csv`;
export const ROUTE_CATEGORIES = '/categories';
export const ROUTE_CREATE_CATEGORY = `${ROUTE_CATEGORIES}/create`;
export const ROUTE_CREATE_PROMO_GROUP = `${ROUTE_CATEGORIES}/create-group`;
export const ROUTE_EDIT_PROMO_GROUP = `${ROUTE_CATEGORIES}/edit-group/:promoGroupId`;
export const ROUTE_EDIT_CATEGORY = `${ROUTE_CATEGORIES}/:categoryId/edit-category`;
export const ROUTE_SUBCATEGORIES = `${ROUTE_CATEGORIES}/:categoryId`;
export const ROUTE_CREATE_SUBCATEGORY = `${ROUTE_CATEGORIES}/:categoryId/:action`;
export const ROUTE_EDIT_SUBCATEGORY = `${ROUTE_CATEGORIES}/:categoryId/:action`;
export const ROUTE_SURVEYS = '/surveys';
export const ROUTE_GIFT_INFO = `${ROUTE_GIFTS}/:giftId/gift-info`;
export const ROUTE_CREATE_SURVEYS = `${ROUTE_SURVEYS}/create`;
export const ROUTE_DETAIL_SURVEYS = `${ROUTE_SURVEYS}/:surveyId`;
export const ROUTE_EDIT_SURVEYS = `${ROUTE_SURVEYS}/:surveyId/edit`;
export const ROUTE_TRANSACTIONS_LIST = '/transactions';
export const ROUTE_DETAIL_TRANSACTION = `${ROUTE_TRANSACTIONS_LIST}/:transactionId`;
export const ROUTE_REDEMPTIONS = '/redemptions';
export const ROUTE_ADMIN_REDEMPTIONS = '/admin/redemptions';
export const ROUTE_HELP = '/help';
export const ROUTE_INBOX = '/inbox';
export const ROUTE_DISPUTES = '/disputes';
export const ROUTE_MY_PROFILE = '/my-profile';
export const ROUTE_PROFILE_SUPPORT = '/support';
export const ROUTE_CHANGE_PLAN = `${ROUTE_MY_PROFILE}/change-plan`;
export const ROUTE_DOWNLOAD = '/download';
export const ROUTE_ADMINS = '/admins';
export const SETTINGS = '/settings';
export const ROUTE_NOTIFY_USERS = '/notify-users';
export const ROUTE_CREATE_ADMIN = `${ROUTE_ADMINS}/create`;
export const ROUTE_EDIT_ADMIN = `${ROUTE_ADMINS}/:adminId/edit-admin`;
export const ROUTE_ADMINS_VERIFY = `${ROUTE_ADMINS}/verify`;
export const ROUTE_ADMINS_DETAIL = `${ROUTE_ADMINS}/:adminId`;
export const ROUTE_ANALYTICS = '/analytics';
export const ROUTE_UNIVERSAL_CARDS = '/universal-cards';

export const ROUTE_STYLEGUIDE = '/styleguide';

export const breadcrumbNameMap = {
  [ROUTE_OVERVIEW]: t('overview.title'),
  [ROUTE_GIFTS]: t('gifts.title'),
  [ROUTE_ORGANIZATION]: t('business'),
  [ROUTE_ORGANIZATIONS]: t('businesses'),
  [ROUTE_CREATE_GIFT]: t('createGift.title'),
  [ROUTE_BALANCE]: t('balance.title'),
  [ROUTE_CUSTOMERS]: t('customers.title'),
  [ROUTE_REDEMPTIONS]: t('redemptions.title'),
  [ROUTE_HELP]: t('help.title'),
  [ROUTE_CHANGE_PLAN]: t('changePlan.title'),
};

export const excludedBreadcrumps = ['edit-group'];
