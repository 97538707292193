import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/support';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { t } from '@/locale/i18n';

export function* sendSupportSaga(action) {
  yield put({ type: actionTypes.SUPPORT_START });

  const { data, formik } = action.payload;
  const url = getLink(endpoints.SUPPORT_SEND);
  try {
    yield axios.post(url, data);

    yield put({
      type: actionTypes.SUPPORT_SEND_SUCCESS,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('common.supportMessage'),
        options: {
          variant: 'success',
        },
      },
    });

    formik.resetForm();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.SUPPORT_STOP,
    });
  }
}

export default sendSupportSaga;
