import * as actionTypes from './types/inbox';

export const getInboxList = params => {
  return {
    type: actionTypes.INBOX_GET_LIST,
    payload: params,
  };
};

export default getInboxList;
