import * as actionTypes from '@/store/actions/types/notifications';

const initialState = {
  list: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PUSH_NOTIFY:
      return {
        ...state,
        list: [
          ...state.list,
          {
            ...action.payload,
            key: new Date().getTime() + Math.random(),
          },
        ],
      };
    case actionTypes.POP_NOTIFY:
      return {
        ...state,
        list: state.list.filter(notify => notify.key !== action.payload.key),
      };
    default:
      return state;
  }
};

export default reducer;
