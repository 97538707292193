import styled from 'styled-components';
import { DialogTitle, Grid } from '@material-ui/core';

import colors from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

const StyledDialogTitle = styled(DialogTitle)`
  position: relative;

  .MuiButtonBase-root {
    position: absolute;
    top: 24px;
    right: 34px;

    @media (max-width: ${breakpoints.xs}) {
      right: 4px;
    }
  }
`;

export const DialogItemContainer = styled(Grid)`
  flex-direction: column;
`;

export const DialogFooter = styled.div`
  padding: 20px 50px;
  border-top: 1px solid ${colors.defaultDividerBg};

  @media (max-width: ${breakpoints.xs}) {
    padding: 16px;
  }

  p.pin-on-the-map {
    margin: 0;
  }
`;

export default StyledDialogTitle;
