// PLANS actions
export const GET_PLANS = 'GET_PLANS';
export const GET_PLANS_START = 'GET_PLANS_START';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAIL = 'GET_PLANS_FAIL';

export const CHANGE_PLAN = 'CHANGE_PLAN';
export const CHANGE_PLAN_START = 'CHANGE_PLAN_START';
export const CHANGE_PLAN_SUCCESS = 'CHANGE_PLAN_SUCCESS';
export const CHANGE_PLAN_FAIL = 'CHANGE_PLAN_FAIL';
