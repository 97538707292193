import * as Yup from 'yup';
import axios from 'axios';

import * as validationConst from '@/constants/validation';
import { ROLE_ADMIN, ROLE_SUB_ADMIN } from '@/constants/roles';
import { BUSINESS_CHECK_NAME } from '@/constants/api';
import { phoneRegEx } from '@/constants/regexp';

export default t => {
  let prevName;

  return {
    name: Yup.string()
      .required(t('validation.organizationNameRequired'))
      .min(validationConst.MIN_BUSINESS_NAME_LENGTH, t('validation.organizationNameLength'))
      .test('name', t('validation.organizationNameAlreadyTaken'), function testName(name) {
        const { organization_id } = this.parent;

        if (
          name &&
          name.trim() &&
          name.length >= validationConst.MIN_BUSINESS_NAME_LENGTH &&
          prevName !== name
        ) {
          prevName = name;
          return axios
            .get(`${BUSINESS_CHECK_NAME}`, { params: { name, organization_id } })
            .then(res => !res.data.exist)
            .catch(() => true);
        }
        return true;
      }),
    categories: Yup.array().nullable(),
    tags: Yup.array().nullable(),
    logo: Yup.array().required(t('validation.logoRequired')),
    cover: Yup.array(),
    description: Yup.string().max(
      validationConst.MAX_DESCRIPTION_LENGTH,
      t('validation.descriptionMaxLength'),
    ),
    email: Yup.string(),
    emails: Yup.string()
      .required(t('validation.emailRequired'))
      .test('emails', t('validation.emailNotValid'), emails => {
        const schema = Yup.string().email();
        const emailsArray = emails.split(',');
        let checker = true;

        emailsArray.forEach(email => {
          if (!schema.isValidSync(email)) {
            checker = false;
          }
        });
        return checker;
      }),
    phone: Yup.string().matches(phoneRegEx, {
      excludeEmptyString: true,
      message: t('validation.phoneNotValid'),
    }),
    percent: Yup.number().when('role', {
      is: role => {
        return role === ROLE_ADMIN || role === ROLE_SUB_ADMIN;
      },
      then: Yup.number()
        .required(t('validation.percent'))
        .typeError('Enter the number')
        .min(0)
        .max(99),
      otherwise: Yup.number(),
    }),
    period: Yup.string(),
    secret_symbol_code: Yup.string().required(t('validation.secretSymbolRequired')),
    video_description: Yup.string().test(
      'video_description',
      t('validation.descriptionVideoMaxLength'),
      function testDescription(description) {
        const schema = Yup.string().max(
          validationConst.MAX_PROMO_VIDEO_DESCRIPTION_LENGTH,
          t('validation.descriptionVideoMaxLength'),
        );
        const { video, video_file } = this.parent;

        if (video?.length || video_file?.length) {
          return schema.isValidSync(description);
        }

        return true;
      },
    ),
  };
};
