import * as actionTypes from '@/store/actions/types/categories';

const initialState = {
  categories: [],
  promoGroups: [],
  subCategories: [],
  loading: false,
  current_page: 0,
  per_page: 10,
  total: 0,
  data: {},
  promoGroup: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CATEGORIES_START:
      return { ...state, loading: true };
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CATEGORIES_GET_LIST_START:
      return { ...state, loading: true };
    case actionTypes.CATEGORIES_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case actionTypes.CATEGORIES_GET_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case actionTypes.CATEGORIES_GET_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.PROMO_GROUPS_GET_LIST_START:
      return { ...state, loading: true };
    case actionTypes.PROMO_GROUPS_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        promoGroups: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case actionTypes.PROMO_GROUPS_GET_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CREATE_CATEGORY_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_CATEGORY_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.CREATE_CATEGORY_FAIL:
      return { ...state, loading: false };
    case actionTypes.CREATE_PROMO_GROUP_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_PROMO_GROUP_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.CREATE_PROMO_GROUP_FAIL:
      return { ...state, loading: false };
    case actionTypes.GET_CATEGORY_DETAIL_START:
      return { ...state, loading: true };
    case actionTypes.GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actionTypes.GET_CATEGORY_DETAIL_FAIL:
      return { ...state, loading: false };
    case actionTypes.GET_PROMO_GROUP_DETAIL_START:
      return { ...state, loading: true };
    case actionTypes.GET_PROMO_GROUP_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        promoGroup: action.payload,
      };
    case actionTypes.GET_PROMO_GROUP_DETAIL_FAIL:
      return { ...state, loading: false };
    case actionTypes.UPDATE_CATEGORY_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actionTypes.UPDATE_CATEGORY_FAIL:
      return { ...state, loading: false };
    case actionTypes.UPDATE_PROMO_GROUP_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_PROMO_GROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actionTypes.UPDATE_PROMO_GROUP_FAIL:
      return { ...state, loading: false };
    case actionTypes.DELETE_CATEGORY_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.DELETE_CATEGORY_FAIL:
      return { ...state, loading: false };
    case actionTypes.DELETE_PROMO_GROUP_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_PROMO_GROUP_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.DELETE_PROMO_GROUP_FAIL:
      return { ...state, loading: false };
    case actionTypes.SUBCATEGORIES_GET_LIST_START:
      return { ...state, loading: true };
    case actionTypes.SUBCATEGORIES_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategories: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case actionTypes.SUBCATEGORIES_GET_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
