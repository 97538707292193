import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { t } from '@/locale/i18n';

import * as actionTypes from '@/store/actions/types/admins';
import * as endpoints from '@/constants/api';

import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';

export const updateAdminFormData = (formData, key, data) => {
  if (key === 'roles') {
    // eslint-disable-next-line guard-for-in
    for (const prop in data) {
      updateAdminFormData(formData, `${key}[]`, data[prop]);
    }
    return;
  }

  formData.append(key, data);
};

export function* getAdminPermissionsSaga() {
  yield put({ type: actionTypes.ADMINS_GET_PERMISSIONS_START });

  const url = endpoints.GET_ADMIN_PERMISSIONS;

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.ADMINS_GET_PERMISSIONS_SUCCESS,
      payload: { permissions: data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMINS_GET_PERMISSIONS_FAIL,
      payload: getErrorMessage(error),
    });
  }
}

export function* createEditAdminSaga(action) {
  yield put({ type: actionTypes.ADMINS_CREATE_EDIT_START });

  const { requestData, callBack, id, isEdit } = action.payload;
  const url = isEdit ? getLink(endpoints.EDIT_ADMIN, { adminId: id }) : endpoints.CREATE_ADMIN;

  try {
    const { data } = isEdit
      ? yield axios.put(url, requestData)
      : yield axios.post(url, requestData);

    yield put({
      type: actionTypes.ADMINS_CREATE_EDIT_SUCCESS,
      payload: { data },
    });
    callBack();
    const message = isEdit ? t('notification.editAdmin') : t('notification.createAdmin');

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message,
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMINS_CREATE_EDIT_FAIL,
      payload: getErrorMessage(error),
    });
  }
}

export function* getAdminsListSaga(action) {
  yield put({ type: actionTypes.ADMINS_GET_LIST_START });

  const { search, ...params } = action.payload;

  const url = endpoints.GET_ADMINS;
  const updatedParam = search ? { search } : {};

  try {
    const { data } = yield axios.get(url, { params: { ...updatedParam, ...params } });

    yield put({
      type: actionTypes.ADMINS_GET_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMINS_GET_LIST_FAIL,
      payload: getErrorMessage(error),
    });
  }
}

export function* getAdminProfileSaga(action) {
  yield put({ type: actionTypes.ADMINS_GET_PROFILE_START });

  const { id } = action.payload;

  const url = getLink(endpoints.GET_ADMIN, { adminId: id });

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.ADMINS_GET_PROFILE_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMINS_GET_PROFILE_FAIL,
      payload: getErrorMessage(error),
    });
  }
}

export function* deleteAdminProfileSaga(action) {
  yield put({ type: actionTypes.ADMINS_DELETE_PROFILE_START });

  const { id, callBack } = action.payload;

  const url = getLink(endpoints.DELETE_ADMIN, { adminId: id });

  try {
    yield axios.delete(url);

    yield put({
      type: actionTypes.ADMINS_DELETE_PROFILE_SUCCESS,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.deleteAdmin'),
        options: {
          variant: 'success',
        },
      },
    });

    callBack();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMINS_DELETE_PROFILE_FAIL,
      payload: getErrorMessage(error),
    });
  }
}

export function* changeStatusAdminSaga(action) {
  yield put({ type: actionTypes.ADMINS_CHANGE_STATUS_START });

  const { id, status } = action.payload;

  const url = getLink(endpoints.CHANGE_STATUS_ADMIN, { adminId: id });

  try {
    yield axios.post(url, { status });

    yield put({
      type: actionTypes.ADMINS_CHANGE_STATUS_SUCCESS,
    });

    yield call(getAdminProfileSaga, { payload: { id } });

    const message =
      status !== 'blocked' ? t('notification.unBlockAdmin') : t('notification.blockAdmin');

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message,
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMINS_CHANGE_STATUS_FAIL,
      payload: getErrorMessage(error),
    });
  }
}

export function* getCountriesSaga() {
  yield put({ type: actionTypes.ADMINS_GET_COUNTRIES_START });

  const url = endpoints.GET_COUNTRIES;

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.ADMINS_GET_COUNTRIES_SUCCESS,
      payload: { countries: data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMINS_GET_COUNTRIES_FAIL,
      payload: getErrorMessage(error),
    });
  }
}
