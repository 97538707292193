import axios from 'axios';
import { put } from 'redux-saga/effects';
import * as actionTypes from '@/store/actions/types/adminSettings';
import * as endpoints from '@/constants/api';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import getErrorMessage from '@/utility/common/errors';
import { t } from '@/locale/i18n';

const convertToArray = (data, key) => {
  const array = data.data.filter(value => value.name === key);
  return array[0]?.value;
};

export function* getNGCStatusSaga() {
  yield put({ type: actionTypes.GET_NGC_STATUS_START });

  const url = endpoints.GET_ALL_NGC_STATUS;

  try {
    const response = yield axios.get(url);

    yield put({
      type: actionTypes.GET_NGC_STATUS_SUCCESS,
      payload: {
        NGCStatus: response.data.data.result,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_NGC_STATUS_FAILED,
    });
  }
}

export function* getAdminSettingsSaga() {
  yield put({ type: actionTypes.GET_ADMINS_SETTINGS_START });

  try {
    const { data } = yield axios.get(endpoints.ADMIN_SETTINGS);

    yield put({
      type: actionTypes.GET_ADMINS_SETTINGS_SUCCESS,
      payload: {
        ignored_users: data.ignore_users,
        unverified_purchase_limit_ngc: convertToArray(data, 'unverified_purchase_limit_ngc'),
        ngc_native_payments_delay: convertToArray(data, 'ngc_native_payments_delay'),
        purchase_limit: convertToArray(data, 'purchase_limit'),
        purchase_card_limit: convertToArray(data, 'purchase_card_limit'),
        support_email: convertToArray(data, 'support_email'),
        test_email: convertToArray(data, 'test_email'),
        ngc_gift_delay_time: convertToArray(data, 'ngc_gift_delay_time'),
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_ADMINS_SETTINGS_FAILED,
    });
  }
}

export function* updateAdminSettingsSaga(actions) {
  yield put({ type: actionTypes.UPDATE_ADMINS_SETTINGS_START });

  try {
    const { data } = yield axios.post(endpoints.ADMIN_SETTINGS, { ...actions.payload });
    const ngcNativePayDelay = convertToArray(data, 'ngc_native_payments_delay');
    const response = data.data;

    const convertedData = {
      purchase_limitMX: response[0].value.MX,
      purchase_limitUS: response[0].value.US,
      purchase_limitES: response[0].value.ES,
      purchase_card_limitMX: response[1].value.MX,
      purchase_card_limitUS: response[1].value.US,
      purchase_card_limitES: response[1].value.ES,
      unverified_purchase_limit_ngc: response[2].value.MX,
      ngc_gift_delay_time: response[3].value.MX,
    };

    yield put({
      type: actionTypes.UPDATE_ADMINS_SETTINGS_SUCCESS,
      payload: {
        NGCStatus: data.data.result,
        ngc_native_payments_delay: ngcNativePayDelay?.US,
        settings: convertedData,
      },
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('settings.messages.saved'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_ADMINS_SETTINGS_FAILED,
    });
  }
}

export function* getAdminNotificationsSaga() {
  yield put({ type: actionTypes.GET_ADMIN_NOTIFICATIONS_START });

  try {
    const { data } = yield axios.get(endpoints.ADMIN_NOTIFICATIONS);
    yield put({
      type: actionTypes.GET_ADMIN_NOTIFICATIONS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_ADMIN_NOTIFICATIONS_FAILED,
    });
  }
}

export function* updateAdminNotificationsSaga(actions) {
  const { notifications, updateNotifications } = actions.payload;

  yield put({ type: actionTypes.UPDATE_ADMIN_NOTIFICATIONS_START });

  try {
    yield axios.post(endpoints.ADMIN_NOTIFICATIONS, { notifications });

    yield put({
      type: actionTypes.UPDATE_ADMIN_NOTIFICATIONS_SUCCESS,
      payload: {
        notifications: updateNotifications,
      },
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('settings.messages.saved'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_ADMIN_NOTIFICATIONS_FAILED,
    });
  }
}

export function* updateToCurrentUsersSaga(actions) {
  const { name } = actions.payload;

  yield put({ type: actionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS_START, payload: { name } });

  try {
    yield axios.post(endpoints.ADMIN_SETTINGS_UPDATE_USERS, { ...actions.payload });

    yield put({
      type: actionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS_SUCCESS,
      payload: { name },
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('settings.messages.updated'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS_FAILED,
      payload: { name },
    });
  }
}

export function* archiveAllNGCSaga(action) {
  const { NGCStatus } = action.payload;

  yield put({ type: actionTypes.ARCHIVE_ALL_NGC_START });
  let res = null;
  try {
    if (NGCStatus) {
      res = yield axios.post(endpoints.ARCHIVE_ALL_ORGANIZATION);
    } else {
      res = yield axios.post(endpoints.UN_ARCHIVE_ALL_ORGANIZATION);
    }
    const count = res.data.count;
    yield put({
      type: actionTypes.ARCHIVE_ALL_NGC_SUCCESS,
      payload: {
        NGCStatus,
      },
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: NGCStatus
          ? t('settings.ngc.archive', { count })
          : t('settings.ngc.unarchived', { count }),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ARCHIVE_ALL_NGC_FAILED,
    });
  }
}

export function* reindexAlgoliaSaga(action) {
  const { name } = action.payload;

  yield put({ type: actionTypes.REINDEX_ALGOLIA_START, payload: { name } });
  try {
    yield axios.post(endpoints.SCOUT_IMPORT, { name });

    yield put({
      type: actionTypes.REINDEX_ALGOLIA_SUCCESS,
      payload: { name },
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('Reindex success'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REINDEX_ALGOLIA_FAILED,
      payload: { name },
    });
  }
}
