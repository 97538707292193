import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypesAuth from '@/store/actions/types/auth';
import * as actionTypesAccounts from '@/store/actions/types/accounts';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { getEmailLanguageSaga } from '@/store/saga/language';
import { getMe } from '@/store/saga/auth';

export function* checkNotificationsSaga(action) {
  const { id } = action.payload;
  const url = getLink(endpoints.CHECK_NOTIFICATIONS, { id });

  try {
    const res = yield axios.get(url);
    const { data } = res;
    yield put({
      type: actionTypesAccounts.CHECK_NOTIFICATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
  }
}

export function* putMainInfoSaga(action) {
  yield put({ type: actionTypesAccounts.ACCOUNTS_PUT_START });
  const { data, formik } = action.payload;
  const { user, responseData } = data;
  const url = getLink(endpoints.ACCOUNTS_MAIN_INFO, { id: user.id });

  try {
    yield axios.put(url, responseData);
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('mainInfo.saveSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
    yield put({
      type: actionTypesAuth.AUTH_UPDATE_USER,
      payload: {
        user,
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_MAIN_INFO_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_MAIN_INFO_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
  formik.resetForm();
}

export function* putSecuritySaga(action) {
  yield put({ type: actionTypesAccounts.ACCOUNTS_PUT_START });
  const { data, formik } = action.payload;
  const { id, responseData } = data;
  const url = getLink(endpoints.ACCOUNTS_SECURITY, { id });

  try {
    yield axios.put(url, responseData);
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('security.saveSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_SECURITY_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_SECURITY_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
  formik.resetForm();
}

export function* getEmailNotificationsSaga(action) {
  yield put({ type: actionTypesAccounts.ACCOUNTS_GET_START });
  const { id } = action.payload;
  const url = getLink(endpoints.ACCOUNTS_EMAIL_NOTIFICATIONS, { id });

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypesAccounts.ACCOUNTS_GET_EMAIL_NOTIFICATIONS_SUCCESS,
      payload: {
        emailNotifications: data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_GET_EMAIL_NOTIFICATIONS_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* putEmailNotificationsSaga(action) {
  yield put({ type: actionTypesAccounts.ACCOUNTS_PUT_START });
  const { data, formik } = action.payload;
  const { id, organizationId, emailNotifications, responseData } = data;
  const url = getLink(endpoints.ACCOUNTS_EMAIL_NOTIFICATIONS, { id });

  try {
    yield axios.put(url, responseData);
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('emailNotificationsTab.saveSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_SUCCESS,
      payload: {
        emailNotifications,
      },
    });
    yield call(getEmailLanguageSaga, { payload: { organizationId } });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
  formik.resetForm();
}

export function* getBillingCardSaga(action) {
  yield put({ type: actionTypesAccounts.ACCOUNTS_GET_START });

  const url = getLink(endpoints.ACCOUNTS_GET_BILLING, { id: action.payload });

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypesAccounts.ACCOUNTS_GET_BILLING_SUCCESS,
      payload: data.card,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_GET_BILLING_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* changeStripeTokenSaga(action) {
  const { stripe, name, id, onClose } = action.payload;
  yield put({ type: actionTypesAccounts.ACCOUNTS_PUT_START });

  const url = getLink(endpoints.ACCOUNTS_CHANGE_BILLING, { id });

  try {
    const { token } = yield call(stripe.createToken, { name });
    const { data } = yield axios.post(url, { stripeToken: token.id });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_BILLING_SUCCESS,
      payload: data.card,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.cardIsUpdated'),
        options: {
          variant: 'success',
        },
      },
    });
    yield call(onClose);
    yield put({
      type: actionTypesAuth.AUTH_UPDATE_CHECK_INFO,
      payload: { key: 'stripe' },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.ACCOUNTS_PUT_BILLING_SUCCESS,
    });
  }
}

export function* changeStripeAccountSaga(action) {
  const { stripe_user_id, organization_id } = action.payload;
  yield put({ type: actionTypesAccounts.CHANGE_STRIPE_ACCOUNT_START });

  try {
    yield axios.put(endpoints.STRIPE_CHANGE, { stripe_user_id, organization_id });

    yield call(getMe);

    yield put({
      type: actionTypesAccounts.CHANGE_STRIPE_ACCOUNT_SUCCESS,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.cardIsUpdated'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.CHANGE_STRIPE_ACCOUNT_FAIL,
    });
  }
}

export function* changeSquareAccountSaga(action) {
  const { square_user_id, organization_id } = action.payload;
  yield put({ type: actionTypesAccounts.CHANGE_SQUARE_ACCOUNT_START });

  try {
    yield axios.put(endpoints.SQUARE_CHANGE, { square_user_id, organization_id });

    yield call(getMe);

    yield put({
      type: actionTypesAccounts.CHANGE_SQUARE_ACCOUNT_SUCCESS,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.cardIsUpdated'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypesAccounts.CHANGE_SQUARE_ACCOUNT_FAIL,
    });
  }
}
