import styled from 'styled-components';
import colors from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';

export const WarningContainer = styled.div`
  background: ${colors.warningBg};
  white-space: pre-line;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 10px;
  margin-bottom: 24px;
  margin-top: 24px;

  @media (max-width: ${breakpoints.sm}) {
    top: ${p => (p.isSearch ? '108px' : '65px')};
  }

  @media (max-width: ${breakpoints.xs}) {
    flex-direction: column;

    top: ${p => (p.isSearch ? '108px' : '60px')};
  }
`;

export default WarningContainer;
