import * as validationConst from '@/constants/validation';
import * as imagesConst from '@/constants/images';
import {
  MAX_DURATION,
  ORGANIZATION_VIDEO_HEIGHT,
  ORGANIZATION_VIDEO_SIZE,
  ORGANIZATION_VIDEO_WIDTH,
} from '@/constants/videos';

export default Object.freeze({
  translations: {
    // Roles
    roles: {
      merchandiser: 'Comerciante',
      admin: 'Administrador',
      subadmin: 'Administrador',
      manager: 'Gerente de QR',
      user: 'Usuario móvil',
    },
    // Tabs
    tabs: {
      analytics: {
        general: 'General',
        users: 'Usuarios',
        gifts: 'Regalos',
        businesses: 'Empresas',
      },
      transactions: {
        merchandisers: 'Comerciantes',
        customers: 'Clientes',
        waiting: 'Esperando seguro',
        blocked: 'Bloqueado',
      },
      settings: {
        paymentLimits: 'Pagos',
        settings: 'Actualización para todas las usuarias',
        notifications: 'Notificaciones',
        emails: 'Correos electrónicos',
        reindex: 'Algolia',
      },
    },
    // Common
    common: {
      tabs: {
        en: 'EN',
        es: 'ES',
      },
      startDate: 'Fecha de inicio:',
      endDate: 'Fecha final:',
      loginAsOrganization: 'Iniciar sesión como negocio',
      loginAsAdmin: 'Inicie sesión como administrador',
      notFindTheExactAddress: 'Si no encuentras la dirección exacta',
      pinOnTheMapLink: 'pon un pin en el mapa',
      poweredByLazo: 'Tecnología de Lazo',
      privacyPolicyLink: 'Política de privacidad',
      statistics: 'estadísticas',
      details: 'Detalles',
      quantity: 'Cantidad',
      completeProfileLink: 'completar perfil',
      CSVTemplate: 'Plantilla CSV',
      rowsPerPage: 'Filas por página',
      profile: 'Perfil',
      settings: 'Configuración',
      logout: 'Cerrar sesión',
      optional: 'Opcional',
      noFileChosen: 'Ningún archivo seleccionado',
      edit: 'Editar',
      profileRestrictionText: '¡Estará disponible cuando completes tu perfil!',
      free: 'Gratis',
      from: 'Desde',
      to: 'hasta',
      ends: 'Termina',
      ended: 'Terminó',
      toSomeone: 'para',
      other: 'Otro',
      discount: 'Descuento',
      link: 'enlace',
      login: 'Inicio de sesión',
      commission: 'comisión',
      month: 'mes',
      monthShortName: 'mes',
      currentPlan: 'Plan actual',
      today: 'hoy',
      selectDay: 'selecciona una fecha...',
      exportCsv: 'Exportación de estadísticas de ventas (csv)',
      support: '¿Tienes algún problema?',
      supportProfile: 'Apoyo',
      supportMessage: 'Su mensaje ha sido enviado con éxito',
      download: '(haz clic para descargar)',
      qrCode: 'código QR',
      en: 'EN',
      es: 'ES',
      or: 'o',
      menu: {
        Overview: 'Resumen',
        Gifts: 'Regalos',
        Balance: 'Saldo',
        Customers: 'Clientes',
        Organization: 'Negocio',
        Organizations: 'Negocios',
        Redemptions: 'Canjes',
        Help: 'Ayuda',
        Users: 'Usuarios',
        Surveys: 'Encuestas',
        Categories: 'Categorías',
        Transactions: 'Transacciones',
        admins: 'Administradores',
        sharing: 'Compartir',
        faqs: 'Preguntas Frecuentes',
        billing: 'Facturación',
        privacyPolicy: 'Política de privacidad',
        userTerms: 'Condiciones de uso',
        merchAgreement: 'Acuerdo comercial',
        analytics: 'Analítica',
      },
      days: {
        workHours: 'Horas laborales',
        roundTheClock: '24 horas del día',
        dayOff: 'Día libre',
        open: 'Abre',
        close: 'Cierra',
        messages: {
          edit: 'El horario de trabajo se cambió con éxito',
          empty: 'La ubicación no tiene días laborales',
        },
        shortName: {
          mon: 'Lun',
          tue: 'Mar',
          wed: 'Mié',
          thu: 'Jue',
          fri: 'Vie',
          sat: 'Sáb',
          sun: 'Dom',
        },
        fullName: {
          mon: 'Lunes',
          tue: 'Martes',
          wed: 'Miércoles',
          thu: 'Jueves',
          fri: 'Viernes',
          sat: 'Sábado',
          sun: 'Domingo',
        },
      },
      months: {
        january: 'enero',
        february: 'febrero',
        march: 'marzo',
        april: 'abril',
        may: 'mayo',
        june: 'junio',
        july: 'julio',
        august: 'agosto',
        september: 'septiembre',
        october: 'octubre',
        november: 'noviembre',
        december: 'deciembre',
        shortName: {
          january: 'enero',
          february: 'feb',
          march: 'mar',
          april: 'ab',
          may: 'mayo',
          june: 'jun',
          july: 'jul',
          august: 'agosto',
          september: 'sept',
          october: 'oct',
          november: 'nov',
          december: 'dic',
        },
      },
      periods: {
        daily: 'Día',
        weekly: 'Semana',
        monthly: 'Mes',
        yearly: 'Año',
        customRange: 'Fechas específicas',
      },
      types: {
        sent: 'Enviados',
        redeems: 'Canjeados',
        redeemed: 'Canjeados',
        views: 'Vistas',
        tips: 'Propinas',
      },
      statuses: {
        // Gift
        dispute: 'Disputa',
        fraudulent: 'Fraudulento',
        failed: 'Fallido',
        converted: 'Convertidos',
        deleted: 'Eliminado',
        survey_donated: 'Donado en encuesta',
        donated: 'Regalo directo',
        active: 'Activo',
        published: 'Publicado',
        drafted: 'Borrador',
        archived: 'Archivado',
        out_of_stock: 'Agotado',
        blocked: 'Bloqueado',
        free: 'Gratis',
        paid: 'Pagado',
        virtual_paid: 'Pagado virtual',
        virtual_card_paid: 'Pago con tarjeta virtual',
        expiration_free: 'Gratis con vencimiento',
        gift: 'Regalo',
        gift_card: 'Tarjeta de regalo',
        geo_gift: 'Regalo geográfico',
        // Employee
        register: 'Registrado',
        invite: 'Invitado',
        invited: 'Invitado',
        draft: 'Borrador',
        complete: 'Completo',
        in_progress: 'En curso',
        registered: 'Registrado',
        pending: 'Pendiente',
        refunded: 'Reembolsado',
        pending_approval: 'Aprobación pendiente',
        waiting: 'Esperando',
        abandoned: 'Abandonado',
        rejected: 'Rechazado',
        cancelled: 'Cancelada por cron',
        sent: 'Enviados',
        redeemed: 'Canjeados',
      },
      plans: {
        ngc: 'NGC plan',
        basic: 'Básico',
        pro: 'Pro',
        enterprise: 'Empresa',
      },
    },
    privacyAndTerms: {
      empty: 'Esta organización no ha creado {{type}}',
    },
    sharing: {
      download: 'Compartir-código-QR',
      title: 'Compartir',
      QRCode: 'Compartir código QR',
      link: 'Compartir enlace',
      info: 'Compartir estadísticas',
      clicks: 'Clics:',
      not_completed: 'Registro no completado:',
      referral_users: 'Usuarios de referencia:',
      empty: 'No has creado  {{item}}',
    },
    downloadQRCode: {
      redemption: {
        print: 'Para imprimir ".pdf"',
        design: 'Activos de diseño ".svg"',
      },
      sharing: {
        print: 'Para impresión / redes sociales ".png"',
        design: 'Activos de diseño ".svg"',
      },
      user: {
        print: 'Para impresión / redes sociales ".png"',
        design: 'Activos de diseño ".svg"',
      },
    },
    detailUser: {
      QRCode: {
        download: 'Descargar código QR',
      },
    },
    // Tooltip
    tooltip: {
      cropper:
        'Haga clic en "cuadrado" o "rectángular" para elegir el tipo de formulario del logotipo de la empresa',
      onlyPublishedSent: 'Solo se pueden enviar regalos publicados sin fecha de vencimiento',
      cantDeleteGift: 'No se pueden eliminar regalos con ventas',
      whenUnPublish:
        'Si este regalo tuvo ventas, su estado cambiará a Archivado o si no, a Borrador',
      alreadyRegistered: 'El empleado ya está registrado',
      geoGift:
        'Puedes establecer límites geográficos en los que las personas pueden disponer de regalos gratis. Tanto los límites amplios como los reducidos tienen sus ventajas. Elige en función de tu estrategia y disfruta de una mayor frecuencia de compra y el monto del ticket.',
      free:
        'Son regalos que los clientes satisfechos pueden enviar de forma gratuita. Deben ser como los regalos que recibes de tus amigos. Nada de descuentos y sin obligaciones de compra. Debe ser un regalo cien por ciento patrocinado. Recuerda: las recomendaciones de boca en boca atraerán nuevos clientes a tu establecimiento.',
      paid:
        'Estos son los regalos que los usuarios podrán comprarles a sus amigos. ¡Empieza por tus productos estrella! Aprovecha las amistades para crear experiencias emocionales en torno a tus productos y servicios. Así es como se construye la fidelidad.',
    },
    // Fill Profile Dialog
    fillProfileDialog: {
      title: 'Perfil',
      subtitle: 'Por favor completa el perfil de tu empresa. Progreso actual:',
    },
    // Warning
    warning: {
      profile: {
        title: 'Tu perfil no está completo. Por favor completa el perfil de tu negocio',
        profile: 'perfil',
        progress: 'Progreso: {{percent}}%',
        info: {
          name: 'Nombre del negocio',
          email: 'Dirección de correo electrónico',
          cover: 'Imagen de portada',
          logo: 'Logotipo de empresa',
          payment: 'Habilitar transferencias bancarias',
        },
      },
      paymentSystem: {
        textPartOne:
          'Tu sistema de pagos ha solicitado documentos adicionales. Es necesario que los proporciones ',
        deadline: 'antes de {{deadline}}',
        textPartTwo: 'o se desactivará temporalmente tu cuenta.',
        please: 'Visita ',
        paymentDashboard: 'el dashboard de pagos ',
        follow: 'y sigue las instrucciones.',
        accountFraud: 'La cuenta de Stripe es rechazada por sospecha de fraude o actividad ilegal.',
        underReview: 'Stripe está revisando la cuenta de Stripe.',
        listed:
          'La cuenta de Stripe puede estar en una lista de personas o empresas prohibidas (Stripe investigará y rechazará o restablecerá la cuenta de manera apropiada).',
        other: 'La cuenta de Stripe se rechaza por otro motivo.',
        rejectedListed:
          'La cuenta de Stripe se rechazó porque está en una lista de empresas o personas prohibidas de terceros (como un proveedor de servicios financieros o el gobierno).',
        rejectedTermsOfService:
          'La cuenta de Stripe se rechaza debido a presuntas violaciones de los términos de servicio.',
        pendingVerification:
          'Stripe está verificando actualmente la información de la cuenta conectada.',
        pastDuo:
          'Se requiere información de verificación adicional para habilitar las capacidades de pago o cargo en esta cuenta.',
        fieldsNeeded: 'Debe completar los campos en su ',
        deauthorized:
          'Su cuenta de pago ha sido desautorizada. Por favor, vuelva a conectar la cuenta o conecte una nueva. ',
      },
    },
    // Pagination
    pagination: {
      rowsPerPage: 'Filas por página',
      previousPage: 'Página anterior',
      nextPage: 'Página siguiente',
      of: 'de',
    },
    // Card
    card: {
      userCardInfo: {
        appVersion: 'Version de aplicacion',
        deviceModel: 'Modelo de dispositivo',
        username: 'Usuario',
        phone: 'Teléfono:',
        name: 'Nombre',
        sent: 'Enviados',
        received: 'Recibido',
        purchased: 'Comprados',
        redeemed: 'Canjeados',
        totalPurchased: 'Total de comprados',
        registration: 'Fecha de Registro',
        totalRedeemed: 'Total de canjeados',
        referralUsers: 'Usuarios de referencia',
        clicks: 'Clics',
        notCompleted: 'Registro no completado',
        consentPernodRicard: 'Consentimiento de Pernod Ricard',
      },
    },
    // Table
    table: {
      emptySearchResults:
        'Tu búsqueda “{{searchString}}” no arrojó ningún resultado. Vuelve a intentarlo con otro término',
      transactionHistory: {
        title: 'Historial de transacciones',
        sent: 'Enviados',
        received: 'Recibidos',
        referralUsers: 'Usuarios de referencia',
        transactionId: 'ID de transacción',
        date: 'Fecha',
        giftName: 'Nombre del regalo',
        recipient: 'Recipiente',
        organization: 'Negocio',
        giftType: 'Tipo de regalo',
        price: 'Precio',
        status: 'Estado',
        paymentStatus: 'Estado de pago',
        sender: 'Remitente',
        tabs: {
          sent: 'Enviados',
          received: 'Usuarios referidos',
          referralUsers: 'Recibidos',
        },
      },
      referralUsers: {
        title: 'Usuarios de referencia',
        avatar: 'Avatar',
        username: 'Nombre de usuario',
        phone: 'Teléfono',
        registration: 'Fecha de Registro',
      },
    },
    // Buttons
    buttons: {
      switchAccount: 'Cambiar cuenta',
      choose: 'Escoger',
      details: 'Detalles',
      rectangle: 'rectángular',
      update: 'Actualizar',
      square: 'cuadrado',
      download: 'Descargar',
      generate: 'Generar',
      editWorkHours: 'Editar horas de trabajo',
      close: 'Cerrar',
      goBack: 'Atrás',
      goToHomePage: 'Ir a la página de inicio',
      signIn: 'Iniciar sesión',
      nextStep: 'Siguiente paso',
      next: 'Siguiente',
      back: 'Atrás',
      create: 'Crear',
      save: 'Guardar',
      continue: 'Seguir',
      cancel: 'Cancelar',
      send: 'Enviar',
      createGift: 'Crear regalo',
      filters: 'Filtros',
      chooseFile: 'Seleccionar archivo',
      uploadPhoto: 'Subir foto',
      remove: 'Eliminar',
      saveAsDraft: 'Guardar como borrador',
      saveAndPublish: 'guardar y publicar',
      cropImage: 'Recortar imagen',
      unpublish: 'Anular publicación',
      changeQuantity: 'Cambiar cantidad / descuento',
      changeQuantityAndExpiration: 'Cambiar cantidad y vencimiento',
      editGift: 'Editar regalo',
      export: 'Exportar',
      import: 'Importar',
      sendDirectGift: 'Enviar regalo directo',
      duplicate: 'Duplicar',
      reset: 'Restablecer',
      applyFilters: 'Aplicar filtros',
      upload: 'Cargar',
      cancelImport: 'Cancelar la importación',
      connect: 'Asociar',
      delete: 'Eliminar',
      addSingleLocation: 'Agregar una sola ubicación',
      addWorkHours: 'Agregar horas de trabajo',
      importCSV: 'Importar CSV',
      resendInvitation: 'Reenviar invitación',
      addSingleEmployee: 'Agregar un solo empleado',
      editEmployee: 'Editar empleado',
      editLocation: 'Editar ubicación',
      deleteLocation: 'Eliminar ubicación',
      backTooOrganization: 'Volver a negocio',
      backTooLocation: 'Volver a ubicación',
      addAmount: 'Agregar importe',
      createOrganizations: 'Crear negocio',
      blockUser: 'Bloquear usuario',
      unBlockUSer: 'Desbloquear usuario',
      archive: 'Archivo',
      archiveBusiness: 'Archivar negocio',
      unArchiveBusiness: 'Desarchivar negocio',
      resendContract: 'Reenviar contrato',
      nextStepCreate: 'Siguiente',
      sendInvitation: 'Enviar invitación',
      createCategory: 'Crear categoría',
      createSubCategory: 'Crear subcategoría',
      deleteCategory: 'Eliminar categoría',
      deletePromoGroup: 'Eliminar grupo promocional',
      editCategory: 'Editar categoría',
      deleteSubCategory: 'Eliminar subcategoría',
      blockGift: 'Bloquear regalo',
      unBlockGift: 'Desbloquear regalo',
      block: 'Bloquear',
      unBlock: 'Desbloquear',
      restoreGift: 'Restaurar regalo',
      createSurvey: 'Crear encuesta',
      stopSurvey: 'Detener encuesta',
      startSurvey: 'Iniciar encuesta',
      edit: 'Editar',
      selectGift: 'Seleccionar regalo',
      select: 'Seleccionar',
      addNewQuestion: 'Agregar pregunta nueva',
      changePlan: 'Cambiar plan',
      reloadPage: 'Recargar página',
      addEmployee: 'Agregar empleado',
      resend: 'Reenviar',
      generateRedemption: 'Generar código de canje',
      downloadPdf: 'Descargar códigos',
      redemption: 'Redención',
      exportStatistics: 'Exportar estadísticas de ventas',
      generateSymbol: 'Generar símbolo secreto nuevo',
      createAdmin: 'Crear administrador',
      blockAdmin: 'Bloquear administrador',
      unBlockAdmin: 'Desbloquear administrador',
      downloadQRCode: 'Descargar código QR',
      enabled: 'Permitir',
      disabled: 'Desactivar',
      blockVirtualBalance: 'Bloquear el balance virtual',
      unBlockVirtualBalance: 'Desbloquear el saldo virtual',
      blockBalance: 'Balance de bloques',
    },
    // Inputs
    inputs: {
      address: 'Dirección',
      addressPlaceholder: 'Escribe tu dirección',
      addressLocationPlaceholder: 'Introduce tu ubicación',
      fullName: 'Nombre completo',
      userName: 'Nombre de usuario',
      comment: 'Comentario',
      fullNamePlaceholder: 'Escribe el nombre completo del empleado',
      fullNamePlaceholder2: 'Escribe el nombre completo del empleado',
      email: 'Email',
      emailMerchandiser: 'Email del comerciante',
      emailOrganization: 'Email del negocio',
      emailPlaceholder: 'Escribe tu email',
      emailPlaceholder2: 'Introduce el email del empleado',
      password: 'Contraseña',
      passwordPlaceholder: 'Introduce tu contraseña',
      passwordMinLengthPlaceholder: `Ingresa ${validationConst.MIN_PASSWORD_LENGTH} caracteres o más`,
      confirmPassword: 'Confirmación de contraseña',
      confirmPasswordPlaceholder: 'Introduce de nuevo la contraseña',
      accountType: 'Tipo de cuenta',
      name: 'Nombre de la cuenta',
      namePlaceholder: 'Introduce el nombre de tu cuenta',
      businessName: 'Nombre del negocio',
      businessNamePlaceholder: 'Introduce el nombre de tu negocio',
      businessLocation: 'Introduce la ubicación de tu negocio',
      businessLocationPlaceholder: 'Introduce la ubicación de tu negocio',
      cardHolderName: 'Nombre del titular',
      cardNamePlaceholder: 'Introduce el nombre',
      cardNumber: 'Número de la tarjeta',
      cardNumberPlaceholder: 'Número de tarjeta válido',
      expirationMonthYear: 'Vencimiento (mes/año)',
      expirationMonthPlaceholder: 'MM',
      expirationYearPlaceholder: 'AA',
      secureCode: 'Código de seguridad',
      secureCodePlaceholder: 'CVV',
      searchPlaceholder: 'Búsqueda (Un mínimo de 3 caracteres)',
      phone: 'Teléfono',
      phonePlaceholder: 'Introduce tu teléfono',
      phonePlaceholder2: 'Introduce tu teléfono',
      oldPassword: 'Contraseña anterior',
      oldPasswordPlaceholder: 'Introduce tu contraseña anterior',
      newPassword: 'Contraseña nueva',
      confirmNewPasswordPlaceholder: 'Vuelve a escribir tu contraseña nueva',
      position: 'Posición',
      contractRecieverEmail: 'Email del destinatario del contrato',
      templatePlaceholder: 'Elige el modelo',
      templateLabel: 'Selecciona el modelo de contrato',
      locationName: 'Nombre de la ubicación',
      locationNamePlaceholder: 'Introduce el nombre de la ubicación',
      businessZip: 'Código postal comercial',
      businessZipPlaceholder: 'Ingrese su codigo postal',
      selectLocation: 'Selecciona la ubicación',
      oldEmail: 'Correo electrónico viejo',
      newEmail: 'Nuevo Email',
      emailNotifyLabel: 'Idioma de notificación por correo electrónico',
      secretSymbolLabel: 'Periodo de validez del símbolo secreto',
      searchBusiness:
        'Buscar por nombre comercial o correo electrónico (Un mínimo de 3 caracteres)',
      searchUsers: 'Buscar por nombre comercial o correo electrónico (Un mínimo de 3 caracteres)',
      searchTransactionMerch: 'Buscar por nombre comercial (Un mínimo de 3 caracteres)',
      searchTransactionCustomer:
        'Buscar por nombre de usuario o teléfono (Un mínimo de 3 caracteres)s',
      fullnamePlaceholder: 'Ingrese su nombre completo',
      usernamePlaceholder: 'Ingrese su nombre de usuario',
      commentPlaceholder: 'Ingrese su comentario',
      timeOpenPlaceholder: 'Seleccionar tiempo abierto',
      timeClosePlaceholder: 'Seleccionar hora de cierre',
      QRCode: 'Tipo de código QR',
      QRCodePlaceholder: 'Seleccione tipo',
      language: 'Idioma',
      languagePlaceholder: 'Elige lengua',
      accountPhone: 'Teléfono de la cuenta',
      servicePhone: 'Teléfono de servicio al cliente',
      accountEmail: 'Email de la cuenta',
      serviceEmails: 'Email de servicio al cliente',
      serviceEmailsToReceive: 'para recibir',
      emailNotifications: 'notificaciones por email',
      hint: 'por favor dar “enter” después de ingresar la dirección de correo electrónico',
    },
    // Validation
    validation: {
      zipRequired: '¡Este campo debe ser obligatorio y consta de números!',
      agreeRules: 'Campo obligatorio',
      accountTypeRequired: '¡El tipo de cuenta es obligatorio!',
      nameRequired: '¡El nombre es obligatorio!',
      emoji: 'El emoji no está permitido',
      locationNameRequired: '¡El nombre de la ubicación es obligatorio!',
      nameLength: `¡El nombre debe contener un mínimo de ${validationConst.MIN_NAME_LENGTH} caracteres!`,
      fullNameRequired: '¡El nombre completo es obligatorio!',
      emailRequired: '¡El email es obligatorio!',
      emailNotValid: 'Introduce una dirección de email válida',
      emailsAlreadyTaken: 'El email ya existe',
      usernameNotExist: 'El usuario no existe',
      urlRequired: '¡La URL es obligatoria!',
      urlNotValid: '¡La URL no es válida!',
      passwordRequired: '¡La contraseña es obligatoria!',
      passwordLength: `¡La contraseña debe contener un mínimo de ${validationConst.MIN_PASSWORD_LENGTH}
        caracteres!`,
      passwordDontMatch: '¡Las contraseñas no coinciden!',
      selectRequired: 'Necesitas seleccionar un valor',
      businessNameRequired: '¡El nombre del negocio es obligatorio!',
      businessNameLength: `¡El nombre del negocio debe contener un mínimo de
        ${validationConst.MIN_BUSINESS_NAME_LENGTH}
        caracteres!`,
      businessNameMaxLength: `El nombre del negocio debe tener menos de
        ${validationConst.MAX_BUSINESS_NAME_LENGTH}
        caracteres!`,
      businessNameAlreadyTaken: 'Este nombre de negocio ya existe',
      organizationNameLength: `¡El nombre del negocio debe contener un mínimo de
        ${validationConst.MIN_BUSINESS_NAME_LENGTH}
        caracteres!`,
      organizationNameAlreadyTaken: 'Este nombre de negocio ya existe',
      locationRequired: '¡La ubicación es obligatoria!',
      locationInvalid: '¡La ubicación no es válida! Intente volver a seleccionarlo.',
      onlySpainBusiness: 'Lazo está disponible solo en España.',
      giftTypeRequired: '¡El tipo de regalo es obligatorio!',
      descriptionMaxLength: `¡La descripción no puede superar los
        ${validationConst.MAX_DESCRIPTION_LENGTH} caracteres!`,
      descriptionVideoMaxLength: `Description should not be more than
        ${validationConst.MAX_PROMO_VIDEO_DESCRIPTION_LENGTH} characters long!`,
      categoryRequired: '¡La categoría es obligatoria!',
      tagsMaxLength: `¡Las etiquetas no pueden superar los ${validationConst.MAX_TAGS_LENGTH} caracteres!`,
      maxUploadImageSize: `La imagen supera el tamaño máximo permitido.
      Sube una imagen de menos de ${validationConst.MAX_IMAGE_SIZE / 1048576} MB`,
      quantityRequired: '¡La cantidad es obligatoria!',
      costRequired: '¡El precio es obligatorio!',
      costLength: '¡El precio debe ser mayor que {{minPrice}}!',
      costMaxLength: `El precio no debe ser mayor que ${validationConst.MAX_GIFT_COST}!`,
      amountRequired: '¡La cantidad es obligatoria!',
      amountLength: '¡El precio debe ser mayor que {{minPrice}}!',
      priceLength: '¡El importe mínimo debe ser mayor que {{minPrice}}!',
      amountMaxLength: `La cantidad no debe ser mayor que ${validationConst.MAX_GIFT_CARD_AMOUNT}!`,
      amountToHigh: '¡La cantidad no debe ser mayor que el saldo!',
      amountRangeRequired: '¡Rango de importe obligatorio!',
      amountFromMoreThanTo: 'Desde no debe ser mayor que Hasta',
      amountToLessThenFrom: 'Para no debe ser menor que De',
      amountFromEqualTo: 'Desde no debe ser igual a Hasta',
      amountMustBeInRange: 'El importe debe situarse dentro del rango',
      discountMinLength: `¡El descuento no debe ser menor que
        ${validationConst.MIN_GIFT_DISCOUNT}!`,
      discountMaxLength: `¡El descuento no debe ser mayor que ${validationConst.MAX_GIFT_DISCOUNT}!`,
      redeemCodeTypeRequired: '¡El tipo de código de canje es obligatorio!',
      typeOfCodeGeneration: '¡El tipo de generación de código es obligatorio!',
      specificQuantityRequired: '¡La cantidad específica es obligatoria!',
      specificQuantityLength: `¡La cantidad específica no debe ser menor que
        ${validationConst.MIN_GIFT_SPECIFIC_QUANTITY}!`,
      amountMin: 'La cantidad no puede ser menor que {{min}}',
      amountMax: 'La cantidad no puede ser mayor que {{max}}',
      phoneNotValid:
        'Introduce un número de teléfono válido. US: +1**********, ' +
        'MX: +52***********, ES: +34 * **** ****',
      phoneRequired: '¡El teléfono es obligatorio!',
      newPasswordRequired: '¡La contraseña nueva es obligatoria!',
      confirmNewPasswordRequired: '¡La confirmación de contraseña es obligatoria!',
      cardHolderNameRequired: '¡El nombre del titular es obligatorio!',
      cardNumberRequired: '¡El número de tarjeta es obligatorio!',
      expirationMonthRequired: '¡El mes de vencimiento es obligatorio!',
      usersRequired: '¡El usuarios es obligatorio!',
      expirationYearRequired: '¡El año de vencimiento es obligatorio!',
      securityCodeRequired: '¡El código de seguridad es obligatorio!',
      organizationNameRequired: '¡El nombre de negocio es obligatorio!',
      organizationRequired: '¡El negocio es obligatorio!',
      codeRequired: '¡El código es obligatorio!',
      shouldBeNumeric: '¡Debe ser numérico!',
      position: '¡El campo de Posición es obligatorio!',
      categoryNameRequired: '¡El nombre de categoría es obligatorio!',
      countryRequired: '¡El país es obligatorio!',
      surveyNameRequired: '¡El nombre de la encuesta es obligatorio!',
      percent: 'La comisión de Lazo es obligatoria',
      typeRequired: 'El tipo es obligatorio',
      template: 'El modelo de contrato es obligatorio',
      secretSymbolRequired: 'Se requiere símbolo secreto',
      userNameRequired: 'Se requiere nombre de usuario',
      languageRequired: 'Se requiere lenguaje',
      permissionsRequired: 'Se requieren permisos',
      commentRequired: 'Se requiere comentario',
      commentMaxLength: `El comentario no debe ser más de
        ${validationConst.MAX_COMMENT_LENGTH} Caracteres largas!`,
      openTimeRequired: 'Se requiere tiempo abierto',
      closeTimeRequired: 'Se requiere tiempo de cierre',
      QRCodeTypeRequired: 'Se requiere el tipo de código QR',
      categoryIconRequired: 'El icono es obligatorio',
      termsRequired: 'Se requieren condiciones de uso',
      privacyRequired: 'Se requiere política de privacidad',
      logoRequired: 'Se requiere el logo',
      coverRequired: 'Se requiere cubierta',
      expirationTypeRequired: 'Se requiere el tipo de vencimiento',
      expirationDays: `El día de caducidad debe ser válido y no puede ser más de ${validationConst.MAX_DAYS}`,
      expirationDateRequired: 'Se requiere fecha de vencimiento',
      expirationDayRequired: 'Se requiere el día de vencimiento',
      distributeGiftRequired: 'Distribuir regalo es obligatorio',
      locationsRequired: 'Se requiere ubicaciones',
      promotionVideoDuration: `La duración máxima del video es de ${MAX_DURATION} segundos`,
      titleMaxLength: `El título no puede ser mayor que ${validationConst.MAX_TITLE_DISCOUNT}!`,
      bodyMaxLength: `La descripción no puede ser mayor que ${validationConst.MAX_DESCRIPTIONS_DISCOUNT}!`,
      textRequired: 'El título es obligatorio',
      bodyRequired: 'Se requiere una descripción',
      notificationDateRequired: 'Se requiere fecha',
      notificationDate: 'Esta fecha no encaja',
    },
    // Notification
    notification: {
      confirmedEmail: 'Tu email se ha confirmado correctamente',
      accountCreated: '¡Cuenta creada correctamente!',
      resetPassSuccess:
        'Hemos enviado un mensaje de recuperación de contraseña a tu dirección de email.',
      changePassSuccess: 'La contraseña se ha cambiado correctamente',
      passSuccessSet: 'Tu contraseña se ha establecido correctamente',
      addLocationAndEmployeeSuccess: 'Ubicación y Empleado agregados correctamente',
      addEmployeeSuccess: 'Empleado agregado correctamente',
      addLocationsSuccess: 'Ubicaciones agregadas correctamente',
      editLocationsSuccess: 'Ubicaciones editadas correctamente',
      deleteLocationsSuccess: 'Ubicaciones eliminadas correctamente',
      paymentConnectSuccess: 'Conéctate a Stripe',
      saveGiftSuccess: 'El regalo se guardó correctamente',
      deleteGiftSuccess: 'El regalo se eliminó correctamente',
      cardIsUpdated: 'La cuenta está actualizada',
      giftChangeQuantitySuccess: 'La datos de regalos se han modificado correctamente',
      formErrors: 'Hay errores en el formulario. Por favor, arréglalo antes de continuar.',
      giftDuplicateSuccess: 'El regalo se duplicó correctamente',
      editEmployeeSuccess: 'El Empleado se guardó correctamente',
      importEmployeesSuccess: 'Se invitó exitosamente al empleado',
      importEmployeeFail: 'La importación falló, revisa el archivo',
      sendDirectSuccess: 'El regalo se envió exitosamente',
      inviteSent: 'Se envió la invitación',
      createOrganization:
        'El negocio se ha creado correctamente. Se envió la invitación para el contrato.',
      saveAsDraft: 'El negocio se guardó como borrador',
      resendContract: 'El contrato se reenvió correctamente',
      createCategory: 'La categoría se creó correctamente',
      createSubCategory: 'La subcategoría se creó correctamente',
      promoGroup: 'Grupo promocional se creó correctamente',
      editCategory: 'La categoría se editó correctamente',
      editSubCategory: 'La subcategoría se editó correctamente',
      deleteCategorySuccess: 'La categoría se eliminó correctamente',
      deleteSubCategorySuccess: 'La subcategoría se eliminó correctamente',
      deletePromoGroupSuccess: 'Grupo de promoción eliminado correctamente',
      createSurvey: 'La encuesta se creó correctamente',
      editSurvey: 'La encuesta se editó correctamente',
      planWillBeActivated: 'El plan {{planName}} se activará a partir del {{startDate}}',
      changesSaved: 'Los cambios se guardaron correctamente',
      changePlanWarning:
        'Estamos procesando tu solicitud. El cambio de plan de precios puede tardar hasta 30 minutos. Durante este tiempo no se puede hacer ningún otro cambio en el plan.',
      resendEmail: 'Revise su correo electrónico y haga clic en el enlace para confirmar',
      exportStatistics: 'Estadísticas exportadas con éxito',
      generateSecretSymbol: 'Símbolo secreto actualizado con éxito',
      createAdmin: 'Administrador creado correctamente',
      editAdmin: 'Administrador editado correctamente',
      deleteAdmin: 'Administrador eliminado correctamente',
      blockAdmin: 'Administrador bloqueado correctamente',
      unBlockAdmin: 'Administrador desbloqueado correctamente',
      privacy: 'Política de privacidad guardada correctamente',
      terms: 'Condiciones de uso guardadas correctamente',
    },
    // Alerts
    alerts: {
      toSellGiftsPart1: 'Para vender regalos',
      toSellGiftsPart2: 'Por ahora, puedes guardarlos como borrador.',
      toSellGiftsPart3: 'Tienes un problema en',
      toSellGiftsPart4: 'Por ahora, puedes guardarlos como borrador o crear regalos gratis.',
    },
    // Complete Profile Dialog
    completeProfileDialog: {
      stripe: 'Inicia sesión en “Stripe connect”',
      square: 'Inicia sesión en “Square connect”',
      stripeConnect: 'Conéctate a Stripe',
      title: 'Completa tu perfil',
      error: 'Algo salió mal, intenta de nuevo',
      addLocation: 'Agrega una ubicación',
      businessLogo: 'Logotipo de empresa',
      logoDescription:
        'Sube el logotipo de tu negocio para crear tarjetas de regalo estandarizadas. De esta manera, el proceso de incorporación es mucho más simple y rápido.',
      addFirstEmployee: 'Agrega tu primer empleado',
      connectDebitCard: 'Asocia tu tarjeta de débito/crédito',
      paymentConnectTitle: '{{payment}} connect',
      connectWith: 'Conectar con',
      paymentConnectSubtitle:
        'Para poderte enviar de forma efectiva y eficiente\n el dinero de todas tus ventas de regalos en Lazo,\n deberás crear una cuenta de {{payment}}. Nuestra plataforma te \nllevará a {{payment}}. Una vez que finalices el proceso,\n volverás automáticamente a Lazo para continuar con el proceso de incorporación.',
      steps: {
        step1Title: 'Sube el logotipo de tu empresa',
        step1Subtitle: 'Para usarlo en tus tarjetas de regalo',
        step2Title: 'Inicia sesión en Stripe connect',
        step2Subtitle: 'Para empezar a recibir dinero',
      },
      paymentToManaging:
        'Para administrar las cuentas asociadas dirígete al dashboard de {{payment}} a través de',
    },
    // Duplicate Gift Dialog
    duplicateGiftDialog: {
      title: 'Duplicar regalo',
      text: `La información de los regalos no se puede editar si se hace una venta. Puedes duplicar \n
       el regalo si el regalo existente está bloqueado. `,
    },
    // Filters Dialog
    filtersDialog: {
      title: 'Filtros',
      contentTitle: 'Muéstrame',
      all: 'Todo',
      user: 'Usuario',
      business: 'Negocio',
    },
    // Error Boundary Page
    errorBoundary: {
      title: 'Something went wrong',
      subtitle:
        'Press the "Reload page" button for a reload current page. We apologize for the inconvenience.',
    },
    // Sign In Page
    signIn: {
      title: 'Iniciar sesión',
      forgetPasswordLink: '¿Olvidaste la contraseña?',
      noAccount: '¿No tienes una cuenta?',
      signUpLink: 'Regístrate',
    },
    // Sign Up Page
    signUp: {
      title: 'Crea tu cuenta',
      haveAccount: '¿Ya tienes una cuenta?',
      signInLink: 'Inicia sesión',
      agreeWithRules: 'Al registrarte, aceptas nuestra',
      and: 'y',
      privacyLink: 'política de privacidad',
      termsLink: 'condiciones de uso',
      merchAgreement: 'acuerdo comercial',
      steps: {
        account: 'Cuenta',
        security: 'Seguridad',
        organization: 'Negocio',
        plan: 'Plan',
      },
      businessLocationError: 'Seleccione una dirección válida con una ciudad y código postal',
      businessPlaceIdError: 'Direccion incorrecta',
      successRegistrationTitle: 'Revisa tu correo electrónico',
      successRegistration: 'Hemos enviado un correo electrónico de verificación a {{email}}',
      resend: '¿Nos necesita para reenviarlo?',
    },
    // Reset Password Page
    resetPassword: {
      title: 'Restablecer contraseña',
      subtitle: `Estamos aquí para ayudarte a recuperar tu contraseña. \n
        Introduce la dirección de email que usaste cuando te registraste \n
        y te enviaremos instrucciones para restablecer la contraseña`,
    },
    // Update Password Page
    updatePassword: {
      title: 'Actualizar contraseña',
      createTitle: 'Crear contraseña',
    },
    // NotFound Page
    notFound: {
      title: '404',
      subTitle: 'Página no encontrada',
    },
    // Resend Email Dialogs
    resendEmailDialog: {
      title: 'Reenviar email',
    },
    // Add Location Dialog
    addLocationDialog: {
      title: 'Agregar una sola ubicación',
      editTitle: 'Editar ubicación',
    },
    // Overview Page
    overview: {
      title: 'Resumen',
      emptyMessage: 'No has creado ningún regalo todavía.',
      total_sent: 'Total enviados',
      total_redeemed: 'Total canjeados',
      total_views: 'Total vistas',
    },
    // Gift Common
    giftCommon: {
      expirationStart: 'Inicio del plazo:',
      expirationEnd: 'Fin del plazo:',
      locations: 'Ubicaciones',
      selectGiftType: 'Selecciona el tipo de regalo',
      selectGiftSpread: 'Elige el alcance',
      giftType: 'Tipo de regalo',
      mainInformation: 'Información principal',
      giftDetails: 'Detalles del regalo',
      giftCardDetails: 'Detalles de la tarjeta de regalo',
      costAndQuantity: 'Precio y cantidad',
      coverImageTitle: 'Imagen de portada',
      coverImageSubtitle: `Tamaño de imagen recomendado de
        ${imagesConst.GIFT_COVER_WIDTH}x${imagesConst.GIFT_COVER_HEIGHT},
         relación de aspecto de 16:9`,
      detailImageTitle: 'Imágenes de detalles del regalo',
      detailImageSubtitle: `Tamaño de imagen recomendado de
        ${imagesConst.GIFT_DETAIL_WIDTH}x${imagesConst.GIFT_DETAIL_HEIGHT} y
        relación de aspecto de 16:9`,
      coverPhoto: 'Foto de portada',
      giftDetailPhoto: 'Foto de detalles de regalo',
      cropYourPhoto: 'Recorta la foto',
      success_published: 'El regalo se publicó correctamente',
      success_drafted: 'El regalo se guardó correctamente como borrador',
      success_archived: 'Regalo archivado correctamente',
      quantityUnlimitedHelpText:
        'Una vez que los códigos del archivo CSV se agoten, Lazo generará nuevos automáticamente',
      quantitySpecificHelpText:
        'No te preocupes. Puedes especificar la cantidad exacta justo después de importar el CSV',
      openAmountHelpText:
        'Los importes abiertos permiten que los clientes especifiquen un monto exacto del rango configurado.',
      steps: {
        giftType: 'Tipo de regalo',
        mainInfo: 'Información principal',
        giftDetails: 'Detalles del regalo',
        giftCardDetails: 'Detalles de la tarjeta de regalo',
        costAndQuantity: 'Precio y cantidad',
        overview: 'Resumen',
      },
      warning: {
        autogeneratedGiftCard:
          '¡Hola! Escuchamos tus recomendaciones e hicimos algunos ajustes. Para simplificar el alta de cuentas y la creación de tarjetas de regalo, a partir de ahora, Lazo creará automáticamente tarjetas de regalo con montos fijos y una con monto abierto. Si tienes alguna duda, comunícate con',
        supportTeam: 'el equipo de soporte de Lazo',
      },
      tabs: {
        web: 'Web',
        mobile: 'Móvil',
      },
      radioButtons: {
        gift: 'Regalo',
        giftCard: 'Tarjeta de regalo',
        giftCity: 'Limitado a ciudades específicas',
        giftCountry: 'Nacional',
      },
      inputs: {
        cities: 'Ciudades',
        giftName: 'Nombre del regalo',
        giftNamePlaceholder: 'Este es el nombre que verán los clientes',
        description: 'Descripción',
        descriptionPlaceholder: 'Introduce la descripción de tu regalo',
        categories: 'Categorías',
        status: 'Estado',
        statusPlaceholder: 'Selecciona una estado',
        categoryPlaceholder: 'Selecciona una categoría',
        place: 'Elige las ciudades',
        placePlaceholder: 'Selecciona las ciudades',
        tags: 'Etiquetas',
        tagsPlaceholder: 'Introduce etiquetas de búsqueda',
        giftType: 'Tipo de regalo',
        cost: 'Precio',
        amount: 'Importe',
        chooseAmountPlaceholder: 'Elige el importe',
        customAmount: 'Importe específico',
        discount: 'Descuento',
        quantity: 'Cantidad',
        redeemCodeType: 'Tipo de código de canje',
        typeOfCodeGeneration: 'Tipo de generación de código',
        redemptionFrequency: 'Frecuencia de canje',
        redemptionFrequencyPlaceholder: 'Selecciona una vez al día/semana/mes',
        specificQuantity: 'Cantidad específica',
        specificQuantityPlaceholder: 'Especifica la cantidad',
        openAmount: 'Importe abierta',
        businessName: 'Todos los negocios',
        expirationType: 'Tipo de vencimiento',
        expiredTypePlaceholder: 'Seleccione el tipo de vencimiento',
        expiration: 'Plazo de vencimiento en días (a partir de la fecha de publicación)',
        expirationEdit: 'Plazo de vencimiento en días (a partir de la fecha de actualización)',
        expirationDayPlaceHolder: 'Ingrese el día de vencimiento',
        expirationDatePlaceHolder: 'Elija la fecha de vencimiento',
      },
      giftPaymentTypes: {
        paid: 'Pagado',
        free: 'Gratis',
        expiration_free: 'Gratis con vencimiento',
        geo_gift: 'Regalo geográfico',
      },
      giftTypes: {
        gift_card: 'Tarjeta de regalo',
        gift: 'Regalo',
      },
      quantityTypes: {
        unlimited: 'Ilimitada',
        specific: 'Específica',
      },
      redeemCodeTypes: {
        alphanumeric: 'Alfanumérico',
        barcode: 'Código de barras',
        qr_code: 'Código QR',
      },
      redemptionFrequency: {
        day: 'Una vez al día',
        week: 'Una vez a la semana',
        month: 'Una vez al mes',
      },
      expirationPeriod: {
        day: 'Día',
        custom_date: 'Fecha personalizada',
      },
      typesOfCodeGeneration: {
        automatic: 'Automático (generado por Lazo)',
        personal: 'Personal (Subir CSV)',
      },
      priceCalculator: {
        youWillGet: 'Obtendrás:',
        altruusFee: 'Tarifa de Lazo',
        altruusFeeVAT: 'Lazo IVA',
        paymentFee: 'Comisión del procesador de pagos',
        paymentVAT: 'IVA del procesador de pagos',
        totalFee: 'Comisión total',
        discountPrice: 'Precio con descuento',
      },
    },
    // Gifts Page
    gifts: {
      title: 'Regalos',
      sendDirectTitle: 'Enviar regalo directo',
      emptyMessage: 'Aún no has creado regalos.',
      emptyMessageBusiness: 'Negocio no he creado regalos todavía.',
      emptySearchResults:
        'Tu búsqueda “{{query}}” arrojó {{results}} resultados. Inténtalo con otro término.',
    },
    // Create Gift Page
    createGift: {
      title: 'Crear regalo',
    },
    // Edit Gift Page
    editGift: {
      title: 'Editar regalo',
    },
    // Change Quantity Page
    changeQuantity: {
      title: 'Cambiar cantidad / descuento',
      title2: 'Cambiar cantidad y vencimiento',
      quantity: 'Cantidad',
      quantityAndExpiration: 'Cantidad y vencimiento',
    },
    // Detail Gift Page
    detailGift: {
      total_sent: 'Total enviados',
      total_redeemed: 'Total canjeados',
      total_views: 'Total vistas',
      sent: 'Enviados',
      redeemed: 'Canjeados',
      giftViews: 'Vistas de regalos',
      transactionHistory: 'Historial de transacciones',
      table: {
        transactionId: 'ID de transacción',
        date: 'Fecha',
        user: 'Usuario',
        recipient: 'Recipiente',
        price: 'Precio',
        status: 'Estado',
        paymentStatus: 'Estado de pago',
      },
    },
    // Balance Page
    balance: {
      title: 'Saldo',
      emptyMessage: 'Aún no hay datos de ventas.',
      totalSales: 'Total de ventas',
      totalFeels: 'Total de comisiones',
      totalTips: 'Total de propina',
      incomeOverview: 'Resumen de ingresos',
      income: 'Ingresos',
      tips: 'Propinas',
      transactionsList: {
        lastTransactions: 'Transacciones recientes',
        showMoreTransactions: 'Mostrar todo el historial de transacciones',
        emptyMessage: 'Aún no hay transacciones.',
        sentDirect: 'envió regalo directo',
        sent: 'enviado',
        fee: 'Comisión',
      },
    },
    // Transactions History
    transactionsHistory: {
      title: 'Historial de transacciones',
      table: {
        date: 'Fecha',
        giftName: 'Nombre del regalo',
        giftType: 'Tipo de regalo',
        customer: 'Cliente',
        tips: 'Propina',
        fee: 'Comisión',
        price: 'Precio',
        status: 'Estado',
      },
      emptyMessage: 'Aún no hay datos de ventas.',
    },
    // Transactions list
    transactionsList: {
      breadcrumbs: 'Transacción',
      title: 'Lista de transacciones',
      tabs: {
        merchandisers: 'Comerciantes',
        customers: 'Clientes',
        waiting: 'Esperando seguro',
        blocked: 'Bloqueado',
      },
      table: {
        time: 'Hora de creación (UTC)',
        organizationName: 'Nombre de negocio',
        userName: 'Usuario',
        sender: 'Remitente',
        recipient: 'Recipiente',
        id: 'ID de la transacción',
        status: 'Estado',
        giftType: 'Tipo de regalo',
        price: 'Precio',
        tips: 'Propina',
        deliveryTime: 'El tiempo de entrega (UTC)',
        giftPrice: 'Precio del regalo',
        paymentStatus: 'Estado de pago',
      },
      promptModal: {
        title: 'Desbloquear transacción',
        text: '¿Está seguro de que desea desbloquear esta transacción?',
      },
      successBlocked: 'La transacción ha sido bloqueada con éxito.',
      successUnblocked: 'La transacción se ha desbloqueado con éxito.',
    },
    // Users page
    users: {
      title: 'Usuarios de móviles',
      emptyMessage: 'Aún no tienes usuarios.',
      onlineUsers: 'Usuarios en linea',
      modal: {
        title: 'Limitar el acceso a este usuario',
        text: '¿Está seguro de que desea limitar el acceso a este usuario?',
        confirm: 'El usuario está verificado',
        block: 'El usuario está restringido en el acceso',
        blockBalance: '¿Estás seguro de que quieres bloquear el saldo virtual?',
      },
      table: {
        username: 'Usuario',
        fullName: 'Nombre completo',
        name: 'Nombre',
        sent: 'Enviados',
        received: 'Recibidos',
        redeemed: 'Canjeados',
        total: 'Total',
        total_redeemed: 'Total canjeados',
        total_purchased: 'Total comprados',
        registration_date: 'Registro',
        sendGift: 'enviar regalo',
        date: 'Fecha',
        status: 'Estado de la cuenta',
        giftName: 'Nombre del regalo',
        giftType: 'Tipo de regalo',
        price: 'Precio',
        referral_users: 'Usuarios de referencia',
        virtual_balance: 'Balance virtual',
      },
    },
    subCategories: {
      emptyMessage: 'La categoría todavía no tiene subcategorías.',
      createSubCategory: 'Crear subcategoría',
      editSubCategory: 'Editar subcategoría',
      table: {
        subCategoryName: 'Nombre de la subcategoría',
      },
      inputs: {
        name: 'Nombre de la subcategoría',
        namePlaceholder: 'Introduce el nombre de la subcategoría',
        tags: 'Etiquetas',
        tagsPlaceholder: 'Introduce etiquetas de búsqueda',
        categoryCover: 'Imagen de portada',
        subcategoryIcon: 'Icono de subcategoría',
        categoryIconSubtitle: `Tamaño de imagen recomendado ${imagesConst.CATEGORY_ICON_WIDTH}x${imagesConst.CATEGORY_ICON_HEIGHT}`,
        categoryCoverSubtitle: `Tamaño de imagen recomendado
          ${imagesConst.CATEGORY_COVER_WIDTH}x${imagesConst.CATEGORY_COVER_HEIGHT},
           relación de aspecto de 16:9`,
      },
    },
    categories: {
      tabs: {
        categories: 'Categorías',
        promoGroups: 'Grupos promocionales',
      },
      title: 'Categorías',
      createCategory: 'Crear categoría',
      editCategory: 'Editar categoría',
      table: {
        name: 'Nombre de categoría',
        subcategories: 'Subcategorías',
      },
      inputs: {
        name: 'Nombre de la categoría',
        namePlaceholder: 'Introduce el nombre de la categoría',
        tags: 'Etiquetas',
        tagsPlaceholder: 'Introduce etiquetas de búsqueda',
        categoryIcon: 'Ícono de categoría',
        categoryCover: 'Imagen de portada',
        categoryIconSubtitle: `Tamaño de imagen recomendado ${imagesConst.CATEGORY_ICON_WIDTH}x${imagesConst.CATEGORY_ICON_HEIGHT}`,
        categoryCoverSubtitle: `Tamaño de imagen recomendado
          ${imagesConst.CATEGORY_COVER_WIDTH}x${imagesConst.CATEGORY_COVER_HEIGHT},
            relación de aspecto de 16:9`,
      },
    },
    promoGroups: {
      title: 'Grupos promocionales',
      createGroup: 'Crear grupo promocional',
      editGroup: 'Editar grupo promocional',
      showOnGiftIdeas: 'Mostrar en ideas de regalo',
      table: {
        name: 'Nombre del grupo',
        subcategories: 'País',
      },
      inputs: {
        subcategories: 'País',
        countryPlaceholder: 'Elegir país',
        name: 'Nombre del grupo de promoción',
        namePlaceholder: 'Ingrese el nombre del grupo',
        categories: 'Categorías',
        categoryCover: 'Imagen de portada',
        categoryPlaceholder: 'Selecciona una categoría',
        categoryCoverNotice:
          'Por favor, use los mismos tamaños de imágenes dentro de un grupo de promoción para una mejor experiencia de usuario.',
        categoryCoverSubtitle: `Tamaño de imagen recomendado ${imagesConst.PROMO_GROUP_RECOMMENDED_ICON_WIDTH}x${imagesConst.PROMO_GROUP_ICON_HEIGHT} o ${imagesConst.PROMO_GROUP_ICON_WIDTH}x${imagesConst.PROMO_GROUP_ICON_HEIGHT}`,
      },
    },
    //  Admins Page
    admins: {
      title: 'Administradores',
      emptyMessage: 'Aún no ha creado administradores.',
      createAdmin: 'Crear administrador',
      editAdmin: 'Editar administrador',
      permissions: 'Permisos',
      allCountries: 'Todos los países',
      allStatuses: 'Todos los estados',
      detail: {
        adminId: 'ID de administrador',
        username: 'Nombre de usuario',
        fullName: 'Nombre completo',
        email: 'Email',
        permissions: 'Permisos',
        status: 'Estado',
      },
      table: {
        name: 'Nombre',
        email: 'Email',
        role: 'El rol',
        permissions: 'Permisos',
        status: 'Estado',
      },
      checkboxes: {
        allCountries: 'Todos los países',
      },
      inputs: {
        username: 'Nombre de usuario',
        userNamePlaceholder: 'Introduzca su nombre de usuario',
        fullName: 'Nombre completo',
        fullNamePlaceholder: 'Ingrese el nombre completo',
        email: 'Email',
        emailPlaceholder: 'Ingrese email',
        language: 'Idioma',
        languagePlaceholder: 'Elige lengua',
        permissions: 'Permisos por pais',
        permissionsPlaceholder: 'Elige país',
        country: 'País',
        countryPlaceholder: 'Elige país',
      },
    },
    // Analytics page
    analytics: {
      title: 'Analítica',
      emptyMessage: 'Aún no tienes ninguna analítica.',
      businessesRegistrations: 'Registros de empresas',
      businesses: {
        title: 'Analytics para empresas',
      },
      tabs: {
        general: 'General',
        users: 'Usuarios',
        gifts: 'Regalos',
        businesses: 'Empresas',
      },
      general: {
        screenTitle: 'Título de la pantalla',
        countOfView: 'Recuento de visitas',
        timeOnTheScreen: 'Tiempo en la pantalla',
        topPopularCategories: 'Principales categorías populares',
        leavingThePurchase: 'Saliendo de la compra',
        addEmotion: 'Añade emoción',
        checkout: 'Verificar',
        countOfScreenView: 'Recuento de visualizaciones de pantalla',
        averageTimeOnScreen: 'Tiempo medio en pantalla',
        categoryName: 'nombre de la categoría',
        categoryView: 'vista de categoría',
        emotionsAnalytics: 'Analítica de emociones',
        countOfAddedEmotions: 'Cuenta de emociones agregadas',
        sentGiftEmotions: 'Envió emociones de regalos',
        thankYouEmotion: 'Gracias emocion',
        video: 'Video',
        photo: 'Foto',
        text: 'Texto',
        audio: 'Audio',
        shareReferralLinks: 'Comparte enlaces de referencia',
        shareInFacebook: 'Comparte emoción en facebook',
        shareInInstagram: 'Comparte emoción en instagram',
        shareInTwitter: 'Comparte emoción en twitter',
        shareInSocialNetworks: 'Comparte emoción en otras redes sociales',
        popularSearching: ' Solicitudes de búsqueda populares',
      },
      gifts: {
        topPopularGifts: 'Top Popular Gifts',
        sent: 'Enviados',
        redeems: 'Canjeados',
        views: 'Vistas',
        giftsSum: 'Suma de regalos',
      },
      users: {
        topPopularUsers: 'Usuarios más populares',
        activeUsersInLastTime: 'Usuarios activos EN LOS ÚLTIMOS 5 MINUTOS',
        averageSessionTime: 'Tiempo medio de la sesión',
        allUsersInSystem: 'Todos los usuarios del sistema',
        activeUsersInPeriod: 'Usuarios activos en el período',
        countOfRegistration: 'Recuento de inscripciones',
        appInstallsInPeriod: 'La aplicación se instala en el período',
        appRemovesInPeriod: 'La aplicación se quita en el período',
        leavingThePurchase: 'Saliendo de la compra',
        addEmotion: 'Añade emoción',
        checkout: 'Verificar',
      },
      tops: {
        name: 'Nombre',
        view: 'Vista',
      },
      sum: {
        name: 'Nombre',
        count: 'Contar',
        sum: 'Suma',
      },
    },
    // Surveys Page
    surveys: {
      mainInformation: 'Información principal',
      questions: 'Preguntas',
      createSurveys: 'Crear encuesta',
      editSurveys: 'Editar encuesta',
      title: 'Encuestas',
      emptyMessage: 'Aún no has creado encuestas.',
      statistic: 'Estadísticas',
      status: 'Estado',
      progress: 'Progreso',
      questionType: {
        category: 'Categoría',
        gift: 'Regalo',
        organization: 'Negocio',
      },
      input: {
        name: 'Nombre de la encuesta',
        namePlaceholder: 'Introduce el nombre de la encuesta',
        quantity: '¿A cuántas personas se debe preguntar?',
        quantityPlaceholder: 'Selecciona la cantidad',
        rewardGift: 'Regalo de recompensa',
        typePlaceholder: 'Categoría de la pregunta',
      },
      table: {
        name: 'Nombre de la encuesta',
        progress: 'Progreso',
        status: 'Estado de la encuesta',
        like: 'A {{renderQuantity}} les gusta este {{type}}',
      },
    },
    // Customers Page
    customers: {
      title: 'Clientes',
      emptyMessage: 'Aún no tienes clientes.',
      table: {
        name: 'Nombre',
        sent: 'Enviados',
        received: 'Recibidos',
        redeemed: 'Canjeados',
        total: 'Total',
        sendGift: 'enviar regalo',
        date: 'Fecha',
        giftName: 'Nombre de regalo',
        giftType: 'Tipo de regalo',
        price: 'Precio',
      },
    },
    // Customers Details Page
    detailsCustomer: {
      card: {
        name: 'Nombre',
        sent: 'Enviados',
        purchased: 'Comprados',
        redeemed: 'Canjeados',
        totalPurchased: 'Total comprados',
        totalRedeemed: 'Total canjeados',
      },
    },
    // Inbox Page
    inbox: {
      post: 'mensajes',
      title: 'Buzón de entrada',
      tab: {
        all: 'Todos',
        customers: 'Clientes',
        altruus: 'Lazo',
      },
      emptyMessage: 'Aún no tienes notificaciones.',
    },
    disputes: {
      title: 'Disputas',
      emptyMessage: 'Aún no tienes disputas.',
      table: {
        time: 'Crear en',
        id: 'ID',
        transaction: 'Transacción',
        paymentType: 'Tipo de pago',
        reason: 'Razón',
        state: 'Expresar',
      },
      AMOUNT_DIFFERS: 'diferencia de cantidad',
      fraudulent: 'fraudulenta',
      warning_needs_response: 'advertencia necesita respuesta',
      EVIDENCE_REQUIRED: 'evidencia requerida',
      needs_response: 'necesita respuesta',
      CANCELLED: 'cancelada',
      DUPLICATE: 'duplicar',
      NO_KNOWLEDGE: 'ningún conocimiento',
    },
    myProfile: {
      title: 'Mi perfil',
      tabs: {
        mainInfo: 'Información principal',
        security: 'Seguridad',
        billing: 'Facturación',
        emailNotifications: 'Notificaciones por email',
      },
    },
    // Main Info Tab
    mainInfo: {
      saveSuccess: 'Información principal actualizada',
    },
    // Security Tab
    security: {
      saveSuccess: 'Seguridad actualizada',
    },
    // Billing Tab
    billingTab: {
      changeCard: 'Cambiar tarjeta',
      nextPayment: 'El próximo pago recurrente de ${{cost}}/mes se cargará el {{date}}',
      createNewStripeAccount: 'Agregar cuenta de Stripe:',
      createNewSquareAccount: 'Agregar cuenta de Square:',
    },
    // Payment Info
    paymentInfo: {
      title: 'Información de pago',
      currentPlan: 'Plan actual',
      monthlyPayment: 'Pago mensual',
      startDate: 'Fecha de inicio',
      nextRecurringPayment: 'Próximo pago recurrente',
    },
    // Billing Tab Dialog
    billingDialog: {
      changeCardTitle: 'Cambiar tarjeta de crédito/débito',
      changeCardDescription:
        'Si cambias la tarjeta, la información de la tarjeta actual no se guardará. El próximo pago se cargará a la tarjeta nueva.',
    },
    // Switch account dialog
    switchAccountDialog: {
      switchAccount: 'Seleccionar cuenta comercial',
      switchStripeAccount: 'Seleccione una cuenta de Stripe existente',
      switchSquareAccount: 'Seleccionar una cuenta de Square existente',
      selectAccount: 'Seleccionar cuenta comercial',
      selectPaymentAccount: 'Seleccionar cuenta de pago',
      createNewBusiness: 'Crear nuevo negocio',
    },
    // Change plan
    changePlan: {
      title: 'Cambio de plan',
      selectPlan: 'Selecciona un plan',
    },
    // Email Notifications Tab
    emailNotificationsTab: {
      title: 'Quiero recibir un email cuando',
      labels: {
        redeemGift: 'Alguien canjea un regalo',
        sentGif: 'Alguien envía un regalo',
        altruusUpdates: 'Notifíquenme sobre las actualizaciones de Lazo',
        likedPost: 'Alguien le da like a un post',
        notifyGift: 'Alguien notifica un regalo',
        register: 'Alguien se registra',
        addEmotions: 'Alguien manda un mensaje emotivo',
        likedPostOrganization: 'Alguien le da like al negocio',
        redemptionSms: 'Notificación de canje por SMS',
        purchaseSms: 'Notificación de compra por SMS',
      },
      saveSuccess: 'Notificaciones por email actualizadas',
      error: 'Algo salió mal, inténtalo de nuevo',
    },
    // Help page
    help: {
      title: 'Ayuda',
      navigation: 'Navegación',
      tabs: {
        faq: 'Preguntas frecuentes',
        another: 'Otras',
      },
    },
    // Create business by user
    createBusiness: {
      title: 'Crear negocio',
      businessName: 'Nombre del negocio',
      organizationEmail: 'Email',
    },
    // Notify users
    notifyUsers: {
      notifyUsers: 'Notificar a los usuarios',
      inputs: {
        country: 'País',
        title: 'Título',
        body: 'Texto',
        notification_date: 'Notificar fecha',
        isAllUsers: 'Enviar a todos los usuarios',
        selectUsers: 'Seleccionar usuarios',
        notifyAt: 'Notificar en:',
        dateTooltip: 'Si no agrega una hora, se enviará una notificación de inmediato.',
      },
      message: 'los usuarios serán notificados a la hora especificada',
    },
    // Universal card
    universalCards: {
      title: 'Tarjetas universales',
      tabs: {
        users: 'Enviado',
        sent: 'Crear',
      },
      inputs: {
        amount: 'Monto',
        sentCard: 'Tarjeta enviada a los usuarios',
      },
      table: {
        name: 'Nombre de tarjeta',
        date: 'Fecha',
        username: 'Nombre de usuario',
        status: 'Estado',
        converted: 'Convertido en',
        fullAmount: 'Monto total',
        isConverted: 'convertido',
        isntConverted: 'no convertido',
      },
      success: 'Tarjeta enviada al usuario',
    },
    // Organization Page
    organization: {
      title: 'Negocio',
      mainInformation: 'Información principal',
      organizationPreview: 'Vista previa del negocio',
      bestGifts: 'Los mejores regalos',
      logoImage: 'Imagen de logotipo',
      preview: 'Imagen de logotipo',
      coverImage: 'Imagen de portada',
      saveLocations: 'Guardar ubicaciones',
      saveEmployees: 'Invitar empleados',
      saveBusinessLogo: 'Logotipo de empresa subido',
      retry: 'Intentar de nuevo',
      tabs: {
        // change const esTabsLength in containers/Organization/Organization.js
        // if changing tabs text
        about: 'Acerca de',
        locationsEmployees: 'Ubicaciones/Empleados',
        privacy: 'Política de privacidad',
        terms: 'Términos de Uso',
        myBusinesses: 'Mis negocios',
        emailNotifications: 'Notificaciones por email',
      },
      inputs: {
        organizationName: 'Nombre del negocio',
        organizationNamePlaceholder: 'Introduce el nombre de tu negocio',
        organizationLogo: 'Logotipo del negocio',
        organizationLogoSubtitle: `Tamaño de imagen recomendado ${imagesConst.ORGANIZATION_LOGO_WIDTH}x${imagesConst.ORGANIZATION_LOGO_HEIGHT} y rectángulo ${imagesConst.ORGANIZATION_LOGO_WIDTH_RECTANGLE}x${imagesConst.ORGANIZATION_LOGO_HEIGHT_RECTANGLE} (1/3)`,
        organizationCover: 'Portada del negocio',
        organizationCoverSubtitle: `Tamaño de imagen recomendado
          ${imagesConst.ORGANIZATION_COVER_WIDTH}x${imagesConst.ORGANIZATION_COVER_HEIGHT} y
           relación de aspecto de 16:9`,
        organizationVideo: 'Video empresarial',
        organizationPreviewVideo: 'Vista previa de vídeo empresarial',
        organizationVideoSubtitle: `'Resolución de video recomendada ${ORGANIZATION_VIDEO_WIDTH}x${ORGANIZATION_VIDEO_HEIGHT} (9:16), duración de ${MAX_DURATION} segundos y tamaño de ${ORGANIZATION_VIDEO_SIZE} MB`,
        organizationPreviewVideoSubtitle: 'Relación de aspecto de video recomendada 9:16',
        description: 'Descripción',
        descriptionPlaceholder: 'Introduce una descripción para tu negocio',
        videoDescription: 'Descripción de la promoción',
        videoDescriptionPlaceholder: 'Ingrese una breve descripción para su video promocional',
        tipsDescription: 'Las propinas están habilitadas',
      },
      saveSuccess: 'Información del negocio actualizada',
      alerts: {
        deleteMyBusinessTitle: 'Eliminar empresa',
        deleteMyBusinessText: '¿Estás seguro de que deseas eliminar esta empresa con tu lista?',
      },
      table: {
        locations: 'Ubicaciones',
        employees: 'Empleados',
        errors: 'Errores',
      },
    },
    // Settings Page
    settings: {
      tabs: {
        // change  const esTabsLength in containers/Settings/Settings.js if changing tabs text
        paymentLimits: 'Pagos',
        settings: 'Actualización para todas las usuarias',
        notifications: 'Notificaciones',
        emails: 'Correos electrónicos',
        reindex: 'Algolia',
      },
      reindex: {
        user: 'Reindexar usuarios',
        gift: 'Regalos Reindex',
        organization: 'Reindexar organizaciones',
        title: 'Reindex Algolia',
        reindex: 'Algolia',
      },
      ngc: {
        nativePayDelay: 'Pago nativo sin demora:',
        enabled: 'Habilitado',
        disabled: 'Desactivado',
        status: 'TRN estado:',
        active: 'Activo',
        block: 'Bloqueado',
        archive: '{{count}} organizaciones archivadas',
        unarchived: '{{count}} organizaciones sin archivar',
      },
      update: {
        purchase_limit: 'Actualizar el límite de compra actual para todos los usuarios:',
        unverified_purchase_limit_ngc: 'Actualice el límite actual de NGC para todos los usuarios:',
        purchase_card_limit:
          'Actualizar el límite actual de la tarjeta de compra para todos los usuarios:',
      },
      archive: 'Archivo',
      modal: {
        title: 'Archivo NGC',
        text: '¿Está seguro de lo que desea archivar todas las organizaciones activas de NGC?',
      },
      messages: {
        updated: 'Todos los usuarios se han actualizado correctamente',
        saved: 'La configuración se ha guardado correctamente',
      },
      inputs: {
        ignoredUsers: 'Usuarios de la lista blanca:',
        ignoredUsersPlaceholder: 'Nombre de usuario de tipo de inicio (min 3 símbolos)',
        NGCLimit: 'Límites para los obsequios de NGC(US$)',
        dailyPurchaseForUser: 'Límites de compra diaria para el usuario (US$)',
        dailyPurchaseForCard: 'Límites para la compra diaria de tarjetas (US$)',
        testEmail: 'Email de prueba',
        supportEmail: 'Correo electrónico de soporte',
        ngcGiftDelayTime: 'Retraso de la tarjeta de regalo de NGC (minutos)',
      },
      existUsers: 'Actualiza a los usuarios existentes con la última configuración guardada',
      settingsFields: 'Campos de configuración',
      paymentLimits: 'Pagos',
      notifications: {
        title: 'Notificaciones de administrador',
        waitingTransactions: 'Esperando transacciones',
        disputes: 'Disputas',
        NGCStatus: 'Estado de NGC',
        giftInventorisation: 'Inventario de regalos',
      },
    },
    // Organizations Page
    organizations: {
      title: 'Negocios',
      formTitle: 'Información principal',
      onlyNGC: 'Solo NGC',
      create: {
        title: 'Crear negocio',
      },
      draft: {
        title: 'Borrador',
      },
      steps: {
        mainInformation: 'Información principal',
        contractDetails: 'Detalles del contrato',
      },
      table: {
        id: 'ID',
        name: 'Nombre del negocio',
        email: 'Email',
        totalGifts: 'Total de regalos',
        sent: 'Enviados',
        redeemed: 'Canjeados',
        views: 'Puntos de vista',
        totalPurchased: 'Total Comprados',
        status: 'Estado',
        referral_users: 'Usuarios de referencia',
        activation_date: 'Fecha de Activacion',
        registered: 'Registrado',
      },
      input: {
        percentName: 'Comisión de Lazo',
        percentNamePlaceholder: 'Introduce la comisión de Lazo para el regalo comprado',
      },
    },
    // Location Detail Page
    locationDetail: {
      employees: 'Empleados',
      table: {
        name: 'Nombre',
        email: 'Email',
        phone: 'Teléfono',
        redeems: 'Canjes',
        status: 'Estado',
        location: 'Ubicación',
      },
    },
    // Locations Import CSV
    locationsImportCSV: {
      title: 'Importar CSV',
      subtitle: 'Importar ubicaciones',
    },
    // Employees Import CSV
    employeesImportCSV: {
      title: 'Importar CSV',
      subtitle: 'Importar empleados',
    },
    // Import CSV
    importCSV: {
      downloadCSVTextPart1: 'Descarga una ',
      downloadCSVTextPart2: ' de muestra para ver un ejemplo del formato correcto.',
      downloadCSVTextPart3:
        'Reemplazar los empleados existentes que tengan el mismo correos electrónicos',
    },
    // Loading CSV
    loadingCSV: {
      importingProcess: 'El proceso de importación tarda hasta 15 minutos',
      spendThisTime: 'Regálate este tiempo o regálaselo a tus seres queridos',
    },
    // Uploaded CSV
    uploadedCSV: {
      successfullyUploaded: 'El CSV con empleados se cargó correctamente',
    },
    // Stripe
    stripe: {
      messages: {
        disabled: 'Tu cuenta de pago no está activa. \n',
        reason: 'Razón:',
        checkIssue: 'Revisa el error en tu',
        stripeDashboard: 'Dashboard de Stripe.',
        notAllFields: 'No has proporcionado toda la información necesaria \n',
      },
      action_required: {
        requested_capabilities:
          'Necesitas solicitar capacidades para la cuenta conectada. Para obtener más detalles, consulta Solicitar y anular la solicitud de capacidades.',
      },
      requirements: {
        past_due:
          'Se necesita información de verificación adicional para habilitar las capacidades de pago o cobro de esta cuenta.',
        pending_verification: 'Stripe está verificando la información de la cuenta conectada.',
      },
      rejected: {
        fraud: 'La cuenta está rechazada por sospecha de fraude o actividad ilegal.',
        terms_of_service:
          'La cuenta está rechazada por sospecha de incumplimiento de las condiciones de servicio.',
        listed:
          'La cuenta está rechazada porque está en una lista de personas o empresas de terceros prohibidas (como un proveedor de servicios financieros o gobierno).',
        other: 'La cuenta está rechazada por otra razón.',
      },
      listed:
        'La cuenta podría estar una lista de personas o empresas prohibidas (Stripe investigará y rechazará o restablecerá la cuenta según corresponda).',
      under_review: 'La cuenta está bajo revisión por parte de Stripe.',
      other:
        'La cuenta no está rechazada pero sí está deshabilitada por otra razón mientras se revisa.',
    },
    // Redemptions page
    redemptions: {
      title: 'Canjes',
      generate: 'Código de canje',
      instructions: 'Instrucciones',
      coaster: 'Portavasos de canje',
      secretSymbol: 'Tu símbolo secreto',
      example: 'El ejemplo de la pantalla de canje',
      lastGenerated: 'Fecha del último símbolo generado:',
      nextGenerated: 'Fecha de generación del próximo símbolo:',
      secretSymbolTooltip:
        'El símbolo aparecerá en la pantalla de canje del usuario para que sus empleados lo validen.',
      submit: 'Enviar',
      redeemOther: 'Canjear otro',
      codeIsInvalid: 'Código inválido',
      alreadyRedeemed: 'Ya canjeado',
      sucessfullyRedeemed: 'Canje exitoso',
      cardBalance: 'Saldo de la tarjeta',
      refund: 'Reembolsar',
      refunded: 'Reembolsado',
      date: 'Fecha',
      amount: 'Importe',
      transactionHistory: 'Historial de transacciones',
      refundQuestion: '¿Reembolsar esta transacción?',
      step1: 'El usuario debe mostrar la pantalla de canje ',
      step2:
        'Verifica el sello de la fecha y hora, así como el símbolo secreto en la pantalla de confirmación!',
      step3: 'Haz válida la tarjeta de regalo restando de la cuenta el importe canjeado.',
      step4: 'Entrégale el regalo al cliente.',
      input: {
        giftCode: 'Código de regalo',
        amount: 'Importe',
        giftPlaceholder: 'Introduce el código de regalo',
      },
      period: {
        week: 'Semanal',
        twoWeek: 'Quincenal',
        month: 'Mensual',
        quartal: 'Trimestral',
      },
      tab: {
        security: 'Seguridad',
        redemptionList: 'Lista de canje',
      },
      emptySearchMessage: 'Disculpe, no hay resultados.',
      fullyRedeemed: 'Completamente canjeado',
      table: {
        date: 'Fecha',
        username: 'Nombre de usuario',
        sender: 'Remitente de regalo',
        giftName: 'Nombre del regalo',
        organization: 'Negocio',
        amount: 'Importe de la redención',
      },
    },
    // Import Product Codes Component
    importProductCodes: {
      title: 'Importar códigos de productos',
      downloadCSVTextPart1: 'Descarga una',
      downloadCSVTextPart2: 'de muestra para ver un ejemplo del formato correcto.',
      downloadCSVTextPart3: 'Reemplazar los empleados existentes que tengan el mismo nombre',
      importingProcessTitle: 'El proceso de importación tarda hasta 15 minutos',
      importingProcessSubtitle: 'Regálate este tiempo o regálaselo a tus seres queridos',
      successImportTitle: '¡{{fileName}} se importó correctamente!',
      successImportSubtitle: '{{codesCount}} códigos importados',
    },
    // DropZone field Component
    dropZoneField: {
      dropTextPart1: 'Arrastra la',
      dropTextPart2: 'y suéltala aquí o',
      dropTextPart3: 'selecciónala',
      dropTextPart4: 'en tu computadora',
    },
    // Archive Business Component
    archiveBusinessDialog: {
      title: '¿Archivar este negocio?',
      text:
        'Cuando archiva una empresa, se bloquearán todos los obsequios asociados con una empresa. Una empresa y sus obsequios relacionados desaparecerán de la lista de la aplicación móvil.',
    },
    // Add/Edit Employee Component
    employeeDialog: {
      addTitle: 'Agregar un solo empleado',
      editTitle: 'Editar empleado',
    },
    // Delete Location Component
    deleteLocationDialog: {
      title: 'Eliminar ubicación',
      text:
        'Si eliminas una ubicación, se borrarán todas las cuentas de empleados asociadas a la misma.',
    },
    // Delete Employee Component
    deleteEmployeeDialog: {
      title: 'Eliminar empleado',
      text: '¿Deseas realmente eliminar el empleado?',
    },
    // Delete Gift Component
    deleteGiftDialog: {
      title: 'Eliminar regalo',
      text: '¿Deseas realmente eliminar el regalo?',
    },
    // StatisticBox Component
    statisticBox: {
      positiveText: 'más que {{period}} anterior',
      negativeText: 'menos que {{period}} anterior',
      noChange: 'Sin cambios',
      periods: {
        day: 'el día',
        week: 'la semana',
        month: 'el mes',
        year: 'el año',
        'custom-range': 'el rango específico',
      },
    },
    // Choose Amount Component
    chooseAmountDialog: {
      title: 'Elige el importe',
    },
    communications: {
      title: 'Tus derechos a la privacidad',
      section1: {
        title: 'Introducción',
        paragraph:
          'LAZO E-COMMERCE, S.L. ("Lazo" o "nosotros") ha establecido esta Política de privacidad con el fin de dar a conocer el uso que hace de los datos que recopila sobre los usuarios. Esta Política de privacidad es efectiva a partir del 30 de noviembre de 2019. Cualquier pregunta relacionada con esta política deberá enviarse por correo electrónico a lazo@lazo-app.com. A continuación se describe cómo Lazo recopila, utiliza y divulga la información procedente de sus aplicaciones móviles, redes sociales y sitio web.',
      },
      section2: {
        title: 'Información recopilada y recibida',
        paragraph1:
          'Lazo podrá recopilar o recibir información sobre cualquier persona que visite el sitio web, descargue o utilice la aplicación móvil o interactúe con nosotros a través de cualquier red social, así como de cualquier persona que se registre en el sitio web o las aplicaciones de Lazo. Dicha información incluye, por ejemplo, tu nombre de usuario, contraseña, nombre y apellidos, dirección de correo electrónico, comportamiento e intereses.',
        paragraph2:
          'Lazo recopila información sobre las páginas que visitas otra información que pudieras proporcionar voluntariamente como la información obtenida a través de encuestas. Lazo también podrá recopilar información sobre tu ubicación, tal y como se describe más adelante en la sección "Sistema de posicionamiento global".',
        paragraph3:
          'Otros terceros podrán también recoger información, como, por ejemplo, la información financiera que reciben los procesadores de pagos y que se recopila en nuestro nombre o al prestarte un servicio.',
      },
      section3: {
        title: 'Usos de la información',
        paragraph1:
          'La información que Lazo recopila se utiliza para prestar los servicios que tú nos solicitas, así como para mejorar y optimizar los servicios y la experiencia del usuario. Esta podrá utilizarse para garantizar el correcto funcionamiento del sitio, con fines publicitarios y de marketing, así como para proporcionar información de utilidad.',
        paragraph2:
          'Lazo podrá utilizar tu dirección IP para ayudar a diagnosticar problemas del servidor de Lazo y para administrar su sitio web. Asimismo, podrá utilizar tu dirección IP para identificarte a ti y a tu perfil en línea, así como para recopilar información demográfica de carácter general.',
        paragraph3:
          'El formulario de registro del sitio de Lazo requiere que los usuarios le proporcionen información de contacto (como su nombre y dirección de correo electrónico) y otros datos. Lazo utiliza la información de contacto de tuformulario de registro para enviarte información sobre Lazo y podría enviarte material promocional de algunos de los socios de Lazo. Tu información de contacto también podrá utilizarse si necesitamos ponernos en contacto contigo. Lazo podrá utilizar tus datos demográficos o de perfil para personalizar tu experiencia en el sitio web o las aplicaciones de Lazo, mostrarte contenidos que Lazo considere que puedan interesarte y que estén basados en tus preferencias. Además, Lazo tendrá derecho a conservar registros de todos los datos relativos al uso del sitio web y las aplicaciones, incluidos, entre otros, el uso, los registros de actividades y los clics.',
        paragraph4:
          'Lazo podrá utilizar otras empresas para prestar servicios como parte de nuestras operaciones. A fin de prestar estos servicios, dichas empresas podrán tener acceso a tu información personal. Se espera que estas empresas traten tu información con arreglo a esta Política de privacidad o a su propia política de privacidad. Sin embargo, no nos hacemos responsables por los daños y perjuicios que pudieran derivarse del uso indebido que estas empresas pudieran dar a tu información personal.',
        paragraph5:
          'En ocasiones, es posible que nos asociemos con otras empresas. Estas empresas asociadas podrían recibir tu información de contacto cuando sea necesaria para completar alguna transacción entre ustedes. En ningún otro caso proporcionaremos tu dirección de correo electrónico o cualquier otra información de contacto que pudiera utilizarse para contactarte directamente. Cualquierexcepción a esta política de compartir tu nombre, dirección o correo electrónico con una empresa asociada se hará exclusivamente con tu consentimiento.',
        paragraph6:
          'Lazo se reserva el derecho de divulgar información cuando lo exija la ley. También nos reservamos el derecho de transferir tu información a otra entidad como parte de una adquisición, fusión o reestructuración corporativa.',
      },
      section4: {
        title: 'Compartir información',
        paragraph:
          'Tras la aceptación voluntaria del usuario, Lazo se reserva el derecho de compartir cualquier información del usuario con Pernod Ricard y otros socios comerciales. Lazo, Pernod Ricard España y sus afiliados globales compartirán con los usuarios información sobre eventos, experiencias y productos a través de canales electrónicos que pueden incluir mensajes dentro de la aplicación, SMS y correo electrónico. Los usuarios tienen el derecho de optar por no participar.',
      },
      section5: {
        title: 'Privacidad de menores',
        paragraph:
          'Lazo no recopila conscientemente ninguna información de menores de 13 años. Si eres menor de 18 años, deberás utilizar este sitio web únicamente bajo la supervisión de tus padres o tutores y no deberás enviarnos ninguna información personal. Si descubrimos que un menor de 13 años nos ha proporcionado cualquier información personal, haremos los esfuerzos comercialmente razonables para eliminar su información personal de todos los sistemas de Lazo.',
      },
      section6: {
        title: 'Sistema de posicionamiento global',
        paragraph:
          'Es posible que se necesite habilitar la tecnología de seguimiento del Sistema de posicionamiento global (GPS, por sus siglas en inglés) en los productos y servicios de Lazo para determinar la ubicación (latitud y longitud) de los usuarios de los productos y servicios de Lazo. Esta información se transmite a Lazo y a los usuarios de Lazo, y Lazo la almacena. Lazo no proporciona esta información a terceros, excepto a los proveedores de servicios. En su caso, esta información se utiliza junto con las aplicaciones de Lazo.',
      },
      section7: {
        title: 'Seguridad',
        paragraph:
          'Para ayudar a garantizar la seguridad de la información, Lazo utiliza tecnologías que cumplen con los estándares del sector a la hora de transferir y recibir los datos de consumo que se intercambian entre Lazo y otras empresas. Este sitio también cuenta con medidas de seguridad para proteger la pérdida, el uso indebido y la alteración de la información bajo el control de Lazo. Sin embargo, no podemos garantizar la seguridad de cualquier información tuya que se transmita a través de los servicios o por correo electrónico, ni de la información que se almacena en las aplicaciones. Cualquier transmisión o almacenamiento de datos será bajo tu propio riesgo. ',
      },
      section8: {
        title: 'Cookies',
        paragraph1:
          'Las "cookies" son pequeños fragmentos de información que tu navegador almacena en el disco duro de tu computadora. El sitio web de Lazo utiliza cookies para darle seguimiento a tu sesión, carrito de compras y envío de publicidad. Lazo también podrá utilizar cookies para ofrecerte contenidos adaptados a tus intereses y para guardar tu contraseña. Lazo podrá utilizar una empresa de publicidad externa para mostrar anuncios en el sitio de Lazo. Estos anuncios también podrán contener cookies. Aunque Lazo utiliza cookies en otras partes de su sitio web, la empresa de publicidad externa que trabaja con Lazo recopila las cookies obtenidas mediante los anuncios publicitarios y Lazo no tiene acceso a dicha información.',
        paragraph2:
          'Lazo podrá utilizar tecnología estándar de Internet, como contadores de visitantes y otras tecnologías similares, para rastrear el uso que haces de nuestros sitios y software. También podrá incluir contadores de visitantes en los mensajes de correo electrónico promocionales o en los boletines electrónicos para determinar si se han abierto los mensajes o se ha llevado a cabo alguna acción en consecuencia. La información que obtenemos de este modo nos permite personalizar los servicios que ofrecemos a nuestros usuarios para ofrecer anuncios adaptados y medir la eficacia general de nuestra publicidad, contenidos, programación u otras actividades en línea.',
        paragraph3:
          'Lazo podrá permitir que terceros, entre ellos nuestros proveedores de servicios autorizados, empresas de publicidad y redes publicitarias, muestren anuncios en su sitio o software. Lazo y estas empresas podrán utilizar tecnologías de seguimiento, como las cookies, para recopilar información sobre los usuarios que ven o interactúan con estos anuncios y se conectan al software o demás propiedad de Lazo. Esta información permite a Lazo y a dichas empresas ofrecer anuncios personalizados y medir la eficacia de los mismos.',
      },
      section9: {
        title: 'Enlaces',
        paragraph1:
          'Lazo podrá crear enlaces a otros sitios web. No nos hacemos responsables del contenido ni de las prácticas de privacidad de dichos sitios web de terceros.',
        paragraph2:
          'Es posible que otros sitios que no estén asociados ni autorizados por Lazo tengan enlaces que lleven a nuestro sitio web. Lazo no tiene control sobre estos enlaces y no se hará responsable de los contenidos que aparezcan en dichos sitios.',
      },
      section10: {
        title: 'Enmiendas',
        paragraph:
          'Lazo se reserva el derecho de modificar esta política en cualquier momento. En caso de que Lazo vaya a utilizar la información de identificación personal recopilada a través del sitio web de Lazo de una manera que sea significativamente distinta a la que se indicó en el momento de la recopilación, Lazo notificará a los usuarios por correo electrónico o mediante un aviso en el sitio web de Lazo durante los treinta (30) días anteriores a dicho uso. La presente Política de privacidad no podrá modificarse de otro modo, salvo en un escrito que haga referencia específica a esta política de privacidad y que esté firmado físicamente por ambas partes.',
      },
      section11: {
        title: 'Sucesores y cesionarios',
        paragraph:
          'Esta política de privacidad se entenderá en beneficio de los sucesores y cesionarios de Lazo.',
      },
      section12: {
        title: 'Contactos',
        paragraph:
          'Si tienes alguna pregunta sobre esta Política de privacidad, las prácticas de este sitio o tu relación con este sitio web, puedes ponerte en contacto con nosotros a través de lazo@lazo-app.com. Bajo previa solicitud y cuando así lo exija la ley, Lazo te proporcionará acceso a la información (por ejemplo, nombre, dirección de correo electrónico) que Lazo recopila y almacena sobre ti. Este sitio te ofrece las siguientes opciones para cambiar y modificar la información previamente proporcionada: (i) correo electrónico: lazo@lazo-app.com; o (ii) cuando proceda, a través de la aplicación móvil.',
      },
      section13: {
        title: 'El derecho al olvido',
        paragraph:
          'Bajo previa solicitud, Lazo eliminará toda la información del usuario. Puedes ponerte en contacto con Lazo en la dirección lazo@lazo-app.com.',
      },
      section14: {
        title: 'Exclusión voluntaria (opt out)',
        paragraph:
          'El sitio de Lazo ofrece a los usuarios la posibilidad de optar por no recibir comunicaciones de Lazo, excepto la correspondencia relacionada con transacciones. Para dejar de recibir nuestras comunicaciones, puedes (i) enviar un correo electrónico a lazo@lazo-app.com; o (ii) si procede, darte de baja a través del enlace para cancelar la suscripción que aparece en la parte inferior de los correos electrónicos.',
      },
      section15: {
        title: 'Solicitud de Acceso de Sujeto de Datos (DSAR)',
        paragraph:
          'De acuerdo con las leyes y regulaciones de protección de datos aplicables, incluyendo, entre otras, el Reglamento General de Protección de Datos (GDPR) y la Ley de Privacidad del Consumidor de California (CCPA), esta Cláusula de Solicitud de Acceso de Sujeto de Datos (DSAR) establece los derechos de los sujetos de datos y los procedimientos para gestionar las DSAR dentro del marco de los términos comerciales mencionados previamente entre Lazo, Inc. ("Lazo") y el Comerciante.',
      },
      section16: {
        title: 'Derechos de DSAR de los Sujetos de Datos',
        paragraph1:
          'a) Los sujetos de datos, cuyos datos personales son procesados bajo los términos del acuerdo entre Lazo y el Comerciante, tienen el derecho de solicitar acceso a sus datos personales.',
        paragraph2:
          'b) Los sujetos de datos pueden ejercer sus derechos de DSAR en cualquier momento, sin incurrir en costos, y recibir información sobre el procesamiento de sus datos personales. ',
      },
      section17: {
        title: 'Presentación de las DSAR',
        paragraph1: 'a) Las DSAR deben presentarse por escrito a Lazo.',
        paragraph2:
          'b) Las DSAR deben incluir el nombre del sujeto de datos, información de contacto y una descripción clara de la información solicitada.',
        paragraph3: 'c) Lazo confirmará la recepción de la DSAR en un plazo de 10 días hábiles.',
      },
      section18: {
        title: 'Verificación del Sujeto de Datos',
        paragraph1:
          'a) Lazo puede solicitar información adicional al sujeto de datos para verificar su identidad y prevenir el acceso no autorizado a los datos personales.',
        paragraph2:
          'b) Lazo responderá a la DSAR en un plazo de 28 días hábiles después de la verificación exitosa.  ',
      },
      section19: {
        title: 'Respuesta a la DSAR',
        paragraph1:
          'a) Una vez verificada con éxito, Lazo proporcionará al sujeto de datos una copia de los datos personales solicitados.',
        paragraph2:
          'b) Si la DSAR es compleja o numerosa, Lazo puede extender el plazo de respuesta por 45 días hábiles adicionales, notificando al sujeto de datos en consecuencia. ',
        paragraph3:
          'c) Lazo puede proporcionar la respuesta a la DSAR electrónicamente o en un formato físico comúnmente utilizado, dependiendo de la preferencia del sujeto de datos.',
      },
      section20: {
        title: 'Exenciones',
        paragraph1:
          'a) Lazo puede retener cierta información en respuesta a una DSAR cuando lo permitan las leyes aplicables de protección de datos, incluyendo, entre otros, información que se relacione con terceros o esté legalmente protegida.',
        paragraph2:
          'b) Si Lazo se niega a responder a una DSAR, se informará al sujeto de datos sobre los motivos de la negativa.',
      },
      section21: {
        title: 'Costos',
        paragraph:
          'a) Lazo no cobrará a los sujetos de datos por realizar una DSAR o por recibir una copia de sus datos personales.',
      },
      section22: {
        title: 'Retención',
        paragraph:
          'a) Lazo retendrá registros de DSAR y respuestas durante 24 meses en cumplimiento de las leyes aplicables de protección de datos.',
      },
      section23: {
        title: 'Cooperación con el Comerciante',
        paragraph:
          'a) El Comerciante acepta cooperar con Lazo para facilitar las DSAR relacionadas con datos personales procesados en nombre del Comerciante.',
      },
      section24: {
        title: 'Oficial de Protección de Datos (DPO)',
        paragraph:
          'a) Lazo ha designado a un Oficial de Protección de Datos (DPO) que puede ser contactado en la dirección lazo@lazo-app.com para cualquier consulta relacionada con DSAR.',
      },
      section25: {
        title: '',
        paragraph:
          'Al aceptar estos términos, ambas partes reconocen su compromiso de cumplir las obligaciones DSAR de conformidad con las leyes y normativas de protección de datos aplicables. Este Se incorpora la Cláusula DSAR a lo previamente definido términos comerciales entre Lazo y el Comerciante.',
      },
    },
    communicationsPR: {
      title: 'Tus derechos a la privacidad Pernod Ricard',
      section1: {
        title: 'Introducción',
        paragraph:
          'Lazo, Inc. ("Lazo" o "nosotros") ha establecido esta Política de privacidad con el fin de dar a conocer el uso que hace de los datos que recopila sobre los usuarios. Esta Política de privacidad es efectiva a partir del 30 de noviembre de 2019. Cualquier pregunta relacionada con esta política deberá enviarse por correo electrónico a support@lazo-app.com. A continuación se describe cómo Lazo recopila, utiliza y divulga la información procedente de sus aplicaciones móviles, redes sociales y sitio web.',
      },
      section2: {
        title: 'Información recopilada y recibida',
        paragraph1:
          'Lazo podrá recopilar o recibir información sobre cualquier persona que visite el sitio web, descargue o utilice la aplicación móvil o interactúe con nosotros a través de cualquier red social, así como de cualquier persona que se registre en el sitio web o las aplicaciones de Lazo. Dicha información incluye, por ejemplo, tu nombre de usuario, contraseña, nombre y apellidos, dirección de correo electrónico, comportamiento e intereses.',
        paragraph2:
          'Lazo recopila información sobre las páginas que visitas y otra información que pudieras proporcionar voluntariamente, como la información obtenida a través de encuestas. Lazo también podrá recopilar información sobre tu ubicación, tal y como se describe más adelante en la sección "Sistema de posicionamiento global".',
        paragraph3:
          'Otros terceros podrán también recoger información, como, por ejemplo, la información financiera que reciben los procesadores de pagos y que se recopila en nuestro nombre o al prestarte un servicio.',
      },
      section3: {
        title: 'Usos de la información',
        paragraph1:
          'La información que Lazo recopila se utiliza para prestar los servicios que tú nos solicitas, así como para mejorar y optimizar los servicios y la experiencia del usuario. Esta podrá utilizarse para garantizar el correcto funcionamiento del sitio, con fines publicitarios y de marketing, así como para proporcionar información de utilidad.',
        paragraph2:
          'Lazo podrá utilizar tu dirección IP para ayudar a diagnosticar problemas del servidor de Lazo y para administrar su sitio web. Asimismo, podrá utilizar tu dirección IP para identificarte a ti y a tu perfil en línea, así como para recopilar información demográfica de carácter general.',
        paragraph3:
          'El formulario de registro del sitio de Lazo requiere que los usuarios le proporcionen información de contacto (como su nombre y dirección de correo electrónico) y otros datos. Lazo utiliza la información de contacto de tu formulario de registro para enviarte información sobre Lazo y podría enviarte material promocional de algunos de los socios de Lazo. Tu información de contacto también podrá utilizarse si necesitamos ponernos en contacto contigo. Lazo podrá utilizar tus datos demográficos o de perfil para personalizar tu experiencia en el sitio web o las aplicaciones de Lazo, mostrarte contenidos que Lazo considere que puedan interesarte y que estén basados en tus preferencias. Además, Lazo tendrá derecho a conservar registros de todos los datos relativos al uso del sitio web y las aplicaciones, incluidos, entre otros, el uso, los registros de actividades y los clics.',
        paragraph4:
          'Lazo podrá utilizar otras empresas para prestar servicios como parte de nuestras operaciones. A fin de prestar estos servicios, dichas empresas podrán tener acceso a tu información personal. Se espera que estas empresas traten tu información con arreglo a esta Política de privacidad o a su propia política de privacidad. Sin embargo, no nos hacemos responsables por los daños y perjuicios que pudieran derivarse del uso indebido que estas empresas pudieran dar a tu información personal.',
        paragraph5:
          'En ocasiones, es posible que nos asociemos con otras empresas. Estas empresas asociadas podrían recibir tu información de contacto cuando sea necesaria para completar alguna transacción entre ustedes. En ningún otro caso proporcionaremos tu dirección de correo electrónico o cualquier otra información de contacto que pudiera utilizarse para contactarte directamente. Cualquier excepción a esta política de compartir tu nombre, dirección o correo electrónico con una empresa asociada se hará exclusivamente con tu consentimiento.',
        paragraph6:
          'Lazo se reserva el derecho de divulgar información cuando lo exija la ley. También nos reservamos el derecho de transferir tu información a otra entidad como parte de una adquisición, fusión o reestructuración corporativa.',
        paragraph7:
          'Except as described in this section, we will not disclose your contact information to any third party without notifying you of our intent to share the information and giving you an opportunity to prevent your information from being shared.',
      },
      section4: {
        title: 'Privacidad de menores',
        paragraph:
          'Lazo no recopila conscientemente ninguna información de menores de 13 años. Si eres menor de 18 años, deberás utilizar este sitio web únicamente bajo la supervisión de tus padres o tutores y no deberás enviarnos ninguna información personal. Si descubrimos que un menor de 13 años nos ha proporcionado cualquier información personal, haremos los esfuerzos comercialmente razonables para eliminar su información personal de todos los sistemas de Lazo.',
      },
      section5: {
        title: 'Sistema de posicionamiento global',
        paragraph:
          'Es posible que se necesite habilitar la tecnología de seguimiento del Sistema de posicionamiento global (GPS, por sus siglas en inglés) en los productos y servicios de Lazo para determinar la ubicación (latitud y longitud) de los usuarios de los productos y servicios de Lazo. Esta información se transmite a Lazo y a los usuarios de Lazo, y Lazo la almacena. Lazo no proporciona esta información a terceros, excepto a los proveedores de servicios. En su caso, esta información se utiliza junto con las aplicaciones de Lazo.',
      },
      section6: {
        title: 'Seguridad',
        paragraph:
          'Para ayudar a garantizar la seguridad de la información, Lazo utiliza tecnologías que cumplen con los estándares del sector a la hora de transferir y recibir los datos de consumo que se intercambian entre Lazo y otras empresas. Este sitio también cuenta con medidas de seguridad para proteger la pérdida, el uso indebido y la alteración de la información bajo el control de Lazo. Sin embargo, no podemos garantizar la seguridad de cualquier información tuya que se transmita a través de los servicios o por correo electrónico, ni de la información que se almacena en las aplicaciones. Cualquier transmisión o almacenamiento de datos será bajo tu propio riesgo.',
      },
      section7: {
        title: 'Cookies',
        paragraph1:
          'Las "cookies" son pequeños fragmentos de información que tu navegador almacena en el disco duro de tu computadora. El sitio web de Lazo utiliza cookies para darle seguimiento a tu sesión, carrito de compras y envío de publicidad. Lazo también podrá utilizar cookies para ofrecerte contenidos adaptados a tus intereses y para guardar tu contraseña. Lazo podrá utilizar una empresa de publicidad externa para mostrar anuncios en el sitio de Lazo. Estos anuncios también podrán contener cookies. Aunque Lazo utiliza cookies en otras partes de su sitio web, la empresa de publicidad externa que trabaja con Lazo recopila las cookies obtenidas mediante los anuncios publicitarios y Lazo no tiene acceso a dicha información.',
        paragraph2:
          'Lazo podrá utilizar tecnología estándar de Internet, como contadores de visitantes y otras tecnologías similares, para rastrear el uso que haces de nuestros sitios y software. También podrá incluir contadores de visitantes en los mensajes de correo electrónico promocionales o en los boletines electrónicos para determinar si se han abierto los mensajes o se ha llevado a cabo alguna acción en consecuencia. La información que obtenemos de este modo nos permite personalizar los servicios que ofrecemos a nuestros usuarios para ofrecer anuncios adaptados y medir la eficacia general de nuestra publicidad, contenidos, programación u otras actividades en línea.',
        paragraph3:
          'Lazo podrá permitir que terceros, entre ellos nuestros proveedores de servicios autorizados, empresas de publicidad y redes publicitarias, muestren anuncios en su sitio o software. Lazo y estas empresas podrán utilizar tecnologías de seguimiento, como las cookies, para recopilar información sobre los usuarios que ven o interactúan con estos anuncios y se conectan al software o demás propiedad de Lazo. Esta información permite a Lazo y a dichas empresas ofrecer anuncios personalizados y medir la eficacia de los mismos.',
      },
      section8: {
        title: 'Enlaces',
        paragraph1:
          'Lazo podrá crear enlaces a otros sitios web. No nos hacemos responsables del contenido ni de las prácticas de privacidad de dichos sitios web de terceros.',
        paragraph2:
          'Es posible que otros sitios que no estén asociados ni autorizados por Lazo tengan enlaces que lleven a nuestro sitio web. Lazo no tiene control sobre estos enlaces y no se hará responsable de los contenidos que aparezcan en dichos sitios.',
      },
      section9: {
        title: 'Enmiendas',
        paragraph:
          'Lazo se reserva el derecho de modificar esta política en cualquier momento. En caso de que Lazo vaya a utilizar la información de identificación personal recopilada a través del sitio web de Lazo de una manera que sea significativamente distinta a la que se indicó en el momento de la recopilación, Lazo notificará a los usuarios por correo electrónico o mediante un aviso en el sitio web de Lazo durante los treinta (30) días anteriores a dicho uso. La presente Política de privacidad no podrá modificarse de otro modo, salvo en un escrito que haga referencia específica a esta política de privacidad y que esté firmado físicamente por ambas partes.',
      },
      section10: {
        title: 'Sucesores y cesionarios',
        paragraph:
          'Esta política de privacidad se entenderá en beneficio de los sucesores y cesionarios de Lazo.',
      },
      section11: {
        title: 'Contactos',
        paragraph:
          'Si tienes alguna pregunta sobre esta Política de privacidad, las prácticas de este sitio o tu relación con este sitio web, puedes ponerte en contacto con nosotros a través de support@lazo-app.com. Bajo previa solicitud y cuando así lo exija la ley, Lazo te proporcionará acceso a la información (por ejemplo, nombre, dirección de correo electrónico) que Lazo recopila y almacena sobre ti. Este sitio te ofrece las siguientes opciones para cambiar y modificar la información previamente proporcionada: (i) correo electrónico: support@lazo-app.com; o (ii) cuando proceda, a través de la aplicación móvil.',
      },
      section12: {
        title: 'Exclusión voluntaria (opt out)',
        paragraph:
          'El sitio de Lazo ofrece a los usuarios la posibilidad de optar por no recibir comunicaciones de Lazo, excepto la correspondencia relacionada con transacciones. Para dejar de recibir nuestras comunicaciones, puedes (i) enviar un correo electrónico a support@lazo-app.com; o (ii) si procede, darte de baja a través del enlace para cancelar la suscripción que aparece en la parte inferior de los correos electrónicos.',
      },
    },
    usageTerms: {
      title: 'CONDICIONES DE USO',
      subtitle:
        'Lee atentamente estas condiciones de uso antes de registrarte en el sitio web y usar los servicios de Lazo. Estas condiciones de uso (el "Acuerdo") regulan el acceso a los contenidos y el uso de los sitios web y las aplicaciones móviles de www.lazo-app.com y sus afiliados. El presente Acuerdo entre el usuario (“tú" o “el usuario") y Lazo está sujeto a cambios por nuestra parte, tal y como se describe a continuación. Al registrarte (i) aceptas este Acuerdo; (ii) te comprometes a cumplir con estas condiciones; y (iii) habrás celebrado un acuerdo vinculante entre tú y Lazo.',
      list: {
        item1:
          'Este sitio web y cualquier aplicación móvil (colectivamente el "Sitio") son propiedad de Lazo ecommerce SL (en adelante “Lazo"). El acceso al Sitio y a su contenido ("Contenido") y al servicio de la plataforma de envío de regalos de Lazo ("Servicios") únicamente es posible de conformidad con el presente Acuerdo. En caso de violación de los derechos de autor del Contenido o de estas condiciones, Lazo o el propietario de los derechos de autor podrá imponer medidas coercitivas en la medida que lo permita la ley. Lazo no se hace responsable de la entrega de los productos o servicios vendidos a través de los Servicios ("Productos de terceros"), ni es responsable de la calidad de dichos Productos de terceros. Túcomprendes y reconoces que el Sitio web es un mercado y que la responsabilidad de Lazo se limita a la entrega de la tarjeta de regalo digital o del comprobante de compra digital necesario para canjear los Productos de terceros, según corresponda.',
        item2:
          'Tú reconoces y aceptas que Lazo, a su total discreción y sin previo aviso, podrá suspender (de forma permanente o temporal) los Servicios (o cualquiera de sus funciones) que te presta a ti o a los usuarios en general. Puedes dejar de utilizar los Servicios en cualquier momento. No es necesario que informes expresamente a Lazo cuando dejes de utilizar los Servicios. Aceptas y reconoces que si Lazo desactiva el acceso a tu cuenta, es posible que no puedas acceder a los Servicios, a los datos de tu cuenta o a cualquier archivo u otro material que se encuentre en tu cuenta.',
        item3:
          'Tú te comprometes a no acceder (o intentar acceder) a ninguno de los Servicios por ningún otro medio que no sea la interfaz que proporciona Lazo, a menos que  tengas permiso expreso para hacerlo mediante un acuerdo por separado con Lazo. Aceptas no participar en ninguna actividad que interfiera o interrumpa los Servicios (o los servidores y redes conectados a los Servicios). Salvo autorización expresa de Lazo, aceptas no reproducir, duplicar, copiar, vender, comercializar o revender los Servicios por ningún motivo. Aceptas ser el único responsable (y que Lazo queda exento de toda responsabilidad ante el usuario o ante terceros) en caso de incumplimiento de las obligaciones contraídas en virtud del presente Acuerdo y de las repercusiones (incluidos los daños y perjuicios que pudiera sufrir Lazo) derivados de dicho incumplimiento.',
        item4:
          'Lazo no cobra por el acceso a su aplicación móvil; sin embargo, Lazo cobra una tasa de servicio (actualmente del cuatro por ciento) cuando el usuario utiliza los Servicios para enviar un regalo (la "Comisión por Servicio") y se aplican las tarifas del operador del usuario, como las comisiones por mensajería de texto y datos cuando se utiliza la aplicación. Tú reconoces, aceptas y das tu consentimiento para que los usuarios puedan sincronizar cualquier dispositivo con tu información. Lazo podrá cambiar la Comisión de Servicio en cualquier momento. Al utilizar el Servicio para enviar un regalo, el usuario conocerá la Comisión de Servicio vigente antes de completar la transacción.',
        item5:
          'Tú comprendes que toda la información y los materiales (como archivos de datos, textos escritos, software informático, música, archivos de audio u otros sonidos, fotografías, videos u otras imágenes) a los que pudieras tener acceso como parte de los Servicios, o a través de su uso, son responsabilidad exclusiva de la persona de la que proceden dichos materiales, pudiendo ser tú mismo.',
        item6:
          'Lazo se reserva todos los derechos no concedidos expresamente en el presente documento. No modificarás los avisos de derechos de autor, las leyendas de propiedad, las atribuciones de marcas comerciales y de servicio, las marcas de patentes y otros indicios de propiedad en el Contenido u otros materiales a los que tengas acceso a través de los Servicios. La entrega y la licencia sobre el Contenido o el acceso a materiales de terceros no le transfieren al usuario ningún derecho de uso comercial o promocional del Contenido o de cualquier parte del mismo. Queda estrictamente prohibido el uso del Contenido o descripciones; el uso derivado de este Sitio o sus materiales; y el uso de data mining, robots o herramientas similares de recolección y extracción de datos. En ningún caso el usuario utilizará marcos (frames) para cualquier parte del Sitio o cualquier material contenido en el mismo.',
        item7:
          'Si bien Lazo realiza esfuerzos razonables para incluir información precisa y actualizada en el Sitio, la empresa no ofrece garantías ni se hace responsable de su exactitud. Lazo no asume ninguna responsabilidad por cualquier error u omisión en el Contenido o el Sitio.',
        item8:
          'El Sitio podrá contener enlaces a otros sitios de Internet propiedad de terceros y gestionados por ellos, como, por ejemplo, procesadores de pagos y otros terceros (los "Sitios externos"). Tú reconoces que Lazo no es responsable de la disponibilidad de los Sitios externos ni de los materiales que se encuentran o se obtienen a través de los mismos. Si tienes alguna duda sobre los enlaces o los materiales que aparecen en esos sitios externos, ponte en contacto con el administrador o el webmaster de dichos Sitios externos.',
        item9:
          'Los usuarios del Sitio podrán publicar comentarios, opiniones y otros materiales, así como enviar sugerencias, ideas u otra información, siempre y cuando los materiales no incluyan ningún contenido ilegal, dañino, amenazante, abusivo, acosador, difamatorio, vulgar, obsceno o que incite al odio, o que sea objetable desde el punto de vista racial, étnico o de otro tipo, o que infrinja los derechos de terceros. Tú reconoces que, al acceder al Sitio, puedes tener contacto con contenidos que te parezcan dañinos, ofensivos, amenazantes, indecentes u objetables, y aceptas que Lazo no será responsable ante el usuario por dicho contenido, incluido, entre otros, el lenguaje explícito y otro material potencialmente ofensivo. Tú te comprometes a no suplantar a ninguna persona ni a comunicarte con un nombre falso o usar un nombre para el cual no tengas derecho o autorización. Lazo tiene el derecho (pero no la obligación) de eliminar, prohibir, editar o interrumpir cualquier contenido del Sitio, incluidos los contenidos publicados por cualquier usuario.',
        item10:
          'Si tú publicas contenido o envías material, salvo que dicho contenido o envío de material esté sujeto a condiciones independientes, Lazo será el propietario de dicho contenido y material y tú cedes por el presente cualquier derecho sobre dicho contenido y material a Lazo. Si la propiedad del contenido o del material no se puede conceder a Lazo, el usuario otorga a Lazo y a sus afiliados el derecho y la licencia perpetuos, irrevocables, mundiales, libres de derechos de autor, no exclusivos y sublicenciables para realizar, utilizar, vender, sublicenciar, reproducir, distribuir, ejecutar, mostrar, elaborar trabajos derivados y explotar de cualquier otro modo todo el contenido y el material como si fuera de su plena propiedad. Además, el usuario concede a Lazo, sus afiliados y sublicenciatarios el derecho a utilizar su nombre o nombre de usuario en relación con el contenido. Tú declaras y garantizas que todos los contenidos y materiales que proporciones serán producto de tu trabajo original y no se basarán en, o derivarán de, información o artículos propiedad de terceros. Además, declaras y garantizas que el uso que haces de los Servicios en relación con cualquier Contenido o contenido de terceros cumple con todas las leyes, incluidas, entre otras, las leyes de derechos de autor de los Estados Unidos. El usuario defenderá, indemnizará y eximirá a Lazo y a sus filiales de cualquier reclamación que se derive de cualquier contenido o material que proporcione en virtud del presente documento o del uso que haga de los Servicios.',
        item11:
          'A tu discreción, podrás enviar ocasionalmente comentarios a Lazo sobre las funciones y el desempeño de los Servicios, incluidos, entre otros, posibles errores, mejoras, modificaciones, correcciones de errores u optimizaciones ("Retroalimentación"). Si el usuario, a través de su evaluación o de otro modo, ofrece cualquier Retroalimentación, cede la propiedad de la misma a Lazo. Si la propiedad de la Retroalimentación no se puede conceder a Lazo, el usuario otorga a Lazo, sin cargo alguno, el derecho y la licencia perpetuos, irrevocables, libres de derechos de autor y mundiales para utilizar, reproducir, divulgar, sublicenciar, distribuir, modificar y explotar de otro modo dicha Retroalimentación sin restricciones. El usuario acepta que Lazo podrá compartir dicha Retroalimentación con terceros de cualquier modo y acepta la capacidad de Lazo de sublicenciar toda la Retroalimentación en cualquier forma a cualquier tercero sin restricción alguna.',
        item12:
          'El Sitio podrá contener secciones en las que se apliquen condiciones adicionales. A efectos del uso de dichas secciones, en caso de conflicto entre las condiciones de dichas secciones y las presentes Condiciones, prevalecerán las condiciones de la otra sección. Lazo podrá revisar en cualquier momento estas Condiciones mediante la actualización de este documento. Tú estás obligado a cumplir con dichas modificaciones y, por lo tanto, deberás visitar regularmente esta página para revisar las Condiciones  actuales que debes cumplir.',
        item13:
          'No enviarás a Lazo ni cargarás en este Sitio ningún Código malicioso ni utilizarás o te apropiarás indebidamente de los datos de este Sitio para tu propio beneficio comercial. Por "Código malicioso" se entenderá cualquier software (en ocasiones denominados virus, gusanos, caballos de Troya, bombas de tiempo, time locks, drop dead devices o programas informáticos de desactivación del sistema operativo, trampas, códigos de acceso, programas de cancelación o puertas trampa) que: (a) esté diseñado intencionalmente para dañar, interrumpir, desactivar, perjudicar, deteriorar, interferir, interceptar, expropiar o impedir de cualquier modo, cualquier dato, medio de almacenamiento, programa, sistema, equipo o comunicación, en función de cualquier evento, incluidos, entre otros, (i) que se exceda el número de copias, (ii) que se exceda el número de usuarios, (iii) que transcurra un periodo de tiempo, (iv) que se adelante a una fecha concreta u otro numeral, o (v) que se utilice una determinada función; o (b) que permita a una persona no autorizada producir dicho resultado; o (c) que permita a una persona no autorizada acceder a la información de otra persona sin el conocimiento y permiso de dicha persona.',
        item14:
          'No está permitido utilizar el nombre de usuario y la contraseña con fines no autorizados.',
        item15:
          'Lazo podrá retener y utilizar la información recopilada durante el uso de los Servicios, siempre que dicha información no identifique al usuario de forma individual.',
        item16:
          'Lazo no recopila deliberadamente ninguna información de ninguna persona que sepamos que es menor de 13 años. Si eres menor de 18 años, deberás utilizar este sitio web únicamente bajo la supervisión de tus padres o tutores y no deberás enviar ninguna información personal a Lazo. Si Lazo detecta que una persona menor de 13 años ha proporcionado a Lazo cualquier tipo de información personal, Lazo hará todos los esfuerzos comercialmente razonables para eliminar la información personal de dicho menor de todos los sistemas de Lazo. El usuario no intentará utilizar los Servicios en contravención de las leyes de cualquier jurisdicción, por ejemplo, al intentar adquirir bebidas alcohólicas para sí mismo o para otros siendo menor de edad.',
        item17:
          'Lazo podrá suspender tu acceso a su Sitio web o a los Servicios por cualquier motivo y en cualquier momento. Las cláusulas que por su naturaleza estén diseñadas para mantenerse en vigor después de la terminación o el vencimiento de este Acuerdo, seguirán vigentes después de que este Acuerdo o el acceso del usuario a nuestro Sitio o los Servicios finalicen o venzan.',
        item18:
          'Al utilizar este Sitio, tú aceptas estas Condiciones de uso y te comprometes a cumplir todas las leyes y reglamentos aplicables, incluidas las leyes y normativas de control de exportaciones y reexportaciones de los Estados Unidos, las leyes de derechos de autor y otras leyes relativas a la propiedad intelectual. Si no estás de acuerdo con estas Condiciones de uso, no utilices el Sitio.',
        item19:
          'Los materiales de este sitio se proporcionan con "derechos restringidos". El uso, la reproducción o la divulgación por parte de organismos gubernamentales están sujetos a las restricciones establecidas en las leyes y reglamentos vigentes. El uso de los materiales por parte de organismosgubernamentales constituye el reconocimiento de los derechos de propiedad de Lazo sobre los mismos',
        item20:
          'LOS MATERIALES, EL CONTENIDO DE ESTE SITIO Y LOS SERVICIOS SE PROPORCIONAN "COMO ESTÁN", "SEGÚN ESTÉN DISPONIBLES", SIN GARANTÍAS DE NINGÚN TIPO, NI EXPRESAS NI IMPLÍCITAS . Lazo NO ASUMIRÁ RESPONSABILIDAD ALGUNA POR CUALQUIER CONTENIDO O MATERIAL PUBLICADO EN EL SITIO O LOS SERVICIOS. Lazo NO GARANTIZA EL TIEMPO DE FUNCIONAMIENTO O LA DISPONIBILIDAD DEL SERVICIO. HASTA DONDE LO PERMITA LA LEY VIGENTE, Lazo RENUNCIA AL OTORGAMIENTO DE TODA GARANTÍA, TANTO EXPRESA COMO IMPLÍCITA, INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO, NO INFRACCIÓN U OTRA VIOLACIÓN DE DERECHOS. EL SERVICIO PODRÁ ESTAR SUJETO A LIMITACIONES, RETRASOS Y OTROS PROBLEMAS INHERENTES AL USO DEL INTERNET Y LAS COMUNICACAONES ELECTRÓNICAS . Lazo NOSE HACE RESPONSABLE DE NINGÚN RETRASO, FALTA DE ENTREGA U OTRO TIPO DE DAÑO DERIVADO DE DICHOS PROBLEMAS , INCLUYENDO LA CONGESTIÓN DE INTERNET, LOS ATAQUES DEVIRUS Y LOS ATAQUES DE DENEGACIÓN DE SERVICIO (DOS, por sus siglas en inglés).',
        item21:
          'BAJO NINGUNA CIRCUNSTANCIA, INCLUIDA, PERO NO LIMITADA A LA NEGLIGENCIA, NI Lazo NI LOS TERCEROS QUE LE OTORGUEN LICENCIAS SERÁN RESPONSABLES DE CUALQUIER DAÑO DIRECTO, INDIRECTO, ESPECIAL, ACCIDENTAL O CONSECUENTE, INCLUIDA, PERO NO LIMITADA A LA PÉRDIDA DE DATOS O GANANCIAS QUE SE DERIVE DEL USO O LA IMPOSIBILIDAD DE USO DE LOS MATERIALES DE ESTE SITIO O DEL SERVICIO, INCLUSO SI Lazo HA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS Y SIN PREJUICIO DE LA INEFICACIA DEL OBJETIVO ESENCIAL DE CUALQUIER RECURSO. SI EL USO QUE EL USUARIO HACE DE LOS MATERIALES DE ESTE SITIO O DEL SERVICIO CONLLEVA A LA NECESIDAD DE REALIZAR TAREAS DE MANTENIMIENTO, REPARACIÓN O CORRECCIÓN DEL EQUIPO O DE LOS DATOS, EL USUARIO ASUME LOS COSTOS CORRESPONDIENTES. ALGUNOS ESTADOS NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE LOS DAÑOS ACCIDENTALES O CONSECUENTES, POR LO QUE ES POSIBLE QUE LA LIMITACIÓN O EXCLUSIÓN ANTERIOR NO SE APLIQUE EN TU CASO.',
        item22:
          'Tú no podrás ceder o transferir el presente Acuerdo sin el consentimiento previo por escrito de Lazo. Cualquier intento de cesión o transferencia del presente Acuerdo por su parte será inválido.',
        item23:
          'Lazo no será responsable ante el usuario de ningún retraso o incumplimiento de sus obligaciones en virtud del presente documento si dicho retraso o incumplimiento se debe a una o varias causas ajenas al control razonable de Lazo. Dichas causas incluyen, pero no se limitan a, casos de fuerza mayor, inundaciones, incendios, pérdida de electricidad u otros servicios públicos, huelga laboral o retrasos por parte del usuario en el cumplimiento de cualquier requisito del presente documento.',
        item24:
          'Los usos comerciales o las prácticas comerciales anteriores que no formen parte del Acuerdo en sus términos expresos no podrán modificar, complementar, calificar o interpretar el presente Acuerdo.',
        item25:
          'Cada una de las cláusulas de este Acuerdo constituye una cláusula ejecutable por separado. Si se determina que alguna de las cláusulas del presente Acuerdo carece de fuerza ejecutoria o es ilegal, dicha cláusula se reformará en la medida mínima necesaria para que el presente Acuerdo se mantenga en vigor de acuerdo con los términos modificados por dicha modificación. La invalidez o imposibilidad de aplicación de cualquier cláusula del presente Acuerdo no afectará la validez del mismo en su conjunto, y las autoridades deberán hacer cumplir dicha cláusula, y en su caso la cláusula deberá modificarse para que se aplique al máximo grado permitido por la legislación aplicable.',
        item26:
          'Si alguna de las partes no hace valer en algún momento alguna de las disposiciones del presente Acuerdo, no ejerce alguna elección u opción prevista en el mismo, o no exige en algún momento a la otra parte el cumplimiento de alguna de las disposiciones del mismo, no se interpretará en modo alguno como una exención del cumplimiento de dichas disposiciones. No se considerará que la no ejecución o la demora en hacer valer cualquier derecho o en ejercer cualquier recurso constituya una renuncia a cualquier derecho o recurso.',
        item27:
          'En caso de litigio relacionado con el cumplimiento o la interpretación del presente Acuerdo, se concederán a la parte ganadora honorarios de abogados y costos razonables.',
        item28:
          'Los títulos de las secciones que se utilizan en el presente documento son solo por conveniencia y no se les dará ningún significado legal.',
        item29:
          'Convenio de arbitraje. Cualquier disputa, controversia o demanda que surja de, o esté relacionada con este Acuerdo y sus modificaciones posteriores, incluidas, entre otras, su formulación, validez, efecto vinculante, interpretación, ejecución, incumplimiento o rescisión, así como las demandas no contractuales, se someterán a arbitraje ante la Asociación Estadounidense de Arbitraje (AAA, por sus siglas en inglés) con arreglo a las Normas de Arbitraje de Consumo de la AAA. El lugar de arbitraje será Austin, Texas, Estados Unidos. El idioma de los procedimientos arbitrales será el inglés. Las partes podrán solicitar a cualquier tribunal de la jurisdicción competente una orden de restricción temporal, una medida preliminar o cualquier otra medida provisional o cautelar que sea necesaria, sin infringir la presente sección y sin limitar las facultades del árbitro. El árbitro podrá conceder cualquier forma de reparación individual o equitativa, incluida una medida cautelar. Cualquier fallo será definitivo y concluyente para las Partes y se podrá registrar en cualquier tribunal de jurisdicción competente. Tú aceptas que se dicten medidas cautelares para suspender cualquier demanda o que se te destituya como participante en cualquier demanda de dicha naturaleza. Al utilizar el Sitio de cualquier forma aceptas este convenio de arbitraje. Al hacerlo, renuncias a tu derecho a acudir a los tribunales para hacer valer o defender cualquier demanda entre Lazo y tú. TAMBIÉN RENUNCIAS AL DERECHO A PARTICIPAR EN UNA ACCIÓN COLECTIVA O EN OTRO PROCESO JUDICIAL COLECTIVO. Esta cláusula que te impide iniciar, adherirte o participar en una demanda colectiva es un convenio en sí mismo. El usuario podrá excluirse de esta cláusula mediante notificación por escrito de su decisión en un plazo de treinta (30) días a partir de la fecha en que utilice por primera vez la Aplicación cedida bajo licencia.',
        item30:
          'Todas las demandas que surjan o estén relacionadas con este Acuerdo, con los Servicios o con la relación del usuario con Lazo y que, por cualquier motivo, no se sometan a arbitraje, se litigarán exclusivamente en los tribunales federales o estatales del condado de Travis, Austin, Texas, Estados Unidos. El usuario y Lazo aceptan el ejercicio de la jurisdicción personal de dichos tribunales en el Estado de Texas y renuncian a cualquier derecho a reclamar que dichos tribunales constituyen un foro inconveniente.',
        item31:
          'El presente Acuerdo constituye el acuerdo completo entre las partes en relación con el objeto descrito en el mismo y sustituye todas las propuestas, negociaciones y escritos anteriores celebrados entre las partes en relación con el mismo.',
      },
      universalCards: {
        title: 'LAZO UNIVERSAL GIFT CARD TERMS AND CONDITIONS',
        subtitle:
          'Gracias por elegir la Tarjeta de Regalo Universal de Lazo ("Tarjeta de Regalo Universal de Lazo") y/o los Tokens de Regalo de Lazo (los "Tokens de Lazo") (en adelante, a veces colectivamente con la Tarjeta de Regalo Universal de Lazo, la "Tarjeta de Regalo de Lazo"), ofrecidos por Lazo e-commerce SL ("Lazo"). Al utilizar la Tarjeta de Regalo de Lazo, aceptas los siguientes términos de uso ("Términos y Condiciones").',
        autorizedUse: {
          title: 'USO AUTORIZADO',
          list: {
            item1:
              'La Tarjeta de Regalo de Lazo solo se puede utilizar para realizar compras autorizadas en los vendedores de Lazo participantes ("Vendedores Participantes"), a través de la aplicación móvil de Lazo (la "Aplicación").',
            item2:
              'Presenta tu Tarjeta de Regalo de Lazo al cajero en un Vendedor Participante o redímela en la aplicación móvil de Lazo al momento de la compra, y el saldo disponible de la tarjeta se aplicará a tu compra autorizada.',
            item3:
              'La Tarjeta de Regalo de Lazo o los Tokens de Lazo no pueden ser canjeados por dinero en efectivo. Lazo, su matriz directa o indirecta, subsidiarias y afiliadas no son responsables por el uso no autorizado de tu Tarjeta de Regalo de Lazo. Excepto según se establece en estedocumento, no se realizarán reembolsos en ninguna Tarjeta de Regalo de Lazo.',
          },
        },
        altruusTokens: {
          title: 'TOKENS LAZO',
          list: {
            item1:
              'Los Tokens de Lazo son tokens electrónicos que se pueden comprar a través de la Aplicación o recibir y/o ganar como premio en relación con ciertas actividades promocionales en línea y en redes sociales de Lazo o como regalo de otros usuarios de Lazo.',
            item2:
              'El valor de tus Tokens de Lazo o Tarjeta de Regalo Universal de Lazo, ya sea monetario o de otro tipo, no será reemplazado por Lazo si tus Tokens de Lazo o Tarjeta de Regalo Universal de Lazo se pierden, son robados o dañados. Debes proteger tus Tokens de Lazo o Tarjeta de Regalo Universal de Lazo. Si compartes tus Tokens de Lazo o Tarjeta de Regalo Universal de Lazo, otras personas pueden canjear el regalo, lo que resultaría en una disminución o pérdida total del valor de tus Tokens de Lazo o Tarjeta de Regalo Universal de Lazo.',
          },
        },
        balance: {
          title: 'CONSULTAS DE SALDO Y FACTURACIÓN',
          list: {
            item1:
              'Nos reservamos el derecho de corregir tu cuenta de Tarjeta de Regalo de Lazo si creemos que se haproducido un error clerical, de facturación o contable. Si tienes preguntas o inquietudes sobre tus Tokens de Lazo o Tarjeta de Regalo Universal de Lazo, incluido tu historial de transacciones, contáctanos a través de la Aplicación o en lazo@lazo-app.com.',
          },
        },
        general: {
          title: 'TÉRMINOS GENERALES',
          list: {
            item1:
              'Al utilizar la Aplicación, reconoces que el procesamiento técnico y la transmisión de la Aplicación, incluida la información que puedas ingresar, pueden implicar transmisiones a través de diversas redes y cambios para cumplir con los requisitos técnicos de las redes o dispositivos de conexión. Lazo y sus proveedores de servicios no son responsables de la transcripción incorrecta o inexacta de la información, de la falta de recepción de cualquier información, de problemas relacionados con cualquier equipo o programación asociada con la Tarjeta de Regalo de Lazo, de cualquier error humano, de cualquier interrupción, eliminación, omisión, defecto o fallo en la línea de cualquier red telefónica o transmisión electrónica, de problemas relacionados con equipos informáticos, software, la incapacidad para acceder a la Aplicación o al servicio en línea, o de cualquier otro error técnico o no técnico o mal funcionamiento.',
            item2:
              'Aceptas toda la responsabilidad por, e indemnizarás, defenderás y eximirás de responsabilidad a Lazo, su matriz directa o indirecta, subsidiarias y afiliadas, y a los respectivos funcionarios, directores, empleados, accionistas, agentes, franquiciados y sucesores de cada una de dichas empresas, cesionarios y proveedores de servicios, de cualquier reclamación que pueda surgir por acciones tomadas por ti o por cualquier acceso no autorizado, uso o mal uso de tu Tarjeta de Regalo de Lazo o la Aplicación.',
            item3:
              'Como condición de uso de la Aplicación y la Tarjeta de Regalo de Lazo, aceptas nuestros Términos de Servicio ubicados en',
            item32: 'https://app.lazo-app.com/terms?mobile',
            item33: ' y nuestra Política de Privacidad ubicada en',
            item34: 'https://app.lazo-app.com/privacy-policy?mobile',
            item35:
              ', ambos de los cuales se incorporan aquí por referencia. Cualquier disputa, reclamación o causa de acción que surja en relación con la Aplicación y/ o la Tarjeta de Regalo de Lazo se resolverá de acuerdo con los Términos de Servicio, incluida la disposición de Arbitraje establecida en ellos.',
            item4:
              'Si alguna disposición de estos Términos y Condiciones se considera inválida o inaplicable por un tribunal de jurisdicción competente, dicha disposición se separará del resto de estos Términos y Condiciones, que, de lo contrario, permanecerán en pleno vigor y efecto.',
            item5:
              'La Aplicación y el programa de Tarjeta de Regalo de Lazo no tienen una fecha de terminación predeterminada y pueden continuar hasta que Lazo decida poner fin a laoperación de la Aplicación y/o la emisión de las Tarjetas de Regalo de Lazo. Lazo puede poner fin a la emisión de Tarjetas de Regalo de Lazo y/o la operación de la Aplicación en cualquier momento, con o sin previo aviso.',
            item6: 'Fecha de vigencia: 30 de noviembre 2023',
          },
        },
      },
      referalRules: {
        title: 'REGLAS DEL PROGRAMA DE REFERIDOS DE LAZO',
        subtitle: {
          item1:
            'Gracias por participar en el Programa de Referidos de Lazo, Inc. ("Lazo"). Como usuario de Lazo, puedes animar a las personas a utilizar las aplicaciones y/o sitios web de Lazo. Participar en el programa de referidos (ya sea refiriendo a tus amigos o familiares o registrándote con un enlace o código de referencia) puede ser una excelente manera para ti y otros usuarios de Lazo de recibir Tokens de Lazo (cuando dicho servicio esté disponible), otros descuentos o ganar incentivos adicionales (cada uno, una "Recompensa de Referido").',
          item2:
            'Las reglas del Programa de Referidos de Lazo (las "Reglas") se aplican a los programas de referidos administrados por Lazo. Al aceptar y compartir tu enlace o código de referencia o al registrarte con un enlace o código de referencia, aceptas quedar vinculado por estas Reglas. Cualquier violación de estas Reglas no solo teimpedirá participar en cualquier programa de referidos de Lazo (ahora o en el futuro), sino que también podría resultar en la pérdida o revocación de Tokens de Lazo u otras recompensas obtenidas a través del programa, e incluso la desactivación de tu cuenta.',
          item3:
            'Ten en cuenta que estas Reglas se suman a los Términos de Servicio actualizados de vez en cuando, que puedes encontrar en ',
          item4: 'https://app.lazo-app.com/terms?mobile',
          item5:
            ' , que rigen tu uso del sitio web y las aplicaciones de Lazo, así como a nuestra Política de Privacidad ubicada aquí ',
          item6: 'https://app.lazo-app.com/privacy-policy?mobile',
          item7: ', ambas incorporadas aquí por referencia.',
        },
        referrer: {
          title: '¿QUIÉN PUEDE SER UN REFERENTE?',
          list: {
            item1:
              'Cualquier usuario de Lazo puede ser un referente que (a) sea residente legal de su país, (b) sea mayor de edad en su lugar de residencia y (c) tenga una cuenta de Lazo en buen estado. Los referentes pueden ser cualquier tipo de usuario de Lazo; sin embargo, no pueden tener más de una cuenta para cada producto o servicio de Lazo.',
          },
        },
        referee: {
          title: '¿QUIÉN PUEDE SER UN REFERIDO, SUJETO DE ELIGIBILIDAD?',
          list: {
            item1:
              'Tus amigos, familiares y otras personas que conozcas (pero no tú mismo) pueden ser elegibles para serreferidos. Para determinar la elegibilidad, ten en cuenta las siguientes estipulaciones:',
            item2:
              'Los referidos elegibles son aquellos que ordenan bienes o servicios de Vendedores de Lazo a través de la aplicación de Lazo:',
            item3:
              'Para recibir una Recompensa de Referido por referir a alguien que ordena bienes o servicios a través de la aplicación de Lazo, tu referido debe (a) ser un nuevo usuario de Lazo o un usuario que no haya utilizado los servicios de Lazo dentro de un cierto período de tiempo, (b) cumplir con las condiciones que Lazo tiene para el uso de la aplicación y (c) completar las acciones requeridas por el programa de referencia específico.',
          },
        },
        howToUse: {
          title: '¿CÓMO PUEDO USAR MI ENLACE O CÓDIGO DE REFERENCIA?',
          list: {
            item1:
              'Lazo tiene el derecho de limitar la cantidad de veces que puedes usar o compartir tu enlace o código de referencia.',
            item2: {
              title:
                'Lazo quiere que compartas tu enlace o código de referencia y ganes Recompensas de Referido, pero los enlaces o códigos de referencia deben usarse solo para fines personales y no comerciales. Esto significa que puedes compartir tu enlace o código de referencia solo con personas que conozcas. Aceptas que no:',
              item1:
                'Duplicarás, venderás o transferirás tu enlace o código de referencia de ninguna manera ni lo pondrás a disposición del público en general;',
              item2:
                'Intentarás obtener referidos mediante spam, envío masivo de correos electrónicos o envío de grandes cantidades de correos electrónicos no solicitados. Las únicas personas a las que debes enviar correos electrónicos son personas que conozcas personalmente;',
              item3:
                'Usarás, mostrarás o manipularás la propiedad intelectual de Lazo (como los logotipos, marcas registradas y obras protegidas por derechos de autor de Lazo) de ninguna manera, excepto para identificarte como usuario de Lazo, referente de Lazo o referente de Lazo;',
              item4:
                'Crearás o registrarás (i) negocios, (ii) URL, (iii) nombres de dominio, (iv) nombres o títulos de aplicaciones de software o (v) identificadores o perfiles en redes sociales que incluyan la palabra "Lazo" u otras marcas comerciales de Lazo o palabras que sean confusamente similares a las marcas comerciales de Lazo;',
              item5:
                'Usarás las marcas comerciales de Lazo como imagen de perfil o fondo de pantalla de tus redes sociales o utilizarás cualquiera de las obrasprotegidas por derechos de autor de Lazo (como gráficos, fotos, imágenes, dibujos y capturas de pantalla del sitio web o la aplicación de Lazo) sin el permiso expreso por escrito de Lazo;',
              item6:
                'Comprarás palabras clave (incluyendo, pero no limitado a, Google AdWords) que contengan alguna de las marcas comerciales de Lazo;',
              item7:
                'Usarás sistemas automatizados o bots a través de cualquier canal para distribuir, publicar o compartir tu enlace o código de referencia;',
              item8:
                'Usarás scripts o marcadores automáticos programados para enviar invitaciones o compartir tu enlace o código de referencia;',
              item9:
                'Harás afirmaciones engañosas sobre Lazo, utilizarás contenido ofensivo/abusivo, crearás sitios web/ páginas web/perfiles de redes sociales/aplicaciones falsos, representarás falsamente tu conexión con Lazo o harás cualquier declaración falsa o engañosa para que un referido utilice tu enlace o código;',
              item10:
                'Usarás tu enlace o código de referencia de ninguna manera que viole la ley o los derechos de cualquier otra persona.',
            },
            item3:
              'Recuerda que cuando compartas tu enlace o código de referencia, debes explicar que eres un referente de Lazo.',
          },
        },
        howToEarnReferrerReward: {
          title: '¿CÓMO PUEDO GANAR MI RECOMPENSA DE REFERIDO COMO REFERENTE?',
          list: {
            item1:
              'Si tú y tu referido siguen estas Reglas y los Términos de Servicio que rigen tu uso de la aplicación de Lazo, y tienes una cuenta activada para recibir la Recompensa de Referido, deberías recibir tu Recompensa de Referido después de que tu referido utilice tu enlace o código para registrarse en Lazo y/o activar la oferta de referencia y cumpla con los requisitos para la Recompensa de Referido específica. ',
            item2:
              'Ten en cuenta que, a menos que se indique lo contrario en la oferta de referencia, todas las invitaciones de referencia caducarán 7 (siete) días después de la fecha de envío y todas las Recompensas de Referido caducarán 7 (siete) días después de la fecha en que el invitado active la oferta de referencia mediante el uso de tu enlace o código de referencia. El período de caducidad de 7 días se reiniciará cada vez que el usuario de Lazo (a) invite a alguien que complete el proceso de registro, (b) canjee un regalo o (c) envíe un regalo.',
            item3:
              'Los referentes ganarán €5 (cinco Euros) en Tokens de Lazo por cada referencia elegible. Los referentes pueden invitar a tantas personas como deseen y pueden seguir ganando Recompensas de Referido.',
            item4:
              'Lazo se reserva el derecho de establecer un límite en la cantidad de veces que puedes usar tu enlace o código de referencia. Los requisitos para recibir y las cantidades de las Recompensas de Referido están sujetos a cambios a discreción exclusiva de Lazo. Las Recompensas de Referido en forma de créditos de Lazo no son transferibles, no tienen valor en efectivo y pueden caducar.',
          },
        },
        howToEarnRefereeReward: {
          title: '¿CÓMO PUEDO GANAR UNA RECOMPENSA DE REFERIDO COMO REFERIDO/INVITADO?',
          list: {
            item1:
              'Cuando la vigencia de las campañas promocionales de referidos esté vigente, los referidos/invitados recibirán €5 (cinco Euros) en Tokens de Lazo como Recompensa de Referido cuando completen exitosamente el proceso de registro. Lazo se reserva el derecho de comenzar y terminar las campañas sin previo aviso.',
          },
        },
        howToUseReward: {
          title: '¿CÓMO PUEDO UTILIZAR LAS RECOMPENSAS DE REFERIDO?',
          list: {
            item1:
              'Los usuarios de Lazo pueden utilizar las Recompensas de Referido ya sea para gastarlas en sí mismos (canjeando una tarjeta de regalo en un negocio registrado en la red de Lazo) o para utilizar el saldo como método de pago para comprar una tarjeta de regalo o SKU para enviar aun amigo. El uso de los Tokens de Lazo o la Tarjeta de Regalo de Lazo está sujeto a los términos ubicados aquí ',
            item2: ', que se incorporan aquí por referencia.',
            link: 'https://app.lazo-app.com/terms?mobile',
          },
        },
        termination: {
          title: 'TERMINACIÓN Y CAMBIOS',
          list: {
            item1:
              'Lazo se reserva el derecho de cambiar, finalizar o suspender, en su totalidad o en parte, el programa de referidos, así como la capacidad de cualquier referente o referido de participar en el programa de referidos o recibir Recompensas de Referido en cualquier momento y por cualquier motivo, incluido el fraude sospechado (tanto por parte del referente como del referido), el abuso o cualquier violación de estas Reglas, así como cualquier acuerdo (si existe) entre tú y Lazo o cualquiera de sus subsidiarias y afiliadas. Si Lazo finaliza el programa de referidos, todas las Recompensas de Referido no utilizadas o no canjeadas pueden perderse en ese momento, siempre y cuando te demos 14 (catorce) días de aviso para usar o canjear tus Recompensas de Referido.',
            item2:
              'Lazo puede actualizar estas Reglas en cualquier momento. Si Lazo realiza una actualización, la publicará en el sitio web y la aplicación de Lazo y te proporcionará un aviso de la actualización. La participación continua en cualquier programa de referidos después de cualquieractualización significará que has aceptado la actualización.',
          },
        },
      },
    },
    usageTermsPR: {
      title: 'CONDICIONES DE USO Pernod Ricard',
      subtitle:
        'Lee atentamente estas condiciones de uso antes de registrarte en el sitio web y usar los servicios de Lazo. Estas condiciones de uso (el "Acuerdo") regulan el acceso a los contenidos y el uso de los sitios web y las aplicaciones móviles de www.Lazo.com y sus afiliados. El presente Acuerdo entre el usuario (“tú" o “el usuario") y Lazo está sujeto a cambios por nuestra parte, tal y como se describe a continuación. Al registrarte (i) aceptas este Acuerdo; (ii) te comprometes a cumplir con estas condiciones; y (iii) habrás celebrado un acuerdo vinculante entre tú y Lazo.',
      list: {
        item1:
          'Este sitio web y cualquier aplicación móvil (colectivamente el "Sitio") son propiedad de Lazo, Inc. (en adelante “Lazo"). El acceso al Sitio y a su contenido ("Contenido") y al servicio de la plataforma de  envío de regalos de Lazo ("Servicios") únicamente es posible de conformidad con el presente Acuerdo. En caso de violación de los derechos de autor del Contenido o de estas condiciones, Lazo o el propietario de los derechos de autor podrá imponer medidas coercitivas en la medida que lo permita la ley. Lazo no se hace responsable de la entrega de los productos o servicios vendidos a través de los Servicios ("Productos de terceros"), ni es responsable de la calidad de dichos Productos de terceros. Tú comprendes y reconoces que el Sitio web es un mercado y que la responsabilidad de Lazo se limita a la entrega de la tarjeta de regalo digital o del comprobante de compra digital necesario para canjear los Productos de terceros, según corresponda.',
        item2:
          'Tú reconoces y aceptas que Lazo, a su total discreción y sin previo aviso, podrá suspender (de forma permanente o temporal) los Servicios (o cualquiera de sus funciones) que te presta a ti o a los usuarios en general. Puedes dejar de utilizar los Servicios en cualquier momento. No es necesario que informes expresamente a Lazo cuando dejes de utilizar los Servicios. Aceptas y reconoces que si Lazo desactiva el acceso a tu cuenta, es posible que no puedas acceder a los Servicios, a los datos de tu cuenta o a cualquier archivo u otro material que se encuentre en tu cuenta.',
        item3:
          'Tú te comprometes a no acceder (o intentar acceder) a ninguno de los Servicios por ningún otro medio que no sea la interfaz que proporciona Lazo, a menos que  tengas permiso expreso para hacerlo mediante un acuerdo por separado con Lazo. Aceptas no participar en ninguna actividad que interfiera o interrumpa los Servicios (o los servidores y redes conectados a los Servicios). Salvo autorización expresa de Lazo, aceptas no reproducir, duplicar, copiar, vender, comercializar o revender los Servicios por ningún motivo. Aceptas ser el único responsable (y que Lazo queda exento de toda responsabilidad ante el usuario o ante terceros) en caso de incumplimiento de las obligaciones contraídas en virtud del presente Acuerdo y de las repercusiones (incluidos los daños y perjuicios que pudiera sufrir Lazo) derivados de dicho incumplimiento.',
        item4:
          'Lazo no cobra por el acceso a su aplicación móvil; sin embargo, Lazo cobra una tasa de servicio (actualmente del cuatro por ciento) cuando el usuario utiliza los Servicios para enviar un regalo (la "Comisión por Servicio") y se aplican las tarifas del operador del usuario, como las comisiones por mensajería de texto y datos cuando se utiliza la aplicación. Tú reconoces, aceptas y das tu consentimiento para que los usuarios puedan sincronizar cualquier dispositivo con tu información. Lazo podrá cambiar la Comisión de Servicio en cualquier momento. Al utilizar el Servicio para enviar un regalo, el usuario conocerá la Comisión de Servicio vigente antes de completar la transacción.',
        item5:
          'Tú comprendes que toda la información y los materiales (como archivos de datos, textos escritos, software informático, música, archivos de audio u otros sonidos, fotografías, videos u otras imágenes) a los que pudieras tener acceso como parte de los Servicios, o a través de su uso, son responsabilidad exclusiva de la persona de la que proceden dichos materiales, pudiendo ser tú mismo.',
        item6:
          'Lazo se reserva todos los derechos no concedidos expresamente en el presente documento. No modificarás los avisos de derechos de autor, las leyendas de propiedad, las atribuciones de marcas comerciales y de servicio, las marcas de patentes y otros indicios de propiedad en el Contenido u otros materiales a los que tengas acceso a través de los Servicios. La entrega y la licencia sobre el Contenido o el acceso a materiales de terceros no le transfieren al usuario ningún derecho de uso comercial o promocional del Contenido o de cualquier parte del mismo. Queda estrictamente prohibido el uso del Contenido o descripciones; el uso derivado de este Sitio o sus materiales; y el uso de data mining, robots o herramientas similares de recolección y extracción de datos. En ningún caso el usuario utilizará marcos (frames) para cualquier parte del Sitio o cualquier material contenido en el mismo.',
        item7:
          'Si bien Lazo realiza esfuerzos razonables para incluir información precisa y actualizada en el Sitio, la empresa no ofrece garantías ni se hace responsable de su exactitud. Lazo no asume ninguna responsabilidad por cualquier error u omisión en el Contenido o el Sitio.',
        item8:
          'El Sitio podrá contener enlaces a otros sitios de Internet propiedad de terceros y gestionados por ellos, como, por ejemplo, procesadores de pagos y otros terceros (los "Sitios externos"). Tú reconoces que Lazo no es responsable de la disponibilidad de los Sitios externos ni de los materiales que se encuentran o se obtienen a través de los mismos. Si tienes alguna duda sobre los enlaces o los materiales que aparecen en esos sitios externos, ponte en contacto con el administrador o el webmaster de dichos Sitios externos.',
        item9:
          'Los usuarios del Sitio podrán publicar comentarios, opiniones y otros materiales, así como enviar sugerencias, ideas u otra información, siempre y cuando los materiales no incluyan ningún contenido ilegal, dañino, amenazante, abusivo, acosador, difamatorio, vulgar, obsceno o que incite al odio, o que sea objetable desde el punto de vista racial, étnico o de otro tipo, o que infrinja los derechos de terceros. Tú reconoces que, al acceder al Sitio, puedes tener contacto con contenidos que te parezcan dañinos, ofensivos, amenazantes, indecentes u objetables, y aceptas que Lazo no será responsable ante el usuario por dicho contenido, incluido, entre otros, el lenguaje explícito y otro material potencialmente ofensivo. Tú te comprometes a no suplantar a ninguna persona ni a comunicarte con un nombre falso o usar un nombre para el cual no tengas derecho o autorización. Lazo tiene el derecho (pero no la obligación) de eliminar, prohibir, editar o interrumpir cualquier contenido del Sitio, incluidos los contenidos publicados por cualquier usuario.',
        item10:
          'Si tú publicas contenido o envías material, salvo que dicho contenido o envío de material esté sujeto a condiciones independientes, Lazo será el propietario de dicho contenido y material y tú cedes por el presente cualquier derecho sobre dicho contenido y material a Lazo. Si la propiedad del contenido o del material no se puede conceder a Lazo, el usuario otorga a Lazo y a sus afiliados el derecho y la licencia perpetuos, irrevocables, mundiales, libres de derechos de autor, no exclusivos y sublicenciables para realizar, utilizar, vender, sublicenciar, reproducir, distribuir, ejecutar, mostrar, elaborar trabajos derivados y explotar de cualquier otro modo todo el contenido y el material como si fuera de su plena propiedad. Además, el usuario concede a Lazo, sus afiliados y sublicenciatarios el derecho a utilizar su nombre o nombre de usuario en relación con el contenido. Tú declaras y garantizas que todos los contenidos y materiales que proporciones serán producto de tu trabajo original y no se basarán en, o derivarán de, información o artículos propiedad de terceros. Además, declaras y garantizas que el uso que haces de los Servicios en relación con cualquier Contenido o contenido de terceros cumple con todas las leyes, incluidas, entre otras, las leyes de derechos de autor de los Estados Unidos. El usuario defenderá, indemnizará y eximirá a Lazo y a sus filiales de cualquier reclamación que se derive de cualquier contenido o material que proporcione en virtud del presente documento o del uso que haga de los Servicios.',
        item11:
          'A tu discreción, podrás enviar ocasionalmente comentarios a Lazo sobre las funciones y el desempeño de los Servicios, incluidos, entre otros, posibles errores, mejoras, modificaciones, correcciones de errores u optimizaciones ("Retroalimentación"). Si el usuario, a través de su evaluación o de otro modo, ofrece cualquier Retroalimentación, cede la propiedad de la misma a Lazo. Si la propiedad de la Retroalimentación no se puede conceder a Lazo, el usuario otorga a Lazo, sin cargo alguno, el derecho y la licencia perpetuos, irrevocables, libres de derechos de autor y mundiales para utilizar, reproducir, divulgar, sublicenciar, distribuir, modificar y explotar de otro modo dicha Retroalimentación sin restricciones. El usuario acepta que Lazo podrá compartir dicha Retroalimentación con terceros de cualquier modo y acepta la capacidad de Lazo de sublicenciar toda la Retroalimentación en cualquier forma a cualquier tercero sin restricción alguna.',
        item12:
          'El Sitio podrá contener secciones en las que se apliquen condiciones adicionales. A efectos del uso de dichas secciones, en caso de conflicto entre las condiciones de dichas secciones y las presentes Condiciones, prevalecerán las condiciones de la otra sección. Lazo podrá revisar en cualquier momento estas Condiciones mediante la actualización de este documento. Tú estás obligado a cumplir con dichas modificaciones y, por lo tanto, deberás visitar regularmente esta página para revisar las Condiciones  actuales que debes cumplir.',
        item13:
          'No enviarás a Lazo ni cargarás en este Sitio ningún Código malicioso ni utilizarás o te apropiarás indebidamente de los datos de este Sitio para tu propio beneficio comercial. Por "Código malicioso" se entenderá cualquier software (en ocasiones denominados virus, gusanos, caballos de Troya, bombas de tiempo, time locks, drop dead devices o programas informáticos de desactivación del sistema operativo, trampas, códigos de acceso, programas de cancelación o puertas trampa) que: (a) esté diseñado intencionalmente para dañar, interrumpir, desactivar, perjudicar, deteriorar, interferir, interceptar, expropiar o impedir de cualquier modo, cualquier dato, medio de almacenamiento, programa, sistema, equipo o comunicación, en función de cualquier evento, incluidos, entre otros, (i) que se exceda el número de copias, (ii) que se exceda el número de usuarios, (iii) que transcurra un periodo de tiempo, (iv) que se adelante a una fecha concreta u otro numeral, o (v) que se utilice una determinada función; o (b) que permita a una persona no autorizada producir dicho resultado; o (c) que permita a una persona no autorizada acceder a la información de otra persona sin el conocimiento y permiso de dicha persona.',
        item14:
          'No está permitido utilizar el nombre de usuario y la contraseña con fines no autorizados.',
        item15:
          'Lazo podrá retener y utilizar la información recopilada durante el uso de los Servicios, siempre que dicha información no identifique al usuario de forma individual.',
        item16:
          'Lazo no recopila deliberadamente ninguna información de ninguna persona que sepamos que es menor de 13 años. Si eres menor de 18 años, deberás utilizar este sitio web únicamente bajo la supervisión de tus padres o tutores y no deberás enviar ninguna información personal a Lazo. Si Lazo detecta que una persona menor de 13 años ha proporcionado a Lazo cualquier tipo de información personal, Lazo hará todos los esfuerzos comercialmente razonables para eliminar la información personal de dicho menor de todos los sistemas de Lazo. El usuario no intentará utilizar los Servicios en contravención de las leyes de cualquier jurisdicción, por ejemplo, al intentar adquirir bebidas alcohólicas para sí mismo o para otros siendo menor de edad.',
        item17:
          'Lazo podrá suspender tu acceso a su Sitio web o a los Servicios por cualquier motivo y en cualquier momento. Las cláusulas que por su naturaleza estén diseñadas para mantenerse en vigor después de la terminación o el vencimiento de este Acuerdo, seguirán vigentes después de que este Acuerdo o el acceso del usuario a nuestro Sitio o los Servicios finalicen o venzan.',
        item18:
          'Al utilizar este Sitio, tú aceptas estas Condiciones de uso y te comprometes a cumplir todas las leyes y reglamentos aplicables, incluidas las leyes y normativas de control de exportaciones y reexportaciones de los Estados Unidos, las leyes de derechos de autor y otras leyes relativas a la propiedad intelectual. Si no estás de acuerdo con estas Condiciones de uso, no utilices el Sitio.',
        item19:
          'Los materiales de este sitio se proporcionan con "derechos restringidos". El uso, la reproducción o la divulgación por parte de organismos gubernamentales están sujetos a las restricciones establecidas en las leyes y reglamentos vigentes. El uso de los materiales por parte de organismos gubernamentales constituye el reconocimiento de los derechos de propiedad de Lazo sobre los mismos.',
        item20:
          'LOS MATERIALES, EL CONTENIDO DE ESTE SITIO Y LOS SERVICIOS SE PROPORCIONAN "COMO ESTÁN", "SEGÚN ESTÉN DISPONIBLES", SIN GARANTÍAS DE NINGÚN TIPO, NI EXPRESAS NI IMPLÍCITAS.Lazo NO ASUMIRÁ RESPONSABILIDAD ALGUNA POR CUALQUIER CONTENIDO O MATERIAL PUBLICADO EN EL SITIO O LOS SERVICIOS. Lazo NO GARANTIZA EL TIEMPO DE FUNCIONAMIENTO O LA DISPONIBILIDAD DEL SERVICIO. HASTA DONDE LO PERMITA LA LEY VIGENTE, Lazo RENUNCIA AL OTORGAMIENTO DE TODA GARANTÍA, TANTO EXPRESA COMO IMPLÍCITA, INCLUIDAS, ENTRE OTRAS, LAS GARANTÍAS IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO, NO INFRACCIÓN U OTRA VIOLACIÓN DE DERECHOS. EL SERVICIO PODRÁ ESTAR SUJETO A LIMITACIONES, RETRASOS Y OTROS PROBLEMAS INHERENTES AL USO DEL INTERNET Y LAS COMUNICACIONES ELECTRÓNICAS. Lazo NO SE HACE RESPONSABLE DE NINGÚN RETRASO, FALTA DE ENTREGA U OTRO TIPO DE DAÑO DERIVADO DE DICHOS PROBLEMAS, INCLUYENDO LA CONGESTIÓN DE INTERNET, LOS ATAQUES DE VIRUS Y LOS ATAQUES DE DENEGACIÓN DE SERVICIO (DOS, por sus siglas en inglés).',
        item21:
          'BAJO NINGUNA CIRCUNSTANCIA, INCLUIDA, PERO NO LIMITADA A LA NEGLIGENCIA, NI Lazo NI LOS TERCEROS QUE LE OTORGUEN LICENCIAS SERÁN RESPONSABLES DE CUALQUIER DAÑO DIRECTO, INDIRECTO, ESPECIAL, ACCIDENTAL O CONSECUENTE, INCLUIDA, PERO NO LIMITADA A LA PÉRDIDA DE DATOS O GANANCIAS QUE SE DERIVE DEL USO O LA IMPOSIBILIDAD DE USO DE LOS MATERIALES DE ESTE SITIO O DEL SERVICIO, INCLUSO SI Lazo HA SIDO ADVERTIDO DE LA POSIBILIDAD DE DICHOS DAÑOS Y SIN PREJUICIO DE LA INEFICACIA DEL OBJETIVO ESENCIAL DE CUALQUIER RECURSO. SI EL USO QUE EL USUARIO HACE DE LOS MATERIALES DE ESTE SITIO O DEL SERVICIO CONLLEVA A LA NECESIDAD DE REALIZAR TAREAS DE MANTENIMIENTO, REPARACIÓN O CORRECCIÓN DEL EQUIPO O DE LOS DATOS, EL USUARIO ASUME LOS COSTOS CORRESPONDIENTES. ALGUNOS ESTADOS NO PERMITEN LA EXCLUSIÓN O LIMITACIÓN DE LOS DAÑOS ACCIDENTALES O CONSECUENTES, POR LO QUE ES POSIBLE QUE LA LIMITACIÓN O EXCLUSIÓN ANTERIOR NO SE APLIQUE EN TU CASO.',
        item22:
          'Tú no podrás ceder o transferir el presente Acuerdo sin el consentimiento previo por escrito de Lazo. Cualquier intento de cesión o transferencia del presente Acuerdo por su parte será inválido.',
        item23:
          'Lazo no será responsable ante el usuario de ningún retraso o incumplimiento de sus obligaciones en virtud del presente documento si dicho retraso o incumplimiento se debe a una o varias causas ajenas al control razonable de Lazo. Dichas causas incluyen, pero no se limitan a, casos de fuerza mayor, inundaciones, incendios, pérdida de electricidad u otros servicios públicos, huelga laboral o retrasos por parte del usuario en el cumplimiento de cualquier requisito del presente documento.',
        item24:
          'Los usos comerciales o las prácticas comerciales anteriores que no formen parte del Acuerdo en sus términos expresos no podrán modificar, complementar, calificar o interpretar el presente Acuerdo.',
        item25:
          'Cada una de las cláusulas de este Acuerdo constituye una cláusula ejecutable por separado. Si se determina que alguna de las cláusulas del presente Acuerdo carece de fuerza ejecutoria o es ilegal, dicha cláusula se reformará en la medida mínima necesaria para que el presente Acuerdo se mantenga en vigor de acuerdo con los términos modificados por dicha modificación. La invalidez o imposibilidad de aplicación de cualquier cláusula del presente Acuerdo no afectará la validez del mismo en su conjunto, y las autoridades deberán hacer cumplir dicha cláusula, y en su caso la cláusula deberá modificarse para que se aplique al máximo grado permitido por la legislación aplicable.',
        item26:
          'Si alguna de las partes no hace valer en algún momento alguna de las disposiciones del presente Acuerdo, no ejerce alguna elección u opción prevista en el mismo, o no exige en algún momento a la otra parte el cumplimiento de alguna de las disposiciones del mismo, no se interpretará en modo alguno como una exención del cumplimiento de dichas disposiciones. No se considerará que la no ejecución o la demora en hacer valer cualquier derecho o en ejercer cualquier recurso constituya una renuncia a cualquier derecho o recurso.',
        item27:
          'En caso de litigio relacionado con el cumplimiento o la interpretación del presente Acuerdo, se concederán a la parte ganadora honorarios de abogados y costos razonables.',
        item28:
          'Los títulos de las secciones que se utilizan en el presente documento son solo por conveniencia y no se les dará ningún significado legal.',
        item29:
          'Convenio de arbitraje. Cualquier disputa, controversia o demanda que surja de, o esté relacionada con este Acuerdo y sus modificaciones posteriores, incluidas, entre otras, su formulación, validez, efecto vinculante, interpretación, ejecución, incumplimiento o rescisión, así como las demandas no contractuales, se someterán a arbitraje ante la Asociación Estadounidense de Arbitraje (AAA, por sus siglas en inglés) con arreglo a las Normas de Arbitraje de Consumo de la AAA. El lugar de arbitraje será Austin, Texas, Estados Unidos. El idioma de los procedimientos arbitrales será el inglés. Las partes podrán solicitar a cualquier tribunal de la jurisdicción competente una orden de restricción temporal, una medida preliminar o cualquier otra medida provisional o cautelar que sea necesaria, sin infringir la presente sección y sin limitar las facultades del árbitro. El árbitro podrá conceder cualquier forma de reparación individual o equitativa, incluida una medida cautelar. Cualquier fallo será definitivo y concluyente para las Partes y se podrá registrar en cualquier tribunal de jurisdicción competente. Tú aceptas que se dicten medidas cautelares para suspender cualquier demanda o que se te destituya como participante en cualquier demanda de dicha naturaleza. Al utilizar el Sitio de cualquier forma aceptas este convenio de arbitraje. Al hacerlo, renuncias a tu derecho a acudir a los tribunales para hacer valer o defender cualquier demanda entre Lazo y tú. TAMBIÉN RENUNCIAS AL DERECHO A PARTICIPAR EN UNA ACCIÓN COLECTIVA O EN OTRO PROCESO JUDICIAL COLECTIVO. Esta cláusula que te impide iniciar, adherirte o participar en una demanda colectiva es un convenio en sí mismo. El usuario podrá excluirse de esta cláusula mediante notificación por escrito de su decisión en un plazo de treinta (30) días a partir de la fecha en que utilice por primera vez la Aplicación cedida bajo licencia.',
        item30:
          'Todas las demandas que surjan o estén relacionadas con este Acuerdo, con los Servicios o con la relación del usuario con Lazo y que, por cualquier motivo, no se sometan a arbitraje, se litigarán exclusivamente en los tribunales federales o estatales del condado de Travis, Austin, Texas, Estados Unidos. El usuario y Lazo aceptan el ejercicio de la jurisdicción personal de dichos tribunales en el Estado de Texas y renuncian a cualquier derecho a reclamar que dichos tribunales constituyen un foro inconveniente.',
        item31:
          'El presente Acuerdo constituye el acuerdo completo entre las partes en relación con el objeto descrito en el mismo y sustituye todas las propuestas, negociaciones y escritos anteriores celebrados entre las partes en relación con el mismo.',
      },
      universalCards: {
        title: 'Lazo Universal Gift Card Terms and Conditions',
        subtitle:
          'Thank you for choosing the Lazo Universal Gift Card (“Lazo Universal Gift Card”) and/or Lazo Gift Tokens (the “Lazo Tokens”) (hereinafter sometimes collectively with the Lazo Universal Gift Card, the “Lazo Gift Card”), brought to you by Lazo, Inc. (“Lazo”). By using the Lazo Gift Card, you agree to the following terms of use (“Terms and Conditions”).',
        autorizedUse: {
          title: 'Authorized Use',
          list: {
            item1:
              'The Lazo Gift Card may only be used for making authorized purchases from participating Lazo vendors (“Participating Vendors”), via the Lazo mobile application. (The “App”).',
            item2:
              'Present your Lazo Gift Card to the cashier at a Participating Vendor or redeem on the Lazo mobile application at the time of purchase and the card’s available balance will be applied toward your authorized purchase.',
            item3:
              'The Lazo Gift Card or Lazo Tokens cannot be redeemed for cash. Lazo, its direct or indirect parent, subsidiaries and affiliates are not responsible for any unauthorized card use of your Lazo Gift Card. Except as otherwise provided herein, no refunds will be provided on any Lazo Gift Card.',
          },
        },
        altruusTokens: {
          title: 'Lazo Tokens',
          list: {
            item1:
              'Lazo Tokens are an electronic tokens that may be purchased via the App or received and/or awarded as a prize in connection with certain Lazo online and social media promotional activities or a gift from other Lazo users.',
            item2:
              'The value of your Lazo Tokens or Lazo Universal Gift Card, monetary or otherwise, will not be replaced by Lazo if your Lazo Tokens or Lazo Universal Gift Card are lost, stolen or damaged. You should protect your Lazo Tokens or Lazo Universal Gift Card. If you share your Lazo Tokens or Lazo Universal Gift Card, others may redeem the gift resulting in a depletion or total loss of the value of your your Lazo Tokens or Lazo Universal Gift Card.',
          },
        },
        balance: {
          title: 'Balance and Billing Inquiries',
          list: {
            item1:
              'We reserve the right to correct your Lazo Gift Card account if we believe that a clerical, billing, or accounting error occurred. If you have questions or concerns regarding your Lazo Tokens or Lazo Universal Gift Card, including your transaction history, please contact us through the App or at support@lazo-app.com.',
          },
        },
        general: {
          title: 'General Terms',
          list: {
            item1:
              'By using the App you acknowledge that technical processing and transmission of the App, including the information you may enter, may involve transmissions over various networks and changes to conform and adopt to technical requirements of connecting networks or devices. Lazo and its service providers are not responsible for incorrect or inaccurate transcription of information, for the failure of any information to be received, for problems related to any of the equipment or programming associated with the Lazo Gift Card, for any human error, for any interruption, deletion, omission, defect, or line failure of any telephone network or electronic transmission, for problems relating to computer equipment, software, inability to access the App or on-line service, or for any other technical or non-technical error or malfunction.',
            item2:
              'You accept all responsibility for, and hereby agree to indemnify, defend, and hold harmless Lazo, its direct or indirect parent, subsidiaries and affiliates and each such company’s respective officers, directors, employees, shareholders, agents, franchisees and successors, assigns and service providers, from and against any claims that may arise from actions taken by you or for any unauthorized access to use or misuse of your Lazo Gift Card or the App.',
            item3:
              'As a condition of use of the App and the Lazo Gift Card, you agree to our Terms of Service located at',
            item32: 'https://app.lazo-app.com/terms?mobile',
            item33: ' and our Privacy Policy located at',
            item34: 'https://app.lazo-app.com/privacy-policy?mobile',
            item35:
              ', both of which are incorporated herein by this reference. Any and all disputes, claims, and causes of action arising out of or connected with the App and/or the Lazo Gift Card shall be resolved in accordance with the Terms of Service including the Arbitration Provision set forth therein.',
            item4:
              'If any provision of these Terms and Conditions is found to be invalid or unenforceable by a court of competent jurisdiction, such provision shall be severed from the remainder of these Terms and Conditions, which will otherwise remain in full force and effect',
            item5:
              'The App and Lazo Gift Card program has no predetermined termination date and may continue until such time as Lazo decides to terminate the operation of the App and/or issuance of the Lazo Gift Cards. Lazo may terminate the issuance of Lazo Gift Cards and/or its operation of the App at any time, with or without notice.',
            item6: 'Effective Date: December 25, 2021',
          },
        },
      },
      referalRules: {
        title: 'Lazo Referral Program Rules',
        subtitle: {
          item1:
            'Thank you for participating in our Lazo, Inc’s (“Lazo”) Referral Program. As an Lazo user, you may encourage people to use the Lazo applications and/or websites. Participating in the referral program (either by referring your friends or family or signing up with a referral link or code) can be a great way for you and other Lazo users to receive Lazo Tokens (where such service is available), other discounts or earn extra incentives (each a “Referral Reward”).',
          item2:
            'Lazo Referral Program rules (the “Rules”) apply to referral programs that are administered by Lazo. By accepting and sharing your referral link or code or by signing up with a referral link or code, you are agreeing to be bound by these Rules. Any violation of these Rules will not only prevent you from participating in any Lazo referral program (now or in the future) but could also result in the forfeiture or retraction of Lazo Tokens, or other rewards earned through the program and even the deactivation of your account.',
          item3:
            'Please note that these Rules are in addition to the Terms of Service as updated from time to time and can be found at ',
          item4: 'https://app.lazo-app.com/terms?mobile',
          item5:
            ' - governing your use of Lazo Website and apps as well our Privacy Policy located here ',
          item6: 'https://app.lazo-app.com/privacy-policy?mobile',
          item7: ' both of which are incorporated herein by this reference.',
        },
        referrer: {
          title: 'Who is eligible to be a referrer?',
          list: {
            item1:
              'Any Lazo user may be a referrer who (a) is a legal resident of his/her country, (b) is of legal age where they live, and (c) has an Lazo account in good standing. Referrers can be any type of Lazo user; however, they cannot have more than one account for each Lazo product or service',
          },
        },
        referee: {
          title: 'Who is eligible to be a referee?',
          list: {
            item1:
              'Your friends, family, and other people you know (but not yourself) may be eligible to be referees. To determine eligibility, keep in mind the following stipulations:',
            item2:
              'Eligible referees for those who order goods or services from Lazo Vendors through the Lazo application:',
            item3:
              'To receive a Referral Reward for referring someone who orders goods or services through the Lazo application, your referee must (a) be a new Lazo user or a user who has not used the Lazo services within a certain period of time, (b) meet the conditions Lazo has for using the app, and (c) complete the actions required by the specific referral program.',
          },
        },
        howToUse: {
          title: 'How can I use my referral link or code?',
          list: {
            item1:
              'Lazo has the right to limit the number of times you may use or share your referral link or code.',
            item2: {
              title:
                'Lazo wants you to share your referral link or code and earn Referral Rewards, but referral links or codes must be used only for personal and non-commercial purposes. This means that you can share your referral link or code only with people you know. You agree that you will not:',
              item1:
                'Duplicate, sell, or transfer your referral link or code in any manner or make it available to the general public;',
              item2:
                'Try to get referees by spamming, bulk emailing, or sending large numbers of unsolicited emails. The only people you should be emailing are people you know personally;',
              item3:
                'Use, display, or manipulate Lazo intellectual property (such as Lazo’s logos, trademarks, and copyright-protected works) in any way, except as to identify yourself as an Lazo user, Lazo referrer, or referrer for Lazo;',
              item4:
                'Create or register any (i) businesses, (ii) URLs, (iii) domain names, (iv) software application names or titles, or (v) social media handles or profiles that include the word “Lazo” or any of Lazo’s other trademarks or any words that are confusingly similar to Lazo’s trademarks.',
              item5:
                'Use Lazo’s trademarks as your social media profile picture or wallpaper or use any of Lazo’s copyright-protected works (such as graphics, photos, images, drawings, and screenshots from Lazo’s website or app) without Lazo’s express written permission;',
              item6:
                'Purchase keywords (including, but not limited to Google AdWords) that contain any of Lazo’s trademarks;',
              item7:
                'Use automated systems or bots through any channel to distribute, post, or otherwise share your referral link or code;',
              item8:
                'Use scripts or programmed or automatic dialers to send invites or otherwise share your referral link or code;',
              item9:
                'Make misleading claims about Lazo, use offensive/abusive content, create fake websites/webpages/social media profiles/apps, misrepresent your connection to Lazo, or otherwise make any false or misleading statements to get a referee to use your link or code;',
              item10:
                'Use your referral link or code in any manner that violates the law or the rights of anyone else.',
            },
            item3:
              'Remember, when you share your referral link or code, you should explain that you are an Lazo referrer.',
          },
        },
        howToEarnReferrerReward: {
          title: 'How do I earn my Referral Reward as a referrer?',
          list: {
            item1:
              'As long as you and your referee follow these Rules and the Terms of Service governing your use of the Lazo app, and you have an activated account to receive the Referral Reward, you should receive your Referral Reward after your referee uses your link or code to sign up with Lazo and/or to activate the referral offer and completes the requirements for the particular Referral Reward.',
            item2:
              'Note that unless stated otherwise on the referral offer, all referral invitations will expire 7 (seven) days from send date and all Referral Rewards will expire 7 (seven) days after the date that the invitee activates the referral offer by using your referral link or code. The 7-day expiration timeframe will restart every time the Lazo user either (a) invites someone who finishes the registration process, (b) redeems a gift, or (c) sends a gift.',
            item3:
              'Referrers will earn $10 (ten U.S. dollars) of Lazo Tokens for each eligible referral. Referrers can invite as many people as they want and can continue earning Referral Rewards.',
            item4:
              'Lazo reserves the right to set a limit on the number of times you may use your referral link or code. The requirements for receiving, and the amounts of, Referral Rewards are subject to change at Lazo’s sole discretion. Referral Rewards in the form of Lazo credits are not transferable, have no cash value, and may expire.',
          },
        },
        howToEarnRefereeReward: {
          title: 'How can I earn a Referral Reward as a referee/invitee?',
          list: {
            item1:
              'Referees/invitees will receive $10 (ten U.S. dollars) of Lazo Tokens as a Referral Reward when they successfully complete the registration process.',
          },
        },
        howToUseReward: {
          title: 'How can I use Referral Rewards?',
          list: {
            item1:
              'Lazo users can use the Referral Rewards to either spend on themselves (redeeming a gift card at a registered business in the Lazo network) or to use balance as a payment method to purchase a gift card or SKU to send to a friend.  Use of the Lazo Tokens or Lazo Gift Card is subject to the terms located here ',
            item2: 'which are incorporated herein by this reference.',
            link: 'https://app.lazo-app.com/terms?mobile',
          },
        },
        termination: {
          title: 'Termination and changes',
          list: {
            item1:
              'Lazo reserves the right to change, end, or pause, in whole or in part, the referral program, as well as any referrer’s or referee’s ability to participate in the referral program or receive Referral Rewards at any time for any reason, including suspected fraud (including by either the referrer and/or referee ), abuse, or any violation of these Rules as well as any agreement (if one exists) between you and Lazo or any of its subsidiaries and affiliates. If Lazo ends the referral program, any unused or unredeemed Referral Rewards may be forfeited at that time, provided we give you 14 (fourteen) days of notice to use or redeem your Referral Rewards.',
            item2:
              'Lazo may update these Rules at any time. If Lazo makes an update, Lazo will post the update on the Lazo.com website and application and provide you notice of the update. Continued participation in any referral program after any update will mean that you have agreed to the update.',
          },
        },
      },
    },
    about: {
      title: 'About Lazo ES',
      section1: {
        title: 'Introduction',
        paragraph:
          'Lazo, Inc. (“Lazo” or “we” or “us”) has created this Privacy Policy in order to disclose its use of data collected about you. This Privacy Policy is effective as of today, 2019. Any questions regarding this policy should be directed by email to support@lazo-app.com. The following discloses Lazo’s information gathering, usage and dissemination practices for its mobile applications, social networking applications and website.\n',
      },
      section2: {
        title: 'Information Collected and Received',
        paragraph1:
          'Lazo may collect or receive information about each person who visits the website, downloads and/or uses the mobile application, or interacts with us on any social networking applications, as well as each person who registers with the Lazo website and/or applications including, but not limited to, user name, password, first and last name, email address, behavior and interests. \n',
        paragraph2:
          'Lazo collects information on the pages you access and other information you may volunteer, such as survey information.  Lazo may also collect user location information, as further described in the “Global Positioning System” paragraph below.\n',
        paragraph3:
          'Information may also be collected by third parties, such as financial information collected by any payment processor, that are either collected on our behalf or as part of providing a service to you.',
      },
      section3: {
        title: 'Uses of Information',
        paragraph1:
          'The information that we collect is used to provide the services that you request as well as to improve and enhance the services and user experience. It may be used to ensure the proper functioning of the site, for advertising and marketing purposes, as well as to provide useful information.\n',
        paragraph2:
          'Lazo may use your IP address to help diagnose problems with Lazo’s server and to administer Lazo’s web site. Your IP address may also be used to help identify you and your online profile and to gather broad demographic information.\n',
        paragraph3:
          'The Lazo site registration form requires users to give Lazo contact information (such as your name and email address) and other information. Lazo uses your contact information from the registration form to send you information about Lazo and may send promotional material from some of Lazo’s partners. Your contact information may also be used to contact you when necessary. Lazo may use demographic and/or profile data to tailor your experience on the Lazo website and/or applications, show you content that Lazo thinks you may be interested in, and display content according to your preferences. Furthermore, Lazo shall have the right to retain records of all data pertaining to use of the website and applications including, but not limited to, usage, activity logs, and click-throughs.',
        paragraph4:
          'We may use other companies to perform services as part of our operations. In the course of providing these services, those companies may have access to your personal information. Those companies are expected to treat your information in accordance with either this Privacy Policy or their own privacy policy. However, we will not be liable for any damages that may result from the misuse of your personal information by these companies.',
        paragraph5:
          'From time to time, we may partner with companies. If required, these partner companies may receive your contact information if necessary to complete the transaction between you and them. We will not otherwise provide to them your email address or any other contact information that could be used to contact you directly. Any exceptions to this policy of sharing your name, address or email address with a partner company will be done only with your permission. \n',
        paragraph6:
          'Lazo reserves the right to disclose information when required by law. We also reserve the right to transfer your information to another entity as part of an acquisition, merger or corporate reorganization. \n',
        paragraph7:
          'Except as described in this section, we will not disclose your contact information to any third party without notifying you of our intent to share the information and giving you an opportunity to prevent your information from being shared.',
      },
      section4: {
        title: 'Children’s Privacy',
        paragraph:
          'The information that we collect is used to provide the services that you request as well as to improve and enhance the services and user experience. It may be used to ensure the proper functioning of the site, for advertising and marketing purposes, as well as to provide useful information.\n',
      },
      section5: {
        title: 'Global Positioning System',
        paragraph:
          'Global Positioning System (GPS) tracking technology may need to be enabled in Lazo products and services in order to determine the location (latitude and longitude) of users of the Lazo products and services. This information is transmitted to Lazo, Lazo’s users, and stored by Lazo. Lazo does not provide this information to any other third party except for any service providers.  Where applicable, this information is used in conjunction with Lazo’s applications.\n',
      },
      section6: {
        title: 'Security',
        paragraph:
          'Lazo uses industry-standard technologies when transferring and receiving consumer data exchanged between Lazo and other companies to help ensure its security. This site also has security measures in place to protect the loss, misuse and alteration of the information under Lazo’s control. However, we cannot guarantee the security of your information transmitted through the services or over email, or the information contained within the applications. Any transmission or data storage is at your own risk.',
      },
      section7: {
        title: 'Cookies',
        paragraph1:
          "“Cookies” are small pieces of information that are stored by your browser on your computer's hard drive. The Lazo site uses cookies to keep track of your session, shopping cart, and advertising delivery. Lazo may also use cookies to deliver content specific to your interests and to save your password. Lazo may use an outside ad company to display ads on the Lazo site. These ads may also contain cookies. While Lazo uses cookies in other parts of its web site, cookies received with banner ads are collected by Lazo’s ad company and Lazo does not have access to this information.\n",
        paragraph2:
          'We may use standard Internet technology, such as web beacons and other similar technologies, to track your use on our sites and software. We also may include web beacons in promotional email messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer our users to deliver targeted advertisements and to measure the overall effectiveness of our online advertising, content, programming or other activities.\n',
        paragraph3:
          'We may allow third parties, including our authorized service providers, advertising companies, and ad networks, to display advertisements on our site and/or software. Lazo and these companies may use tracking technologies, such as cookies, to collect information about users who view or interact with these advertisements and connect to the Lazo properties and/or software. This information allows Lazo and these companies to deliver targeted advertisements and gauge their effectiveness.',
      },
      section8: {
        title: 'Links',
        paragraph1:
          'Lazo may create links to other web sites. We are not responsible for the content or privacy practices of these third-party websites. \n',
        paragraph2:
          'Many other sites that are not associated or authorized by Lazo may have links leading to our site. Lazo cannot control these links and Lazo is not responsible for any content appearing on these sites.',
      },
      section9: {
        title: 'Amendments',
        paragraph:
          'Lazo may amend this policy at any time. If Lazo is going to use personally identifiable information collected through the Lazo site in a manner materially different from that stated at the time of collection, Lazo will notify users via email and/or by posting a notice on the Lazo site for thirty (30) days prior to such use. This Privacy Policy may not be otherwise amended except in a writing that specifically refers to this Privacy Policy and is physically signed by both parties.\n',
      },
      section10: {
        title: 'Successors and Assigns\n',
        paragraph: 'This Privacy Policy inures to the benefit of successors and assigns of Lazo.',
      },
      section11: {
        title: 'Contacts',
        paragraph:
          'If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this web site, you can contact support@lazo-app.com. Upon request and where required by law, Lazo will provide you with access to the information (e.g., name, email address) that Lazo collects and maintains about you. This site gives you the following options for changing and modifying information previously provided: (i) email: support@lazo-app.com; or (ii) where applicable, through the mobile application.\n',
      },
      section12: {
        title: 'Opt-Out',
        paragraph:
          'The Lazo site provides users the opportunity to opt-out of receiving communications from Lazo, except transactional correspondence. To opt-out of receiving our communications, you can (i) send email to support@lazo-app.com; or (ii) if applicable, opt-out through the unsubscribe link at the bottom of an email.',
      },
    },
    howItWorks: {
      title: 'How it works? ES',
      section1: {
        title: 'Introduction',
        paragraph:
          'Lazo, Inc. (“Lazo” or “we” or “us”) has created this Privacy Policy in order to disclose its use of data collected about you. This Privacy Policy is effective as of today, 2019. Any questions regarding this policy should be directed by email to support@lazo-app.com. The following discloses Lazo’s information gathering, usage and dissemination practices for its mobile applications, social networking applications and website.\n',
      },
      section2: {
        title: 'Information Collected and Received',
        paragraph1:
          'Lazo may collect or receive information about each person who visits the website, downloads and/or uses the mobile application, or interacts with us on any social networking applications, as well as each person who registers with the Lazo website and/or applications including, but not limited to, user name, password, first and last name, email address, behavior and interests. \n',
        paragraph2:
          'Lazo collects information on the pages you access and other information you may volunteer, such as survey information.  Lazo may also collect user location information, as further described in the “Global Positioning System” paragraph below.\n',
        paragraph3:
          'Information may also be collected by third parties, such as financial information collected by any payment processor, that are either collected on our behalf or as part of providing a service to you.',
      },
      section3: {
        title: 'Uses of Information',
        paragraph1:
          'The information that we collect is used to provide the services that you request as well as to improve and enhance the services and user experience. It may be used to ensure the proper functioning of the site, for advertising and marketing purposes, as well as to provide useful information.\n',
        paragraph2:
          'Lazo may use your IP address to help diagnose problems with Lazo’s server and to administer Lazo’s web site. Your IP address may also be used to help identify you and your online profile and to gather broad demographic information.\n',
        paragraph3:
          'The Lazo site registration form requires users to give Lazo contact information (such as your name and email address) and other information. Lazo uses your contact information from the registration form to send you information about Lazo and may send promotional material from some of Lazo’s partners. Your contact information may also be used to contact you when necessary. Lazo may use demographic and/or profile data to tailor your experience on the Lazo website and/or applications, show you content that Lazo thinks you may be interested in, and display content according to your preferences. Furthermore, Lazo shall have the right to retain records of all data pertaining to use of the website and applications including, but not limited to, usage, activity logs, and click-throughs.',
        paragraph4:
          'We may use other companies to perform services as part of our operations. In the course of providing these services, those companies may have access to your personal information. Those companies are expected to treat your information in accordance with either this Privacy Policy or their own privacy policy. However, we will not be liable for any damages that may result from the misuse of your personal information by these companies.',
        paragraph5:
          'From time to time, we may partner with companies. If required, these partner companies may receive your contact information if necessary to complete the transaction between you and them. We will not otherwise provide to them your email address or any other contact information that could be used to contact you directly. Any exceptions to this policy of sharing your name, address or email address with a partner company will be done only with your permission. \n',
        paragraph6:
          'Lazo reserves the right to disclose information when required by law. We also reserve the right to transfer your information to another entity as part of an acquisition, merger or corporate reorganization. \n',
        paragraph7:
          'Except as described in this section, we will not disclose your contact information to any third party without notifying you of our intent to share the information and giving you an opportunity to prevent your information from being shared.',
      },
      section4: {
        title: 'Children’s Privacy',
        paragraph:
          'The information that we collect is used to provide the services that you request as well as to improve and enhance the services and user experience. It may be used to ensure the proper functioning of the site, for advertising and marketing purposes, as well as to provide useful information.\n',
      },
      section5: {
        title: 'Global Positioning System',
        paragraph:
          'Global Positioning System (GPS) tracking technology may need to be enabled in Lazo products and services in order to determine the location (latitude and longitude) of users of the Lazo products and services. This information is transmitted to Lazo, Lazo’s users, and stored by Lazo. Lazo does not provide this information to any other third party except for any service providers.  Where applicable, this information is used in conjunction with Lazo’s applications.\n',
      },
      section6: {
        title: 'Security',
        paragraph:
          'Lazo uses industry-standard technologies when transferring and receiving consumer data exchanged between Lazo and other companies to help ensure its security. This site also has security measures in place to protect the loss, misuse and alteration of the information under Lazo’s control. However, we cannot guarantee the security of your information transmitted through the services or over email, or the information contained within the applications. Any transmission or data storage is at your own risk.',
      },
      section7: {
        title: 'Cookies',
        paragraph1:
          "“Cookies” are small pieces of information that are stored by your browser on your computer's hard drive. The Lazo site uses cookies to keep track of your session, shopping cart, and advertising delivery. Lazo may also use cookies to deliver content specific to your interests and to save your password. Lazo may use an outside ad company to display ads on the Lazo site. These ads may also contain cookies. While Lazo uses cookies in other parts of its web site, cookies received with banner ads are collected by Lazo’s ad company and Lazo does not have access to this information.\n",
        paragraph2:
          'We may use standard Internet technology, such as web beacons and other similar technologies, to track your use on our sites and software. We also may include web beacons in promotional email messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer our users to deliver targeted advertisements and to measure the overall effectiveness of our online advertising, content, programming or other activities.\n',
        paragraph3:
          'We may allow third parties, including our authorized service providers, advertising companies, and ad networks, to display advertisements on our site and/or software. Lazo and these companies may use tracking technologies, such as cookies, to collect information about users who view or interact with these advertisements and connect to the Lazo properties and/or software. This information allows Lazo and these companies to deliver targeted advertisements and gauge their effectiveness.',
      },
      section8: {
        title: 'Links',
        paragraph1:
          'Lazo may create links to other web sites. We are not responsible for the content or privacy practices of these third-party websites. \n',
        paragraph2:
          'Many other sites that are not associated or authorized by Lazo may have links leading to our site. Lazo cannot control these links and Lazo is not responsible for any content appearing on these sites.',
      },
      section9: {
        title: 'Amendments',
        paragraph:
          'Lazo may amend this policy at any time. If Lazo is going to use personally identifiable information collected through the Lazo site in a manner materially different from that stated at the time of collection, Lazo will notify users via email and/or by posting a notice on the Lazo site for thirty (30) days prior to such use. This Privacy Policy may not be otherwise amended except in a writing that specifically refers to this Privacy Policy and is physically signed by both parties.\n',
      },
      section10: {
        title: 'Successors and Assigns\n',
        paragraph: 'This Privacy Policy inures to the benefit of successors and assigns of Lazo.',
      },
      section11: {
        title: 'Contacts',
        paragraph:
          'If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this web site, you can contact support@lazo-app.com. Upon request and where required by law, Lazo will provide you with access to the information (e.g., name, email address) that Lazo collects and maintains about you. This site gives you the following options for changing and modifying information previously provided: (i) email: support@lazo-app.com; or (ii) where applicable, through the mobile application.\n',
      },
      section12: {
        title: 'Opt-Out',
        paragraph:
          'The Lazo site provides users the opportunity to opt-out of receiving communications from Lazo, except transactional correspondence. To opt-out of receiving our communications, you can (i) send email to support@lazo-app.com; or (ii) if applicable, opt-out through the unsubscribe link at the bottom of an email.',
      },
    },
    userTerms: {
      title: 'User terms',
      section1: {
        title: 'Introduction',
        paragraph:
          'Lazo, Inc. (“Lazo” or “we” or “us”) has created this Privacy Policy in order to disclose its use of data collected about you. This Privacy Policy is effective as of ________, 2019. Any questions regarding this policy should be directed by email to support@lazo-app.com. The following discloses Lazo’s information gathering, usage and dissemination practices for its mobile applications, social networking applications and website.\n',
      },
      section2: {
        title: 'Information Collected and Received',
        paragraph1:
          'Lazo may collect or receive information about each person who visits the website, downloads and/or uses the mobile application, or interacts with us on any social networking applications, as well as each person who registers with the Lazo website and/or applications including, but not limited to, user name, password, first and last name, email address, behavior and interests. \n',
        paragraph2:
          'Lazo collects information on the pages you access and other information you may volunteer, such as survey information.  Lazo may also collect user location information, as further described in the “Global Positioning System” paragraph below.\n',
        paragraph3:
          'Information may also be collected by third parties, such as financial information collected by any payment processor, that are either collected on our behalf or as part of providing a service to you.',
      },
      section3: {
        title: 'Uses of Information',
        paragraph1:
          'The information that we collect is used to provide the services that you request as well as to improve and enhance the services and user experience. It may be used to ensure the proper functioning of the site, for advertising and marketing purposes, as well as to provide useful information.\n',
        paragraph2:
          'Lazo may use your IP address to help diagnose problems with Lazo’s server and to administer Lazo’s web site. Your IP address may also be used to help identify you and your online profile and to gather broad demographic information.\n',
        paragraph3:
          'The Lazo site registration form requires users to give Lazo contact information (such as your name and email address) and other information. Lazo uses your contact information from the registration form to send you information about Lazo and may send promotional material from some of Lazo’s partners. Your contact information may also be used to contact you when necessary. Lazo may use demographic and/or profile data to tailor your experience on the Lazo website and/or applications, show you content that Lazo thinks you may be interested in, and display content according to your preferences. Furthermore, Lazo shall have the right to retain records of all data pertaining to use of the website and applications including, but not limited to, usage, activity logs, and click-throughs.',
        paragraph4:
          'We may use other companies to perform services as part of our operations. In the course of providing these services, those companies may have access to your personal information. Those companies are expected to treat your information in accordance with either this Privacy Policy or their own privacy policy. However, we will not be liable for any damages that may result from the misuse of your personal information by these companies.',
        paragraph5:
          'From time to time, we may partner with companies. If required, these partner companies may receive your contact information if necessary to complete the transaction between you and them. We will not otherwise provide to them your email address or any other contact information that could be used to contact you directly. Any exceptions to this policy of sharing your name, address or email address with a partner company will be done only with your permission. \n',
        paragraph6:
          'Lazo reserves the right to disclose information when required by law. We also reserve the right to transfer your information to another entity as part of an acquisition, merger or corporate reorganization. \n',
        paragraph7:
          'Except as described in this section, we will not disclose your contact information to any third party without notifying you of our intent to share the information and giving you an opportunity to prevent your information from being shared.',
      },
      section4: {
        title: 'Children’s Privacy',
        paragraph:
          'The information that we collect is used to provide the services that you request as well as to improve and enhance the services and user experience. It may be used to ensure the proper functioning of the site, for advertising and marketing purposes, as well as to provide useful information.\n',
      },
      section5: {
        title: 'Global Positioning System',
        paragraph:
          'Global Positioning System (GPS) tracking technology may need to be enabled in Lazo products and services in order to determine the location (latitude and longitude) of users of the Lazo products and services. This information is transmitted to Lazo, Lazo’s users, and stored by Lazo. Lazo does not provide this information to any other third party except for any service providers.  Where applicable, this information is used in conjunction with Lazo’s applications.\n',
      },
      section6: {
        title: 'Security',
        paragraph:
          'Lazo uses industry-standard technologies when transferring and receiving consumer data exchanged between Lazo and other companies to help ensure its security. This site also has security measures in place to protect the loss, misuse and alteration of the information under Lazo’s control. However, we cannot guarantee the security of your information transmitted through the services or over email, or the information contained within the applications. Any transmission or data storage is at your own risk.',
      },
      section7: {
        title: 'Cookies',
        paragraph1:
          "“Cookies” are small pieces of information that are stored by your browser on your computer's hard drive. The Lazo site uses cookies to keep track of your session, shopping cart, and advertising delivery. Lazo may also use cookies to deliver content specific to your interests and to save your password. Lazo may use an outside ad company to display ads on the Lazo site. These ads may also contain cookies. While Lazo uses cookies in other parts of its web site, cookies received with banner ads are collected by Lazo’s ad company and Lazo does not have access to this information.\n",
        paragraph2:
          'We may use standard Internet technology, such as web beacons and other similar technologies, to track your use on our sites and software. We also may include web beacons in promotional email messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer our users to deliver targeted advertisements and to measure the overall effectiveness of our online advertising, content, programming or other activities.\n',
        paragraph3:
          'We may allow third parties, including our authorized service providers, advertising companies, and ad networks, to display advertisements on our site and/or software. Lazo and these companies may use tracking technologies, such as cookies, to collect information about users who view or interact with these advertisements and connect to the Lazo properties and/or software. This information allows Lazo and these companies to deliver targeted advertisements and gauge their effectiveness.',
      },
      section8: {
        title: 'Links',
        paragraph1:
          'Lazo may create links to other web sites. We are not responsible for the content or privacy practices of these third-party websites. \n',
        paragraph2:
          'Many other sites that are not associated or authorized by Lazo may have links leading to our site. Lazo cannot control these links and Lazo is not responsible for any content appearing on these sites.',
      },
      section9: {
        title: 'Amendments',
        paragraph:
          'Lazo may amend this policy at any time. If Lazo is going to use personally identifiable information collected through the Lazo site in a manner materially different from that stated at the time of collection, Lazo will notify users via email and/or by posting a notice on the Lazo site for thirty (30) days prior to such use. This Privacy Policy may not be otherwise amended except in a writing that specifically refers to this Privacy Policy and is physically signed by both parties.\n',
      },
      section10: {
        title: 'Successors and Assigns\n',
        paragraph: 'This Privacy Policy inures to the benefit of successors and assigns of Lazo.',
      },
      section11: {
        title: 'Contacts',
        paragraph:
          'If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this web site, you can contact support@lazo-app.com. Upon request and where required by law, Lazo will provide you with access to the information (e.g., name, email address) that Lazo collects and maintains about you. This site gives you the following options for changing and modifying information previously provided: (i) email: support@lazo-app.com; or (ii) where applicable, through the mobile application.\n',
      },
      section12: {
        title: 'Opt-Out',
        paragraph:
          'The Lazo site provides users the opportunity to opt-out of receiving communications from Lazo, except transactional correspondence. To opt-out of receiving our communications, you can (i) send email to support@lazo-app.com; or (ii) if applicable, opt-out through the unsubscribe link at the bottom of an email.',
      },
    },
    merchAgreement: {
      title: 'Contrato con el Comerciante',
      paragraph:
        'Este Contrato con el Comerciante ("Contrato con el Comerciante"), efectivo a partir del momento en que el Comerciante acepta los términos y crea una cuenta de Comerciante ("Fecha Efectiva"), se celebra entre Lazo, Inc., con oficinas principales en Paseo de la Dirección, 340, 5ºC, 28029 Madrid ("Lazo") y el ("Comerciante"). El Comerciante desea ofrecer sus productos o servicios para la venta al público para su compra en www.Lazo-app.com (el "Sitio web") o a través de la aplicación móvil de Lazo ("Aplicación Móvil"). Lazo desea vender los productos o servicios del Comerciante a los compradores en el Sitio web y/o la Aplicación Móvil, siendo el Comerciante el vendedor de los bienes, servicios y/o tarjetas de regalo (en conjunto, "Productos"), y Lazo actuando como intermediario en línea para facilitar la venta de los Productos. Al hacer clic en el botón "Acepto", en consideración de los convenios mutuos de las partes y otra consideración valiosa, cuya suficiencia y recepción se reconocen por este medio, el Comerciante (i) acepta este Contrato con el Comerciante; (ii) se compromete a cumplir con estos términos y condiciones; y (iii) ha celebrado un acuerdo vinculante con Lazo, en los siguientes términos:',
      section1: {
        title: 'Definiciones',
        list: {
          paragraph1:
            '"Importe de Remesa" significa el importe total agregado de todos los Productos comprados por los compradores para un comerciante específico, menos las tarifas aplicables de servicios de pago de terceros',
        },
      },
      section2: {
        title: 'Venta de Productos',
        paragraph:
          'El Comerciante autoriza a Lazo a ofrecer y vender sus Productos en su nombre, de acuerdo con este Contrato con el Comerciante. El Comerciante reconoce que Lazo puede dar por terminada la publicación o promoción de los Productos en cualquier momento. En ningún caso, Lazo será responsable de proporcionar los Productos ofrecidos por el Comerciante. Lazo no es responsable de la entrega de los Productos vendidos a través del Sitio web o la Aplicación Móvil, ni es responsable de la calidad de dichos Productos. El Comerciante comprende y reconoce que Lazo es un mercado en línea y que la responsabilidad de Lazo se limita únicamente a la entrega de la tarjeta de regalo digital o el recibo de compra digital necesarios para canjear el Producto, según corresponda. El Comerciante es responsable de obtener la verificación adecuada para determinar si un cliente tiene la edad legal para adquirir los Productos.',
      },
      section3: {
        title: 'Pago',
        paragraph:
          'Una transacción de depósito comenzará inmediatamente después de la compra de un Producto utilizando un servicio de pago de terceros (por ejemplo, Stripe). Las tarifas por la compra se transferirán del servicio de pago de terceros al Comerciante, y Lazo no retendrá dinero alguno en relación con la transacción. Para mayor claridad, Lazo no posee ningún fondo perteneciente a un consumidor. El Comerciante está registrado con fines de recaudación de impuestos sobre las ventas y el uso, y será responsable de pagar todos los impuestos sobre las ventas y el uso relacionados con los Productos descritos en la oferta.',
      },
      section4: {
        title: 'Remesa',
        paragraph:
          'La transacción comenzará inmediatamente después de cada compra de Producto utilizando un servicio de pago de terceros (como Stripe). El servicio de pago de terceros remitirá el Importe de Remesa al Comerciante.',
      },
      section5: {
        title: 'Licencia a Lazo',
        paragraph:
          'El Comerciante otorga a Lazo una licencia no exclusiva y mundial para usar, reproducir, otorgar licencias, mostrar, distribuir y transmitir el nombre del Comerciante, el logotipo y cualquier marca comercial ("Marcas del Comerciante") y cualquier fotografía, gráfico, obra de arte, texto y otro contenido proporcionado o especificado por el Comerciante ("Contenido") en relación con la comercialización, promoción o venta de los Productos del Comerciante, en cualquier y todos los medios o formatosen los que dichos bienes y servicios se comercialicen, promocionen, transmitan o vendan, incluyendo, entre otros, en el Sitio web y/o la Aplicación Móvil. Lazo ejercerá la diligencia debida para resguardar dicha información, pero no se hace responsable ante cualquier ataque cibernético que pueda ocurrir en el futuro o el uso de cualquier copia no autorizada resultado de actos ilícitos.',
      },
      section6: {
        title: 'Contenido de alta resolución',
        paragraph:
          'El Comerciante acepta proporcionar a Lazo Contenido de alta calidad y alta resolución para mostrarlo en su Aplicación Móvil. El Comerciante acepta que si, a juicio exclusivo de Lazo, su Contenido no cumple con la calidad o resolución requerida para ser adecuadamente mostrado en la Aplicación Móvil, Lazo puede, previo aviso al Comerciante, eliminar o reemplazar dicho Contenido. Dicho aviso se enviará a la dirección de correo electrónico correspondiente utilizada por el Comerciante al registrarse como Comerciante o a través de mensajes en el portal del Comerciante.',
      },
      section7: {
        title: 'Plazo y Terminación',
        paragraph:
          'Este Contrato con el Comerciante continuará en vigor hasta su terminación. Cualquiera de las partes podrá dar por terminado este Contrato con el Comerciante en cualquier momento y por cualquier motivo mediante el envío de un aviso por escrito de dicha terminación a la otra parte. La terminación de este Contrato con elComerciante no afectará de ninguna manera el uso de los Productos por parte de los compradores o la obligación del Comerciante al respecto, es decir, el Comerciante debe honrar los regalos y tarjetas de regalo vendidos antes de la terminación o reembolsar a los compradores en su totalidad por todos los regalos y/o tarjetas de regalo pendientes. Las Secciones 1, 3, 6, 7, 8, 9, 10 y cualquier reclamo de pagos adeudados a Lazo sobrevivirán a cualquier vencimiento o terminación de este Contrato con el Comerciante. ',
      },
      section8: {
        title: 'Representaciones y Garantías del Comerciante, e Indemnización',
        list: {
          paragraph1:
            'El comerciante declara y garantiza que: (a) el comerciante tiene el derecho, poder y autoridad para celebrar este Acuerdo del Comerciante; (b) el comerciante está registrado con fines de recaudación de impuestos sobre las ventas y el uso en todos los estados en los que los Productos del comerciante se proporcionarán de acuerdo con los términos y la presentación de los Productos; (c) los términos y condiciones de los Productos, incluidos cualquier descuento o Producto ofrecido en virtud de los mismos, cumplen con todas las leyes aplicables y no violan ni violarán ninguna ley, estatuto, regla, regulación o ordenanza local, estatal, autonómica o federal; (e) el comerciante es dueño de todos los derechos, títulos e intereses en las Marcas y Contenido del Comerciante y tiene el derecho de otorgar las licencias en las Marcas y Contenido del Comerciante establecidas en este Acuerdodel Comerciante; (e) cualquier publicidad o promoción de los productos y servicios del comerciante relacionados con los mismos no constituirán publicidad falsa, engañosa o desleal ni difamación en virtud de ninguna ley aplicable; (f) las Marcas y Contenido del Comerciante no violan ni violarán ningún derecho de autor, marca comercial u otro derecho de propiedad intelectual ni derecho de privacidad o publicidad de terceros ni ninguna ley; y (g) el comerciante tiene una base razonable para todas las afirmaciones hechas en el Contenido, posee la documentación adecuada para respaldar dichas afirmaciones y cumplirá con todos los compromisos hechos en el Contenido. ',
          paragraph2:
            'El comerciante acepta defender, indemnizar y eximir de responsabilidad a Lazo, sus entidades afiliadas y relacionadas, y a cualquiera de sus funcionarios, directores, agentes, empleados y clientes finales, de y contra cualquier reclamo, demanda, investigación, sanción, daño, pérdida o gasto (incluyendo, entre otros, honorarios y costos de abogados) que surja de o esté relacionado con cualquiera de los siguientes: (a) cualquier incumplimiento o presunto incumplimiento por parte del comerciante de este Acuerdo del Comerciante o las representaciones y garantías contenidas en el mismo; (b) cualquier reclamo de obligaciones fiscales estatales sobre las ventas o el uso ("Impuestos") derivado de los Productos; (c) cualquier reclamo por parte de una entidad gubernamental local, estatal, autonómica o federal por Productos no redimidos o valores en efectivo no redimidosde Productos o cualquier otro monto bajo cualquier ley de propiedad abandonada o no reclamada aplicable o ley de escheat, incluyendo, entre otros, cualquier reclamo por penalizaciones e intereses ("Reclamaciones de Propiedad Abandonada"); (d) cualquier reclamo derivado de una violación de la ley y/o regulación que rige el uso, venta y/o distribución de alcohol; o (e) cualquier reclamo derivado de o relacionado con los productos y servicios proporcionados por el comerciante, incluyendo, entre otros, reclamos por publicidad falsa, defectos del producto, lesiones personales, muerte o daños materiales. Sin limitar lo anterior, el comerciante pagará cualquier suma adeudada a cualquier parte, así como todos los honorarios de abogados, relacionados con cualquier acción contra, o determinaciones en contra de, Lazo relacionadas con cualquier acción para perseguir a Lazo por Impuestos o Reclamaciones de Propiedad Abandonada o cualquier otro reclamo de conformidad con esta Sección. ',
        },
      },
      section9: {
        title: 'Confidencialidad y Derechos de Propiedad Intelectual',
        list: {
          paragraph1:
            'Los términos de este Acuerdo del Comerciante son confidenciales, y el comerciante acepta no divulgar los términos descritos aquí a ninguna otra parte (excepto a sus empleados que están sujetos a obligaciones deconfidencialidad no menos restrictivas que las contenidas aquí, en una base de necesidad de conocer). Cualquier incumplimiento de esta disposición de confidencialidad por parte del comerciante resultará en daños irreparables y continuos para Lazo, para los cuales no existirá un remedio adecuado en la ley; y en caso de dicho incumplimiento, Lazo tendrá derecho a medidas cautelares y/o cumplimiento específico, así como cualquier otro remedio adecuado (incluyendo daños monetarios si corresponde).',
          paragraph2:
            'El comerciante acepta y reconoce que Lazo es propietario de todos los derechos, títulos e intereses en el Sitio web y Aplicaciones Móviles de Lazo, las marcas comerciales de Lazo y cualquier software, tecnología o herramientas utilizadas por Lazo para promover, comercializar o vender los productos o servicios (colectivamente, la Propiedad Intelectual de Lazo "PI de Lazo"). El comerciante no alquilará, arrendará, sublicenciará, distribuirá, transferirá, copiará, reproducirá, descargará, mostrará, modificará ni compartirá la PI de Lazo ni ninguna parte de la misma, ni utilizará de otra manera dicha PI de Lazo. El comerciante no creará ninguna obra derivada basada en la PI de Lazo. E l c o m e r c i a n t e n o t r a d u c i r á , d e s c o m p i l a r á , desensamblará ni manipulará la PI de Lazo.',
        },
      },
      section10: {
        title: 'Limitación de Responsabilidad',
        paragraph:
          'Lazo no tendrá ninguna responsabilidad hacia el comerciante por pérdida de ganancias u otros dañosconsecuentes, especiales, indirectos o incidentales, basados en una reclamación de cualquier tipo o naturaleza (incluyendo, pero sin limitarse a, contrato, agravio, estatuto o de cualquier otro tipo, incluyendo negligencia, garantía o responsabilidad estricta), incluyendo (sin limitación) la pérdida de tiempo de operación y la pérdida de buena voluntad o ahorros anticipados, incluso si se le hubiese advertido de la posibilidad de dichos daños y a pesar del fracaso del propósito esencial de cualquier remedio. en ningún caso Lazo será responsable bajo cualquier teoría de responsabilidad, ya sea en una acción equitativa, legal o de derecho común derivada del presente, por contrato, responsabilidad estricta, indemnización, agravio (incluyendo negligencia), u otra, por daños que superen las cantidades retenidas por Lazo por los productos que dieron lugar a los daños en el mes anterior a la reclamación y a pesar del fracaso del propósito esencial de cualquier remedio.',
      },
      section11: {
        title: 'Misceláneo',
        list: {
          paragraph1:
            'Las partes son contratistas independientes. Nada en este Acuerdo del Comerciante deberá interpretarse como la creación de una empresa conjunta, sociedad, franquicia o relación de agencia entre las partes. Ninguna de las partes tiene la autoridad, sin la aprobación previa por escrito de la otra parte, para vincular o comprometer a laotra parte de ninguna manera, excepto que el Comerciante cumplirá con todas las obligaciones de proporcionar los productos y servicios.',
          paragraph2:
            'Este Acuerdo del Comerciante constituye el acuerdo completo entre las partes en relación con su objeto y reemplaza todos los acuerdos previos o contemporáneos, ya sean orales o escritos, relacionados con dicho objeto. Este Acuerdo del Comerciante solo podrá ser enmendado o modificado mediante un acuerdo mutuo por escrito de representantes autorizados de las partes por escrito.',
          paragraph3:
            'Cada disposición de este Acuerdo del Comerciante es una disposición que puede aplicarse por separado. Si alguna disposición de este Acuerdo del Comerciante se determina que es o se vuelve inexigible o ilegal, dicha disposición se reformará en la medida mínima necesaria para que este Acuerdo del Comerciante permanezca en vigencia de acuerdo con sus términos modificados por dicha reforma. ',
          paragraph4:
            'Ninguna falta de acción o demora en hacer valer ningún derecho o ejercer ningún recurso se considerará una renuncia a ningún derecho o recurso. Si alguna parte de este Acuerdo del Comerciante se determina que es o se vuelve inexigible o ilegal, dicha parte se reformará en la medida mínima necesaria para que este Acuerdo del Comerciante permanezca en vigencia de acuerdo con sus términos modificados por dicha reforma.',
          paragraph5:
            'El Comerciante no podrá ceder ni transferir este Acuerdo del Comerciante sin el consentimiento previo por escrito de Lazo. Cualquier intento de ceder o transferir este Acuerdo por parte del Comerciante será nulo.',
          paragraph6:
            'Excepto según se establece expresamente en este acuerdo, Lazo no hace representaciones o garantías, expresas o implícitas, incluyendo, sin limitación, cualquier garantía implícita de comerciabilidad, idoneidad para un propósito particular o no infracción. Lazo no garantiza ni garantiza que los servicios ofrecidos en el sitio web o la aplicación móvil sean ininterrumpidos o sin errores, que los productos estarán libres de errores o que se corregirán cualquier error, omisión o desplazamiento en los productos/servicios, o que los productos/servicios generarán ingresos o ganancias para el comerciante. ',
          paragraph7:
            'Este Acuerdo estará regido por las leyes del Reino de España, sin tener en cuenta las disposiciones de conflicto de leyes de ningún estado o jurisdicción. La validez y ejecución de este Contrato se regirá por la legislación española, las partes se someten a la jurisdicción exclusiva de los tribunales españoles para resolver cualquier controversia entre ellas.',
          paragraph8:
            'Todas las disputas entre las partes que surjan o estén relacionadas con este Contrato se remitirán, en primer lugar, a los respectivos responsables de cada una de las partes para su resolución. Si, habiendo sido remitido así, la disputa no se resuelve en un pLazo máximo de treinta(30) días, cualquiera de las partes podrá proceder de conformidad con lo dispuesto anteriormente.',
        },
      },
      section12: {
        title: 'Acuerdo de Licencia de Software',
        paragraph:
          'Este Contrato de Licencia de Software ("Contrato de Software") celebrado entre Lazo, Inc. ("Lazo") con domicilio en Paseo de la Dirección, 340, 5ºC, 28029 Madrid y ("Comerciante"), para otorgar una licencia del Software especificado en el presente, sujeto a los Términos y Condiciones que se indican a continuación. Al hacer clic en el botón "Acepto", en consideración a los convenios mutuos de las partes y a otras consideraciones valiosas, cuya suficiencia y recibo se reconocen por la presente, el Comerciante (i) acepta quedar sujeto a los términos y condiciones de este Contrato de Software; y (ii) ha celebrado un acuerdo vinculante con Lazo, de la siguiente manera:',
      },
      section13: {
        title: 'Licencia de Uso de Software',
        paragraph:
          'El Comerciante puede utilizar el Software de acuerdo con los términos de este Contrato de Software: El Software proporcionado en este documento será alojado por Lazo. El Comerciante puede utilizar el Software licenciado aquí únicamente en relación con (i) productos a nivel de SKU que se enviarán como regalos; (ii) tarjetas de regalo por un monto específico en efectivo; o (iii) regalos que son gratuitos para que los usuarios los envíen como regalos promocionales (solo el 100% como regalo, no se requierecompra); siempre que lo anterior haya sido agregado al Software por terceros comerciantes. ',
      },
      section14: {
        title: 'Plazo',
        paragraph:
          'El pLazo de este Contrato de Software continuará hasta la terminación por cualquiera de las partes según se establece a continuación. ',
      },
      section15: {
        title: 'Tarifa',
        paragraph:
          'La licencia para el Software otorgada de acuerdo con este Pedido es una licencia de suscripción de software como servicio sujeta a los términos y condiciones contenidos en el Contrato de Software. No hay tarifa por el uso del Software por parte del Comerciante.',
      },
      section16: {
        title: 'Mantenimiento y servicios de soporte',
        paragraph: 'No se incluyen servicios de mantenimiento ni de soporte con la suscripción.',
      },
      attachment: {
        title: 'Términos y condiciones',
        column1: {
          paragraph:
            'Estos Términos y Condiciones ("Acuerdo") complementan el Pedido y establecen los términos y condiciones bajo los cuales Lazo proporcionará acceso a cierta tecnología patentada al Cliente. El Acuerdo establece los términos y condiciones bajo los cuales el Cliente podrá Utilizar (según se define en este documento) el software de Lazo indicado en el Pedido (“Software”).',
          list1: {
            title: 'Concesión de licencia y derecho de uso',
            subtitle1: 'Concesión de Licencia.',
            subtitle2: 'Uso.',
            subtitle3: 'Restricciones adicionales. ',
            paragraph1:
              'Sujeto a todas las limitaciones y restricciones contenidas en este documento, Lazo otorga al Comerciante un derecho de uso no exclusivo y no transferible del Software tal como está alojado por Lazo ("Uso").',
            paragraph2:
              'El Comerciante no permitirá que ningún sitio web que no sea propiedad en su totalidad del Comerciante enmarque, sindique, distribuya, replique o copie ninguna parte del sitio web del Comerciante que proporcione acceso directo o indirecto al Software. ',
            paragraph3:
              'El Comerciante no permitirá que ningún sitio web que no sea propiedad en su totalidad del Comerciante enmarque, sindique, distribuya, replique o copie ninguna parte del sitio web del Comerciante que proporcione acceso directo o indirecto al Software. ',
          },
          list2: {
            title: 'Propiedad',
            subtitle1: 'Reserva de Derechos. ',
            paragraph1:
              'Al firmar el Pedido, el Comerciante reconoce irrevocablemente que, sujeto a las licencias otorgadas en este documento, el Comerciante no tiene ningún interés de propiedad en el Software o los materiales proporcionados por Lazo al Comerciante. Lazo será propietario de todos los derechos, títulos e intereses en dicho Software ymateriales de Lazo, sujeto a cualquier limitación asociada con los derechos de propiedad intelectual de terceros. Lazo se reserva todos los derechos no otorgados específicamente en este documento. ',
          },
          list3: {
            title: 'Confidencialidad',
            subtitle1: 'Definición.',
            subtitle2: 'Confidencialidad del Software.',
            subtitle3: 'Excepciones.',
            subtitle4: 'Propiedad de la Información Confidencial.',
            subtitle5: 'No Divulgación.',
            subtitle6: 'Medidas Cautelares.',
            subtitle7: 'Sugerencias/Mejoras al Software.',
            paragraph1:
              '"Información Confidencial" incluye toda la información marcada de conformidad con esta Sección y divulgada por cualquiera de las partes, antes o después de la Fecha Efectiva, y que generalmente no sea de conocimiento público, ya sea tangible o intangible y en cualquier forma o medio proporcionado, así como cualquier información generada por una de las partes que contenga, refleje o se derive de dicha información. ',
            paragraph2:
              'Toda la Información Confidencial en forma tangible deberá estar marcada como "Confidencial" o similar, o si es intangible (por ejemplo, divulgada oralmente), deberá designarse como confidencial en el momento de la divulgación y se confirmará como tal por escrito dentro de los treinta (30) días posteriores a la divulgación inicial. No obstante lo anterior, lo siguiente se considera Información Confidencial de Lazo con o sin dicha marcación o confirmación por escrito: (i) el Software y otros materiales relacionados proporcionados por Lazo; (ii) la información oral y visual relacionada conel Software; y los términos y condiciones de este Contrato de Software. ',
            paragraph3:
              'Sin otorgar ningún derecho ni licencia, las obligaciones de las partes en virtud de este documento no se aplicarán a ningún material o información que: (i) forme parte del dominio público sin ningún acto u omisión por parte de la parte receptora; (ii) sea desarrollado de manera independiente por la otra parte sin utilizar la Información Confidencial de la parte que la divulga; (iii) sea obtenido legítimamente de un tercero sin ninguna obligación de confidencialidad; o (iv) sea conocido por la parte receptora sin ninguna obligación de confidencialidad antes de obtener la Información Confidencial de la parte que la divulga. Además, ninguna de las partes será responsable de la divulgación de Información Confidencial si se realiza en respuesta a una orden válida de un tribunal o agencia autorizada del gobierno, siempre que se notifique de inmediato a la parte que la divulga para que esta última pueda solicitar una orden de protección y emprender otros esfuerzos para minimizar la divulgación requerida. Las partes cooperarán plenamente en la búsqueda de dicha orden de protección y en la realización de otros esfuerzos. ',
            paragraph4:
              'Nada en este Contrato de Software se interpretará como latransmisión de ningún título o derecho de propiedad al Software u otra Información Confidencial al Comerciante o a cualquier patente, derecho de autor, marca comercial o secreto comercial incorporado en ella, ni otorgará ningún otro derecho, título o interés de propiedad a la Información Confidencial de Lazo. Ninguna de las partes, en su totalidad o en parte, venderá, arrendará, otorgará licencia, cederá, transferirá o divulgará la Información Confidencial a terceros, ni copiará, reproducirá ni distribuirá la Información Confidencial, excepto según lo permitido expresamente en este Contrato de Software. Cada parte tomará todas las precauciones razonables, pero no menos que las precauciones utilizadas para proteger su propia Información Confidencial, para evitar el robo, la divulgación y la copia no autorizada, la reproducción o la distribución de la Información Confidencial. ',
            paragraph5:
              'Cada parte acuerda en todo momento mantener estrictamente confidencial toda la Información Confidencial perteneciente a la otra parte. Cada parte acuerda restringir el acceso a la Información Confidencial de la otra parte solo a aquellos empleados o subcontratistas que (i) requieran acceso en el curso de sus deberes y responsabilidades asignados; y (ii) hayan acordado por escrito estar sujetos a disposiciones no menos restrictivas que las establecidas en esta Sección. ',
            paragraph6:
              'Cada parte reconoce que cualquier divulgación o uso no autorizado de la Información Confidencial causaría a la otra parte un daño inminente e irreparable y que dicha parte tendrá derecho, además de cualquier otro remedio disponible en la ley o en equidad, a medidas cautelares temporales, preliminares y permanentes en caso de que la otra parte no cumpla con sus obligaciones en virtud de esta Sección. ',
            paragraph7:
              'No obstante esta Sección, a menos que se acuerde expresamente por escrito, todas las sugerencias, soluciones, mejoras, correcciones y otras contribuciones proporcionadas por el Comerciante con respecto al Software u otros materiales de Lazo proporcionados al Comerciante serán propiedad de Lazo, y el Comerciante por la presente acuerda ceder cualquier derecho de este tipo a Lazo. Nada en este Contrato de Software prevenir que Lazo use de cualquier manera o para cualquier propósito que considere necesario, el knowhow, técnicas o procedimientos adquiridos o utilizados por Lazo en la prestación de servicios en virtud de este contrato.',
          },
          list4: {
            title: 'Garantías',
            subtitle1: 'Representante Autorizado.',
            subtitle2: 'Descargo de garantías.',
            subtitle3: 'Sin modificaciones.',
            paragraph1:
              'El Comerciante y Lazo garantizan que cada uno tiene el derecho de celebrareste Contrato de Software y que el Contrato de Software ha sido acordado por un representante autorizado de cada entidad. ',
            paragraph2:
              'El cliente reconoce y acepta que no se está basando en ningún estado o garantía que no se proporcione expresamente en este contrato con respecto al software o el mantenimiento o otros servicios proporcionados en virtud del mismo. salvo que se indique lo contrario en este acuerdo, el software se proporciona "tal cual" y Lazo no ofrece garantías, expresas o implícitas, incluidas garantías expresas o implícitas de comerciabilidad, idoneidad para un propósito particular y no infracción. ',
            paragraph3:
              'El cliente reconoce y acepta que no se está basando en ningún estado o garantía que no se proporcione expresamente en este contrato con respecto al software o el mantenimiento o otros servicios proporcionados en virtud del mismo. salvo que se indique lo contrario en este acuerdo, el software se proporciona "tal cual" y Lazo no ofrece garantías, expresas o implícitas, incluidas garantías expresas o implícitas de comerciabilidad, idoneidad para un propósito particular y no infracción. ',
          },
          list5: {
            title: 'LIMITACIÓN DE RESPONSABILIDAD',
            subtitle1: 'Límite de responsabilidad.',
            subtitle2: 'Descargo de daños.',
            paragraph1:
              'En ningún caso será Lazo responsable bajo cualquier teoría de responsabilidad, ya sea en una acción equitativa, legal o de commonlaw derivada del contrato, responsabilidad estricta, indemnización, agravio (incluida la negligencia) o de otra manera, por daños que, en conjunto, excedan la cantidad de las tarifas pagadas por el cliente por el software que dio lugar a tales daños en los seis (6) meses anteriores a la reclamación, y a pesar de la falta de éxito de cualquier remedio. ',
            paragraph2:
              'En ningún caso será Lazo responsable por daños especiales, incidentales, indirectos, ejemplares, punitivos o consecuentes de cualquier tipo y de cualquier manera causados, incluyendo, pero no limitado a, interrupción de negocios o pérdida de beneficios, oportunidades de negocios o buena voluntad, incluso si se ha notificado de la posibilidad de tales daños, y a pesar de la falta de éxito de cualquier remedio. ',
          },
        },
        column2: {
          list1: {
            title: 'Término y terminación',
            subtitle1: 'Terminación por Lazo. ',
            subtitle2: 'Terminación por el Comerciante.',
            subtitle3: 'Terminación. ',
            paragraph1:
              'Este Contrato de Software y cualquier licencia creada en virtud del mismo pueden ser terminados por Lazo (i) si el Comerciante no realiza los pagos que se deben en virtud del mismo en un pLazo de quince (15) días a partir de la fecha de vencimiento; (ii) con treinta (30) días de aviso por escrito al Comerciante si el Comerciante no cumple con cualquier otra obligación material requerida de él en virtud del mismo, y tal incumplimiento no se corrige dentro de dicho período de treinta (30) días; o(iii) si el Comerciante presenta una solicitud de quiebra o insolvencia, se presenta una solicitud involuntaria en su contra, inicia una acción que prevé la liberación en virtud de las leyes de quiebra, presenta una solicitud para el nombramiento de un receptor o se declara un deudor en quiebra. ',
            paragraph2:
              'Este Contrato de Software puede ser terminado por el Comerciante con treinta (30) días de aviso por escrito a Lazo si Lazo no cumple con cualquier obligación material requerida de él en virtud del mismo, y tal incumplimiento no se corrige dentro de treinta (30) días a partir de la recepción por parte de Lazo del aviso del Comerciante o un período más largo si Lazo está trabajando diligentemente para corregirlo.',
            paragraph3:
              'Tras la terminación de este Contrato de Software, el Comerciante ya no tendrá acceso al Software y no eludirá ningún mecanismo de seguridad contenido en el mismo. La terminación de este Contrato de Software no afectará de ninguna manera el uso de los Productos (según se define en el Contrato de Comerciante) por parte de los compradores ni la obligación del Comerciante con respecto a los mismos, es decir, el Comerciante debe honrar los regalos y tarjetas de regalo vendidos antes de la terminación o reembolsar por completo a los compradores por todos los regalos y/o tarjetas de regalo pendientes.',
          },
          list2: {
            title: 'Misceláneo',
            subtitle1: 'Cumplimiento de las leyes. ',
            subtitle2: 'Asignación. ',
            subtitle3: 'Supervivencia. ',
            subtitle4: 'Notificaciones. ',
            subtitle5: 'Fuerza Mayor ',
            subtitle6: 'Derechos Restringidos. ',
            subtitle7: 'Idioma del Contrato.  ',
            subtitle8: 'Enmiendas. ',
            subtitle9: 'Vigencia. ',
            subtitle10: 'Contrato completo.  ',
            subtitle11: 'Firma electrónica.  ',
            paragraph1:
              'El Comerciante acuerda cumplir con todas las leyes, regulaciones y ordenanzas aplicables relacionadas con su desempeño en virtud de este Contrato de Software. Las partes acuerdan que este Contrato de Software no estará sujeto a la Convención de las Naciones Unidas sobre Contratos de Compraventa Internacional de Mercaderías ni a la UCITA, cuya aplicación se excluye expresamente.',
            paragraph2:
              'El Comerciante no podrá ceder este Contrato de Software ni transferir de otra manera ninguna licencia creada en virtud del mismo, ya sea por ley, cambio de control o de cualquier otra manera, sin el consentimiento previo por escrito de Lazo. Cualquier asignación o transferencia en violación de esta Sección será nula y sin efecto. ',
            paragraph3:
              'Las disposiciones establecidas en las Secciones 2, 5, 6.2, 7, 8.4 y 9 de este Contrato de Software sobrevivirán a la terminación o expiración de este Acuerdo y cualquier licencia aplicable en virtud del mismo. ',
            paragraph4:
              'Cualquier notificación requerida en virtud de este Contrato de Software se realizará por escrito y se considerará efectiva una vez entregada a la parte a la que se dirige. Todas las notificaciones se enviarán a la dirección de correo electrónico aplicableutilizada por el Comerciante cuando se registró para usar el Software, al Comerciante a través de mensajes en el propio Software, o a cualquier otra dirección que las partes puedan designar por escrito. A menos que se especifique lo contrario, todas las notificaciones a Lazo se enviarán a la dirección lazo@Lazo-app.com. Cualquier notificación de incumplimiento sustancial deberá definir claramente el incumplimiento, incluida la obligación contractual específica que se ha incumplido. ',
            paragraph5:
              'Lazo no será responsable ante el Comerciante por ningún retraso o incumplimiento de las obligaciones de Lazo en virtud del presente documento si dicho retraso o incumplimiento se debe a cualquier causa o causas más allá del control razonable de Lazo. Dichas causas incluirán, entre otras, actos de Dios, inundaciones, incendios, pérdida de electricidad u otros servicios públicos, o retrasos por parte del Comerciante en la provisión de los recursos o el apoyo requeridos o en el cumplimiento de cualquier otro requisito en virtud del presente documento.',
            paragraph6:
              'El uso del Software por parte del Gobierno de los Estados Unidos está condicionado a que el Gobierno acepte que el Software está sujeto a Derechos Restringidos según lo establecido en las disposiciones de FAR 52.227-19. El Comerciante será responsable deasegurarse de que esta disposición se incluya en todos los acuerdos de sublicencia o sublicencia y acuerdos de usuario final con el Comerciante. ',
            paragraph7:
              'El Comerciante acuerda que la versión en inglés de este Contrato de Software prevalecerá en caso de conflicto entre las versiones en inglés y cualquier traducción.',
            paragraph8:
              'Este Contrato de Software no puede ser modificado, modificado ni cambiado a menos que se haga por escrito y se firme por un representante autorizado de cada parte.',
            paragraph9:
              'Este Contrato de Software entrará en vigencia en la Fecha Efectiva y continuará hasta que se rescinda de conformidad con sus términos. ',
            paragraph10:
              'Este Contrato de Software contiene el acuerdo completo de las partes con respecto al Software y reemplaza todos los acuerdos previos y contemporáneos, escritos y orales, con respecto al Software. Cualquier cambio o enmienda a este Contrato de Software solo será efectivo si se realiza por escrito y está firmado por ambas partes.',
            paragraph11:
              'Al hacer clic en el botón "Aceptar" o de manera similar, el Comerciante manifiesta su intención de celebrar un contrato vinculante con Lazo y acepta todos los términos y condiciones establecidos en este Contrato de Software. Laaceptación electrónica de este Contrato de Software tendrá la misma fuerza y efecto que una firma manuscrita.',
          },
        },
      },
    },
  },
});
