import * as actionTypes from './types/profileDialog';

export const openDialogProfile = () => {
  return {
    type: actionTypes.PROFILE_DIALOG_OPEN,
  };
};

export const closeDialogProfile = () => {
  return {
    type: actionTypes.PROFILE_DIALOG_CLOSE,
  };
};
