import * as actionTypes from './types/redemptions';

export const redeemInfo = (code, formik) => {
  return {
    type: actionTypes.REDEMPTIONS_INFO,
    payload: {
      code,
      formik,
    },
  };
};

export const redeemSubmit = (responseData, formik) => {
  return {
    type: actionTypes.REDEMPTIONS_SUBMIT,
    payload: {
      responseData,
      formik,
    },
  };
};

export const redeemOther = () => {
  return {
    type: actionTypes.REDEMPTIONS_REDEEM_OTHER,
  };
};

export const getRedemptionHistory = params => {
  return {
    type: actionTypes.REDEMPTIONS_GET_HISTORY,
    payload: params,
  };
};

export const refundRedemption = params => {
  return {
    type: actionTypes.REDEMPTIONS_REFUND,
    payload: params,
  };
};

export const setRedemptionInitState = () => {
  return {
    type: actionTypes.REDEMPTIONS_SET_INIT_STATE,
  };
};

export const generateRedemptionCode = ({ type, id }) => {
  return {
    type: actionTypes.GENERATE_REDEMPTION_CODE,
    payload: {
      type,
      id,
    },
  };
};

export const redemptionDownloadQrCode = params => {
  return {
    type: actionTypes.REDEMPTION_DOWNLOAD_QR_CODE,
    payload: params,
  };
};

export const redemptionGetSecretSymbols = () => {
  return {
    type: actionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS,
  };
};

export const redemptionSendSecretSymbol = params => {
  return {
    type: actionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL,
    payload: params,
  };
};

export const redemptionDownloadCoster = params => {
  return {
    type: actionTypes.REDEMPTION_DOWNLOAD_COSTER,
    payload: params,
  };
};

export const getRedemptions = params => {
  return {
    type: actionTypes.GET_REDEMPTIONS,
    payload: params,
  };
};

export const getRedemptionsCodeInfo = params => {
  return {
    type: actionTypes.REDEMPTIONS_CODE_INFO,
    payload: params,
  };
};
