import * as Yup from 'yup';

import * as validationConst from '@/constants/validation';
import * as giftConst from '@/constants/gift';
import { days } from '@/constants/regexp';

export default t => {
  return {
    quantity_type: Yup.string().required(t('validation.quantityRequired')),
    quantity: Yup.number()
      .min(validationConst.MIN_GIFT_SPECIFIC_QUANTITY, t('validation.specificQuantityLength'))
      .when('quantity_type', {
        is: quantity_type => quantity_type === giftConst.QUANTITY_TYPE_SPECIFIC,
        then: Yup.number().required(t('validation.specificQuantityRequired')),
        otherwise: Yup.number(),
      }),
    expiration_value: Yup.string().when('expiration_type', {
      is: val => val === giftConst.EXPIRATION_DAY,
      then: Yup.string()
        .required(t('validation.expirationDayRequired'))
        .matches(days, t('validation.expirationDays')),
      otherwise: Yup.string(),
    }),
  };
};
