import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import { ROUTE_SURVEYS } from '@/constants/routes';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import history from '@/utility/common/history';
import * as actionTypes from '@/store/actions/types/surveys';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { t } from '@/locale/i18n';

export function* getSurveysListSaga(action) {
  yield put({ type: actionTypes.GET_SURVEYS_LIST_START });

  const { search, ...params } = action.payload;
  const url = search ? endpoints.GET_SURVEYS_SEARCH : endpoints.GET_SURVEYS;
  const updatedParam = search ? { search } : {};

  try {
    const res = yield axios.get(url, { params: { ...updatedParam, ...params } });
    const { data } = res;

    yield put({
      type: actionTypes.GET_SURVEYS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_SURVEYS_LIST_FAIL,
    });
  }
}

export function* getSurveyDetailSaga(action) {
  yield put({ type: actionTypes.GET_DETAIL_SURVEY_START });

  const url = getLink(endpoints.SURVEY_DETAIL, { surveyId: action.payload });

  try {
    const res = yield axios.get(url);
    const { data } = res;

    yield put({
      type: actionTypes.GET_DETAIL_SURVEY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_DETAIL_SURVEY_FAIL,
    });
  }
}

export function* changeStatusSurveySaga(action) {
  yield put({ type: actionTypes.CHANGE_STATUS_SURVEY_START });

  const { surveyId, status } = action.payload;
  const url = getLink(endpoints.CHANGE_STATUS, { surveyId });

  try {
    const { data } = yield axios.put(url, { status });

    yield put({
      type: actionTypes.CHANGE_STATUS_SURVEY_SUCCESS,
      payload: data,
    });

    history.push(ROUTE_SURVEYS);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CHANGE_STATUS_SURVEY_FAIL,
    });
  }
}

export function* createSurveySaga(action) {
  yield put({ type: actionTypes.CREATE_SURVEY_START });

  const url = getLink(endpoints.CREATE_SURVEY);

  try {
    yield axios.post(url, action.payload);

    yield put({
      type: actionTypes.CREATE_SURVEY_SUCCESS,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.createSurvey'),
        options: {
          variant: 'success',
        },
      },
    });

    history.push(ROUTE_SURVEYS);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CREATE_SURVEY_FAIL,
    });
  }
}

export function* editSurveySaga(action) {
  yield put({ type: actionTypes.EDIT_SURVEY_START });

  const { surveyId, updateData } = action.payload;
  const url = getLink(endpoints.EDIT_SURVEY, { surveyId });

  try {
    yield axios.put(url, updateData);

    yield put({
      type: actionTypes.EDIT_SURVEY_SUCCESS,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.editSurvey'),
        options: {
          variant: 'success',
        },
      },
    });

    history.push(ROUTE_SURVEYS);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.EDIT_SURVEY_FAIL,
    });
  }
}
