import * as Yup from 'yup';

export default t => {
  return {
    name: Yup.object().test('name', t('validation.categoryNameRequired'), name => {
      return !!name.en || !!name.es;
    }),
    country: Yup.object().required(t('validation.countryRequired')),
    selectCategories: Yup.array()
      .required(t('validation.categoryRequired'))
      .test('selectCategories', t('validation.categoryNameRequired'), categories => {
        let isValid = true;
        categories.forEach(cat => {
          if (!cat.image?.length) {
            isValid = false;
          }
        });
        return isValid;
      }),
  };
};
