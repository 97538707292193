import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import * as actionTypes from '@/store/actions/types/square';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* getSquareUrlSaga(action) {
  const { organization_id, url } = action.payload;

  yield put({ type: actionTypes.SQUARE_START });

  try {
    const { data } = yield axios.get(endpoints.GET_SQUARE_URL, {
      params: { callback: encodeURIComponent(url), organization_id },
    });

    yield put({
      type: actionTypes.SQUARE_URL_CONNECT_SUCCESS,
      payload: { url: data.url, error: data.error },
    });

    if (data.url) {
      window.location.href = data.url;
    }
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.SQUARE_STOP,
    });
  }
}

export default getSquareUrlSaga;
