export const GET_MY_ORGANIZATIONS_LIST = 'GET_MY_ORGANIZATIONS_LIST';
export const GET_MY_ORGANIZATIONS_LIST_START = 'GET_MY_ORGANIZATIONS_LIST_START';
export const GET_MY_ORGANIZATIONS_LIST_SUCCESS = 'GET_MY_ORGANIZATIONS_LIST_SUCCESS';
export const GET_MY_ORGANIZATIONS_LIST_FAIL = 'GET_MY_ORGANIZATIONS_LIST_FAIL';

export const DELETE_ORGANIZATION = 'DELETE_ORGANIZATION';
export const DELETE_ORGANIZATION_START = 'DELETE_ORGANIZATION_START';
export const DELETE_ORGANIZATION_SUCCESS = 'DELETE_ORGANIZATION_SUCCESS';
export const DELETE_ORGANIZATION_FAIL = 'DELETE_ORGANIZATION_FAIL';

export const CHOOSE_MY_ORGANIZATION = 'CHOOSE_MY_ORGANIZATION';
export const CHOOSE_MY_ORGANIZATION_START = 'CHOOSE_MY_ORGANIZATION_START';
export const CHOOSE_MY_ORGANIZATION_SUCCESS = 'CHOOSE_MY_ORGANIZATION_SUCCESS';
export const CHOOSE_MY_ORGANIZATION_FAIL = 'CHOOSE_MY_ORGANIZATION_FAIL';

export const CREATE_NEW_ORGANIZATION = 'CREATE_NEW_ORGANIZATION';
export const CREATE_NEW_ORGANIZATION_START = 'CREATE_NEW_ORGANIZATION_START';
export const CREATE_NEW_ORGANIZATION_SUCCESS = 'CREATE_NEW_ORGANIZATION_SUCCESS';
export const CREATE_NEW_ORGANIZATION_FAIL = 'CREATE_NEW_ORGANIZATION_FAIL';
