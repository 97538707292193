import * as actionTypes from './types/giftsList';

export const getGiftsList = params => {
  return {
    type: actionTypes.GET_GIFTS_LIST,
    payload: params,
  };
};

export default getGiftsList;
