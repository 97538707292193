import * as Yup from 'yup';

import * as validationConst from '@/constants/validation';

export default t => {
  return {
    address: Yup.string().required(t('validation.locationRequired')),
    name: Yup.string()
      .required(t('validation.nameRequired'))
      .min(validationConst.MIN_NAME_LENGTH, t('validation.nameLength')),
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid')),
  };
};
