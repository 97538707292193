import * as Yup from 'yup';

export default t => {
  return {
    unverified_purchase_limit_ngc: Yup.number().required(t('validation.limitsForNGCGifts')),
    purchase_limitMX: Yup.number().required(t('validation.limitsForUser')),
    purchase_limitUS: Yup.number().required(t('validation.limitsForUser')),
    purchase_limitES: Yup.number().required(t('validation.limitsForUser')),
    purchase_card_limitMX: Yup.number().required(t('validation.limitsForCard')),
    purchase_card_limitUS: Yup.number().required(t('validation.limitsForCard')),
    purchase_card_limitES: Yup.number().required(t('validation.limitsForCard')),
    support_emailMX: Yup.string().required(t('validation.emailRequired')),
    support_emailUS: Yup.string().required(t('validation.emailRequired')),
    test_email: Yup.string().required(t('validation.emailRequired')),
    ngc_gift_delay_time: Yup.number().required(t('validation.delayForNGCGiftCard')),
  };
};
