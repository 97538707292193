import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { SnackbarProvider as SnackbarProviderBase } from 'notistack';
import colors, { snackbarBg, grandis, mintGreen } from '@/constants/colors';

const styles = createStyles({
  root: {
    minHeight: '48px',
  },
  variantSuccess: {
    backgroundColor: snackbarBg,
    '& svg': {
      color: mintGreen,
    },
  },
  variantError: {
    backgroundColor: snackbarBg,
    '& svg': {
      color: colors.inputBorderError,
    },
  },
  variantWarning: {
    backgroundColor: snackbarBg,
    '& svg': {
      color: grandis,
    },
  },
  variantInfo: { backgroundColor: snackbarBg },
});

const snackbarProvider = props => {
  return <SnackbarProviderBase {...props} />;
};

export default React.memo(withStyles(styles)(snackbarProvider));
