import * as actionTypes from '@/store/actions/types/admins';

const initialState = {
  permissions: null,
  countries: [],
  list: [],
  data: null,
  current_page: 0,
  per_page: 10,
  total: 0,
  loading: {
    status: false,
    permissions: false,
    countries: false,
    list: false,
    data: false,
  },
  errors: {
    status: null,
    permissions: null,
    countries: null,
    list: null,
    data: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADMINS_GET_PERMISSIONS_START:
      return {
        ...state,
        loading: { ...state.loading, permissions: true },
        errors: null,
      };
    case actionTypes.ADMINS_GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, permissions: false },
        permissions: action.payload.permissions,
        errors: null,
      };
    case actionTypes.ADMINS_GET_PERMISSIONS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, permissions: false },
        errors: { ...state.errors, permissions: action.payload },
      };

    case actionTypes.ADMINS_GET_LIST_START:
      return {
        ...state,
        loading: { ...state.loading, list: true },
        error: null,
      };
    case actionTypes.ADMINS_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, list: false },
        list: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
        errors: null,
      };
    case actionTypes.ADMINS_GET_LIST_FAIL:
      return {
        ...state,
        loading: { ...state.loading, list: false },
        errors: { ...state.errors, permissions: action.payload },
      };

    case actionTypes.ADMINS_CREATE_EDIT_START:
      return {
        ...state,
        loading: { ...state.loading, data: true },
        errors: null,
      };
    case actionTypes.ADMINS_CREATE_EDIT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        data: action.payload.data,
        errors: null,
      };
    case actionTypes.ADMINS_CREATE_EDIT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        errors: { ...state.errors, data: action.payload },
      };

    case actionTypes.ADMINS_GET_PROFILE_START:
      return {
        ...state,
        loading: { ...state.loading, data: true },
        errors: null,
      };
    case actionTypes.ADMINS_GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        data: action.payload.data,
        errors: null,
      };
    case actionTypes.ADMINS_GET_PROFILE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        errors: { ...state.errors, data: action.payload },
      };

    case actionTypes.ADMINS_DELETE_PROFILE_START:
      return {
        ...state,
        loading: { ...state.loading, data: true },
        errors: null,
      };
    case actionTypes.ADMINS_DELETE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        errors: null,
      };
    case actionTypes.ADMINS_DELETE_PROFILE_FAIL:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        errors: { ...state.errors, data: action.payload },
      };

    case actionTypes.ADMINS_CHANGE_STATUS_START:
      return {
        ...state,
        loading: { ...state.loading, status: true },
        errors: null,
      };
    case actionTypes.ADMINS_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, status: false },
        errors: null,
      };
    case actionTypes.ADMINS_CHANGE_STATUS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, status: false },
        errors: { ...state.errors, data: action.payload },
      };

    case actionTypes.ADMINS_GET_COUNTRIES_START:
      return {
        ...state,
        loading: { ...state.loading, countries: true },
        errors: null,
      };
    case actionTypes.ADMINS_GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, countries: false },
        countries: action.payload.countries?.filter(item => item.canView) || [],
        errors: null,
      };
    case actionTypes.ADMINS_GET_COUNTRIES_FAIL:
      return {
        ...state,
        loading: { ...state.loading, countries: false },
        errors: { ...state.errors, countries: action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
