import PropTypes from 'prop-types';
import React from 'react';

const ValueContainer = ({ selectProps, children }) => {
  return <div className={selectProps.classes.valueContainer}>{children}</div>;
};

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

export default ValueContainer;
