import * as Yup from 'yup';

export default t => {
  return {
    name: Yup.object().shape({
      en: Yup.string().required('Category name is required!'),
      es: Yup.string().required('¡El nombre de categoría es obligatorio!'),
    }),
    logo_image_id: Yup.array().required(t('validation.categoryIconRequired')),
    selectCategories: Yup.array()
      .of(Yup.object().shape({ value: Yup.string().required(), label: Yup.string().required() }))
      .min(1)
      .required(),
  };
};
