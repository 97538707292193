// -- Endpoints --

// Privacy and Terms
export const GET_PRIVACY = 'organizations/:organizationId/privacy-policy';
export const GET_TERMS = 'organizations/:organizationId/terms-of-use';

// Authentication
export const SUPPORT_CHECK_USERNAME = 'auth/username/verify';
export const VERIFY_USER = 'auth/user/verify';
export const REGISTER_BUSINESS = 'auth/register/business';
export const BUSINESS_VERIFY = 'auth/register/business/verify';
export const USER_CHECK_EMAIL = 'auth/register/business/checkemail';
export const PROFILE_CHECK_EMAIL = 'users/merchandisers/check-email';
export const BUSINESS_CHECK_EMAIL = 'organizations/checkemail';
export const RESEND_EMAIL = 'auth/register/business/resendEmail';
export const LOGIN = 'auth/login';
export const LOGOUT = 'auth/logout';
export const GET_AUTH_USER = 'auth/me';
export const PASS_RESET = 'auth/password/email';
export const PASS_RECOVERY = 'auth/password/reset';
export const PASS_CREATE = 'auth/password/create';
export const FIRST_LOGIN = 'auth/change/first-login';
export const BUSINESS_CHECK_NAME = 'auth/register/business/check';
export const ADMIN_VERIFY = '/admin/confirm';

// Support
export const SUPPORT_SEND = 'support';

// Accounts
export const ACCOUNTS_MAIN_INFO = '/accounts/:id';
export const ACCOUNTS_SECURITY = '/accounts/:id/change-password';
export const ACCOUNTS_GET_BILLING = '/accounts/:id/billing';
export const ACCOUNTS_CHANGE_BILLING = '/accounts/:id/billing/change-card';
export const ACCOUNTS_EMAIL_NOTIFICATIONS = '/accounts/:id/notifications';
export const LOGIN_AS_ORGANIZATION = 'admin/organization/login/:id';
export const LOGIN_AS_ADMIN = 'admin/login/:id';
export const STRIPE_CHANGE = 'stripe/change';
export const SQUARE_CHANGE = 'square/change';

// Gifts
export const GIFTS_CARDS = 'organizations/:id/gifts/cards';
export const GIFTS_CHART = 'organizations/:id/gifts/chart';
export const GIFTS_LIST = 'organizations/:id/gifts';

// Admin gifts
export const GIFTS = 'gifts';
export const CHANGE_STATUS_GIFT = 'gifts/:giftId/change-status';

// Gift
export const CREATE_GIFT = 'gifts';
export const GET_GIFT = 'gifts/:id';
export const UPDATE_GIFT = 'gifts/:id';
export const DELETE_GIFT = 'gifts/:id';
export const GIFT_CHANGE_QUANTITY = 'gifts/:id/changequantity';
export const GIFT_DUPLICATE = 'gifts/:id/clone';
export const GET_GIFT_CHART = 'gifts/:id/chart';
export const GET_GIFT_CARDS = 'gifts/:id/cards';

// Send direct gift
export const DIRECT_GIFT_SEND = 'organizations/:id/customers/:customerId/send-gift';

// Customers
export const GET_CUSTOMERS_LIST = 'organizations/:id/customers';
export const GET_CUSTOMERS_SEARCH = 'organizations/:id/customers/search/:search';
export const GET_CUSTOMERS_DETAIL = 'organizations/:id/customers/:customer_id';

// Users
export const GET_USERS_LIST = 'users/mobile';
export const GET_USERS_SEARCH = 'users/mobile/search/:search';
export const GET_USERS_DETAIL = 'users/mobile/:userId';
export const BLOCK_USER = 'users/mobile/:userId/block';
export const UNBLOCK_USER = 'users/mobile/:userId/unblock';
export const INVITED_USERS = 'users/mobile/:userId/invited_users';
export const USER_DOWNLOAD_QR_CODE = 'users/:userId/qr-code';
export const USER_GENERATE_QR_CODE = 'users/:userId/invite-link';
export const CHECK_NOTIFICATIONS = 'users/:id/quantity-notifications';
export const ONLINE_USER_COUNT = 'auth/online-users-count';
export const SENT_MULTIPLE_NOTIFICATIONS = 'admin/send-multiple-notifications';
export const CHANGE_VIRTUAL_STATUS = 'virtual/bonus/active';

// CSV
export const CSV_IMPORT_LOCATIONS = 'organizations/:id/import-locations';
export const CSV_SAVE_LOCATIONS = 'organizations/:id/save-locations';
export const CSV_IMPORT_EMPLOYEES = 'organizations/:id/locations/:locationId/import';
export const CSV_SAVE_EMPLOYEES = 'organizations/:id/locations/:locationId/employees/save-import';
export const CSV_EXPORT_STATISTICS = 'organizations/:id/balance/statistics';

// Transactions
export const GET_TRANSACTION_CUSTOMER_LIST =
  'organizations/:id/customers/:customer_id/transactions';
export const GET_TRANSACTION_USER_LIST = 'users/mobile/:userId/transactions';
export const GET_TRANSACTION_BALANCE_LIST = 'organizations/:id/balance/transactions';
export const GET_TRANSACTION_GIFT_LIST = 'gifts/:gift_id/transactions';
export const GET_TRANSACTION_LAST_TRANSACTIONS = '/organizations/:id/balance/last-transactions';
export const GET_TRANSACTION_LIST = 'organizations/balance/transactions';
export const GET_TRANSACTION_LIST_SEARCH = 'organizations/balance/transactions/search/:search';
export const GET_TRANSACTION_PENDING_LIST =
  'organizations/balance/transactions/pending?type=:type&search=:search';
export const TRANSACTION_DETAIL = 'transactions/:transactionId';
export const TRANSACTION_BLOCK = 'code/:transactionId/codes-transaction-blocked';
export const TRANSACTION_UN_BLOCK = 'code/:transactionId/codes-transaction-unblocked';

// Balance
export const GET_ORGANIZATIONS_BALANCE_CHART = 'organizations/:id/balance/chart';
export const GET_ORGANIZATIONS_BALANCE_CARDS = 'organizations/:id/balance/cards';

// Stripe
export const SET_STRIPE_TOKEN = 'stripe';
export const SET_STRIPE_CONNECT = 'stripe/connect';

// Locations
export const ADD_LOCATIONS = 'organizations/:id/save-locations';
export const ADD_EMPLOYEES_TO_LOCATION = 'organizations/:id/locations/:locationId/employees';
export const GET_ORGANIZATION_LOCATIONS = 'organizations/:id/locations';
export const DELETE_ORGANIZATION_LOCATION = 'organizations/:id/locations/:locationId';
export const EDIT_LOCATIONS = 'organizations/:id/locations/:locationId';

// Inbox
export const GET_INBOX_LIST = 'users/:id/inbox/:filter';

// Disputes
export const GET_DISPUTES_LIST = 'disputes';

// Categories
export const GET_CATEGORIES = 'categories/search';

// Images
export const UPLOAD_IMAGES = 'images/upload';

// Organizations
export const ORGANIZATION_INFO = 'organizations/:id';

// Employees
export const GET_EMPLOYEES_LIST = 'organizations/:id/locations/:locationId/employees';
export const EDIT_EMPLOYEES = 'organizations/:id/locations/:locationId/employee/:user_id';
export const RESEND_EMPLOYEES = 'organizations/:id/locations/:locationId/employees/:user_id/resent';
export const DELETE_EMPLOYEES = 'organizations/:id/locations/:locationId/employee/:user_id';

// Redemptions
export const REDEMPTIONS = 'redemptions';
export const ORGANIZATION_REDEMPTIONS = 'organizations/:organizationId/redemptions';
export const REDEEM_INFO = 'code/info';
export const REDEEM_SUBMIT = 'code/redeem';
export const REDEEM_HISTORY = 'code/transaction-history';
export const GENERATE_REDEMPTION_CODE = 'organizations/codes';
export const REDEEM_REFUND = 'transactions/:transaction_id/transaction-history/:id/refund';
export const REDEMPTION_DOWNLOAD_QR_CODE = 'organizations/:id/codes/download';
export const REDEMPTION_DOWNLOAD_COSTER = 'organizations/:id/codes/download-coster';
export const REDEMPTION_DOWNLOAD_CODES = 'organizations/:id/employees/codes/download';
export const REDEMPTION_GET_SECRET_SYMBOLS = 'organizations/secret-symbols';
export const REDEMPTION_SEND_SECRET_SYMBOL = 'organizations/:organizationId/secret-symbols';
export const CODE_REDEMPTIONS = 'codes/:id/redemptions';

// Gifts admin
export const GIFTS_CHART_ADMIN = 'gifts/chart';
export const GIFTS_CARDS_ADMIN = 'gifts/cards';

// Organizations admin
export const GET_ORGANIZATIONS_SEARCH = 'organizations/search/:search';
export const GET_ORGANIZATIONS_LIST = 'organizations';
export const ORGANIZATIONS_CHECK_EMAIL = 'organizations/checkemail';

// Archive business
export const ARCHIVE_BUSINESS = 'organizations/:organizationId/archive';
export const UNARCHIVE_BUSINESS = 'organizations/:organizationId/unarchive';

// Create organization
export const CREATE_ORGANIZATIONS = 'organizations';
export const GET_TEMPLATES_LIST = 'docusign/get-list-templates';

// Upload business logo
export const UPLOAD_BUSINESS_LOGO = 'organizations/:organizationId/onboarding-logo';

// Send contract
export const SEND_CONTRACT = 'organizations/send-contract';

// Send privacy policy or terms of use
export const SEND_PRIVACY_OR_TERMS = 'organizations/:organizationId/:type';

// Resend contract
export const RESEND = 'organizations/:organizationId/resend-contract';

// Categories admin
export const GET_CATEGORIES_LIST = 'categories';
export const GET_CATEGORIES_SEARCH = 'categories/search/:search';
export const GET_CATEGORY = 'categories/:categoryId';
export const DELETE_CATEGORY = 'categories/:categoryId';
export const GET_SUBCATEGORIES_LIST = 'categories/:categoryId/subcategories';
export const GET_SUBCATEGORIES_SEARCH = 'categories/:categoryId/subcategories/search/:search';
export const CREATE_CATEGORY = 'categories';
export const EDIT_CATEGORY = 'categories/:categoryId';
export const GET_PROMO_GROUPS = 'category-groups';
export const CREATE_PROMO_GROUPS = 'category-groups';
export const GET_PROMO_GROUP_DETAILS = 'category-groups/:promoGroupId';
export const UPDATE_PROMO_GROUP_DETAILS = 'category-groups/:promoGroupId';
export const DELETE_PROMO_GROUP = 'category-groups/:promoGroupId';

// Surveys admin
export const GET_SURVEYS = 'surveys';
export const GET_SURVEYS_SEARCH = 'surveys';
export const SURVEY_DETAIL = 'surveys/:surveyId';
export const CHANGE_STATUS = 'surveys/:surveyId/change-status';
export const CREATE_SURVEY = 'surveys';
export const EDIT_SURVEY = 'surveys/:surveyId';

// Plans
export const GET_PLANS = 'plans';
export const CHANGE_PLAN = 'plans/:planName';

// Language
export const SET_LANGUAGE = 'set-language/:userId';
export const GET_EMAIL_LANGUAGE = 'organizations/:organizationId/email-language';

// Admins
export const GET_ADMIN_PERMISSIONS = 'admin/permissions';
export const CREATE_ADMIN = 'admin';
export const EDIT_ADMIN = 'admin/:adminId';
export const GET_ADMINS = 'admin';
export const GET_ADMIN = 'admin/:adminId';
export const DELETE_ADMIN = 'admin/:adminId';
export const CHANGE_STATUS_ADMIN = 'admin/:adminId/block';
export const GET_COUNTRIES = 'countries'; // endpoint used only for admins (businesses page)

// Square
export const GET_SQUARE_URL = '/square/url';

// Work hours
export const GET_WORK_HOURS = 'organizations/:organizationId/locations/:locationId/working-hours';

// Sharing
export const SHARING_DOWNLOAD = 'sharing/download';
export const GET_SHARING_INFO = 'sharing';
export const GET_SHARING_INFO_BY_ID = 'organization/sharing/:organizationId';

// FAQ
export const FAQ_DOWNLOAD = 'faq/download';

// Tags
export const GET_TAGS = 'tags';

// Analytics
export const GET_ANALYTIC_COUNT = 'analytic/events';
export const GET_ANALYTIC_DATA = 'analytic/events-data';
export const GET_ANALYTIC_MOST_VIEWED = 'analytic/most-viewed';
export const GET_ANALYTIC_AVERAGE = 'analytic/events-average';
export const GET_ANALYTIC_ACTIVE_USERS = 'analytic/active-users';
export const GET_ANALYTIC_AVERAGE_SESSION = 'analytic/average-session-time';
export const GET_ANALYTIC_BUSINESS_REGISTRATIONS = 'analytic/business-register';
export const GET_ANALYTIC_TOP_BUSINESSES = 'analytic/top-business-purchase';
export const GET_GIFT_SUM_LIST = 'analytic/count-gifts-sum';
export const GET_GIFT_BRAND_LIST = 'analytic/count-gifts-brands';
export const COUNT_USERS = 'analytic/count-users';

// Admin Settings
export const GET_ALL_NGC_STATUS = 'organizations/has-bulk-archived';
export const ARCHIVE_ALL_ORGANIZATION = 'organizations/archive-all-ngc';
export const SCOUT_IMPORT = 'admin/settings/scout-import';
export const UN_ARCHIVE_ALL_ORGANIZATION = 'organizations/unarchive-all-ngc';
export const ADMIN_SETTINGS = 'admin/settings';
export const ADMIN_SETTINGS_UPDATE_USERS = 'admin/settings/update-users';
export const ADMIN_NOTIFICATIONS = 'accounts/admin-emails/notifications';

// Multiple account
export const USER_ORGANIZATIONS = 'user-organizations';
export const USER_ORGANIZATIONS_BY_ID = 'user-organizations/:userId';
export const USER_ACTIVE_ORGANIZATIONS = 'user-organizations/check-active';

// Universal card
export const UNIVERSAL_CARD = 'virtual/amounts';
export const UNIVERSAL_CARD_BY_ID = 'virtual/amount/:id';
export const USERS_UNIVERSAL_CARDS = 'virtual/credits';
export const PRESENT_CARD_TO_USER = 'virtual/amounts/:id/present';
export const USERS_UNIVERSAL_CARD_BY_ID = 'virtual/credits/:id';
