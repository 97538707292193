import * as actionTypes from '@/store/actions/types/multipleAccounts';

const initialState = {
  loaders: {
    getOrganizations: false,
    switchOrganizations: false,
    createOrganizations: false,
  },
  data: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MY_ORGANIZATIONS_LIST_START:
      return { ...state, loaders: { ...state.loaders, getOrganizations: true } };
    case actionTypes.GET_MY_ORGANIZATIONS_LIST_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, getOrganizations: false },
        data: action.payload.data,
      };
    case actionTypes.GET_MY_ORGANIZATIONS_LIST_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, getOrganizations: false },
      };
    case actionTypes.CHOOSE_MY_ORGANIZATION_START:
      return { ...state, loaders: { ...state.loaders, switchOrganizations: true } };
    case actionTypes.CHOOSE_MY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, switchOrganizations: false },
      };
    case actionTypes.CHOOSE_MY_ORGANIZATION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, switchOrganizations: false },
      };
    case actionTypes.CREATE_NEW_ORGANIZATION_START:
      return { ...state, loaders: { ...state.loaders, createOrganizations: true } };
    case actionTypes.CREATE_NEW_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loaders: { ...state.loaders, createOrganizations: false },
      };
    case actionTypes.CREATE_NEW_ORGANIZATION_FAIL:
      return {
        ...state,
        loaders: { ...state.loaders, createOrganizations: false },
      };
    case actionTypes.DELETE_ORGANIZATION_START:
      return { ...state, loading: true };
    case actionTypes.DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: state.data.filter(
          item => item.orgonization_id !== action.payload.data.orgonization_id,
        ),
      };
    case actionTypes.DELETE_ORGANIZATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
