import * as actionTypes from './types/organizations';

export const getBalanceChart = (organizationId, dateRange) => {
  return {
    type: actionTypes.ORGANIZATIONS_BALANCE_CHART,
    payload: {
      organizationId,
      dateRange,
    },
  };
};

export const getTotalProfitCards = (organizationId, date_range) => {
  return {
    type: actionTypes.ORGANIZATIONS_BALANCE_CARDS,
    payload: {
      organizationId,
      date_range,
    },
  };
};

export const getOrganizationInfo = organizationId => {
  return {
    type: actionTypes.ORGANIZATIONS_GET_INFO,
    payload: {
      organizationId,
    },
  };
};

export const putOrganizationInfo = (data, formik) => {
  return {
    type: actionTypes.ORGANIZATIONS_PUT_INFO,
    payload: {
      data,
      formik,
    },
  };
};

export const getOrganizations = params => {
  return {
    type: actionTypes.GET_ORGANIZATIONS,
    payload: params,
  };
};

export const resendContract = params => {
  return {
    type: actionTypes.RESEND_CONTRACT,
    payload: params,
  };
};

export const archiveBusiness = params => {
  return {
    type: actionTypes.GET_ARCHIVE,
    payload: params,
  };
};

export const createOrganization = data => {
  return {
    type: actionTypes.CREATE_ORGANIZATION,
    payload: data,
  };
};

export const sendContract = data => {
  return {
    type: actionTypes.SEND_INVITATION,
    payload: data,
  };
};

export const getTamplatesList = () => {
  return {
    type: actionTypes.GET_TEMPLATES,
  };
};

export const uploadBusinessLogo = params => {
  return {
    type: actionTypes.UPLOAD_BUSINESS_LOGO,
    payload: params,
  };
};
