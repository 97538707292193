import * as Yup from 'yup';

import * as validationConst from '@/constants/validation';

export default t => {
  return {
    fullname: Yup.string(),
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid')),
    comment: Yup.string()
      .required(t('validation.commentRequired'))
      .max(validationConst.MAX_COMMENT_LENGTH, t('validation.commentMaxLength')),
  };
};
