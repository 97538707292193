import * as actionTypes from '@/store/actions/types/inbox';

const initialState = {
  list: [],
  error: null,
  loading: false,
  current_page: 0,
  per_page: 5,
  total: 0,
  detail: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INBOX_START:
      return { ...state, loading: true, error: false };
    case actionTypes.INBOX_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.INBOX_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

export default reducer;
