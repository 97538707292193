import styled from 'styled-components';

import breakpoints from '@/constants/breakpoints';
import CreditCardConnected from '@/assets/images/credit-card-connected.svg';
import CreditCardNotConnected from '@/assets/images/credit-card-not-connected.svg';

export const PaymentInfoWrapper = styled.div`
  padding-top: 52px;
  padding-bottom: 52px;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 16px 0;
  }

  .payment-info {
    &__title {
      margin-bottom: 28px;
    }
  }
`;

export const CardInfoWrapper = styled.div`
  padding-top: 41px;
  padding-bottom: 52px;

  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 35px;
    padding-bottom: 45px;
    text-align: center;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 16px 0;
  }
`;

export const CardConnectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
  padding-bottom: 36px;
  && {
    .MuiButtonBase-root {
      margin-top: 18px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 16px 0;
  }
`;

export const CardInfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  @media screen and (max-width: ${breakpoints.sm}) {
    flex-direction: column;
    text-align: center;
  }
`;

export const CardInfoImage = styled.div`
  width: 115px;
  height: 123px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${CreditCardConnected});

  @media screen and (max-width: ${breakpoints.sm}) {
    margin-bottom: 10px;
    width: 90px;
    height: 74px;
  }
`;

export const CardConnectImage = styled.div`
  width: 115px;
  height: 123px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${CreditCardNotConnected});

  @media screen and (max-width: ${breakpoints.sm}) {
    width: 90px;
    height: 74px;
    margin-bottom: 10px;
  }
`;

export const StripeWrapper = styled.div`
  padding-top: 52px;
  padding-bottom: 49px;

  && {
    .MuiTypography-root {
      margin-bottom: 24px;
    }

    #stripe-button-container .MuiTypography-root {
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 16px 0;
  }
`;

export const SquareWrapper = styled.div`
  padding-top: 52px;
  padding-bottom: 49px;

  && {
    .MuiTypography-root {
      margin-bottom: 24px;
    }

    #square-button-container {
      align-items: center;
    }

    #square-button-container .MuiTypography-root {
      margin-bottom: 0px;
    }

    #square-connect-button .MuiTypography-root {
      margin-bottom: 0px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 16px 0;
  }
`;

export const StripeFormWrapper = styled.div`
  padding: 60px 90px;
  @media screen and (max-width: ${breakpoints.sm}) {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 16px;
  }
`;
