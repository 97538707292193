import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';

// Reducers
import auth from './reducers/auth';
import profileDialog from './reducers/profileDialog';
import notifications from './reducers/notifications';
import accounts from './reducers/accounts';
import gifts from './reducers/gifts';
import gift from './reducers/gift';
import customers from './reducers/customers';
import users from './reducers/users';
import transactions from './reducers/transactions';
import organizations from './reducers/organizations';
import stripe from './reducers/stripe';
import square from './reducers/square';
import locations from './reducers/locations';
import language from './reducers/language';
import inbox from './reducers/inbox';
import categories from './reducers/categories';
import employees from './reducers/employees';
import redemptions from './reducers/redemptions';
import giftsList from './reducers/giftsList';
import csv from './reducers/csv';
import directGift from './reducers/directGift';
import surveys from './reducers/surveys';
import plans from './reducers/plans';
import admins from './reducers/admins';
import support from './reducers/support';
import workHours from './reducers/workHours';
import sharing from './reducers/sharing';
import tags from './reducers/tags';
import analytics from './reducers/analytics';
import privacyAndTerms from './reducers/privacyAndTerms';
import adminSettings from './reducers/adminSettings';
import disputes from './reducers/disputes';
import multipleAccount from './reducers/multipleAccount';
import credits from './reducers/credits';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  support,
  auth,
  admins,
  profileDialog,
  notifications,
  accounts,
  gifts,
  gift,
  customers,
  users,
  transactions,
  stripe,
  square,
  organizations,
  locations,
  language,
  inbox,
  categories,
  employees,
  redemptions,
  giftsList,
  csv,
  directGift,
  surveys,
  plans,
  workHours,
  sharing,
  tags,
  analytics,
  privacyAndTerms,
  adminSettings,
  disputes,
  multipleAccount,
  credits,
});

const composeEnhancers =
  process.env.NODE_ENV === 'development' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const configureStore = () => {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
