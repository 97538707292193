import * as actionTypes from '@/store/actions/types/locations';

const initialState = {
  list: [],
  current_page: 0,
  per_page: 5,
  total: 0,
  newLocation: [],
  newEmployees: null,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOCATIONS_START:
      return { ...state, loading: true };
    case actionTypes.LOCATIONS_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.LOCATIONS_ADD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        newEmployees: action.payload,
      };
    case actionTypes.LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

export default reducer;
