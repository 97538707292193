export const MIN_PASSWORD_LENGTH = 8;
export const MIN_NAME_LENGTH = 2;
export const MIN_BUSINESS_NAME_LENGTH = 2;
export const MAX_BUSINESS_NAME_LENGTH = 100;
export const MAX_TAGS_LENGTH = 5;
export const MAX_DESCRIPTION_LENGTH = 256;
export const MAX_PROMO_VIDEO_DESCRIPTION_LENGTH = 60;
export const MAX_IMAGE_SIZE = 5242880; // 5MB
export const MAX_VIDEO_SIZE = 52428800; // 50MB
export const MIN_GIFT_COST = 0.5;
export const MAX_GIFT_COST = 99999999;
export const MIN_GIFT_CARD_AMOUNT = 0.5;
export const MIN_GIFT_CARD_AMOUNT_USA = 0.5;
export const MIN_GIFT_CARD_AMOUNT_MEX = 10;
export const MIN_GIFT_CARD_AMOUNT_ES = 0.5;
export const MAX_GIFT_CARD_AMOUNT = 99999999;
export const MIN_GIFT_SPECIFIC_QUANTITY = 1;
export const MIN_GIFT_DISCOUNT = 0.01;
export const MAX_GIFT_DISCOUNT = 99;
export const MAX_TITLE_DISCOUNT = 20;
export const MAX_DESCRIPTIONS_DISCOUNT = 191;
export const MAX_COMMENT_LENGTH = 2000;
export const MAX_DAYS = 365;
