import * as actionTypes from '../actions/types/profileDialog';

const initialState = {
  dialogIsOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_DIALOG_OPEN:
      return {
        ...state,
        dialogIsOpen: true,
      };
    case actionTypes.PROFILE_DIALOG_CLOSE:
      return {
        ...state,
        dialogIsOpen: false,
      };
    default:
      return state;
  }
};

export default reducer;
