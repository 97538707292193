import * as actionTypes from '@/store/actions/types/credits';

const initialState = {
  universalCards: [],
  usersCards: { data: [], current_page: 0, per_page: 25, total: 0 },
  loadings: {
    universalCards: false,
    usersCards: false,
    userCard: false,
    addCardToUser: false,
  },
  userCard: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_UNIVERSAL_CARDS_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          usersCards: true,
        },
      };
    case actionTypes.GET_USERS_UNIVERSAL_CARDS_SUCCESS:
      return {
        ...state,
        usersCards: {
          data: action.payload.data,
          current_page: action.payload.meta.current_page - 1,
          per_page: action.payload.meta.per_page,
          total: action.payload.meta.total,
        },
        loadings: {
          ...state.loadings,
          usersCards: false,
        },
      };
    case actionTypes.GET_USERS_UNIVERSAL_CARDS_FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          usersCards: false,
        },
      };
    case actionTypes.GET_UNIVERSAL_CARDS_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          universalCards: true,
        },
      };
    case actionTypes.GET_UNIVERSAL_CARDS_SUCCESS:
      return {
        ...state,
        universalCards: action.payload.data,
        loadings: {
          ...state.loadings,
          universalCards: false,
        },
      };
    case actionTypes.GET_UNIVERSAL_CARDS_FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          universalCards: false,
        },
      };
    case actionTypes.GET_USER_UNIVERSAL_CARD_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          userCard: false,
        },
      };
    case actionTypes.GET_USER_UNIVERSAL_CARD_SUCCESS:
      return {
        ...state,
        userCard: action.payload.data,
        loadings: {
          ...state.loadings,
          userCard: false,
        },
      };
    case actionTypes.GET_USER_UNIVERSAL_CARD_FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          userCard: false,
        },
      };
    case actionTypes.ADD_UNIVERSAL_CARD_TO_USER_START:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          addCardToUser: true,
        },
      };
    case actionTypes.ADD_UNIVERSAL_CARD_TO_USER_SUCCESS:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          addCardToUser: false,
        },
      };
    case actionTypes.ADD_UNIVERSAL_CARD_TO_USER_FAIL:
      return {
        ...state,
        loadings: {
          ...state.loadings,
          addCardToUser: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
