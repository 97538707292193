const giftMapper = data => {
  return {
    amounts: data.amounts,
    amount_from: data.amount_from,
    amount_to: data.amount_to,
    cost: data.cost,
    cover_image: data.cover_image,
    currency: data.currency,
    description: data.description,
    discount: data.discount,
    expiration_gift: data.expiration_gift,
    external_service: data.external_service,
    fixed_amount: data.fixed_amount,
    id: data.id,
    images: data.images,
    is_filled: data.is_filled,
    is_open: data.is_open,
    is_sold: data.is_sold,
    is_mine: data.is_mine,
    is_generated: data.is_generated,
    locations: data.locations,
    name: data.name,
    organization: data.organization,
    payment_type: data.payment_type,
    quantity: data.quantity,
    quantity_type: data.quantity_type,
    redemption_frequency: data.redemption_frequency,
    redemption_type: data.redemption_type,
    status: data.status,
    tags: data.tags,
    step: data.step,
    type: data.type,
    views: data.views,
  };
};

export default giftMapper;
