import { put } from 'redux-saga/effects';
import * as actionTypes from '@/store/actions/types/credits';
import getLink from '@/utility/common/routes';
import * as endpoints from '@/constants/api';
import axios from 'axios';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import getErrorMessage from '@/utility/common/errors';
import { t } from '@/locale/i18n';

export function* getUsersUniversalCardsSaga(action) {
  yield put({ type: actionTypes.GET_USERS_UNIVERSAL_CARDS_START });
  try {
    const res = yield axios.get(endpoints.USERS_UNIVERSAL_CARDS, { params: action.payload });
    const { data, meta } = res.data;

    yield put({
      type: actionTypes.GET_USERS_UNIVERSAL_CARDS_SUCCESS,
      payload: {
        data,
        meta,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_USERS_UNIVERSAL_CARDS_FAIL,
    });
  }
}

export function* getUniversalCardsSaga(action) {
  yield put({ type: actionTypes.GET_UNIVERSAL_CARDS_START });
  const url = getLink(endpoints.UNIVERSAL_CARD);
  try {
    const res = yield axios.get(url, { params: action.payload });
    const { data } = res.data;
    yield put({
      type: actionTypes.GET_UNIVERSAL_CARDS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_UNIVERSAL_CARDS_FAIL,
    });
  }
}

export function* getUserUniversalCardSaga(action) {
  const { id } = action.payload;
  yield put({ type: actionTypes.GET_USER_UNIVERSAL_CARD_START });
  const url = getLink(endpoints.USERS_UNIVERSAL_CARD_BY_ID, { id });
  try {
    const res = yield axios.get(url);
    const { data } = res;
    yield put({
      type: actionTypes.GET_USER_UNIVERSAL_CARD_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_USER_UNIVERSAL_CARD_FAIL,
    });
  }
}

export function* addUniversalCardToUserSaga(action) {
  const { resetForm, virtual_amount_id, user_ids, amount } = action.payload;
  yield put({ type: actionTypes.ADD_UNIVERSAL_CARD_TO_USER_START });
  try {
    const url = getLink(endpoints.PRESENT_CARD_TO_USER, { id: virtual_amount_id });
    yield axios.post(url, { user_ids, amount });

    yield put({
      type: actionTypes.ADD_UNIVERSAL_CARD_TO_USER_SUCCESS,
    });
    if (resetForm) {
      resetForm();
    }

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('universalCards.success'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADD_UNIVERSAL_CARD_TO_USER_FAIL,
    });
  }
}
