import styled from 'styled-components';

import colors from '@/constants/colors';

export const ItemContainer = styled.li`
  color: ${colors.customStepperText};
  padding: 25px 58px;
  transition: background-color 0.4s;
  line-height: 2.4rem;

  &:not(.disabled):hover,
  &.active {
    background-color: ${colors.customStepperActiveBg};
  }

  &.complete {
    color: ${colors.customStepperTextComplete};

    .stepper-number {
      background-color: ${colors.customStepperNumberCompleteBg};
    }
  }

  &:not(.disabled) {
    cursor: pointer;
  }
`;

export const Number = styled.div`
  height: 43px;
  width: 43px;
  display: flex;
  justify-content: center;
  font-size: 1.9rem;
  line-height: 4.6rem;
  color: ${colors.customStepperNumber};
  border-radius: 50%;
  background-color: ${colors.customStepperText};
`;

export const Subtitle = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 0.5rem;
  margin-bottom: 0;
`;
