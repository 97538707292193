// Employees actions
export const EMPLOYEES_START = 'EMPLOYEES_START';
export const EMPLOYEES_STOP = 'EMPLOYEES_STOP';

export const EMPLOYEES_GET_LIST = 'EMPLOYEES_GET_LIST';
export const EMPLOYEES_LIST_SUCCESS = 'EMPLOYEES_LIST_SUCCESS';

export const EMPLOYEES_DELETE = 'EMPLOYEES_DELETE';

export const EMPLOYEES_EDIT = 'EMPLOYEES_EDIT';
