export const GET_USERS_UNIVERSAL_CARDS = 'GET_USERS_UNIVERSAL_CARDS';
export const GET_USERS_UNIVERSAL_CARDS_START = 'GET_USERS_UNIVERSAL_CARDS_START';
export const GET_USERS_UNIVERSAL_CARDS_SUCCESS = 'GET_USERS_UNIVERSAL_CARDS_SUCCESS';
export const GET_USERS_UNIVERSAL_CARDS_FAIL = 'GET_USERS_UNIVERSAL_CARDS_FAIL';

export const GET_UNIVERSAL_CARDS = 'GET_UNIVERSAL_CARDS';
export const GET_UNIVERSAL_CARDS_START = 'GET_UNIVERSAL_CARDS_START';
export const GET_UNIVERSAL_CARDS_SUCCESS = 'GET_UNIVERSAL_CARDS_SUCCESS';
export const GET_UNIVERSAL_CARDS_FAIL = 'GET_UNIVERSAL_CARDS_FAIL';

export const GET_USER_UNIVERSAL_CARD = 'GET_USER_UNIVERSAL_CARD';
export const GET_USER_UNIVERSAL_CARD_START = 'GET_USER_UNIVERSAL_CARD_START';
export const GET_USER_UNIVERSAL_CARD_SUCCESS = 'GET_USER_UNIVERSAL_CARD_SUCCESS';
export const GET_USER_UNIVERSAL_CARD_FAIL = 'GET_USER_UNIVERSAL_CARD_FAIL';

export const ADD_UNIVERSAL_CARD_TO_USER = 'ADD_UNIVERSAL_CARD_TO_USER';
export const ADD_UNIVERSAL_CARD_TO_USER_START = 'ADD_UNIVERSAL_CARD_TO_USER_START';
export const ADD_UNIVERSAL_CARD_TO_USER_SUCCESS = 'ADD_UNIVERSAL_CARD_TO_USER_SUCCESS';
export const ADD_UNIVERSAL_CARD_TO_USER_FAIL = 'ADD_UNIVERSAL_CARD_TO_USER_FAIL';
