import * as actionTypes from './types/multipleAccounts';

export const getMyOrganizationsList = params => {
  return {
    type: actionTypes.GET_MY_ORGANIZATIONS_LIST,
    payload: params,
  };
};

export const creatNewOrganization = params => {
  return {
    type: actionTypes.CREATE_NEW_ORGANIZATION,
    payload: params,
  };
};

export const chooseMyOrganization = params => {
  return {
    type: actionTypes.CHOOSE_MY_ORGANIZATION,
    payload: params,
  };
};

export const deleteOrganization = params => {
  return {
    type: actionTypes.DELETE_ORGANIZATION,
    payload: params,
  };
};
