export const GIFT_COVER_HEIGHT = 720;
export const GIFT_COVER_WIDTH = 1280;
export const MIN_GIFT_COVER_HEIGHT = 388;
export const MIN_GIFT_COVER_WIDTH = 690;
export const GIFT_DETAIL_WIDTH = 1280;
export const GIFT_DETAIL_HEIGHT = 720;
export const ORGANIZATION_LOGO_HEIGHT = 400;
export const ORGANIZATION_LOGO_WIDTH = 400;
export const ORGANIZATION_LOGO_HEIGHT_RECTANGLE = 400;
export const ORGANIZATION_LOGO_WIDTH_RECTANGLE = 1200;
export const ORGANIZATION_COVER_HEIGHT = 720;
export const ORGANIZATION_COVER_WIDTH = 1280;
export const CATEGORY_COVER_HEIGHT = 720;
export const CATEGORY_COVER_WIDTH = 1280;
export const CATEGORY_ICON_HEIGHT = 124;
export const CATEGORY_ICON_WIDTH = 124;
export const PROMO_GROUP_ICON_HEIGHT = 170;
export const PROMO_GROUP_ICON_WIDTH = 215;
export const PROMO_GROUP_RECOMMENDED_ICON_WIDTH = 170;
