import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/disputes';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { checkNotificationsSaga } from '@/store/saga/accounts';

export function* getDisputesListSaga(action) {
  const { id, ...params } = action.payload;
  yield put({ type: actionTypes.DISPUTES_GET_LIST_START });
  const url = getLink(endpoints.GET_DISPUTES_LIST);
  try {
    const res = yield axios.get(url, { params: { ...params } });
    const { data } = res;

    yield put({
      type: actionTypes.DISPUTES_GET_LIST_SUCCESS,
      payload: data,
    });

    if (id) {
      yield call(checkNotificationsSaga, { payload: { id } });
    }
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.DISPUTES_GET_LIST_FAIL,
    });
  }
}

export default getDisputesListSaga;
