// Transactions types
import { ROLE_MERCHANDISER, ROLE_USER } from '@/constants/roles';

export const TRANSACTION_BLOCKED = 'blocked';
export const TRANSACTION_WAITING = 'waiting';
export const TRANSACTION_CUSTOMER = ROLE_USER;
export const TRANSACTION_MERCHANT = ROLE_MERCHANDISER;

export const TRANSACTION_REQUEST_TYPE_ROLE = 'transactionsByRole';
export const TRANSACTION_REQUEST_TYPE_STATUS = 'pendingType';
