import * as actionTypes from './types/notifications';

export const popNotification = key => {
  return {
    type: actionTypes.POP_NOTIFY,
    payload: {
      key,
    },
  };
};

export const pushNotification = options => {
  return {
    type: actionTypes.PUSH_NOTIFY,
    payload: {
      ...options,
    },
  };
};
