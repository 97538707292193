import * as Yup from 'yup';
import * as validationConst from '@/constants/validation';

export default t => {
  return {
    oldPassword: Yup.string()
      .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength'))
      .required(t('validation.passwordRequired')),
    newPassword: Yup.string()
      .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength'))
      .required(t('validation.newPasswordRequired')),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t('validation.passwordDontMatch'))
      .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength'))
      .required(t('validation.confirmNewPasswordRequired')),
  };
};
