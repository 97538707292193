import axios from 'axios';
import { logout } from '@/store/actions/auth';

const httpService = {
  // we pass the redux store and history in order to dispatch the logout actions
  // and push the user to login

  setupInterceptors: (store, history) => {
    axios.interceptors.response.use(
      response => {
        // simply return the response if there is no error

        return response;
      },
      error => {
        if (!error.response) {
          return Promise.reject(error);
        }
        // in this case we only care about unauthorized errors
        if (error.response.status === 401) {
          // we dispatch our logout action (more than likely changes a boolean
          // somewhere in your store ex. isAuthenticated: false)

          store.dispatch(logout());

          // send the user to the login page since the user/token is not valid

          history.push('/');
        }
        return Promise.reject(error);
      },
    );
  },
};
export default httpService;
