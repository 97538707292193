import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

import colors, { white, linkWater, selago } from '@/constants/colors';

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.primary,
      secondary: colors.textSecondary,
    },
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        borderRadius: '8px',
        padding: '11px 15px',
        minHeight: 48,
      },
      message: {
        fontSize: '1.4rem',
        lineHeight: '2.6rem',
        padding: 0,
      },
    },
    // GRID
    MuiGrid: {
      'spacing-xs-10': { margin: 0, width: '100%' },
      'spacing-xs-8': { margin: 0, width: '100%' },
    },
    // PAPER
    MuiPaper: {
      root: {
        '&.detail-statistic': {
          padding: '27px 30px 12px',

          [breakpoints.down('sm')]: {
            padding: '27px 16px 12px',
          },
        },
      },
      elevation1: {
        boxShadow: `0 .2rem .4rem ${colors.paperBoxShadow}`,
        padding: '5.6rem 4.6rem',

        [breakpoints.down('sm')]: {
          padding: '3rem 2.4rem',
        },
      },
      elevation4: {
        boxShadow: `0px 2px 4px ${linkWater}`,
      },
      elevation8: {
        boxShadow: `0px 2px 4px ${linkWater}`,
      },
    },
    // FAB
    MuiFab: {
      root: {
        fontSize: '1.3rem',
        lineHeight: '2.4rem',
        letterSpacing: '0.3rem',
        boxShadow: 'none',
      },
      extended: {
        height: '5.4rem',
        borderRadius: '5.1rem',
        padding: '0 3.5rem',
      },
      primary: {
        backgroundColor: colors.primaryBtnBg,
        '&:hover': {
          backgroundColor: colors.primaryBtnHoverBg,
        },
      },
    },
    // BUTTON
    MuiIconButton: {
      root: {
        '&.icon-outlined': {
          border: '1px solid',
          '& svg': {
            fontSize: '2.5rem',
          },
        },
      },
    },
    MuiButton: {
      label: {
        whiteSpace: 'nowrap',
      },
      root: {
        fontFamily: 'OpenSans',
        fontWeight: 600,
        borderRadius: '5.1rem',
        padding: '0.9rem 3.75rem',
        fontSize: '1rem',
        lineHeight: '2.4rem',
        letterSpacing: '0.03rem',
        '&.icon-outlined': {
          '& svg': {
            fontSize: '2.5rem',
          },
        },
      },
      sizeLarge: {
        padding: '1.5rem 4.9rem',
        fontSize: '1.3rem',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          fontSize: '1.29rem',
          backgroundColor: colors.containedBtnDisabledBg,
          color: colors.containedBtnDisabledText,
        },
      },
      outlined: {
        padding: '0.8rem 3.7rem',
        '&.Mui-disabled': {
          borderColor: colors.outlinedBtnDisabledBorder,
          color: colors.outlinedBtnDisabledText,
        },
      },
      containedPrimary: {
        backgroundColor: colors.primaryBtnBg,
        '&:hover': {
          backgroundColor: colors.primaryBtnHoverBg,
        },
      },
      containedSecondary: {
        backgroundColor: colors.secondaryBtnBg,
        color: colors.secondaryBtnText,
        '&:hover': {
          backgroundColor: colors.secondaryBtnHoverBg,
        },
      },
      outlinedPrimary: {
        color: colors.outlinedPrimaryBtnText,
        borderColor: colors.outlinedPrimaryBtnBorder,
        '&:hover': {
          backgroundColor: colors.outlinedPrimaryBtnHoverBg,
          color: colors.outlinedPrimaryBtnHoverText,
          borderColor: colors.outlinedPrimaryBtnHoverBorder,
        },
      },
      outlinedSecondary: {
        '&:hover': {
          backgroundColor: colors.outlinedSecondaryBtnHoverBg,
          color: colors.outlinedSecondaryBtnHoverText,
        },
        '&.Mui-disabled': {
          borderColor: colors.outlinedSecondaryBtnDisabledBorder,
          color: colors.outlinedSecondaryBtnDisabledText,
        },
      },
    },
    // LINK
    MuiLink: {
      root: {
        cursor: 'pointer',
        '&.btn-link': {
          textTransform: 'uppercase',
          '&--small': {
            fontSize: '1rem',
            lineHeight: '1rem',
            fontWeight: 500,
          },
          '&--blue': {
            color: colors.blueLink,
          },
        },
      },
    },
    // FORM
    MuiFormControl: {
      root: {
        marginBottom: '30px',
        '&.m-b-0': {
          marginBottom: 0,
        },
        '& label': {
          position: 'static',
          '& + .MuiInput-formControl': {
            marginTop: '17px',
            '& .MuiSelect-icon': {
              top: 'calc(50% - 5px)',
            },
            '& .MuiInputBase-input': {
              marginTop: 0,
            },
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        fontWeight: 400,
        color: colors.label,
        '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
          color: colors.label,
        },
      },
      filled: {
        '& + .MuiInputBase-adornedStart': {
          '& .MuiTypography-colorTextSecondary': {
            color: colors.inputText,
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1.4rem',
        lineHeight: '1.4rem',
        fontWeight: 400,
        marginTop: '.8rem',
        '&.Mui-error': {
          color: colors.inputTextError,
        },
      },
    },
    // ADORNMENT
    MuiInputAdornment: {
      root: {
        '& p': {
          fontSize: '1.6rem',
          lineHeight: '2.5rem',
          fontWeight: 400,
          color: colors.inputPlaceholder,
        },
      },
      positionStart: {
        '&.adornment-inside': {
          position: 'absolute',
          top: 'calc(50% + .5rem)',
          left: '17px',
        },
        '&.adornment-flag': {
          position: 'absolute',
          left: '10px',
        },
        '&.adornment-flag + input': {
          paddingLeft: '40px',
        },
        '&.adornment-currency': {
          position: 'absolute',
          top: 'calc(50% + .5rem)',
          left: '17px',
        },
        '&.adornment-currency + input': {
          paddingLeft: '52px',
        },
      },
    },
    // INPUT
    MuiInputBase: {
      root: {
        '&.Mui-error input, &.Mui-error textarea, &.Mui-error .MuiSelect-select': {
          borderColor: colors.inputBorderError,
        },
      },
      input: {
        fontSize: '1.6rem',
        lineHeight: '2.5rem',
        fontWeight: 400,
        color: colors.inputText,
        height: 'auto',
        padding: '1.1rem 3.7rem 1.1rem 1.8rem',
        border: `1px solid ${colors.inputBorder}`,
        borderRadius: '.4rem',
        boxSizing: 'border-box',
        marginTop: '1rem',
        '&::-webkit-input-placeholder': {
          color: colors.inputPlaceholder,
          opacity: 1,
        },
        '&:focus': {
          borderColor: colors.inputBorderFocused,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.inputDisabledBg,
          borderColor: colors.inputDisabledBorder,
          color: colors.inputDisabledText,
        },
        '-webkit-appearance': 'none',
      },
      inputMarginDense: {
        paddingTop: '1.3rem',
      },
      multiline: {
        padding: 0,
      },
      inputMultiline: {
        padding: '1.3rem 3.7rem 1.3rem 1.8rem',
      },
      inputAdornedStart: {
        paddingLeft: '30px',
      },
    },
    MuiInput: {
      formControl: {
        '&.select-empty .MuiSelect-select': {
          color: colors.inputPlaceholder,
        },
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    // SELECT
    MuiSelect: {
      select: {
        borderRadius: '.4rem',
        minHeight: '5.1rem',
        '&:focus': {
          borderRadius: '.4rem',
          backgroundColor: colors.selectFocusedBg,
        },
        '&[aria-pressed="true"]': {
          borderColor: colors.selectOpenedBorder,
        },
      },
      icon: {
        top: 'calc(50% - 10px)',
        fill: colors.selectIcon,
        width: '2rem',
        height: 'auto',
        right: '1.2rem',
      },
    },
    // FORM CONTROL LABEL
    MuiFormControlLabel: {
      label: {
        fontSize: '1.6rem',
        lineHeight: '2.5rem',
        fontWeight: 400,
        paddingTop: '2px',
      },
    },
    // MENU
    MuiMenu: {
      paper: {
        '&.MuiPaper-elevation8': {
          boxShadow: '0px 2px 12px rgba(26, 75, 120, 0.07)',
        },
      },
      list: {
        '&.MuiList-padding': {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
      },
    },
    // LIST
    MuiListItem: {
      root: {
        '&.secondary': {
          borderLeft: '5px solid transparent',
          transition: 'all 0.3s ease',
        },
        '&.Mui-selected': {
          color: colors.listItemSelectedText,
          backgroundColor: colors.listItemSelectedBg,
          '&.secondary': {
            color: colors.secondary,
            borderLeft: `5px solid ${colors.secondary}`,
          },
        },
        '&.Mui-selected:hover': {
          backgroundColor: colors.listItemHoverBg,
          '&.secondary': {
            color: colors.secondary,
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: colors.listItemHoverBg,
          '&.secondary': {
            color: colors.secondary,
          },
        },
      },
    },
    // TYPOGRAPHY
    MuiTypography: {
      root: {
        '&.m-b-30': {
          marginBottom: 30,
        },
      },
      noWrap: {
        maxWidth: '100%',
      },
      body2: {
        fontSize: '1.4rem',
        lineHeight: '1.6rem',
      },
      h2: {
        lineHeight: '3.5rem',
      },
      subtitle1: {
        fontSize: '1.8rem',
        lineHeight: '3.1rem',
        fontWeight: 300,
        color: colors.textSecondary,
      },
      subtitle2: {
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        fontWeight: 400,
      },
      colorPrimary: {
        '&.MuiLink-root': {
          color: colors.primaryLink,

          '&[disabled]': {
            color: colors.disabledLink,
            cursor: 'default',
          },
        },
      },
      colorSecondary: {
        '&.MuiLink-root': {
          color: colors.secondaryLink,
        },

        '&[disabled]': {
          color: colors.disabledLink,
          cursor: 'default',
        },
      },
      caption: {
        fontSize: '1.2rem',
        lineHeight: '2.6rem',
      },
    },
    // STEPPER
    MuiStepper: {
      root: {
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,

        '&.transparent': {
          backgroundColor: 'transparent',

          '& .MuiStepIcon-root:not(.MuiStepIcon-active)': {
            color: selago,
          },
        },
      },
    },
    MuiStep: {
      completed: {
        '& .MuiStepIcon-root': {
          borderColor: colors.stepIconActive,

          '& .MuiStepIcon-text': {
            fill: colors.stepIconActive,
          },
        },
        '& .MuiStepLabel-label': {
          opacity: 1,
        },
        '& .MuiStepIcon-root .inner-circle': {
          opacity: 1,
        },
      },
    },
    MuiStepIcon: {
      root: {
        fill: colors.background,
        fontSize: '2.3rem',
        color: 'transparent',

        '&.MuiStepIcon-white': {
          fill: white,
        },

        '&.MuiStepIcon-active': {
          border: 'none',
          fill: colors.stepIconActive,

          '& .inner-circle': {
            opacity: 1,
          },
        },

        '& .inner-circle': {
          fill: colors.stepIconActive,
          opacity: 0.5,
        },
      },
      active: {
        '& .MuiStepIcon-text': {
          fill: `${white} !important`,
        },
        '& .inner-circle': {
          fill: colors.customStepperNumberBg,
        },
      },
      text: {
        fill: colors.stepIcon,
        fontSize: '2rem',
        transform: 'translate(0px, 1px)',
      },
    },
    MuiStepLabel: {
      label: {
        color: colors.stepLabel,
        opacity: 0.4,
        '&.MuiStepLabel-active': {
          color: colors.stepLabel,
          opacity: 1,
          fontWeight: 400,
        },
        '&.MuiStepLabel-completed': {
          color: colors.stepLabel,
          fontWeight: 400,
        },
      },
    },
    MuiStepConnector: {
      lineHorizontal: {
        borderColor: colors.stepConnector,
        opacity: 0.5,
      },
      active: {
        '& .MuiStepConnector-lineHorizontal': {
          opacity: 1,
        },
      },
      alternativeLabel: {
        top: '11px',
        left: 'calc(-50% + 5px)',
        right: 'calc(50% + 5px)',

        [breakpoints.down('xs')]: {
          top: '16px',
        },
      },
    },
    MuiStepButton: {
      root: {
        padding: 0,
        margin: 0,
        zIndex: 2,
      },
    },
    // DIALOG
    MuiDialog: {
      paper: {
        margin: '16px',
      },
      paperScrollPaper: {
        maxHeight: 'calc(100% - 32px)',
      },
      paperFullWidth: {
        width: 'calc(100% - 32px)',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '35px 50px',

        [breakpoints.down('xs')]: {
          padding: '30px 50px 30px 16px',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 50px 27px',

        [breakpoints.down('xs')]: {
          padding: '0 16px 16px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '0 50px 35px',

        [breakpoints.down('xs')]: {
          padding: '0 16px 16px',
        },
      },
    },
    // BACKDROP
    MuiBackdrop: {
      root: {
        backgroundColor: colors.backdrop,
      },
    },
    // Divider
    MuiDivider: {
      root: {
        backgroundColor: colors.defaultDividerBg,
      },
    },
    // Chip
    MuiChip: {
      root: {
        height: '22px',
        fontSize: '1.6rem',
        fontWeight: 400,
        lineHeight: '2.2rem',
        color: colors.chip.text,
        backgroundColor: colors.chip.bg,
        borderRadius: '4px',
      },
      deletable: {
        '&:hover, &:focus': {
          backgroundColor: colors.chip.focusedBg,
        },
      },
      clickable: {
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover, &:focus': {
          backgroundColor: colors.chip.focusedBg,
        },
      },
      label: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      deleteIcon: {
        color: colors.chip.text,
        opacity: 0.5,
        margin: 0,
        marginRight: '10px',
        '&:hover': {
          color: colors.chip.text,
          opacity: 1,
        },
      },
    },
    // Table
    MuiTableRow: {
      root: {
        height: '69px',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '1.4rem',
        lineHeight: '2.6rem',
        padding: '0 25px',
        borderBottomColor: colors.table.cellBorder,

        [breakpoints.down('xs')]: {
          padding: '0 16px',
        },

        '&:first-of-type': {
          paddingLeft: '47px',

          [breakpoints.down('xs')]: {
            paddingLeft: '16px',
          },
        },
        '&:last-of-type': {
          paddingRight: '47px',

          [breakpoints.down('xs')]: {
            paddingRight: '16px',
          },
        },
      },
      head: {
        fontSize: '1.4rem',
        lineHeight: '2.6rem',
        fontWeight: 400,
        color: colors.table.head,
      },
    },
    MuiTablePagination: {
      root: {
        color: colors.table.pagination,
      },
      toolbar: {
        paddingRight: '24px',
        '& .MuiInputBase-input': {
          minWidth: '50px',
          border: 'none',
          marginTop: 0,
          fontSize: '1.2rem',
        },
      },
      selectIcon: {
        top: 'calc(50% - 1rem)',
        right: '.4rem',
        fill: colors.table.pagination,
      },
      select: {
        minHeight: 'initial', // override Mui select min-height
      },
      caption: {
        '&:first-of-type': {
          [breakpoints.down('xs')]: {
            display: 'none',
          },
        },
      },
    },
    // Tabs
    MuiTabs: {
      root: {
        color: colors.tabs.text,
        backgroundColor: colors.tabs.bg,
        height: '6.9rem',
        padding: '0 2.8rem',

        [breakpoints.down('xs')]: {
          padding: '0 1.6rem',
        },

        '&.secondary': {
          backgroundColor: colors.tabs.secondary.bg,

          '& .MuiTab-textColorInherit': {
            color: colors.tabs.secondary.text,

            '&.Mui-selected': {
              color: colors.tabs.secondary.activeText,
            },
          },

          '& .MuiTabs-indicator': {
            backgroundColor: colors.tabs.secondary.indicator,
          },
        },
      },
    },
    // Tab
    MuiTab: {
      root: {
        fontWeight: 400,
        height: '6.9rem',
        minWidth: 'initial !important',
        textTransform: 'capitalize',
        marginRight: '33px',
        '&:last-child': {
          marginRight: 0,
        },

        [breakpoints.down('xs')]: {
          marginRight: '10px',
        },

        '&$selected': {
          color: colors.tabs.activeText,
        },
      },
      wrapper: {
        fontSize: '1.8rem',
      },
    },
    // Circular Progress
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.progress.primary,
      },
    },
    // Tooltip
    MuiTooltip: {
      tooltip: {
        fontSize: '1.2rem',
        lineHeight: '1.8rem',
        fontWeight: 300,
        maxWidth: '452px',
        padding: '14px 12px',
        backgroundColor: colors.tooltip.bg,
      },
    },
    // Switch
    MuiSwitch: {
      root: {
        width: 50,
        height: 28,
        padding: 0,
      },
      switchBase: {
        padding: '4px',
        '&.Mui-checked': {
          transform: 'translateX(78%)',
          color: colors.switch.thumb,
          '& + $track': {
            backgroundColor: colors.switch.bg,
            border: 'none',
            opacity: 1,
          },
        },
        '&:hover': {
          backgroundColor: 'initial',
        },
      },
      thumb: {
        width: 20,
        height: 20,
        boxShadow: 'none',
      },
      track: {
        borderRadius: 25,
        backgroundColor: colors.switch.bg,
      },
      checked: {},
      colorPrimary: {
        '&.Mui-checked': {
          color: colors.switch.thumb,
          '& + $track': {
            backgroundColor: colors.switch.activeBg,
          },
        },
      },
    },
    // Radio
    MuiRadio: {
      root: {
        color: colors.checkbox.border,

        '& .MuiSvgIcon-root': {
          fontSize: '2rem',
        },
      },
    },
  },
  // Change default props
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'p',
        subtitle2: 'p',
        body1: 'p',
        body2: 'p',
      },
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiButton: {
      size: 'large',
      color: 'primary',
      variant: 'contained',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiMenu: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      getContentAnchorEl: null,
    },
    MuiFab: {
      variant: 'extended',
      color: 'primary',
    },
    MuiLink: {
      variant: 'body2',
    },
    MuiDialog: {
      PaperProps: {
        square: true,
        elevation: 0,
      },
    },
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiPaper: {
      square: true,
    },
    MuiSwitch: {
      color: 'primary',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: ['SofiaPro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    h1: {
      fontSize: '4rem',
      lineHeight: '4rem',
    },
    h2: {
      fontSize: '3.2rem',
      lineHeight: '3.2rem',
    },
    h3: {
      fontSize: '2.4rem',
      lineHeight: '2.4rem',
      fontWeight: 300,
    },
    h4: {
      fontSize: '1.8rem',
      lineHeight: '2.4rem',
      fontWeight: 300,
    },
    h5: {
      fontSize: '1.2rem',
      lineHeight: '2.4rem',
      fontWeight: 300,
    },
    body1: {
      fontSize: '1.8rem',
      lineHeight: '3.1rem',
      fontWeight: 300,
    },
  },
});

export default theme;
