import * as actionTypes from './types/employees';

export const getEmployeesList = params => {
  return {
    type: actionTypes.EMPLOYEES_GET_LIST,
    payload: params,
  };
};

export const deleteEmployee = params => {
  return {
    type: actionTypes.EMPLOYEES_DELETE,
    payload: params,
  };
};

export const editEmployee = params => {
  return {
    type: actionTypes.EMPLOYEES_EDIT,
    payload: params,
  };
};
