import { takeEvery, call, takeLatest } from 'redux-saga/effects';
// Action Types
import * as authActionTypes from '@/store/actions/types/auth';
import * as accountsActionTypes from '@/store/actions/types/accounts';
import * as giftsTypes from '@/store/actions/types/gifts';
import * as giftTypes from '@/store/actions/types/gift';
import * as customersActionTypes from '@/store/actions/types/customers';
import * as usersActionTypes from '@/store/actions/types/users';
import * as transactionsActionTypes from '@/store/actions/types/transactions';
import * as organizationsActionTypes from '@/store/actions/types/organizations';
import * as inboxActionTypes from '@/store/actions/types/inbox';
import * as categoriesActionTypes from '@/store/actions/types/categories';
import * as redemptionsActionTypes from '@/store/actions/types/redemptions';
import * as stripeActionTypes from '@/store/actions/types/stripe';
import * as locationsActionTypes from '@/store/actions/types/locations';
import * as employeesActionTypes from '@/store/actions/types/employees';
import * as giftsListActionTypes from '@/store/actions/types/giftsList';
import * as csvActionTypes from '@/store/actions/types/csv';
import * as directGiftActionTypes from '@/store/actions/types/directGift';
import * as surveysActionTypes from '@/store/actions/types/surveys';
import * as plansActionTypes from '@/store/actions/types/plans';
import * as languageActionTypes from '@/store/actions/types/language';
import * as adminsActionTypes from '@/store/actions/types/admins';
import * as squareActionTypes from '@/store/actions/types/square';
import * as supportActionTypes from '@/store/actions/types/support';
import * as workHoursActionTypes from '@/store/actions/types/workHours';
import * as sharingActionTypes from '@/store/actions/types/sharing';
import * as tagsActionTypes from '@/store/actions/types/tags';
import * as analyticsActionTypes from '@/store/actions/types/analytics';
import * as privacyAndTermsActionTypes from '@/store/actions/types/privacyAndTerms';
import * as adminSettingsActionTypes from '@/store/actions/types/adminSettings';
import * as disputesActionTypes from '@/store/actions/types/disputes';
import * as multipleAccountsActionTypes from '@/store/actions/types/multipleAccounts';
import * as creditsActionTypes from '@/store/actions/types/credits';

// Saga functions
import {
  getPrivacySaga,
  getTermsSaga,
  sendPrivacyAndTermsSaga,
} from '@/store/saga/privacyAndTerms';

import {
  getMe,
  authSaga,
  clearLocalStorageSaga,
  registerBusinessSaga,
  authCheckLocalStorageSaga,
  verifyBusinessSaga,
  resetPassSaga,
  recoveryPassSaga,
  createPassSaga,
  updateFirstLoginSaga,
  loginAsOrganizationSaga,
  loginAsAdminSaga,
  resendEmailSaga,
  verifyAdminSaga,
} from '@/store/saga/auth';
import { sendSupportSaga } from '@/store/saga/support';
import { getSharingInfoSaga, downloadQRCodeSharingSaga } from '@/store/saga/sharing';
import { getWorkHoursSaga, editWorkHoursSaga } from '@/store/saga/workHours';
import {
  putMainInfoSaga,
  putSecuritySaga,
  getEmailNotificationsSaga,
  putEmailNotificationsSaga,
  changeStripeTokenSaga,
  getBillingCardSaga,
  checkNotificationsSaga,
  changeStripeAccountSaga,
  changeSquareAccountSaga,
} from '@/store/saga/accounts';
import { getTotalChartSaga, getTotalStatisticCardsSaga } from '@/store/saga/gifts';
import {
  getSurveysListSaga,
  getSurveyDetailSaga,
  changeStatusSurveySaga,
  createSurveySaga,
  editSurveySaga,
} from '@/store/saga/surveys';
import {
  createGiftSaga,
  getGiftSaga,
  updateGiftSaga,
  deleteGiftSaga,
  changeQuantitySaga,
  duplicateGiftSaga,
  getGiftChartSaga,
  getGiftCardsSaga,
  updateStatusGiftSaga,
} from '@/store/saga/gift';
import { getCustomersListSaga, getCustomersDetailSaga } from '@/store/saga/customers';
import {
  getUsersListSaga,
  getUsersDetailSaga,
  blockUserSaga,
  getInvitedUsersSaga,
  usersDownloadQRCodeSaga,
  usersGenerateQrCodeSaga,
  verifyUserSaga,
  notifyUsersSaga,
  getOnlineUsersCountSaga,
  changeAltruusBalanceStatus,
} from '@/store/saga/users';
import { setStripeTokenSaga, setStripeConnectSaga } from '@/store/saga/stripe';
import { getSquareUrlSaga } from '@/store/saga/square';
import {
  getTransactionsListSaga,
  getLastTransactionsSaga,
  getDetailsTransactionSaga,
  updateTransactionStatusSaga,
} from '@/store/saga/transactions';
import {
  getBalanceChartSaga,
  getTotalProfitCardsSaga,
  getOrganizationInfo,
  putOrganizationInfo,
  getOrganizationsSaga,
  archiveBusinessSaga,
  createOrganizationSaga,
  sendContractSaga,
  resendContractSaga,
  getTemplatesSaga,
  uploadBusinessLogoSaga,
} from '@/store/saga/organizations';
import { getInboxListSaga } from '@/store/saga/inbox';
import {
  getCategoriesSaga,
  getCategoriesListSaga,
  createCategorySaga,
  getCategoryDetailSaga,
  updateCategorySaga,
  deleteCategorySaga,
  getSubCategoriesListSaga,
  getPromoGroupsListSaga,
  getPromoGroupSaga,
  createPromoGroupSaga,
  deletePromoGroupSaga,
} from '@/store/saga/categories';
import {
  addEmployeesToLocationSaga,
  addLocationSaga,
  getLocationsListSaga,
  deleteLocationSaga,
  editLocationSaga,
} from '@/store/saga/locations';
import { getEmployeesListSaga, deleteEmployeeSaga, editEmployeeSaga } from '@/store/saga/employees';
import {
  getNGCStatusSaga,
  archiveAllNGCSaga,
  getAdminSettingsSaga,
  updateAdminSettingsSaga,
  updateToCurrentUsersSaga,
  getAdminNotificationsSaga,
  updateAdminNotificationsSaga,
  reindexAlgoliaSaga,
} from '@/store/saga/adminSettings';
import {
  redeemInfoSaga,
  redeemSubmitSaga,
  getRedemptionHistorySaga,
  refundRedemptionSaga,
  generateRedemptionCodeSaga,
  redemptionDownloadQrCodeSaga,
  redemptionGetSecretSymbolsSaga,
  redemptionSendSecretSymbolSaga,
  redemptionDownloadСosterSaga,
  getRedemptionsSaga,
  getRedemptionsCodeInfoSaga,
} from '@/store/saga/redemptions';
import { getGiftsListSaga } from '@/store/saga/giftsList';
import {
  importLocationsSaga,
  saveLocationsSaga,
  importEmployeesSaga,
  saveEmployeesSaga,
  exportCSVStatisticsSaga,
} from '@/store/saga/csv';
import { sendDirectGiftSaga } from '@/store/saga/directGift';
import { getPlansSaga, changePlanSaga } from '@/store/saga/plans';
import { setLanguageSaga, getEmailLanguageSaga } from '@/store/saga/language';
import {
  getAdminPermissionsSaga,
  createEditAdminSaga,
  getAdminsListSaga,
  getAdminProfileSaga,
  deleteAdminProfileSaga,
  changeStatusAdminSaga,
  getCountriesSaga,
} from '@/store/saga/admins';
import { getTagsSaga } from '@/store/saga/tags';
import { getDisputesListSaga } from '@/store/saga/disputes';
import {
  getMyOrganizationsListSaga,
  createNewOrganizationSaga,
  chooseMyOrganizationSaga,
  deleteOrganizationSaga,
} from '@/store/saga/multipleAccount';
import {
  getBusinessDetailsSaga,
  getGeneralAnalyticsSaga,
  getUsersAnalyticsSaga,
  getGiftsAnalyticsSaga,
  getGiftsBrandListSaga,
  getGiftSumListSaga,
  getTopBrandsSaga,
} from '@/store/saga/analytics';
import {
  getUsersUniversalCardsSaga,
  getUniversalCardsSaga,
  getUserUniversalCardSaga,
  addUniversalCardToUserSaga,
} from './credits';

export default function* rootSaga() {
  // Privacy and Terms
  yield takeEvery(privacyAndTermsActionTypes.GET_PRIVACY, getPrivacySaga);
  yield takeEvery(privacyAndTermsActionTypes.GET_TERMS, getTermsSaga);
  yield takeEvery(privacyAndTermsActionTypes.SEND_PRIVACY_AND_TERMS, sendPrivacyAndTermsSaga);
  // Auth
  yield takeEvery(authActionTypes.AUTH_GET_ME, getMe);
  yield takeEvery(authActionTypes.AUTH_USER, authSaga);
  yield takeEvery(authActionTypes.AUTH_LOGOUT, clearLocalStorageSaga);
  yield takeEvery(authActionTypes.REGISTER_BUSINESS, registerBusinessSaga);
  yield takeEvery(authActionTypes.BUSINESS_VERIFY, verifyBusinessSaga);
  yield takeEvery(authActionTypes.AUTH_RESET_PASS, resetPassSaga);
  yield takeEvery(authActionTypes.AUTH_CREATE_PASS, createPassSaga);
  yield takeEvery(authActionTypes.AUTH_CHANGE_PASS, recoveryPassSaga);
  yield takeEvery(authActionTypes.AUTH_UPDATE_FIRST_LOGIN, updateFirstLoginSaga);
  yield takeEvery(authActionTypes.AUTH_LOGIN_AS_ORGANIZATION, loginAsOrganizationSaga);
  yield takeEvery(authActionTypes.AUTH_LOGIN_AS_ADMIN, loginAsAdminSaga);
  yield takeEvery(authActionTypes.AUTH_RESEND_EMAIL, resendEmailSaga);
  yield takeEvery(authActionTypes.ADMIN_VERIFY, verifyAdminSaga);
  // SUPPORT
  yield takeEvery(supportActionTypes.SUPPORT_SEND, sendSupportSaga);
  // DIRECT GIFT
  yield takeEvery(directGiftActionTypes.DIRECT_GIFT_SEND, sendDirectGiftSaga);
  // CSV
  yield takeEvery(csvActionTypes.CSV_IMPORT_LOCATIONS, importLocationsSaga);
  yield takeEvery(csvActionTypes.CSV_SAVE_LOCATIONS, saveLocationsSaga);
  yield takeEvery(csvActionTypes.CSV_IMPORT_EMPLOYEES, importEmployeesSaga);
  yield takeEvery(csvActionTypes.CSV_SAVE_EMPLOYEES, saveEmployeesSaga);
  yield takeEvery(csvActionTypes.CSV_EXPORT_STATISTICS, exportCSVStatisticsSaga);
  // Gifts
  yield takeEvery(giftsTypes.GIFTS_CHART, getTotalChartSaga);
  yield takeLatest(giftsTypes.GIFTS_CARDS, getTotalStatisticCardsSaga);
  // Gifts list
  yield takeLatest(giftsListActionTypes.GET_GIFTS_LIST, getGiftsListSaga);
  // Gift
  yield takeEvery(giftTypes.CREATE_GIFT, createGiftSaga);
  yield takeEvery(giftTypes.GET_GIFT, getGiftSaga);
  yield takeEvery(giftTypes.UPDATE_GIFT, updateGiftSaga);
  yield takeEvery(giftTypes.DELETE_GIFT, deleteGiftSaga);
  yield takeEvery(giftTypes.CHANGE_QUANTITY, changeQuantitySaga);
  yield takeEvery(giftTypes.GIFT_DUPLICATE, duplicateGiftSaga);
  yield takeEvery(giftTypes.GIFT_GET_CHART, getGiftChartSaga);
  yield takeEvery(giftTypes.GIFT_GET_CARDS, getGiftCardsSaga);
  // Customers
  yield takeLatest(customersActionTypes.CUSTOMERS_GET_LIST, getCustomersListSaga);
  yield takeLatest(customersActionTypes.CUSTOMERS_GET_DETAIL, getCustomersDetailSaga);
  yield takeLatest(transactionsActionTypes.TRANSACTIONS_GET_LIST, getTransactionsListSaga);
  yield takeLatest(transactionsActionTypes.TRANSACTIONS_GET_LAST, getLastTransactionsSaga);
  yield takeLatest(transactionsActionTypes.UPDATE_STATUS_TRANSACTION, updateTransactionStatusSaga);
  // Organizations
  yield takeLatest(organizationsActionTypes.ORGANIZATIONS_BALANCE_CHART, getBalanceChartSaga);
  yield takeLatest(organizationsActionTypes.ORGANIZATIONS_BALANCE_CARDS, getTotalProfitCardsSaga);
  yield takeLatest(organizationsActionTypes.ORGANIZATIONS_GET_INFO, getOrganizationInfo);
  yield takeEvery(organizationsActionTypes.ORGANIZATIONS_PUT_INFO, putOrganizationInfo);
  yield takeEvery(organizationsActionTypes.UPLOAD_BUSINESS_LOGO, uploadBusinessLogoSaga);
  // Stripe
  yield takeEvery(stripeActionTypes.STRIPE_SET_TOKEN, setStripeTokenSaga);
  yield takeEvery(stripeActionTypes.STRIPE_CONNECT, setStripeConnectSaga);
  // Locations
  yield takeEvery(locationsActionTypes.LOCATIONS_ADD, addLocationSaga);
  yield takeEvery(locationsActionTypes.LOCATIONS_ADD_EMPLOYEES, addEmployeesToLocationSaga);
  yield takeEvery(locationsActionTypes.LOCATIONS_LIST, getLocationsListSaga);
  yield takeEvery(locationsActionTypes.LOCATIONS_DELETE, deleteLocationSaga);
  yield takeEvery(locationsActionTypes.LOCATIONS_EDIT, editLocationSaga);
  // Account
  yield takeEvery(accountsActionTypes.ACCOUNTS_PUT_MAIN_INFO, putMainInfoSaga);
  yield takeEvery(accountsActionTypes.ACCOUNTS_PUT_SECURITY, putSecuritySaga);
  yield takeEvery(accountsActionTypes.ACCOUNTS_GET_EMAIL_NOTIFICATIONS, getEmailNotificationsSaga);
  yield takeEvery(accountsActionTypes.ACCOUNTS_PUT_EMAIL_NOTIFICATIONS, putEmailNotificationsSaga);
  yield takeEvery(accountsActionTypes.ACCOUNTS_GET_BILLING, getBillingCardSaga);
  yield takeEvery(accountsActionTypes.ACCOUNTS_PUT_BILLING, changeStripeTokenSaga);
  yield takeEvery(accountsActionTypes.CHECK_NOTIFICATIONS, checkNotificationsSaga);
  yield takeEvery(accountsActionTypes.CHANGE_STRIPE_ACCOUNT, changeStripeAccountSaga);
  yield takeEvery(accountsActionTypes.CHANGE_SQUARE_ACCOUNT, changeSquareAccountSaga);
  // Categories
  yield takeLatest(categoriesActionTypes.GET_CATEGORIES, getCategoriesSaga);
  // Redemptions
  yield takeLatest(redemptionsActionTypes.REDEMPTIONS_INFO, redeemInfoSaga);
  yield takeLatest(redemptionsActionTypes.REDEMPTIONS_SUBMIT, redeemSubmitSaga);
  yield takeLatest(redemptionsActionTypes.REDEMPTIONS_GET_HISTORY, getRedemptionHistorySaga);
  yield takeLatest(redemptionsActionTypes.REDEMPTIONS_REFUND, refundRedemptionSaga);
  yield takeLatest(redemptionsActionTypes.GENERATE_REDEMPTION_CODE, generateRedemptionCodeSaga);
  yield takeLatest(
    redemptionsActionTypes.REDEMPTION_DOWNLOAD_QR_CODE,
    redemptionDownloadQrCodeSaga,
  );
  yield takeLatest(redemptionsActionTypes.REDEMPTION_DOWNLOAD_COSTER, redemptionDownloadСosterSaga);
  yield takeLatest(
    redemptionsActionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS,
    redemptionGetSecretSymbolsSaga,
  );
  yield takeLatest(
    redemptionsActionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL,
    redemptionSendSecretSymbolSaga,
  );
  yield takeLatest(redemptionsActionTypes.GET_REDEMPTIONS, getRedemptionsSaga);
  yield takeLatest(redemptionsActionTypes.REDEMPTIONS_CODE_INFO, getRedemptionsCodeInfoSaga);
  // Inbox
  yield takeEvery(inboxActionTypes.INBOX_GET_LIST, getInboxListSaga);
  // Employees
  yield takeEvery(employeesActionTypes.EMPLOYEES_GET_LIST, getEmployeesListSaga);
  yield takeEvery(employeesActionTypes.EMPLOYEES_DELETE, deleteEmployeeSaga);
  yield takeEvery(employeesActionTypes.EMPLOYEES_EDIT, editEmployeeSaga);
  // Admin organizations
  yield takeEvery(organizationsActionTypes.GET_ORGANIZATIONS, getOrganizationsSaga);
  yield takeEvery(organizationsActionTypes.GET_ARCHIVE, archiveBusinessSaga);
  yield takeEvery(organizationsActionTypes.CREATE_ORGANIZATION, createOrganizationSaga);
  yield takeEvery(organizationsActionTypes.SEND_INVITATION, sendContractSaga);
  yield takeEvery(organizationsActionTypes.RESEND_CONTRACT, resendContractSaga);
  // Users
  yield takeLatest(usersActionTypes.USERS_GET_LIST, getUsersListSaga);
  yield takeLatest(usersActionTypes.USERS_GET_DETAIL, getUsersDetailSaga);
  yield takeLatest(usersActionTypes.GET_BLOCK, blockUserSaga);
  yield takeLatest(usersActionTypes.USERS_GET_INVITED_LIST, getInvitedUsersSaga);
  yield takeLatest(usersActionTypes.USER_DOWNLOAD_QRCODE, usersDownloadQRCodeSaga);
  yield takeLatest(usersActionTypes.USER_GENERATE_QRCODE, usersGenerateQrCodeSaga);
  yield takeEvery(usersActionTypes.VERIFY_USER, verifyUserSaga);
  yield takeLatest(usersActionTypes.NOTIFY_USERS, notifyUsersSaga);
  yield takeLatest(usersActionTypes.GET_ONLINE_USERS_COUNT, getOnlineUsersCountSaga);
  yield takeLatest(usersActionTypes.CHANGE_ALTRUUS_BALANCE_STATUS, changeAltruusBalanceStatus);
  // Admin categories
  yield takeLatest(categoriesActionTypes.CATEGORIES_GET_LIST, getCategoriesListSaga);
  yield takeEvery(categoriesActionTypes.CREATE_CATEGORY, createCategorySaga);
  yield takeEvery(categoriesActionTypes.GET_CATEGORY_DETAIL, getCategoryDetailSaga);
  yield takeEvery(categoriesActionTypes.UPDATE_CATEGORY, updateCategorySaga);
  yield takeEvery(categoriesActionTypes.DELETE_CATEGORY, deleteCategorySaga);
  yield takeLatest(categoriesActionTypes.SUBCATEGORIES_GET_LIST, getSubCategoriesListSaga);
  // Admin tasty categories
  yield takeLatest(categoriesActionTypes.PROMO_GROUPS_GET_LIST, getPromoGroupsListSaga);
  yield takeEvery(categoriesActionTypes.CREATE_PROMO_GROUP, createPromoGroupSaga);
  yield takeEvery(categoriesActionTypes.GET_PROMO_GROUP_DETAIL, getPromoGroupSaga);
  yield takeEvery(categoriesActionTypes.UPDATE_PROMO_GROUP, createPromoGroupSaga);
  yield takeEvery(categoriesActionTypes.DELETE_PROMO_GROUP, deletePromoGroupSaga);
  // Admin gifts
  yield takeEvery(giftTypes.UPDATE_GIFT_STATUS, updateStatusGiftSaga);
  // Admin surveys
  yield takeLatest(surveysActionTypes.GET_SURVEYS_LIST, getSurveysListSaga);
  yield takeEvery(surveysActionTypes.GET_DETAIL_SURVEY, getSurveyDetailSaga);
  yield takeEvery(surveysActionTypes.CHANGE_STATUS_SURVEY, changeStatusSurveySaga);
  yield takeEvery(surveysActionTypes.CREATE_SURVEY, createSurveySaga);
  yield takeEvery(surveysActionTypes.EDIT_SURVEY, editSurveySaga);
  // Admin transactions
  yield takeEvery(transactionsActionTypes.GET_DETAIL_TRANSACTION, getDetailsTransactionSaga);
  // Admin templates
  yield takeEvery(organizationsActionTypes.GET_TEMPLATES, getTemplatesSaga);
  // Plans
  yield takeEvery(plansActionTypes.GET_PLANS, getPlansSaga);
  yield takeEvery(plansActionTypes.CHANGE_PLAN, changePlanSaga);
  // Language
  yield takeEvery(languageActionTypes.SET_LANGUAGE, setLanguageSaga);
  yield takeEvery(languageActionTypes.GET_EMAIL_LANG, getEmailLanguageSaga);
  // Admins
  yield takeEvery(adminsActionTypes.ADMINS_GET_PERMISSIONS, getAdminPermissionsSaga);
  yield takeEvery(adminsActionTypes.ADMINS_CREATE_EDIT, createEditAdminSaga);
  yield takeEvery(adminsActionTypes.ADMINS_GET_LIST, getAdminsListSaga);
  yield takeEvery(adminsActionTypes.ADMINS_GET_PROFILE, getAdminProfileSaga);
  yield takeEvery(adminsActionTypes.ADMINS_DELETE_PROFILE, deleteAdminProfileSaga);
  yield takeEvery(adminsActionTypes.ADMINS_CHANGE_STATUS, changeStatusAdminSaga);
  yield takeEvery(adminsActionTypes.ADMINS_GET_COUNTRIES, getCountriesSaga);
  // Square
  yield takeEvery(squareActionTypes.SQUARE_URL_CONNECT, getSquareUrlSaga);
  // Work hours
  yield takeEvery(workHoursActionTypes.GET_WORK_HOURS, getWorkHoursSaga);
  yield takeEvery(workHoursActionTypes.CHANGE_WORK_HOURS, editWorkHoursSaga);
  // Sharing
  yield takeEvery(sharingActionTypes.SHARING_GET_INFO, getSharingInfoSaga);
  yield takeEvery(sharingActionTypes.DOWNLOAD_SHARING_FILE, downloadQRCodeSharingSaga);
  // Categories
  yield takeEvery(tagsActionTypes.GET_TAGS, getTagsSaga);
  // Analytics
  yield takeEvery(analyticsActionTypes.ANALYTICS_GET_BUSINESS_DETAILS, getBusinessDetailsSaga);
  yield takeEvery(analyticsActionTypes.ANALYTICS_GENERAL, getGeneralAnalyticsSaga);
  yield takeEvery(analyticsActionTypes.ANALYTICS_USERS, getUsersAnalyticsSaga);
  yield takeEvery(analyticsActionTypes.ANALYTICS_GIFTS, getGiftsAnalyticsSaga);
  yield takeEvery(analyticsActionTypes.ANALYTICS_GET_SUM, getGiftSumListSaga);
  yield takeEvery(analyticsActionTypes.ANALYTICS_GET_BRANDS, getGiftsBrandListSaga);
  yield takeEvery(analyticsActionTypes.GET_TOP_BRANDS, getTopBrandsSaga);
  // Admin settings
  yield takeEvery(adminSettingsActionTypes.GET_NGC_STATUS, getNGCStatusSaga);
  yield takeEvery(adminSettingsActionTypes.ARCHIVE_ALL_NGC, archiveAllNGCSaga);
  yield takeEvery(adminSettingsActionTypes.GET_ADMINS_SETTINGS, getAdminSettingsSaga);
  yield takeEvery(adminSettingsActionTypes.UPDATE_ADMINS_SETTINGS, updateAdminSettingsSaga);
  yield takeEvery(adminSettingsActionTypes.GET_ADMIN_NOTIFICATIONS, getAdminNotificationsSaga);
  yield takeEvery(
    adminSettingsActionTypes.UPDATE_ADMIN_NOTIFICATIONS,
    updateAdminNotificationsSaga,
  );
  yield takeEvery(
    adminSettingsActionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS,
    updateToCurrentUsersSaga,
  );
  yield takeEvery(adminSettingsActionTypes.REINDEX_ALGOLIA, reindexAlgoliaSaga);
  // Disputes
  yield takeEvery(disputesActionTypes.DISPUTES_GET_LIST, getDisputesListSaga);
  // Multiple account
  yield takeLatest(
    multipleAccountsActionTypes.GET_MY_ORGANIZATIONS_LIST,
    getMyOrganizationsListSaga,
  );
  yield takeLatest(multipleAccountsActionTypes.CREATE_NEW_ORGANIZATION, createNewOrganizationSaga);
  yield takeLatest(multipleAccountsActionTypes.CHOOSE_MY_ORGANIZATION, chooseMyOrganizationSaga);
  yield takeLatest(multipleAccountsActionTypes.DELETE_ORGANIZATION, deleteOrganizationSaga);
  // Credits
  yield takeLatest(creditsActionTypes.GET_USERS_UNIVERSAL_CARDS, getUsersUniversalCardsSaga);
  yield takeLatest(creditsActionTypes.GET_UNIVERSAL_CARDS, getUniversalCardsSaga);
  yield takeLatest(creditsActionTypes.GET_USER_UNIVERSAL_CARD, getUserUniversalCardSaga);
  yield takeLatest(creditsActionTypes.ADD_UNIVERSAL_CARD_TO_USER, addUniversalCardToUserSaga);

  yield call(authCheckLocalStorageSaga);
}
