import { ORGANIZATION_STATUS_ACTIVE } from '@/constants/organizations';

export const getMainInformation = data => {
  return {
    full_name: data.full_name,
    email: data.email,
    percent: data.percent,
    location: JSON.stringify(data.location),
    business_name: data.business_name,
  };
};

export const formatOrganizations = items => {
  if (items) {
    return items.map(item => {
      return {
        label: item.name,
        value: item.id,
        id: item.id,
        key: item.id,
      };
    });
  }
  return [];
};

export const checkIsStripeVerified = (requirements, checkOnDev = true) => {
  if (checkOnDev || process.env.REACT_APP_ENV === 'production') {
    return !requirements?.currently_due?.length && !requirements?.disabled_reason;
  }

  return true;
};

export const checkIsPaymentVerified = (checkInfo, status = '') => {
  if (checkInfo.square_connect || status === ORGANIZATION_STATUS_ACTIVE) {
    return true;
  }
  return checkIsStripeVerified(checkInfo?.stripe_connect_requirements);
};

export const checkIsPaymentNeedDocuments = (checkInfo, status = '') => {
  if (checkInfo.square_connect) {
    return false;
  }

  return (
    status === ORGANIZATION_STATUS_ACTIVE &&
    checkInfo?.stripe_connect_requirements?.currently_due?.length > 0
  );
};

export const checkPaymentAccountProblem = (checkInfo, isAdmin) => {
  if (isAdmin || (!checkInfo.stripe_connect && !checkInfo.square_connect)) return false;
  if (checkInfo?.square_connect) return false;
  if (checkInfo && !checkInfo.stripe_connect_is_active) {
    return true;
  }
};
