import {
  ROUTE_DETAIL_GIFT,
  ROUTE_DETAIL_ORGANIZATION,
  ROUTE_DETAILS_USER,
  ROUTE_EDIT_CATEGORY,
  excludedBreadcrumps,
} from '@/constants/routes';

const getLink = (route, params = {}) => {
  let updatedRoute = route;
  Object.keys(params).forEach(param => {
    updatedRoute = updatedRoute.replace(`:${param}`, params[param]);
  });

  return updatedRoute;
};

export const checkRoles = (roles, userRole) => {
  if (!roles) {
    return true;
  }
  return roles.includes(userRole);
};

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const filterForRoutes = route => {
  const isExcluded = excludedBreadcrumps.includes(route);
  return route && !isExcluded;
};

export const getOrganizationLink = id => {
  return getLink(ROUTE_DETAIL_ORGANIZATION, { organizationId: id });
};

export const getGiftLink = id => {
  return getLink(ROUTE_DETAIL_GIFT, { giftId: id });
};

export const getCategoryLink = id => {
  return getLink(ROUTE_EDIT_CATEGORY, { categoryId: id });
};

export const getUserLink = id => {
  return getLink(ROUTE_DETAILS_USER, { userId: id });
};

export default getLink;
