import * as actionTypes from './types/stripe';

export const setStripeToken = params => {
  return {
    type: actionTypes.STRIPE_SET_TOKEN,
    payload: params,
  };
};

export const setStripeConnect = params => {
  return {
    type: actionTypes.STRIPE_CONNECT,
    payload: params,
  };
};
