import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { t, getCurrentLang, changeLang } from '@/locale/i18n';
import { ROLE_MERCHANDISER } from '@/constants/roles';
import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import history from '@/utility/common/history';
import { parseJwt } from '@/utility/common/auth';
import { bugsnagClient, bugsnagSetMetaData } from '@/utility/common/bugsnagClient';
import * as actionTypes from '@/store/actions/types/auth';
import * as actionOrganizationTypes from '@/store/actions/types/organizations';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { setAuthorizationToken } from '@/store/configDefaultAPI';
import getLink from '@/utility/common/routes';
import { getMyOrganizationsListSaga } from '@/store/saga/multipleAccount';
import getDeviceId from '@/store/saga/common';

function* setLocalSaga(token, tokenType, login_as_organization, role) {
  yield localStorage.setItem('token', token);
  yield localStorage.setItem('token_type', tokenType);
  yield localStorage.setItem('login_as_organization', login_as_organization);
  yield localStorage.setItem('role', role);
}

export function* clearLocalStorageSaga() {
  yield localStorage.removeItem('token');
  yield localStorage.removeItem('token_type');
  yield localStorage.removeItem('giftId');
  yield localStorage.removeItem('admin_id');
  yield localStorage.removeItem('login_as_organization');
  yield localStorage.removeItem('role');
  yield localStorage.removeItem('device_id');
  yield call(setAuthorizationToken, null);
  bugsnagClient.user = {};
}

export function* setAuth(data, needChangeLang = true) {
  if (needChangeLang) {
    yield call(changeLang, data.user.language);
  }
  yield call(setAuthorizationToken, data.token, data.token_type);
  yield call(setLocalSaga, data.token, data.token_type, data.login_as_organization, data.user.role);
}

export function* getMe() {
  const token = yield localStorage.getItem('token');
  if (token) {
    try {
      const deviceId = yield getDeviceId();
      const res = yield axios.get(`${process.env.REACT_APP_BASE_API}${endpoints.GET_AUTH_USER}`, {
        params: {
          device_id: deviceId,
        },
      });
      const { data } = res;
      let organization = {};
      let check_info = {};
      yield call(getMyOrganizationsListSaga, { payload: { userId: data.user.id } });
      if (data.user.role === ROLE_MERCHANDISER) {
        organization = data.organization;
        check_info = data.check_info;
      }

      yield put({
        type: actionTypes.AUTH_SUCCESS,
        payload: {
          user: data.user,
          role: data.user.role,
          organization,
          check_info,
        },
      });

      if (data.user.role === ROLE_MERCHANDISER) {
        yield put({
          type: actionOrganizationTypes.ORGANIZATIONS_GET_INFO_SUCCESS,
          payload: {
            data: organization,
          },
        });

        bugsnagSetMetaData('organization', {
          id: organization.id,
          country: organization.country,
          name: organization.name,
          email: organization.email,
        });
      }

      bugsnagClient.user = {
        id: data.user.id,
        name: data.user.username,
        role: data.user.role,
        email: data.user.email,
      };
    } catch (error) {
      yield put({
        type: PUSH_NOTIFY,
        payload: {
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        },
      });
    }
  }
}

export function* authSaga(action) {
  yield put({ type: actionTypes.AUTH_START });
  const url = `${endpoints.LOGIN}?role_id=3`;
  const body = {
    login: action.payload.email,
    password: action.payload.password,
  };
  yield getDeviceId();
  try {
    const res = yield axios.post(url, body);
    const { data } = res;

    yield call(setAuth, data);
    yield call(getMe);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.AUTH_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* registerBusinessSaga(action) {
  yield put({ type: actionTypes.AUTH_START });

  const url = endpoints.REGISTER_BUSINESS;
  const language = getCurrentLang();
  const { data, onSuccess } = action.payload;

  try {
    yield axios.post(url, { ...data, language });

    onSuccess();

    yield put({
      type: actionTypes.REGISTER_BUSINESS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REGISTER_BUSINESS_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

function* checkTokenExpirationDate() {
  const token = yield localStorage.getItem('token');
  const tokenType = yield localStorage.getItem('token_type');
  const parsedToken = yield parseJwt(token);

  // Check token expiration
  if (parsedToken.exp && parsedToken.exp > Math.floor(Date.now() / 1000)) {
    yield call(setAuthorizationToken, token, tokenType);
    yield call(getMe);
  } else {
    yield put({ type: actionTypes.AUTH_LOGOUT });
  }
}

export function* authCheckLocalStorageSaga() {
  if (!localStorage) {
    return;
  }

  const token = yield localStorage.getItem('token');

  if (token) {
    try {
      yield call(checkTokenExpirationDate);
    } catch (error) {
      yield put({
        type: PUSH_NOTIFY,
        payload: {
          message: getErrorMessage(error),
          options: {
            variant: 'error',
          },
        },
      });
      yield put({ type: actionTypes.AUTH_LOGOUT });
    }
  }
}

export function* verifyBusinessSaga(action) {
  yield put({ type: actionTypes.AUTH_START });
  const url = endpoints.BUSINESS_VERIFY;

  try {
    yield axios.post(url, action.payload);

    yield put({
      type: actionTypes.BUSINESS_VERIFY_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.BUSINESS_VERIFY_FAIL,
      payload: getErrorMessage(error),
    });
  }
}

export function* resetPassSaga(action) {
  yield put({ type: actionTypes.AUTH_START });
  const url = endpoints.PASS_RESET;
  try {
    yield axios.post(url, action.payload);
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.resetPassSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
    yield call(history.push, '/');
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
  }
  yield put({
    type: actionTypes.AUTH_RESOLVE,
  });
}

export function* recoveryPassSaga(action) {
  yield put({ type: actionTypes.AUTH_START });
  const url = endpoints.PASS_RECOVERY;
  try {
    yield axios.post(url, action.payload.data);
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.passSuccessSet'),
        options: {
          variant: 'success',
        },
      },
    });
    yield call(history.push, '/');
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
  }
  yield put({
    type: actionTypes.AUTH_RESOLVE,
  });
}

export function* createPassSaga(action) {
  yield put({ type: actionTypes.AUTH_START });

  const url = endpoints.PASS_CREATE;

  try {
    const res = yield axios.post(url, action.payload.data);
    const { data } = res;

    yield call(setAuth, data);
    yield call(getMe);
  } catch (error) {
    let errorMessage = error.message;
    if (error.response && error.response.data.message) {
      errorMessage = error.response.data.message;
    }
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: errorMessage,
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.AUTH_FAIL,
      payload: { errorMessage },
    });
  }
}

export function* updateFirstLoginSaga() {
  const url = endpoints.FIRST_LOGIN;

  try {
    yield axios.post(url, { status: false });
    yield put({
      type: actionTypes.AUTH_UPDATE_FIRST_LOGIN_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
  }
}

export function* loginAsOrganizationSaga(action) {
  yield put({ type: actionTypes.AUTH_START });

  const { organizationId, adminId, callBack } = action.payload;

  const url = getLink(endpoints.LOGIN_AS_ORGANIZATION, { id: organizationId });
  const deviceId = yield getDeviceId();
  try {
    const { data } = yield axios.get(url, { params: { deviceId } });

    yield call(setAuth, data, false);
    yield localStorage.setItem('admin_id', adminId);
    yield localStorage.setItem('login_as_organization', data.login_as_organization);
    yield call(getMe);

    yield put({
      type: actionTypes.AUTH_LOGIN_AS_ORGANIZATION_SUCCESS,
      payload: data.login_as_organization,
    });

    callBack();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });

    yield put({
      type: actionTypes.AUTH_LOGIN_AS_ORGANIZATION_FAIL,
    });
  }
}

export function* loginAsAdminSaga(action) {
  yield put({ type: actionTypes.AUTH_START });

  const { callBack } = action.payload;
  const adminId = yield localStorage.getItem('admin_id');
  const login_as_organization = yield localStorage.getItem('login_as_organization');

  const url = getLink(endpoints.LOGIN_AS_ADMIN, { id: +adminId });

  try {
    const { data } = yield axios.get(url, { params: { login_as_organization } });

    yield call(setAuth, data);
    yield call(getMe);

    yield put({
      type: actionTypes.AUTH_LOGIN_AS_ADMIN_SUCCESS,
      payload: null,
    });

    callBack();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });

    yield put({
      type: actionTypes.AUTH_LOGIN_AS_ADMIN_FAIL,
    });
  }
}

export function* resendEmailSaga(action) {
  yield put({ type: actionTypes.AUTH_RESEND_EMAIL_START });

  const { requestData } = action.payload;

  const url = getLink(endpoints.RESEND_EMAIL);

  try {
    yield axios.post(url, requestData);

    yield put({
      type: actionTypes.AUTH_RESEND_EMAIL_SUCCESS,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.resendEmail'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });

    yield put({
      type: actionTypes.AUTH_RESEND_EMAIL_FAIL,
      payload: {
        errorMessage: error,
      },
    });
  }
}

export function* verifyAdminSaga(action) {
  yield put({ type: actionTypes.AUTH_START });

  const url = endpoints.ADMIN_VERIFY;

  try {
    const { data } = yield axios.post(url, action.payload);

    yield call(setAuth, data);
    yield call(getMe);

    yield put({
      type: actionTypes.ADMIN_VERIFY_SUCCESS,
      payload: {
        user: data.user,
        organization: {},
        check_info: {},
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ADMIN_VERIFY_FAIL,
      payload: getErrorMessage(error),
    });
  }
}
