import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import { t } from '@/locale/i18n';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/workHours';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* getWorkHoursSaga(action) {
  yield put({ type: actionTypes.GET_WORK_HOURS_START });

  const { organizationId, locationId } = action.payload;
  const url = getLink(endpoints.GET_WORK_HOURS, { organizationId, locationId });

  try {
    const res = yield axios.get(url);
    const { data } = res;
    yield put({
      type: actionTypes.GET_WORK_HOURS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_WORK_HOURS_FAIL,
    });
  }
}

export function* editWorkHoursSaga(action) {
  yield put({ type: actionTypes.CHANGE_WORK_HOURS_START });

  const { requestData, organizationId, locationId, onClose } = action.payload;
  const url = getLink(endpoints.GET_WORK_HOURS, { organizationId, locationId });

  try {
    const res = yield axios.put(url, requestData);
    const { data } = res;

    yield put({
      type: actionTypes.CHANGE_WORK_HOURS_SUCCESS,
      payload: { data },
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('common.days.messages.edit'),
        options: {
          variant: 'success',
        },
      },
    });
    onClose();

    yield call(getWorkHoursSaga, { payload: { organizationId, locationId } });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CHANGE_WORK_HOURS_FAIL,
    });
  }
}
export default getWorkHoursSaga;
