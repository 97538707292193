import axios from 'axios';
import { put } from 'redux-saga/effects';

import { ROLE_MERCHANDISER } from '@/constants/roles';
import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/giftsList';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* getGiftsListSaga(action) {
  const { role, id, reset, nextPage, ...params } = action.payload;
  const isSearch = !!params.search?.length;
  yield put({ type: actionTypes.GET_GIFTS_LIST_START, payload: { isSearch } });
  let url;

  if (role === ROLE_MERCHANDISER) {
    url = getLink(endpoints.GIFTS_LIST, { id });
  } else {
    url = getLink(endpoints.GIFTS);
  }

  try {
    const res = yield axios.get(url, { params });
    const { data, meta } = res.data;

    yield put({
      type: isSearch
        ? actionTypes.GET_GIFTS_LIST_SUCCESS_SEARCH
        : actionTypes.GET_GIFTS_LIST_SUCCESS,
      payload: {
        data,
        meta,
        reset,
        nextPage,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
  } finally {
    yield put({
      type: actionTypes.GET_GIFTS_LIST_FAIL,
    });
  }
}

export default getGiftsListSaga;
