import {
  ROUTE_OVERVIEW,
  ROUTE_GIFTS,
  ROUTE_BALANCE,
  ROUTE_CUSTOMERS,
  ROUTE_ORGANIZATION,
  ROUTE_REDEMPTIONS,
  ROUTE_HELP,
  ROUTE_ORGANIZATIONS,
  ROUTE_USERS,
  ROUTE_CATEGORIES,
  ROUTE_SURVEYS,
  ROUTE_TRANSACTIONS_LIST,
  ROUTE_ADMINS,
  ROUTE_SHARING,
  ROUTE_MY_PROFILE,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS,
  ROUTE_MERCH_AGREEMENT,
  ROUTE_ADMIN_REDEMPTIONS,
  SETTINGS,
  ROUTE_NOTIFY_USERS,
  ROUTE_ANALYTICS,
  ROUTE_UNIVERSAL_CARDS,
} from './routes';
import * as endpoints from '@/constants/api';

// Menu merchandise
export const menuList = [
  { label: 'common.menu.Redemptions', route: ROUTE_REDEMPTIONS, icon: 'icon-redemptions' },
  { label: 'common.menu.Overview', route: ROUTE_OVERVIEW, icon: 'icon-overview' },
  { label: 'common.menu.Gifts', route: ROUTE_GIFTS, icon: 'icon-gifts' },
  { label: 'common.menu.Balance', route: ROUTE_BALANCE, icon: 'icon-balance' },
  {
    label: 'common.menu.billing',
    route: `${ROUTE_MY_PROFILE}#billing`,
    icon: 'icon-transactions',
  },
  { label: 'common.menu.Customers', route: ROUTE_CUSTOMERS, icon: 'icon-customers' },
  {
    label: 'common.menu.Organization',
    route: ROUTE_ORGANIZATION,
    icon: 'icon-organizations',
  },
  {
    label: 'common.menu.sharing',
    route: ROUTE_SHARING,
    icon: 'icon-sharing',
  },
  {
    label: 'common.menu.faqs',
    url: `${process.env.REACT_APP_BASE_API}${endpoints.FAQ_DOWNLOAD}`,
    icon: 'icon-doc',
  },
];
export const utilityList = [
  { label: 'common.menu.Help', route: ROUTE_HELP, icon: 'icon-overview', target: '_self' },
  {
    label: 'common.menu.privacyPolicy',
    route: ROUTE_PRIVACY_POLICY,
    icon: 'icon-privacy-policy',
    target: '_blank',
  },
  { label: 'common.menu.userTerms', route: ROUTE_TERMS, icon: 'icon-user-terms', target: '_blank' },
  {
    label: 'common.menu.merchAgreement',
    route: ROUTE_MERCH_AGREEMENT,
    icon: 'icon-merch-agreement',
    target: '_blank',
  },
];

// Menu admin
export const menuListAdmin = [
  { label: 'common.menu.Overview', route: ROUTE_OVERVIEW, icon: 'icon-overview' },
  {
    label: 'common.menu.Organizations',
    route: ROUTE_ORGANIZATIONS,
    icon: 'icon-organizations-admin',
  },
  { label: 'common.menu.Users', route: ROUTE_USERS, icon: 'icon-users' },
  { label: 'common.menu.Gifts', route: ROUTE_GIFTS, icon: 'icon-gifts' },
  { label: 'common.menu.Surveys', route: ROUTE_SURVEYS, icon: 'icon-surveys' },
  { label: 'common.menu.Categories', route: ROUTE_CATEGORIES, icon: 'icon-categories' },
  { label: 'common.menu.Redemptions', route: ROUTE_ADMIN_REDEMPTIONS, icon: 'icon-redemptions' },
  {
    label: 'common.menu.Transactions',
    route: ROUTE_TRANSACTIONS_LIST,
    icon: 'icon-transactions',
  },
  {
    label: 'common.menu.NotifyUsers',
    route: ROUTE_NOTIFY_USERS,
    icon: 'icon-people',
  },
  {
    label: 'common.menu.UniversalCards',
    route: ROUTE_UNIVERSAL_CARDS,
    icon: 'icon-redo2',
  },
  {
    label: 'common.menu.analytics',
    route: ROUTE_ANALYTICS,
    icon: 'icon-stats-bars',
  },
  {
    label: 'common.settings',
    route: SETTINGS,
    icon: 'icon-cogs',
  },
  {
    label: 'common.menu.admins',
    route: ROUTE_ADMINS,
    icon: 'icon-customers',
  },
  {
    label: 'common.menu.sharing',
    route: ROUTE_SHARING,
    icon: 'icon-sharing',
  },
  {
    label: 'common.menu.faqs',
    url: `${process.env.REACT_APP_BASE_API}${endpoints.FAQ_DOWNLOAD}`,
    icon: 'icon-doc',
  },
];

// Menu subadmin
export const menuListSubAdmin = [
  { label: 'common.menu.Overview', route: ROUTE_OVERVIEW, icon: 'icon-overview' },
  {
    label: 'common.menu.Organizations',
    route: ROUTE_ORGANIZATIONS,
    icon: 'icon-organizations-admin',
  },
  { label: 'common.menu.Users', route: ROUTE_USERS, icon: 'icon-users' },
  { label: 'common.menu.Gifts', route: ROUTE_GIFTS, icon: 'icon-gifts' },
  { label: 'common.menu.Surveys', route: ROUTE_SURVEYS, icon: 'icon-surveys' },
  { label: 'common.menu.Categories', route: ROUTE_CATEGORIES, icon: 'icon-categories' },
  {
    label: 'common.menu.Transactions',
    route: ROUTE_TRANSACTIONS_LIST,
    icon: 'icon-transactions',
  },
  {
    label: 'common.menu.analytics',
    route: ROUTE_ANALYTICS,
    icon: 'icon-stats-bars',
  },
  {
    label: 'common.menu.NotifyUsers',
    route: ROUTE_NOTIFY_USERS,
    icon: 'icon-people',
  },
  {
    label: 'common.menu.UniversalCards',
    route: ROUTE_UNIVERSAL_CARDS,
    icon: 'icon-redo2',
  },
  {
    label: 'common.menu.sharing',
    route: ROUTE_SHARING,
    icon: 'icon-sharing',
  },
  {
    label: 'common.menu.faqs',
    url: `${process.env.REACT_APP_BASE_API}${endpoints.FAQ_DOWNLOAD}`,
    icon: 'icon-doc',
  },
];
