import { useEffect, useRef, useState } from 'react';
import { getCroppedImgV2 } from '@/utility/media/media';

const checkRecommendedSize = (img, heightArray, widthArray) => {
  const indexOfAlignment = widthArray.indexOf(img.width);
  return indexOfAlignment > -1 && heightArray[indexOfAlignment] === img.height;
};

const checkRecommendedRatio = (img, ratio) => {
  const imgAspectRatio = img.width / img.height;
  const roundedImgRatio = Math.round((imgAspectRatio - Number.EPSILON) * 100) / 100;
  const roundedRatio = Math.round((ratio + Number.EPSILON) * 100) / 100;
  const ratioDiff = roundedImgRatio - roundedRatio;

  return Math.abs(ratioDiff) <= 0.1;
};

export const useImagePreview = ({
  data,
  recommendHeight,
  recommendWidth,
  cropAspect,
  cropWidth,
  cropHeight,
  onCropImage,
}) => {
  const [isRecommendedSize, setIsRecommendedSize] = useState(false);
  const [isCropped, setIsCropped] = useState(false);
  const cropEl = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = data.src;

    img.onload = () => {
      const isRecommendedSizeValue = checkRecommendedSize(img, recommendHeight, recommendWidth);
      const isRecommendedRatio = checkRecommendedRatio(img, cropAspect);

      if (isRecommendedSizeValue || isRecommendedRatio) {
        setIsRecommendedSize(true);
      }
    };
  }, []);

  const cropImage = () => {
    const croppedCanvas = cropEl.current?.getCroppedCanvas({
      width: cropWidth,
      height: cropHeight,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    });

    if (typeof croppedCanvas === 'undefined' || !croppedCanvas) {
      return;
    }

    const croppedImage = getCroppedImgV2(croppedCanvas.toDataURL(data.type), data.name);

    onCropImage(croppedImage);
    setIsCropped(true);
  };

  const handleAspectRatio = type => {
    if (!cropEl || !cropEl.current) return;

    const handleSquareSize = isRecommended => {
      if (isRecommended) {
        setIsRecommendedSize(true);
      } else {
        cropEl.current.setAspectRatio(1);
      }
    };

    if (type === 'square') {
      const img = new Image();
      img.src = data.src;
      img.onload = () => {
        const isRecommendedRatio = checkRecommendedRatio(img, 1);

        handleSquareSize(isRecommendedRatio);
      };
      cropEl.current.setAspectRatio(1);
    } else {
      cropEl.current.setAspectRatio(cropAspect);
    }
  };

  const isCropper = data.type && !isRecommendedSize && !isCropped;

  return {
    isCropped,
    isCropper,
    isRecommendedSize,
    cropEl,
    cropImage,
    handleAspectRatio,
  };
};

export default useImagePreview;
