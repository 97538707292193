import styled from 'styled-components';
import Select from 'react-select';

import colors from '@/constants/colors';
import { emphasize, makeStyles } from '@material-ui/core/styles';

export const SelectStyled = styled(Select)`
  && {
    .MuiInputLabel-formControl {
      right: 0;
    }
    .MuiTypography-root {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 5px 0 15px 0;
  color: ${colors.dropZone.error};
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LabelAddText = styled.span`
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${colors.labelAddText};
`;

export const useStyles = makeStyles(theme => ({
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding: '0.8rem 0.8rem 0.8rem 1.8rem',
  },
  Input: {
    display: 'none',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
    // wordBreak: 'break-all',
    height: 'auto',
    '& > span': {
      whiteSpace: 'normal',
    },
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
    fontWeight: 400,
  },
  placeholder: {
    position: 'absolute',
    color: colors.inputPlaceholder,
    left: 20,
    bottom: 12,
    fontSize: 16,
    fontWeight: 400,
  },
  paper: {
    position: 'absolute',
    right: 0,
    left: 0,
    padding: 0,
    zIndex: 1,
    marginTop: -30,
  },
  divider: {
    height: theme.spacing(2),
  },
}));
