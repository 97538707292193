import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/users';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { t } from '@/locale/i18n';

export function* verifyUserSaga({ payload }) {
  yield put({ type: actionTypes.VERIFY_USER_START, payload });

  try {
    yield axios.post(endpoints.VERIFY_USER, payload);

    yield put({
      type: actionTypes.VERIFY_USER_SUCCESS,
      payload,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: payload.is_verified ? t('users.modal.confirm') : t('users.modal.block'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.VERIFY_USER_FAIL,
      payload,
    });
  }
}

export function* getUsersListSaga(action) {
  yield put({ type: actionTypes.USERS_GET_LIST_START });

  const { search, ...params } = action.payload;
  const isGetAll = params.all;
  const url = search ? getLink(endpoints.GET_USERS_SEARCH, { search }) : endpoints.GET_USERS_LIST;

  try {
    const res = yield axios.get(url, { params: { ...params, search } });
    const { data } = res;

    yield put({
      type: actionTypes.USERS_GET_LIST_SUCCESS,
      payload: isGetAll ? { data } : data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.USERS_GET_LIST_FAIL,
    });
  }
}

export function* changeAltruusBalanceStatus(action) {
  yield put({ type: actionTypes.CHANGE_ALTRUUS_BALANCE_STATUS_START });
  const { ...params } = action.payload;
  const url = endpoints.CHANGE_VIRTUAL_STATUS;
  try {
    yield axios.post(url, { ...params });
    yield put({
      type: actionTypes.CHANGE_ALTRUUS_BALANCE_STATUS_SUCCESS,
      payload: {
        credit_balance_status: params.active,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({ type: actionTypes.CHANGE_ALTRUUS_BALANCE_STATUS_FAIL });
  }
}

export function* getUsersDetailSaga(action) {
  yield put({ type: actionTypes.USERS_GET_DETAIL_START });

  const { ...params } = action.payload;
  const url = getLink(endpoints.GET_USERS_DETAIL, { ...params });

  try {
    const res = yield axios.get(url, { ...params });
    const { data } = res;

    yield put({
      type: actionTypes.USERS_GET_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.USERS_GET_DETAIL_FAIL,
    });
  }
}

export function* blockUserSaga(action) {
  yield put({ type: actionTypes.GET_BLOCK_START });

  const { userId, status } = action.payload;
  let url;

  if (status !== 'registered') {
    url = getLink(endpoints.UNBLOCK_USER, { userId });
  } else {
    url = getLink(endpoints.BLOCK_USER, { userId });
  }

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_BLOCK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_BLOCK_FAIL,
    });
  }
}

export function* getInvitedUsersSaga(action) {
  yield put({ type: actionTypes.USERS_GET_INVITED_LIST_START });

  const { userId, ...params } = action.payload;
  const url = getLink(endpoints.INVITED_USERS, { userId });

  try {
    const { data } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.USERS_GET_INVITED_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.USERS_GET_INVITED_LIST_FAIL,
    });
  }
}

export function* usersDownloadQRCodeSaga(action) {
  yield put({ type: actionTypes.USER_DOWNLOAD_QRCODE_START });

  const { userId, type, language, name, callback } = action.payload;
  const url = getLink(endpoints.USER_DOWNLOAD_QR_CODE, { userId });

  try {
    const { data } = yield axios.get(url, {
      responseType: 'blob',
      params: { type, language },
    });

    const timestamp = Math.floor(Date.now() / 1000);
    const fileName = `${name}${timestamp}`;
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(data);
    link.download = `${fileName}.${type}`;
    link.click();

    yield put({
      type: actionTypes.USER_DOWNLOAD_QRCODE_SUCCESS,
    });
    callback();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.USER_DOWNLOAD_QRCODE_FAIL,
    });
  }
}

export function* usersGenerateQrCodeSaga(action) {
  yield put({ type: actionTypes.USER_GENERATE_QRCODE_START });

  const { userId, ...params } = action.payload;
  const url = getLink(endpoints.USER_GENERATE_QR_CODE, { userId });

  try {
    yield axios.get(url, { params });

    yield put({
      type: actionTypes.USER_GENERATE_QRCODE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.USER_GENERATE_QRCODE_FAIL,
    });
  }
}

export function* getOnlineUsersCountSaga() {
  yield put({ type: actionTypes.GET_ONLINE_USERS_COUNT_START });

  const url = getLink(endpoints.ONLINE_USER_COUNT);

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_ONLINE_USERS_COUNT_SUCCESS,
      payload: {
        count: data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_ONLINE_USERS_COUNT_FAIL,
    });
  }
}

export function* notifyUsersSaga(action) {
  yield put({ type: actionTypes.NOTIFY_USERS_START });

  const url = getLink(endpoints.SENT_MULTIPLE_NOTIFICATIONS);

  try {
    yield axios.post(url, { ...action.payload });

    yield put({
      type: actionTypes.NOTIFY_USERS_SUCCESS,
    });
    if (action.payload.resetForm) {
      yield action.payload.resetForm();
    }
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notifyUsers.message'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.NOTIFY_USERS_FAIL,
    });
  }
}
