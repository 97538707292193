// User roles
import { ROUTE_OVERVIEW, ROUTE_REDEMPTIONS } from '@/constants/routes';

export const ROLE_ADMIN = 'admin';
export const ROLE_SUB_ADMIN = 'subadmin';
export const ROLE_MERCHANDISER = 'merchandiser';
export const ROLE_USER = 'user';

// Countries roles
export const ROLE_USA = 'USA';
export const ROLE_MEXICO = 'Mexico';
export const ROLE_SPAIN = 'Spain';
export const ROLE_ALL_COUNTRIES = 'all-countries';

export const roleMainPage = {
  [ROLE_ADMIN]: ROUTE_OVERVIEW,
  [ROLE_SUB_ADMIN]: ROUTE_OVERVIEW,
  [ROLE_MERCHANDISER]: ROUTE_REDEMPTIONS,
  [ROLE_USER]: ROUTE_OVERVIEW,
};

export const rolePermissions = {
  [ROLE_ADMIN]: {
    loginAsBusiness: true,
    editBusiness: true,
    archiveBusiness: true,
    viewOrganization: true,
    viewOrganizationDetails: true,
  },
  [ROLE_SUB_ADMIN]: {
    loginAsBusiness: true,
    editBusiness: true,
    archiveBusiness: true,
    viewOrganization: true,
    viewOrganizationDetails: true,
  },
  [ROLE_MERCHANDISER]: {
    editBusiness: true,
    viewOrganizationDetails: true,
  },
  [ROLE_USER]: {},
};
