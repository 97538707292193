import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import * as actionTypes from '@/store/actions/types/tags';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import getLink from '@/utility/common/routes';

export function* getTagsSaga(action) {
  yield put({ type: actionTypes.TAGS_START });

  const search = action.payload;
  const url = search ? getLink(`${endpoints.GET_TAGS}/${search}`) : endpoints.GET_TAGS;

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_TAGS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_TAGS_FAIL,
    });
  }
}

export default getTagsSaga;
