import * as Yup from 'yup';
import axios from 'axios';
import { USER_CHECK_EMAIL } from '@/constants/api';

export default t => {
  let prevEmail;

  return {
    all_countries: Yup.boolean(),
    username: Yup.string().required(t('validation.userNameRequired')),
    full_name: Yup.string().required(t('validation.fullNameRequired')),
    email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid'))
      .test('email', t('validation.emailsAlreadyTaken'), email => {
        const schema = Yup.string().email();

        if (schema.isValidSync(email) && prevEmail !== email) {
          prevEmail = email;
          return axios
            .get(`${USER_CHECK_EMAIL}/${email}?role_id=5`)
            .then(res => !res.data.exist)
            .catch(() => true);
        }
        return true;
      }),
    language: Yup.string().required(t('validation.languageRequired')),
    role: Yup.string,
    countries: Yup.array().when('all_countries', {
      is: all_countries => all_countries === true,
      then: Yup.array(),
      otherwise: Yup.array().required('validation.permissionsRequired'),
    }),
  };
};
