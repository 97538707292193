import * as actionTypes from '@/store/actions/types/csv';

const initialState = {
  locations: [],
  locationsError: [],
  savedLocation: [],
  saved: false,
  employees: [],
  employeesError: [],
  savedEmployees: [],
  isSavedEmployees: false,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CSV_START:
      return { ...state, loading: true };
    case actionTypes.CSV_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CSV_IMPORT_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        locations: action.payload.locations,
        locationsError: action.payload.errors ? action.payload.errors.import_file : [],
      };
    case actionTypes.CSV_SAVE_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        savedLocation: action.payload,
        saved: true,
      };
    case actionTypes.CSV_IMPORT_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload.success.locations[0].location.users,
        employeesError: action.payload.errors ? action.payload.errors.users : [],
      };
    case actionTypes.CSV_SAVE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        savedEmployees: action.payload,
        isSavedEmployees: true,
      };

    case actionTypes.CSV_EXPORT_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CSV_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
