import * as Yup from 'yup';
import { phoneRegEx } from '@/constants/regexp';
import axios from 'axios';
import { PROFILE_CHECK_EMAIL } from '@/constants/api';

export default t => {
  let prevEmail;

  return {
    username: Yup.string(),
    email: Yup.string()
      .email(t('validation.emailNotValid'))
      .test('check email', t('validation.emailsAlreadyTaken'), function testEmail(email) {
        const { userId } = this.parent;

        const schema = Yup.string().email();
        if (schema.isValidSync(email) && prevEmail !== email) {
          prevEmail = email;
          return axios
            .get(`${PROFILE_CHECK_EMAIL}/${email}`, { params: { user_id: userId } })
            .then(res => {
              return !res.data.exist;
            })
            .catch(() => true);
        }
        return true;
      }),
    phone: Yup.string().matches(phoneRegEx, {
      excludeEmptyString: true,
      message: t('validation.phoneNotValid'),
    }),
  };
};
