import React from 'react';
import PropTypes from 'prop-types';
import DialogBase from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Dialog({ fullScreenKey, ...props }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(fullScreenKey));
  const options = {
    fullScreen,
  };
  if (fullScreen) {
    options.TransitionComponent = Transition;
  }
  return <DialogBase {...options} {...props} />;
}

Dialog.defaultProps = {
  fullScreenKey: 'xs',
};

Dialog.propTypes = {
  fullScreenKey: PropTypes.string,
};

export default Dialog;
