import * as actionTypes from '@/store/actions/types/users';

const initialState = {
  users: [],
  invited: [],
  error: null,
  loadings: {
    users: false,
    invited: false,
    detail: false,
    block: false,
    download: false,
    generate: false,
    online: false,
    notifyUsers: false,
    virtualBalance: false,
  },
  onlineUsers: 0,
  current_page: 0,
  per_page: 25,
  total: 0,
  detail: {},
  storedUser: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USERS_GET_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, users: true },
        error: false,
      };
    case actionTypes.USERS_GET_LIST_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, users: false },
        users: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case actionTypes.USERS_GET_LIST_FAIL:
      return {
        ...state,
        loading: { ...state.loadings, users: true },
        error: false,
      };

    case actionTypes.VERIFY_USER_START:
      return {
        ...state,
        storedUser: {
          ...state.storedUser,
          [action.payload.user_id]: {
            loader: true,
          },
        },
        error: false,
      };
    case actionTypes.VERIFY_USER_SUCCESS:
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id === action.payload.user_id) {
            return { ...user, is_verified: action.payload.is_verified };
          }
          return user;
        }),
        detail: {
          ...state.detail,
          is_verified: action.payload.is_verified,
        },
        storedUser: {
          ...state.storedUser,
          [action.payload.user_id]: {
            loader: false,
          },
        },
      };
    case actionTypes.VERIFY_USER_FAIL:
      return {
        ...state,
        storedUser: {
          ...state.storedUser,
          [action.payload.user_id]: {
            loader: false,
          },
        },
        error: false,
      };

    case actionTypes.USERS_GET_INVITED_LIST_START:
      return {
        ...state,
        loadings: { ...state.loadings, invited: true },
        error: false,
      };
    case actionTypes.USERS_GET_INVITED_LIST_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, invited: false },
        invited: action.payload.data.data,
        current_page: action.payload.data.current_page - 1,
        per_page: action.payload.data.per_page,
        total: action.payload.data.total,
      };
    case actionTypes.USERS_GET_INVITED_LIST_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, invited: true },
        error: false,
      };

    case actionTypes.USERS_GET_DETAIL_START:
      return {
        ...state,
        loadings: { ...state.loadings, detail: true },
        error: false,
      };
    case actionTypes.USERS_GET_DETAIL_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, detail: false },
        detail: action.payload,
      };
    case actionTypes.USERS_GET_DETAIL_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, detail: true },
        error: false,
      };

    case actionTypes.GET_BLOCK_START:
      return { ...state, loadings: { ...state.loadings, block: true }, error: false };
    case actionTypes.GET_BLOCK_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, block: false },
        error: false,
        detail: action.payload,
      };
    case actionTypes.GET_BLOCK_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, block: false },
      };

    case actionTypes.USER_DOWNLOAD_QRCODE_START:
      return { ...state, loadings: { ...state.loadings, download: true }, error: false };
    case actionTypes.USER_DOWNLOAD_QRCODE_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, download: false },
        error: false,
      };
    case actionTypes.USER_DOWNLOAD_QRCODE_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, download: false },
      };

    case actionTypes.USER_GENERATE_QRCODE_START:
      return { ...state, loadings: { ...state.loadings, generate: true }, error: false };
    case actionTypes.USER_GENERATE_QRCODE_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, generate: false },
        error: false,
      };
    case actionTypes.USER_GENERATE_QRCODE_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, generate: false },
      };

    case actionTypes.GET_ONLINE_USERS_COUNT_START:
      return {
        ...state,
        loadings: { ...state.loadings, online: true },
      };
    case actionTypes.GET_ONLINE_USERS_COUNT_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, online: false },
        onlineUsers: action.payload.count,
      };
    case actionTypes.GET_ONLINE_USERS_COUNT_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, online: false },
      };

    case actionTypes.NOTIFY_USERS_START:
      return {
        ...state,
        loadings: { ...state.loadings, notifyUsers: true },
      };
    case actionTypes.NOTIFY_USERS_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, notifyUsers: false },
      };
    case actionTypes.NOTIFY_USERS_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, notifyUsers: false },
      };
    case actionTypes.CHANGE_ALTRUUS_BALANCE_STATUS_SUCCESS:
      return {
        ...state,
        loadings: { ...state.loadings, virtualBalance: false },
        detail: { ...state.detail, balance_active: action.payload.credit_balance_status },
      };
    case actionTypes.CHANGE_ALTRUUS_BALANCE_STATUS_START:
      return {
        ...state,
        loadings: { ...state.loadings, virtualBalance: true },
      };
    case actionTypes.CHANGE_ALTRUUS_BALANCE_STATUS_FAIL:
      return {
        ...state,
        loadings: { ...state.loadings, virtualBalance: false },
      };
    default:
      return state;
  }
};

export default reducer;
