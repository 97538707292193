// LOCATIONS actions
export const LOCATIONS_START = 'LOCATIONS_START';
export const LOCATIONS_STOP = 'LOCATIONS_STOP';

export const LOCATIONS_ADD = 'LOCATIONS_ADD';

export const LOCATIONS_ADD_EMPLOYEES = 'LOCATIONS_ADD_EMPLOYEES';
export const LOCATIONS_ADD_EMPLOYEES_SUCCESS = 'LOCATIONS_ADD_EMPLOYEES_SUCCESS';

export const LOCATIONS_LIST = 'LOCATIONS_LIST';
export const LOCATIONS_LIST_SUCCESS = 'LOCATIONS_LIST_SUCCESS';

export const LOCATIONS_DELETE = 'LOCATIONS_DELETE';

export const LOCATIONS_EDIT = 'LOCATIONS_EDIT';
