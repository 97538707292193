import * as Yup from 'yup';

export default t => {
  return {
    open: Yup.object()
      .when(['roundTheClock', 'notWork'], {
        is: (roundTheClock, notWork) => roundTheClock === false && notWork === false,
        then: Yup.object().required(t('validation.openTimeRequired')),
        otherwise: Yup.object(),
      })
      .nullable(),
    close: Yup.object()
      .when(['roundTheClock', 'notWork'], {
        is: (roundTheClock, notWork) => roundTheClock === false && notWork === false,
        then: Yup.object().required(t('validation.closeTimeRequired')),
        otherwise: Yup.object(),
      })
      .nullable(),
    days: Yup.array(),
    roundTheClock: Yup.bool(),
    notWork: Yup.bool(),
  };
};
