import {
  MIN_GIFT_CARD_AMOUNT_ES,
  MIN_GIFT_CARD_AMOUNT_MEX,
  MIN_GIFT_CARD_AMOUNT_USA,
} from '@/constants/validation';

export default [
  {
    country: 'United States',
    currency: 'usd',
    symbol: '\tUS\u0024',
    minStripePrice: MIN_GIFT_CARD_AMOUNT_USA,
  },
  {
    country: 'Mexico',
    currency: 'peso',
    symbol: '\tMX\u0024',
    minStripePrice: MIN_GIFT_CARD_AMOUNT_MEX,
  },
  {
    country: 'Spain',
    currency: 'eur',
    symbol: '€',
    minStripePrice: MIN_GIFT_CARD_AMOUNT_ES,
  },
];
