import axios from 'axios';
import { call, fork, put } from 'redux-saga/effects';

import { getCurrentLang, t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import { SEND_INVITATION_TYPE } from '@/constants/invite';
import history from '@/utility/common/history';
import getErrorMessage from '@/utility/common/errors';
import { getMainInformation } from '@/utility/orgonizations/organization';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/organizations';
import * as authActionTypes from '@/store/actions/types/auth';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { generateRedemptionCodeSaga } from '@/store/saga/redemptions';
import { getMe } from '@/store/saga/auth';
import { ORGANIZATION_STATUS_ARCHIVE, TYPE_ORGANIZATION } from '@/constants/organizations';

function getFormData(object) {
  const formData = new FormData();

  formData.append('_method', 'put');
  Object.keys(object).forEach(key => {
    if (typeof object[key] === 'boolean') {
      formData.append(key, object[key] ? '1' : '0');
      return;
    }
    if (key === 'logo' || key === 'cover') {
      if (object[key]) {
        if (!object[key][0].type) {
          formData.append(key, '');
        } else {
          formData.append(key, object[key][0]);
        }
      } else {
        formData.append(key, '0');
      }
      return;
    }
    if (key === 'tags' || key === 'categories') {
      object[key].map(item => formData.append(`${key}[]`, item.id));
      return;
    }
    if (key === 'videos') {
      object[key].forEach((item, index) => {
        Object.keys(item).forEach(itemKey => {
          formData.append(`${key}[${index}][${itemKey}]`, item[itemKey]);
        });
      });

      // formData.append(key, objData);
      return;
    }

    return formData.append(key, object[key]);
  });

  return formData;
}

export function* getBalanceChartSaga(action) {
  yield put({ type: actionTypes.ORGANIZATIONS_BALANCE_CHART_START });

  const { organizationId, dateRange } = action.payload;
  const url = `${getLink(endpoints.GET_ORGANIZATIONS_BALANCE_CHART, {
    id: organizationId,
  })}`;

  try {
    const { data } = yield axios.get(url, { params: { date_range: dateRange } });

    yield put({
      type: actionTypes.ORGANIZATIONS_BALANCE_CHART_SUCCESS,
      payload: {
        data: data.data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ORGANIZATIONS_BALANCE_CHART_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* getTotalProfitCardsSaga(action) {
  yield put({ type: actionTypes.ORGANIZATIONS_BALANCE_CARDS_START });

  const { organizationId, date_range } = action.payload;
  const url = getLink(endpoints.GET_ORGANIZATIONS_BALANCE_CARDS, {
    id: organizationId,
    date_range,
  });

  try {
    const { data } = yield axios.get(url, { params: { date_range } });

    yield put({
      type: actionTypes.ORGANIZATIONS_BALANCE_CARDS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ORGANIZATIONS_BALANCE_CARDS_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* getOrganizationInfo(action) {
  yield put({ type: actionTypes.ORGANIZATIONS_GET_START });

  const { organizationId } = action.payload;
  const url = getLink(endpoints.ORGANIZATION_INFO, {
    id: organizationId,
  });

  try {
    const { data } = yield axios.get(url);
    yield put({
      type: actionTypes.ORGANIZATIONS_GET_INFO_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ORGANIZATIONS_GET_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* putOrganizationInfo(action) {
  yield put({ type: actionTypes.ORGANIZATIONS_PUT_START });

  const {
    data: { organizId, requestData },
    formik,
  } = action.payload;
  const url = getLink(endpoints.ORGANIZATION_INFO, {
    id: organizId,
  });

  try {
    const { data } = yield axios.post(url, getFormData(requestData), {
      'Content-Type': 'multipart/form-data',
    });

    yield put({
      type: actionTypes.ORGANIZATIONS_PUT_INFO_SUCCESS,
      payload: {
        data: data.organization,
      },
    });

    yield put({
      type: authActionTypes.AUTH_UPDATE_ORG_LOGO,
      payload: {
        logo: data.organization.logo,
      },
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('organization.saveSuccess'),
        options: {
          variant: 'success',
        },
      },
    });

    formik.resetForm();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.ORGANIZATIONS_PUT_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
    formik.setSubmitting(false);
  }
}

export function* getOrganizationsSaga(action) {
  yield put({ type: actionTypes.GET_ORGANIZATIONS_START });
  const { search, typeOrganization, ...params } = action.payload;
  const url = search
    ? getLink(endpoints.GET_ORGANIZATIONS_SEARCH, { search })
    : endpoints.GET_ORGANIZATIONS_LIST;

  try {
    const res = yield axios.get(url, { params });
    const { data } = res;
    const type =
      typeOrganization === 'select'
        ? actionTypes.GET_ORGANIZATIONS_SELECT_SUCCESS
        : actionTypes.GET_ORGANIZATIONS_SUCCESS;

    yield put({
      type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_ORGANIZATIONS_FAIL,
    });
  }
}

export function* archiveBusinessSaga(action) {
  yield put({ type: actionTypes.GET_ARCHIVE_START });

  const { organizId, status } = action.payload;
  let url;

  if (status === ORGANIZATION_STATUS_ARCHIVE) {
    url = getLink(endpoints.UNARCHIVE_BUSINESS, { organizationId: organizId });
  } else {
    url = getLink(endpoints.ARCHIVE_BUSINESS, { organizationId: organizId });
  }

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_ARCHIVE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_ARCHIVE_FAIL,
    });
  }
}

export function* resendContractSaga(action) {
  yield put({ type: actionTypes.RESEND_CONTRACT_START });

  const { organizId } = action.payload;
  const url = getLink(endpoints.RESEND, { organizationId: organizId });

  try {
    yield axios.get(url);
    yield put({
      type: actionTypes.RESEND_CONTRACT_SUCCESS,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.resendContract'),
        options: {
          variant: 'success',
        },
      },
    });
    yield call(history.push, '/organizations');
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.RESEND_CONTRACT_FAIL,
    });
  }
}

export function* createOrganizationSaga(action) {
  yield put({ type: actionTypes.CREATE_ORGANIZATION_START });

  const url = endpoints.CREATE_ORGANIZATIONS;
  const dataSend = getMainInformation(action.payload);
  const language = getCurrentLang();

  try {
    const response = yield axios.post(url, { ...dataSend, language });
    const { data } = response;

    yield fork(generateRedemptionCodeSaga, { payload: { type: TYPE_ORGANIZATION, id: data.id } });

    yield put({
      type: actionTypes.CREATE_ORGANIZATION_SUCCESS,
      payload: data.id,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CREATE_ORGANIZATION_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* sendContractSaga(action) {
  yield put({ type: actionTypes.SEND_INVITATION_START });

  const { invitation } = action.payload;
  const url = endpoints.SEND_CONTRACT;
  const language = getCurrentLang();

  try {
    yield axios.post(url, { ...action.payload, language });

    yield put({
      type: actionTypes.SEND_INVITATION_SUCCESS,
    });

    if (invitation === SEND_INVITATION_TYPE) {
      yield put({
        type: PUSH_NOTIFY,
        payload: {
          message: t('notification.createOrganization'),
          options: {
            variant: 'success',
          },
        },
      });
    } else {
      yield put({
        type: PUSH_NOTIFY,
        payload: {
          message: t('notification.saveAsDraft'),
          options: {
            variant: 'success',
          },
        },
      });
    }

    yield call(history.push, '/organizations');
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.SEND_INVITATION_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* getTemplatesSaga() {
  yield put({ type: actionTypes.GET_TEMPLATES_START });

  try {
    const {
      data: { templates },
    } = yield axios.get(endpoints.GET_TEMPLATES_LIST);

    yield put({
      type: actionTypes.GET_TEMPLATES_SUCCESS,
      payload: templates,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_TEMPLATES_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* uploadBusinessLogoSaga(action) {
  yield put({ type: actionTypes.UPLOAD_BUSINESS_LOGO_START });

  const { id, logo, callback } = action.payload;

  const url = getLink(endpoints.UPLOAD_BUSINESS_LOGO, { organizationId: id });
  const formData = new FormData();
  formData.append('_method', 'put');
  formData.append('logo', logo[0]);

  try {
    const { data } = yield axios.post(url, formData, {
      'Content-Type': 'multipart/form-data',
    });
    yield call(getMe);
    yield put({
      type: actionTypes.UPLOAD_BUSINESS_LOGO_SUCCESS,
      payload: {
        data: data.organization,
      },
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('organization.saveBusinessLogo'),
        options: {
          variant: 'success',
        },
      },
    });

    callback();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPLOAD_BUSINESS_LOGO_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}
