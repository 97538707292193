import * as actionTypes from '@/store/actions/types/tags';

const initialState = {
  tags: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TAGS_START:
      return { ...state, loading: true };
    case actionTypes.GET_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload.data,
        loading: false,
      };
    case actionTypes.GET_TAGS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
