import * as actionTypes from './types/plans';

export const getPlans = (params = {}) => {
  return {
    type: actionTypes.GET_PLANS,
    payload: params,
  };
};

export const changePlan = planName => {
  return {
    type: actionTypes.CHANGE_PLAN,
    payload: {
      planName,
    },
  };
};

export default getPlans;
