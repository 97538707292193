import * as Yup from 'yup';

// COMMON
import * as validationConst from '@/constants/validation';

export const redeemCode = t => {
  return {
    code: Yup.string().required(t('validation.codeRequired')),
  };
};

export const giftCardAmount = (t, max) => {
  return {
    code: Yup.string(),
    amount: Yup.number()
      .min(validationConst.MIN_GIFT_CARD_AMOUNT, t('validation.amountLength'))
      .max(max, t('validation.amountToHigh'))
      .required(t('validation.amountRequired')),
  };
};

export const secretSymbol = t => {
  return {
    period: Yup.string(),
    secret_symbol_code: Yup.string().required(t('validation.secretSymbolRequired')),
  };
};
