import { t } from '@/locale/i18n';
import React from 'react';
import { bugsnagUIClick } from '@/utility/common/bugsnagClient';
import { ROUTE_REDEMPTIONS } from '@/constants/routes';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  StripeBtn,
  StripeFirstSymbol,
  StripeText,
  CreateNewAccount,
  ButtonContainers,
} from './styles';

const stripeBtn = ({ organization_logo, history, match }) => {
  const { url } = match;

  // TODO ADD HASH
  const backUrl = () => {
    const hash = history.location.hash;
    if (url.includes('edit')) {
      return ROUTE_REDEMPTIONS;
    }
    if (hash) {
      // return `${url}${hash}`;
    }
    return url;
  };

  const handleConnectStripe = () => {
    bugsnagUIClick('stripe-connect');
    if (organization_logo !== null) {
      window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${
        process.env.REACT_APP_STRIPE_CLIENT_ID
      }&scope=read_write&redirect_uri=${origin}${backUrl()}`;
    } else {
      history.push(ROUTE_REDEMPTIONS);
    }
  };

  return (
    <ButtonContainers id="stripe-button-container">
      <CreateNewAccount variant="subtitle2" color="textSecondary">
        {t('billingTab.createNewStripeAccount')}
      </CreateNewAccount>
      <StripeBtn onClick={handleConnectStripe} id="stripe-connect-button">
        <StripeFirstSymbol>S</StripeFirstSymbol>
        <StripeText>{t('completeProfileDialog.stripeConnect')}</StripeText>
      </StripeBtn>
    </ButtonContainers>
  );
};

stripeBtn.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  organization_logo: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

const mapStateToProps = ({ auth }) => {
  return {
    organization_logo: auth.organization_logo,
  };
};

export default compose(withRouter, connect(mapStateToProps, null))(stripeBtn);
