// AUTH actions
export const AUTH_USER = 'AUTH_USER';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_GET_ME = 'AUTH_GET_ME';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const AUTH_RESET_PASS = 'AUTH_RESET_PASS';
export const AUTH_CHANGE_PASS = 'AUTH_CHANGE_PASS';
export const AUTH_CREATE_PASS = 'AUTH_CREATE_PASS';
export const AUTH_RESOLVE = 'AUTH_RESOLVE';

export const REGISTER_BUSINESS = 'REGISTER_BUSINESS';
export const REGISTER_BUSINESS_SUCCESS = 'REGISTER_BUSINESS_SUCCESS';
export const REGISTER_BUSINESS_FAIL = 'REGISTER_BUSINESS_FAIL';

export const BUSINESS_VERIFY = 'BUSINESS_VERIFY';
export const BUSINESS_VERIFY_SUCCESS = 'BUSINESS_VERIFY_SUCCESS';
export const BUSINESS_VERIFY_FAIL = 'BUSINESS_VERIFY_FAIL';

export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER';
export const AUTH_UPDATE_FIRST_LOGIN = 'AUTH_UPDATE_FIRST_LOGIN';
export const AUTH_UPDATE_FIRST_LOGIN_SUCCESS = 'AUTH_UPDATE_FIRST_LOGIN_SUCCESS';

export const AUTH_UPDATE_CHECK_INFO = 'AUTH_UPDATE_CHECK_INFO';

export const AUTH_UPDATE_ORG_LOGO = 'AUTH_UPDATE_ORG_LOGO';

export const AUTH_UPDATE_PLAN_STATUS = 'AUTH_UPDATE_PLAN_STATUS';

export const AUTH_LOGIN_AS_ORGANIZATION_FAIL = 'AUTH_LOGIN_AS_ORGANIZATION_FAIL';
export const AUTH_LOGIN_AS_ORGANIZATION = 'AUTH_LOGIN_AS_ORGANIZATION';
export const AUTH_LOGIN_AS_ORGANIZATION_SUCCESS = 'AUTH_LOGIN_AS_ORGANIZATION_SUCCESS';

export const AUTH_LOGIN_AS_ADMIN_FAIL = 'AUTH_LOGIN_AS_ADMIN_FAIL';
export const AUTH_LOGIN_AS_ADMIN = 'AUTH_LOGIN_AS_ADMIN';
export const AUTH_LOGIN_AS_ADMIN_SUCCESS = 'AUTH_LOGIN_AS_ADMIN_SUCCESS';

export const AUTH_RESEND_EMAIL_START = 'AUTH_RESEND_EMAIL_START';
export const AUTH_RESEND_EMAIL_FAIL = 'AUTH_RESEND_EMAIL_FAIL';
export const AUTH_RESEND_EMAIL = 'AUTH_RESEND_EMAIL';
export const AUTH_RESEND_EMAIL_SUCCESS = 'AUTH_RESEND_EMAIL_SUCCESS';

export const ADMIN_VERIFY = 'ADMIN_VERIFY';
export const ADMIN_VERIFY_SUCCESS = 'ADMIN_VERIFY_SUCCESS';
export const ADMIN_VERIFY_FAIL = 'ADMIN_VERIFY_FAIL';
