import * as Yup from 'yup';

export default t => {
  return {
    cardHolderName: Yup.string().required(t('validation.cardHolderNameRequired')),
    cardNumber: Yup.string().required(t('validation.cardNumberRequired')),
    expirationMonth: Yup.string().required(t('validation.expirationMonthRequired')),
    expirationYear: Yup.string().required(t('validation.expirationYearRequired')),
    securityCode: Yup.string().required(t('validation.securityCodeRequired')),
  };
};
