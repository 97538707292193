import styled from 'styled-components';
import { Paper, Grid } from '@material-ui/core/index';

import breakpoints from '@/constants/breakpoints';
import colors, { aliceBlue, astronaut, white } from '@/constants/colors';

const { giftStatuses } = colors;

export const CodePageWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Download = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Instructions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export const CodeTopButtonWrap = styled.div`
  display: block;
  width: 100%;
  text-align: end;
  margin: 0 auto 2rem;
  padding-right: 20px;
  @media (max-width: 1700px) {
    padding-right: 0px;
  }
`;

export const StepsWrap = styled(Grid)`
  margin: 2rem auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
`;

export const PeriodPicker = styled(Grid)`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  background-color: ${aliceBlue};
  margin-top: 10px;
  margin-bottom: 30px;
  justify-content: space-between;
`;

export const CustomGrid = styled(Grid)`
  flex-direction: row;
  gap: 20px;

  @media (max-width: ${breakpoints.xs}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const PeriodItem = styled.span`
  border-radius: 18px;
  align-self: center;
  background-color: ${p => (p.isActive ? astronaut : aliceBlue)};
  ${p => (p.isActive ? `color: ${white};` : '')}
  font-size: 16px;
  cursor: pointer;
  font-weight: normal;
  line-height: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;

  @media (max-width: 470px) {
    font-size: 12px;
    line-height: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const RedemptionsWrap = styled(Grid)`
  width: 100%;
  flex-direction: row;
`;

export const RedemptionStep = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  background: ${aliceBlue};
  padding: 8px;
  border-radius: 16px;
  && {
    margin: 6px 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const ImgStyled = styled.img`
  margin-right: 10px;
`;

export const Status = styled.span`
  &.status {
    line-height: 35px;
    &:before {
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 8px;
      background-color: ${giftStatuses.published};
    }

    &--drafted {
      &:before {
        background-color: ${giftStatuses.drafted};
      }
    }

    &--archived {
      &:before {
        background-color: ${giftStatuses.archived};
      }
    }

    &--out_of_stock {
      &:before {
        background-color: ${giftStatuses.outOfStoke};
      }
    }

    &--blocked {
      &:before {
        background-color: ${giftStatuses.blocked};
      }
    }
  }
`;

export const RedemptionsForm = styled(Paper)`
  && {
    padding: 40px 40px 0px;
    margin: 2rem auto 2rem;
    width: 100%;
    height: 100%;
    min-height: 650px;
    max-width: 1440px;
    @media (max-width: ${breakpoints.xs}) {
      padding: 20px;
    }
    @media (max-width: 960px) {
      margin: 0;
    }
  }
`;

export const Example = styled.img`
  display: block;
  max-width: 300px;
  width: 100%;
`;

export const SecretPeriodWrapper = styled.div`
  margin: 2rem auto 5rem;
  font-size: 14px;
  line-height: 18px;
`;

export const Emoji = styled.img`
  width: 40px;
  height: 40px;
  margin: 0 20px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8.5rem 2.4rem;
  & label {
    left: 50%;
    transform: translateX(-50%);
  }
  & input {
    text-align: center;
    padding: 1.1rem 1.8rem;
  }
  & div {
    max-width: 290px;
  }
`;

export const LoaderWrap = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const TableContainer = styled(Paper)`
  && {
    padding: 0;
  }
`;
