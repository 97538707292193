import { t } from '@/locale/i18n';
import colors from '@/constants/colors';

const { highchart } = colors;

// Statistic types
export const STATISTIC_TYPE_SENT = 'sent';
export const STATISTIC_TYPE_REDEEMED = 'redeemed';
export const STATISTIC_TYPE_VIEWS = 'views';
// Statistic date ranges
export const STATISTIC_DATE_RANGE_DAY = 'day';
export const STATISTIC_DATE_RANGE_WEEK = 'week';
export const STATISTIC_DATE_RANGE_MONTH = 'month';
export const STATISTIC_DATE_RANGE_YEAR = 'year';
export const MAX = 10;

const seriesData = {
  data: [],
  color: highchart.seriesColor,
  lineWidth: 2,
  shadow: { color: highchart.seriesShadow, offsetX: 0, offsetY: 5, opacity: '0.01', width: 15 },
  marker: {
    fillColor: highchart.seriesMarkerFillColor,
    lineColor: highchart.seriesMarkerLineColor,
    lineWidth: 2,
    radius: 5,
    states: {
      hover: {
        radius: 8,
      },
    },
  },
};

export const mainTotalChart = {
  chart: {
    type: 'area',
    height: '49%',
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: null,
    },
    allowDecimals: false,
    min: 0,
    endOnTick: false,
    gridLineDashStyle: 'dash',
    gridLineColor: highchart.gridLineColor,
    lineWidth: 1,
    lineColor: highchart.lineColor,
    labels: {
      x: -20,
      y: 5,
      style: {
        fontFamily: 'SofiaPro',
        fontSize: '1.4rem',
        lineHeight: '2.6rem',
        fontWeight: '400',
        color: highchart.labelsText,
      },
    },
  },
  xAxis: {
    lineColor: highchart.lineColor,
    tickmarkPlacement: 'on',
    categories: [],
    labels: {
      y: 40,
      style: {
        fontFamily: 'SofiaPro',
        fontSize: '1.4rem',
        lineHeight: '2.6rem',
        fontWeight: '400',
        color: highchart.labelsText,
      },
    },
  },
  plotOptions: {
    series: {
      pointPlacement: 'on',
      cursor: 'pointer',
    },
    area: {
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, highchart.areaGradientFillColor1],
          [1, highchart.areaGradientFillColor2],
        ],
      },
    },
  },
  tooltip: {
    borderColor: highchart.tooltipBorderColor,
    backgroundColor: highchart.tooltipBackgroundColor,
    borderRadius: 4,
    padding: 15,
    shadow: false,
    useHTML: true,
    headerFormat: '<span class="highcharts-tooltip-header">{point.key}</span><br/>',
    pointFormat: '<span class="highcharts-tooltip-subtitle">{series.name}: {point.y}</span>',
  },
  series: [
    {
      ...seriesData,
      name: t('common.types.sent'),
    },
    {
      ...seriesData,
      name: t('common.types.tips'),
      color: highchart.secondarySeriesColor,
    },
  ],
};

export const smallTotalChart = {
  chart: {
    type: 'area',
    height: 63,
    spacing: [0, 0, 0, 0],
  },
  title: {
    text: null,
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: null,
    },
    visible: false,
    min: 0,
    tickAmount: 5,
    endOnTick: false,
    allowDecimals: false,
  },
  xAxis: {
    visible: false,
    lineColor: highchart.lineColor,
    tickmarkPlacement: 'on',
    categories: [],
    labels: {
      y: 40,
      style: {
        fontFamily: 'SofiaPro',
        fontSize: '1.4rem',
        lineHeight: '2.6rem',
        fontWeight: '400',
        color: highchart.labelsText,
      },
    },
  },
  plotOptions: {
    series: {
      pointPlacement: 'on',
      states: {
        hover: {
          enabled: false,
        },
      },
    },
    area: {
      fillColor: {
        linearGradient: {
          x1: 0,
          y1: 0,
          x2: 0,
          y2: 1,
        },
        stops: [
          [0, highchart.areaGradientFillColor1],
          [1, highchart.areaGradientFillColor2],
        ],
      },
    },
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      name: t('common.types.sent'),
      data: [],
      color: highchart.seriesColor,
      lineWidth: 2,
      shadow: { color: highchart.seriesShadow, offsetX: 0, offsetY: 5, opacity: '0.01', width: 15 },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  ],
};
