import { rgba } from 'polished';

export const white = '#FFFFFF';
export const black = '#000000';

// Primary
export const astronaut = '#273178';
export const astronautDark = '#1B2256';
export const luckyPoint = '#1B2367';
export const royalBlue = '#6650EF';
export const azureRadiance = '#D74590';
export const mulberry = '#B4418B';
// Secondary
export const porcelain = '#E6EAEC';
export const sirocco = '#737778';
export const smaltBlue = '#708EB0';
export const aliceBlue = '#F7FAFF';
export const baliHai = '#8498B6';
export const athensGray = '#EBECF1';
export const blueHaze = '#8498E2';
export const malachite = '#12B822';
export const mintGreen = '#84FF90';
export const grandis = '#FFD37C';
export const ronchi = '#F1DA50';
export const festival = '#E6D04B';
export const mySin = '#FFB014';
export const vividTangerine = '#FF4D42';
export const selago = '#EAF1FC';
export const linkWater = '#DFE9F4';
export const snuff = '#DEDCF1';
export const deepKoamaru = '#201678';
export const geyser = '#CDD7DE';
export const eminence = '#79347B';
export const comet = '#555978';
export const ebony = '#0D1028';
export const turquoiseBlue = '#5EEBA4';
export const gigas = '#483DAB';
export const blue = '#0118E9';
export const cornflowerBlue = '#5086EF';
export const mystic = '#E1E4EE';
export const casper = '#B1BFD3';
export const zircon = '#EEF1FF';
export const azureRadianceBlue = '#0072EA';
export const quartz = '#D6DCF2';
export const mineShaft = '#3A3A3A';
export const blackSqueeze = '#F5F9FC';
export const hintOfRed = '#F9F7F6';
export const amethystSmoke = '#8F8FB0';
export const governorBay = '#493CBC';
export const snackbarBg = 'rgba(9, 14, 51, 0.95)';
export const customRangeSelected = '#7A7FF7';
export const chathamsBlue = '#1A4B78';
// Mobile
export const aquamarine = '#5FFFCA';
export const bunting = '#1C1850';
export const manatee = '#9091A9';
export const darkBlue = '#2e79bf';
export const lightBlue = '#3a8bd7';

const colors = {
  primary: astronaut,
  secondary: royalBlue,
  background: selago,
  paperBoxShadow: linkWater,
  // Text
  textSecondary: baliHai,
  // Buttons
  containedBtnDisabledBg: rgba(smaltBlue, 0.2),
  containedBtnDisabledText: rgba(smaltBlue, 0.4),
  outlinedBtnDisabledBorder: rgba(smaltBlue, 0.2),
  outlinedBtnDisabledText: rgba(smaltBlue, 0.4),
  primaryBtnBg: azureRadiance,
  primaryBtnHoverBg: mulberry,
  secondaryBtnBg: rgba(royalBlue, 0.1),
  secondaryBtnText: smaltBlue,
  secondaryBtnHoverBg: snuff,
  secondaryLargeBtnHoverText: smaltBlue,
  outlinedPrimaryBtnText: azureRadiance,
  outlinedPrimaryBtnBorder: azureRadiance,
  outlinedPrimaryBtnHoverBg: azureRadiance,
  outlinedPrimaryBtnHoverBorder: azureRadiance,
  outlinedPrimaryBtnHoverText: white,
  outlinedSecondaryBtnHoverBg: royalBlue,
  outlinedSecondaryBtnHoverText: white,
  outlinedSecondaryBtnDisabledBorder: rgba(smaltBlue, 0.4),
  outlinedSecondaryBtnDisabledText: rgba(smaltBlue, 0.4),
  // Link
  primaryLink: azureRadiance,
  secondaryLink: royalBlue,
  blueLink: azureRadianceBlue,
  disabledLink: rgba(astronaut, 0.7),
  // Forms
  label: rgba(astronaut, 0.7),
  labelAddText: rgba(astronaut, 0.4),
  inputBorder: rgba(blueHaze, 0.4),
  inputBorderFocused: royalBlue,
  inputBorderError: vividTangerine,
  inputText: rgba(astronaut, 0.7),
  inputPlaceholder: rgba(astronaut, 0.4),
  inputTextError: vividTangerine,
  inputDisabledBg: rgba(astronaut, 0.05),
  inputDisabledBorder: rgba(astronaut, 0.1),
  inputDisabledText: rgba(astronaut, 0.4),
  selectOpenedBorder: azureRadiance,
  selectIcon: astronaut,
  selectFocusedBg: white,
  selectItem: astronaut,
  selectItemSelected: azureRadiance,
  padItemHover: rgba(royalBlue, 0.04),
  selectDropDown: rgba(chathamsBlue, 0.1),
  // Notifications
  notificationDefault: baliHai,
  notificationSuccess: malachite,
  notificationError: vividTangerine,
  notificationWarning: mySin,
  // Alerts
  alerts: {
    bg: rgba(astronaut, 0.2),
  },
  // Sidebar
  sideMenu: luckyPoint,
  navigationIconNotActive: baliHai,
  navigationIconHover: white,
  navigationIconActive: white,
  navigationText: white,
  navigationActive: azureRadiance,
  navigationHover: eminence,
  navigationFocus: eminence,
  // Divider
  defaultDividerBg: athensGray,
  deviderBg: comet,
  // Header
  headerBg: white,
  headerText: astronaut,
  headerTitle: astronaut,
  headerBadgeBg: baliHai,
  reportBadgeBg: vividTangerine,
  headerBadgeDot: azureRadiance,
  headerBadgeDotBorder: white,
  headerBreadcrumbs: azureRadiance,
  headerBreadcrumbsCurrent: baliHai,
  // Warning
  warningBg: rgba(ronchi, 0.8),
  warningTooltipBg: rgba(white, 0.9),
  warningTooltipLabel: astronaut,
  // Header Search
  headerSearch: {
    border: rgba(mystic, 0.4),
    icon: rgba(casper, 0.4),
  },
  // Stepper
  stepIcon: rgba(royalBlue, 0.5),
  stepIconActive: royalBlue,
  stepLabel: royalBlue,
  stepConnector: royalBlue,
  // Custom Stepper
  customStepperText: white,
  customStepperTextComplete: turquoiseBlue,
  customStepperActiveBg: gigas,
  customStepperNumber: deepKoamaru,
  customStepperNumberBg: white,
  customStepperNumberCompleteBg: turquoiseBlue,
  // Dialog
  backdrop: rgba(ebony, 0.8),
  dialogHeaderBorder: rgba(smaltBlue, 0.2),
  // List
  listItemSelectedText: azureRadiance,
  listItemSelectedBg: white,
  listItemBulletColor: smaltBlue,
  listItemHoverBg: rgba(royalBlue, 0.04),
  // Complete Profile Dialog
  leftSideBg: deepKoamaru,
  // Table
  table: {
    cellBorder: rgba(athensGray, 0.4),
    head: rgba(astronaut, 0.7),
    headBorder: rgba(athensGray, 0.4),
    pagination: smaltBlue,
  },
  // Highcharts
  highchart: {
    tooltipHeaderText: baliHai,
    tooltipSubtitleText: astronaut,
    tooltipBorderColor: royalBlue,
    tooltipBackgroundColor: white,
    gridLineColor: athensGray,
    lineColor: athensGray,
    labelsText: baliHai,
    seriesColor: royalBlue,
    secondarySeriesColor: malachite,
    seriesShadow: blue,
    seriesMarkerFillColor: royalBlue,
    seriesMarkerLineColor: white,
    areaGradientFillColor1: rgba(cornflowerBlue, 0.19),
    areaGradientFillColor2: rgba(royalBlue, 0.05),
  },
  // StatisticBox Component
  statisticBox: {
    statisticPercentUp: malachite,
    statisticPercentDown: vividTangerine,
  },
  // GiftStatuses Component
  giftStatuses: {
    published: mintGreen,
    drafted: grandis,
    archived: baliHai,
    outOfStoke: vividTangerine,
    blocked: astronaut,
  },
  // RadioCard Component
  radioCard: {
    border: athensGray,
    checkedBorder: royalBlue,
  },
  // ChoosePlanCard Component
  choosePlanCard: {
    border: rgba(blueHaze, 0.4),
    activeBorder: royalBlue,
    currentLabel: malachite,
    currentLabelIconBg: rgba(malachite, 0.05),
  },
  // Chip
  chip: {
    text: white,
    bg: astronaut,
    focusedText: white,
    focusedBg: azureRadiance,
  },
  // DropZone
  dropZone: {
    error: vividTangerine,
    bg: rgba(zircon, 0.5),
    border: blueHaze,
  },
  // Checkbox
  checkbox: {
    border: rgba(blueHaze, 0.4),
  },
  // TransactionsList Component
  transactionsList: {
    amount: malachite,
  },
  // Tabs
  tabs: {
    bg: rgba(quartz, 0.4),
    text: rgba(astronaut, 0.4),
    activeText: royalBlue,
    secondary: {
      bg: white,
      text: rgba(astronaut, 0.4),
      activeText: azureRadiance,
      indicator: azureRadiance,
    },
  },
  // Progress
  progress: {
    primary: royalBlue,
    bg: rgba(white, 0.4),
  },
  // Tooltip
  tooltip: {
    bg: rgba(mineShaft, 0.98),
  },
  // Organization Preview
  organizationPreview: {
    previewContainer: blackSqueeze,
    headerBg: deepKoamaru,
    headerText: rgba(white, 0.9),
    logoBorder: hintOfRed,
    organizationBg: zircon,
    giftBg: rgba(amethystSmoke, 0.4),
  },
  // userStatuses Component
  userStatuses: {
    registered: mintGreen,
    invited: grandis,
  },
  switch: {
    bg: smaltBlue,
    activeBg: malachite,
    thumb: white,
  },
  // Survey
  survey: {
    question: {
      border: rgba(athensGray, 0.4),
    },
  },
  // Stripe connect
  stripeConnect: {
    button: {
      bg: lightBlue,
      border: darkBlue,
      color: white,
    },
  },
  // Categories
  categories: {
    tabBg: white,
  },
};

export default colors;
