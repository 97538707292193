import * as Yup from 'yup';
import moment from 'moment';
import { getCurrentLang } from '@/locale/i18n';

export default (t, choosedtDate) => {
  return {
    time: Yup.string()
      .nullable()
      .test('time', t('validation.notificationDate'), date => {
        if (!date) return false;

        const format = getCurrentLang() === 'es' ? 'HH:mm:ss' : 'hh:mm:ss A';

        const formatDate = moment(choosedtDate).format('YYYY-MM-DD');
        const time = moment(date).format(format);
        return moment(`${formatDate} ${time}`).isAfter(moment());
      }),
  };
};
