import styled from 'styled-components';
import colors from '@/constants/colors';

const { dropZone } = colors;

const DropContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: ${p => p.height || 148}px;
  background-color: ${dropZone.bg};
  border: 1px dashed ${dropZone.border};
  border-radius: 4px;
  padding: 0 16px;
  width: ${p => `${p.width}px` || null};

  &:focus {
    outline: none;
  }

  &.dropzone {
    &--error {
      border-color: ${dropZone.error};
    }

    .dropzone__error {
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: ${dropZone.error};
      margin-bottom: 0;
    }
  }

  p {
    text-align: center;
    max-width: 300px;
    line-height: 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    * {
      line-height: 1.8rem;
    }
  }
`;

export default DropContainer;
