import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import SnackbarProvider from '@/components/SnackbarProvider';
import { ClearCacheProvider } from 'react-clear-cache';

import configureStore from './store/configureStore';
import history from '@/utility/common/history';
import httpService from '@/utility/app/httpService';
import { bugsnagClient } from '@/utility/common/bugsnagClient';
import { setDefaultAxios, createAxiosResponseInterceptor } from './store/configDefaultAPI';
import theme from './theme/materialTheme';
import App from './App';

import '@/locale/i18n';

export const store = configureStore();
const tagManagerArgs = {
  gtmId: 'GTM-N8XVGLP',
};

if (process.env.REACT_APP_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
}

// auth Interceptor
httpService.setupInterceptors(store, history);

// set API configs
setDefaultAxios();
createAxiosResponseInterceptor();

// Bugsnag
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={history}>
            <SnackbarProvider maxSnack={3}>
              <ClearCacheProvider duration={3600000}>
                <App />
              </ClearCacheProvider>
            </SnackbarProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

export default store;
