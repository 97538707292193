import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';

export const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  releaseStage: process.env.REACT_APP_ENV,
  notifyReleaseStages: ['production', 'staging', 'develop'],
  metaData: {},
  autoBreadcrumbs: false,
  navigationBreadcrumbsEnabled: true,
  interactionBreadcrumbsEnabled: true,
});

export const bugsnagUIClick = buttonId => {
  return bugsnagClient.leaveBreadcrumb('button click', { button: buttonId }, 'user');
};

export const bugsnagDeleteMetaData = keys => {
  keys.forEach(key => delete bugsnagClient.metaData[key]);
};

export const bugsnagSetMetaData = (key, data) => {
  bugsnagClient.metaData[key] = data;
};

bugsnagClient.use(bugsnagReact, React);
