import React from 'react';
import PropTypes from 'prop-types';

import NotificationContainer from './styles';

const alert = ({ text, type, ...restProps }) => {
  return (
    <NotificationContainer {...restProps} className="notification" type={type}>
      {text}
    </NotificationContainer>
  );
};

alert.defaultProps = {
  type: 'default',
};

alert.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};

export default React.memo(alert);
