import * as Yup from 'yup';
import { TYPE_GIFT } from '@/constants/gift';
import { TYPE_CATEGORY } from '@/constants/categories';
import { TYPE_ORGANIZATION } from '@/constants/organizations';

export default t => {
  return {
    name: Yup.string().required(t('validation.surveyNameRequired')),
    quantity: Yup.string().required(t('validation.quantityRequired')),
    // rewardGift: Yup.object().required(),
    questions: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(t('validation.typeRequired')),
        category: Yup.object().when('type', {
          is: type => type === TYPE_CATEGORY,
          then: Yup.object().required(t('validation.categoryRequired')),
          otherwise: Yup.object().nullable(),
        }),
        organization: Yup.object().when('type', {
          is: type => type === TYPE_ORGANIZATION,
          then: Yup.object().required(t('validation.organizationRequired')),
          otherwise: Yup.object().nullable(),
        }),
        giftData: Yup.object().when('type', {
          is: type => type === TYPE_GIFT,
          then: Yup.object().required(),
          otherwise: Yup.object().nullable(),
        }),
      }),
    ),
  };
};
