import * as actionTypes from '@/store/actions/types/disputes';

const initialState = {
  list: [],
  loading: false,
  current_page: 0,
  per_page: 20,
  total: 0,
  count: 0,
  total_pages: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DISPUTES_GET_LIST_START:
      return { ...state, loading: true };
    case actionTypes.DISPUTES_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        current_page: action.payload.meta.current_page - 1,
        per_page: action.payload.meta.per_page,
        count: action.payload.meta.count,
        total: action.payload.meta.total,
        total_pages: action.payload.meta.total_pages,
      };
    case actionTypes.DISPUTES_GET_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
