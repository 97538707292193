import * as actionTypes from '@/store/actions/types/adminSettings';

const initialState = {
  NGCStatus: null,
  loading: {
    NGCArchiveStatus: false,
    NGCStatus: false,
    adminSettings: false,
    updateSettings: false,
    reindexAlgolia: {
      gift: false,
      organization: false,
      user: false,
    },
    currentUserUpdate: {
      purchase_limit: false,
      unverified_purchase_limit_ngc: false,
      purchase_card_limit: false,
      getNotifications: false,
      updateNotifications: false,
    },
  },
  notifications: [],
  settings: {
    unverified_purchase_limit_ngc: '',
    ngc_native_payments_delay: null,
    purchase_limitMX: '',
    purchase_limitUS: '',
    purchase_limitES: '',
    purchase_card_limitMX: '',
    purchase_card_limitUS: '',
    purchase_card_limitES: '',
    support_emailMX: '',
    support_emailUS: '',
    test_emailMX: '',
    test_emailUS: '',
    ngc_gift_delay_time: '',
    ignored_users: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NGC_STATUS_START:
      return {
        ...state,
        loading: { ...state.loading, NGCStatus: true },
      };
    case actionTypes.GET_NGC_STATUS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, NGCStatus: false },
        NGCStatus: action.payload.NGCStatus,
      };
    case actionTypes.GET_NGC_STATUS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, NGCStatus: false },
      };

    case actionTypes.GET_ADMIN_NOTIFICATIONS_START:
      return {
        ...state,
        loading: { ...state.loading, getNotifications: true },
      };
    case actionTypes.GET_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, getNotifications: false },
        notifications: action.payload.data,
      };
    case actionTypes.GET_ADMIN_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, getNotifications: false },
      };

    case actionTypes.UPDATE_ADMIN_NOTIFICATIONS_START:
      return {
        ...state,
        loading: { ...state.loading, updateNotifications: true },
      };
    case actionTypes.UPDATE_ADMIN_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateNotifications: false },
        notifications: action.payload.notifications,
      };
    case actionTypes.UPDATE_ADMIN_NOTIFICATIONS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, updateNotifications: false },
      };

    case actionTypes.ARCHIVE_ALL_NGC_START:
      return {
        ...state,
        loading: { ...state.loading, NGCArchiveStatus: true },
      };
    case actionTypes.ARCHIVE_ALL_NGC_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, NGCArchiveStatus: false },
        NGCStatus: action.payload.NGCStatus,
      };
    case actionTypes.ARCHIVE_ALL_NGC_FAILED:
      return {
        ...state,
        loading: { ...state.loading, NGCArchiveStatus: false },
      };

    case actionTypes.GET_ADMINS_SETTINGS_START:
      return {
        ...state,
        loading: { ...state.loading, adminSettings: true },
      };
    case actionTypes.GET_ADMINS_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, adminSettings: false },
        settings: {
          unverified_purchase_limit_ngc: action.payload.unverified_purchase_limit_ngc.US,
          ngc_native_payments_delay: action.payload.ngc_native_payments_delay.US,
          purchase_limitMX: action.payload.purchase_limit.MX,
          purchase_limitUS: action.payload.purchase_limit.US,
          purchase_limitES: action.payload.purchase_limit.ES,
          purchase_card_limitMX: action.payload.purchase_card_limit.MX,
          purchase_card_limitUS: action.payload.purchase_card_limit.US,
          purchase_card_limitES: action.payload.purchase_card_limit.ES,
          support_emailMX: action.payload.support_email.MX,
          support_emailUS: action.payload.support_email.US,
          test_email: action.payload.test_email.US,
          ngc_gift_delay_time: action.payload.ngc_gift_delay_time.US,
          ignored_users: action.payload.ignored_users.map(user => ({
            label: user.username,
            value: user.id,
          })),
        },
      };
    case actionTypes.GET_ADMINS_SETTINGS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, adminSettings: false },
      };
    case actionTypes.UPDATE_ADMINS_SETTINGS_START:
      return {
        ...state,
        loading: { ...state.loading, updateSettings: true },
      };
    case actionTypes.UPDATE_ADMINS_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.payload.settings,
          ngc_native_payments_delay:
            action.payload.ngc_native_payments_delay !== undefined
              ? action.payload.ngc_native_payments_delay
              : state.settings.ngc_native_payments_delay,
        },
        loading: { ...state.loading, updateSettings: false },
      };
    case actionTypes.UPDATE_ADMINS_SETTINGS_FAILED:
      return {
        ...state,
        loading: { ...state.loading, updateSettings: false },
      };
    case actionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          currentUserUpdate: {
            ...state.loading.currentUserUpdate,
            [action.payload.name]: true,
          },
        },
      };
    case actionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          currentUserUpdate: {
            ...state.loading.currentUserUpdate,
            [action.payload.name]: false,
          },
        },
      };
    case actionTypes.UPDATE_SETTINGS_TO_CURRENT_USERS_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          currentUserUpdate: {
            ...state.loading.currentUserUpdate,
            [action.payload.name]: false,
          },
        },
      };
    case actionTypes.REINDEX_ALGOLIA_START:
      return {
        ...state,
        loading: {
          ...state.loading,
          reindexAlgolia: {
            ...state.loading.reindexAlgolia,
            [action.payload.name]: true,
          },
        },
      };
    case actionTypes.REINDEX_ALGOLIA_SUCCESS:
      return {
        ...state,
        loading: {
          ...state.loading,
          reindexAlgolia: {
            ...state.loading.reindexAlgolia,
            [action.payload.name]: false,
          },
        },
      };
    case actionTypes.REINDEX_ALGOLIA_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          reindexAlgolia: {
            ...state.loading.reindexAlgolia,
            [action.payload.name]: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
