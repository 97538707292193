import * as actionTypes from './types/workHours';

export const getWorkHours = (params = {}) => {
  return {
    type: actionTypes.GET_WORK_HOURS,
    payload: params,
  };
};

export const editWorkHours = (params = {}) => {
  return {
    type: actionTypes.CHANGE_WORK_HOURS,
    payload: params,
  };
};

export default getWorkHours;
