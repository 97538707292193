import * as Yup from 'yup';
import * as validationConst from '@/constants/validation';
import axios from 'axios';
import * as endpoints from '@/constants/api';

let prevName;
let prevEmail;
let nameError = false;
let emailError = false;

export default t => {
  return {
    account: {
      account_type: Yup.string(),
      is_agree: Yup.boolean().oneOf([true], t('validation.agreeRules')),
      email: Yup.string()
        .required(t('validation.emailRequired'))
        .email(t('validation.emailNotValid'))
        .test('email', t('validation.emailsAlreadyTaken'), email => {
          if (email) {
            const schema = Yup.string().email();
            if (schema.isValidSync(email) && prevEmail !== email) {
              prevEmail = email;

              return axios
                .get(`${endpoints.USER_CHECK_EMAIL}/${email}?role_id=3`)
                .then(res => {
                  emailError = !res.data.exist;
                  return emailError;
                })
                .catch(() => true);
            }

            return emailError;
          }
          return true;
        }),
    },
    security: {
      password: Yup.string()
        .required(t('validation.passwordRequired'))
        .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength')),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], t('validation.passwordDontMatch'))
        .min(validationConst.MIN_PASSWORD_LENGTH, t('validation.passwordLength'))
        .required(t('validation.passwordRequired')),
    },
    organization: {
      business_name: Yup.string()
        .required(t('validation.businessNameRequired'))
        .min(validationConst.MIN_BUSINESS_NAME_LENGTH, t('validation.businessNameLength'))
        .max(validationConst.MAX_BUSINESS_NAME_LENGTH, t('validation.businessNameMaxLength'))
        .test('business_name', t('validation.businessNameAlreadyTaken'), name => {
          if (name && name.trim() && name.length >= validationConst.MIN_BUSINESS_NAME_LENGTH) {
            if (prevName !== name) {
              prevName = name;
              return axios
                .get(`${endpoints.BUSINESS_CHECK_NAME}`, { params: { name } })
                .then(res => {
                  nameError = !res.data.exist;
                  return nameError;
                })
                .catch(() => true);
            }

            return nameError;
          }
          return true;
        }),
      location: Yup.string().required(t('validation.locationRequired')),
      zip: Yup.string()
        .required(t('validation.zipRequired'))
        .matches(/^[0-9]{4,6}(?:-[0-9]{4})?$/, t('validation.zipRequired')),
    },
    plan: {
      plan: Yup.string().required(),
    },
  };
};
