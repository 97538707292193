// LANGUAGES actions

export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_LANGUAGE_START = 'SET_LANGUAGE_START';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const SET_LANGUAGE_FAIL = 'SET_LANGUAGE_FAIL';

export const GET_EMAIL_LANG = 'GET_EMAIL_LANG';
export const GET_EMAIL_LANG_START = 'GET_EMAIL_LANG_START';
export const GET_EMAIL_LANG_SUCCESS = 'GET_EMAIL_LANG_SUCCESS';
export const GET_EMAIL_LANG_FAIL = 'GET_EMAIL_LANG_FAIL';
