export const getAccounts = (paymentType, check_info) => {
  if (paymentType === 'stripe') {
    return check_info.stripe_connect_accounts?.map(account => ({
      value: account?.stripe_user_id,
      label: account?.name || account?.stripe_user_id,
    }));
  }

  if (paymentType === 'square') {
    return check_info.square_connect_accounts?.map(account => ({
      value: account?.square_user_id,
      label: account?.name || account?.square_user_id,
    }));
  }
  return [];
};

export const activeAccount = (paymentData, check_info) => {
  if (!paymentData) return null;

  const { paymentType, accountId } = paymentData;
  const mainAccount =
    paymentType === 'stripe'
      ? check_info.stripe_connect_accounts?.find(account => account.stripe_user_id === accountId)
      : check_info.square_connect_accounts?.find(account => account.square_user_id === accountId);

  if (!mainAccount) return null;

  if (paymentType === 'stripe') {
    return {
      value: mainAccount.stripe_user_id,
      label: mainAccount.name || mainAccount.stripe_user_id,
    };
  }
  if (paymentType === 'square') {
    return {
      value: mainAccount.square_user_id,
      label: mainAccount.name || mainAccount.square_user_id,
    };
  }
};

export const isSwitchAvailable = (accounts, connection) => {
  return accounts?.length > 1 || (!connection && accounts?.length);
};
