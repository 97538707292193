// Type ot gift
export const TYPE_GIFT = 'gift';
export const TYPE_GIFT_CARD = 'gift_card';
// Gift type
export const GIFT_TYPE_PAID = 'paid';
export const GIFT_TYPE_FREE = 'free';
export const GIFT_TYPE_GEO_GIFT = 'geo_gift';
export const GIFT_TYPE_FREE_EXPIRATION = 'expiration_free';

// Gift spread
export const GIFT_BY_CITY = 'city';
export const GIFT_BY_COUNTRY = 'country';

// Gift free expiration
export const EXPIRATION_DAY = 'day';

// Redemption frequency
export const RED_FREQUENCY_DAY = 'day';
export const RED_FREQUENCY_WEEK = 'week';
export const RED_FREQUENCY_MONTH = 'month';
// Quantity
export const QUANTITY_TYPE_UNLIMITED = 'unlimited';
export const QUANTITY_TYPE_SPECIFIC = 'specific';
// Redeem code type
export const REDEEM_CODE_TYPE_ALPHANUMERIC = 'alphanumeric';
// Gift status
export const GIFT_STATUS_DRAFT = 'drafted';
export const GIFT_STATUS_PUBLISH = 'published';
export const GIFT_STATUS_ARCHIVE = 'archived';
export const GIFT_STATUS_BLOCKED = 'blocked';
export const GIFT_STATUS_OUT_OF_STOCK = 'out_of_stock';

export const REWARD_GIFT = 'rewardGift';
