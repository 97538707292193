// GIFT actions
export const GIFT_START = 'GIFT_START';
export const GIFT_STOP = 'GIFT_STOP';

// CREATE GIFT
export const CREATE_GIFT = 'CREATE_GIFT';
export const CREATE_GIFT_SUCCESS = 'CREATE_GIFT_SUCCESS';
export const CREATE_GIFT_FAIL = 'CREATE_GIFT_FAIL';

// GET GIFT
export const GET_GIFT = 'GET_GIFT';
export const GET_GIFT_START = 'GET_GIFT_START';
export const GET_GIFT_SUCCESS = 'GET_GIFT_SUCCESS';
export const GET_GIFT_FAIL = 'GET_GIFT_FAIL';

// UPDATE GIFT
export const UPDATE_GIFT = 'UPDATE_GIFT';
export const UPDATE_GIFT_SUCCESS = 'UPDATE_GIFT_SUCCESS';
export const UPDATE_GIFT_FAIL = 'UPDATE_GIFT_FAIL';
export const UPDATE_GIFT_IMAGES_SUCCESS = 'UPDATE_GIFT_IMAGES_SUCCESS';

// DELETE GIFT
export const DELETE_GIFT = 'DELETE_GIFT';
export const DELETE_GIFT_SUCCESS = 'DELETE_GIFT_SUCCESS';
export const DELETE_GIFT_FAIL = 'DELETE_GIFT_FAIL';

// CHANGE QUANTITY
export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';

// DUPLICATE GIFT
export const GIFT_DUPLICATE = 'GIFT_DUPLICATE';

// GET GIFT CHART
export const GIFT_GET_CHART = 'GIFT_GET_CHART';
export const GIFT_GET_CHART_START = 'GIFT_GET_CHART_START';
export const GIFT_GET_CHART_FAIL = 'GIFT_GET_CHART_FAIL';
export const GIFT_GET_CHART_SUCCESS = 'GIFT_GET_CHART_SUCCESS';

// GET GIFT CARDS
export const GIFT_GET_CARDS = 'GIFT_GET_CARDS';
export const GIFT_GET_CARDS_START = 'GIFT_GET_CARDS_START';
export const GIFT_GET_CARDS_FAIL = 'GIFT_GET_CARDS_FAIL';
export const GIFT_GET_CARDS_SUCCESS = 'GIFT_GET_CARDS_SUCCESS';

// ADMIN UPDATE GIST STATUS
export const UPDATE_GIFT_STATUS = 'UPDATE_GIFT_STATUS';
export const UPDATE_GIFT_STATUS_START = 'UPDATE_GIFT_STATUS_START';
export const UPDATE_GIFT_STATUS_SUCCESS = 'UPDATE_GIFT_STATUS_SUCCESS';
export const UPDATE_GIFT_STATUS_FAIL = 'UPDATE_GIFT_STATUS_FAIL';
