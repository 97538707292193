import { useEffect } from 'react';
import { useClearCacheCtx } from 'react-clear-cache';

export const useClearCache = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, [isLatestVersion]);
};

export default useClearCache;
