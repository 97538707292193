import * as actionTypes from '@/store/actions/types/employees';
import * as actionTypesRedemptions from '@/store/actions/types/redemptions';

const initialState = {
  list: [],
  error: null,
  loading: false,
  current_page: 0,
  per_page: 5,
  total: 0,
  location_name: '',
};

const generateRedemptionCode = (list, payloadData) => {
  return list.map(item => {
    if (item.id === payloadData.employeeId) {
      return { ...item, redemption_code: payloadData.redemption_code };
    }
    return item;
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMPLOYEES_START:
      return { ...state, loading: true, error: false };
    case actionTypes.EMPLOYEES_STOP:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.EMPLOYEES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data.data,
        current_page: action.payload.data.current_page - 1,
        per_page: action.payload.data.per_page,
        total: action.payload.data.total,
        location_name: action.payload.location_name,
      };

    case actionTypesRedemptions.GENERATE_REDEMPTION_CODE_USER_START:
      return { ...state, loading: true };
    case actionTypesRedemptions.GENERATE_REDEMPTION_CODE_USER_SUCCESS:
      return {
        ...state,
        list: generateRedemptionCode(state.list, action.payload),
        loading: false,
      };
    case actionTypesRedemptions.GENERATE_REDEMPTION_CODE_USER_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
