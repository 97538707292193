import styled from 'styled-components';
import { Grid, TextField } from '@material-ui/core';
import { linearGradient } from 'polished';
import breakpoints from '@/constants/breakpoints';

import colors, { deepKoamaru, white } from '@/constants/colors';

const gradientParams = {
  colorStops: [deepKoamaru, deepKoamaru],
  toDirection: '0deg',
  fallback: white,
};

export const CustomTextField = styled(TextField)`
  .MuiInputBase-input {
    padding-right: 2rem;
  }
`;

export const Wrapper = styled(Grid)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const AuthWrapper = styled(Grid)`
  min-height: 100vh;

  .right-side {
    background-color: white;
    position: relative;
  }

  .left-side {
    ${linearGradient(gradientParams)};

    @media (max-width: 959px) {
      display: none;
    }
  }

  .image-wrapp {
    height: 100%;

    img {
      max-width: 100%;
      height: auto;
      padding: 2rem;
    }
  }
`;

export const ProgressWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 1102;
  background: ${colors.background};
`;

export const ErrorBoundaryWrapper = styled.div`
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  padding: 10px;

  text-align: center;

  img {
    max-width: 100%;
  }

  h2 {
    margin-top: 40px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`;

export const TableSearchWrap = styled.div`
  padding: 25px 47px 23px;
  border-bottom: 1px solid ${colors.table.headBorder};

  @media (max-width: ${breakpoints.xs}) {
    padding: 24px 16px;
  }

  & button {
    margin-top: 10px;
  }

  & input {
    padding-left: 50px;
  }
`;
