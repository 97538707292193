// ORGANIZATIONS actions
export const ORGANIZATIONS_GET_START = 'ORGANIZATIONS_GET_START';
export const ORGANIZATIONS_GET_FAIL = 'ORGANIZATIONS_GET_FAIL';

export const ORGANIZATIONS_PUT_START = 'ORGANIZATIONS_PUT_START';
export const ORGANIZATIONS_PUT_FAIL = 'ORGANIZATIONS_PUT_FAIL';

export const ORGANIZATIONS_BALANCE_CHART = 'ORGANIZATIONS_BALANCE_CHART';
export const ORGANIZATIONS_BALANCE_CHART_START = 'ORGANIZATIONS_BALANCE_CHART_START';
export const ORGANIZATIONS_BALANCE_CHART_SUCCESS = 'ORGANIZATIONS_BALANCE_CHART_SUCCESS';
export const ORGANIZATIONS_BALANCE_CHART_FAIL = 'ORGANIZATIONS_BALANCE_CHART_FAIL';

export const ORGANIZATIONS_BALANCE_CARDS = 'ORGANIZATIONS_BALANCE_CARDS';
export const ORGANIZATIONS_BALANCE_CARDS_START = 'ORGANIZATIONS_BALANCE_CARDS_START';
export const ORGANIZATIONS_BALANCE_CARDS_SUCCESS = 'ORGANIZATIONS_BALANCE_CARDS_SUCCESS';
export const ORGANIZATIONS_BALANCE_CARDS_FAIL = 'ORGANIZATIONS_BALANCE_CARDS_FAIL';

export const ORGANIZATIONS_GET_INFO = 'ORGANIZATIONS_GET_INFO';
export const ORGANIZATIONS_GET_INFO_SUCCESS = 'ORGANIZATIONS_GET_INFO_SUCCESS';

export const ORGANIZATIONS_PUT_INFO = 'ORGANIZATIONS_PUT_INFO';
export const ORGANIZATIONS_PUT_INFO_SUCCESS = 'ORGANIZATIONS_PUT_INFO_SUCCESS';

// Organizations actions
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_START = 'GET_ORGANIZATIONS_START';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_SELECT_SUCCESS = 'GET_ORGANIZATIONS_SELECT_SUCCESS';
export const GET_ORGANIZATIONS_FAIL = 'GET_ORGANIZATIONS_FAIL';

export const GET_ARCHIVE = 'GET_ARCHIVE';
export const GET_ARCHIVE_START = 'GET_ARCHIVE_START';
export const GET_ARCHIVE_SUCCESS = 'GET_ARCHIVE_SUCCESS';
export const GET_ARCHIVE_FAIL = 'GET_ARCHIVE_FAIL';

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL';

export const SEND_INVITATION = 'SEND_INVITATION';
export const SEND_INVITATION_START = 'SEND_INVITATION_START';
export const SEND_INVITATION_SUCCESS = 'SEND_INVITATION_SUCCESS';
export const SEND_INVITATION_FAIL = 'SEND_INVITATION_FAIL';

export const RESEND_CONTRACT = 'RESEND_CONTRACT';
export const RESEND_CONTRACT_START = 'RESEND_CONTRACT_START';
export const RESEND_CONTRACT_SUCCESS = 'RESEND_CONTRACT_SUCCESS';
export const RESEND_CONTRACT_FAIL = 'RESEND_CONTRACT_FAIL';

export const GET_TEMPLATES = 'GET_TEMPLATES';
export const GET_TEMPLATES_START = 'GET_TEMPLATES_START';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAIL = 'GET_TEMPLATES_FAIL';

export const UPLOAD_BUSINESS_LOGO = 'UPLOAD_BUSINESS_LOGO';
export const UPLOAD_BUSINESS_LOGO_START = 'UPLOAD_BUSINESS_LOGO_START';
export const UPLOAD_BUSINESS_LOGO_SUCCESS = 'UPLOAD_BUSINESS_LOGO_SUCCESS';
export const UPLOAD_BUSINESS_LOGO_FAIL = 'UPLOAD_BUSINESS_LOGO_FAIL';
