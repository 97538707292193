import axios from 'axios';
import { put, call, select } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import * as actionTypes from '@/store/actions/types/stripe';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { getMe } from '@/store/saga/auth';

export function* setStripeTokenSaga(action) {
  const { stripe, name, onClose } = action.payload;
  yield put({ type: actionTypes.STRIPE_START });
  try {
    const { token } = yield call(stripe.createToken, { name });
    const res = yield axios.post(endpoints.SET_STRIPE_TOKEN, { stripeToken: token.id });
    const { data } = res;
    yield put({
      type: actionTypes.STRIPE_SUCCESS,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: data.message,
        options: {
          variant: 'success',
        },
      },
    });
    yield call(getMe);
    if (onClose) {
      yield call(onClose);
    }
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.STRIPE_FAIL,
    });
  }
}

export const organizationId = state => state.auth?.organization_id;

export function* setStripeConnectSaga(action) {
  const { code, cleanSearch } = action.payload;
  yield put({ type: actionTypes.STRIPE_START });
  try {
    const organization_id = yield select(organizationId);
    const { data } = yield axios.post(endpoints.SET_STRIPE_CONNECT, { code, organization_id });
    yield put({
      type: actionTypes.STRIPE_SUCCESS,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: data.message,
        options: {
          variant: 'success',
        },
      },
    });
    if (cleanSearch) {
      cleanSearch();
    }
    yield call(getMe);
  } catch (error) {
    yield put({
      type: actionTypes.STRIPE_FAIL,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
  }
}
