import * as Yup from 'yup';

import signInSchema from './schemas/signInSchema';
import signUpSchema from './schemas/signUpSchema';
import resetPasswordSchema from './schemas/resetPasswordSchema';
import createGiftSchema from './schemas/createGiftSchema';
import profileMainInfo from './schemas/profileMainInfo';
import profileSecurity from './schemas/profileSecurity';
import profileBilling from './schemas/profileBilling';
import profileEmailNotifications from './schemas/profileEmailNotifications';
import changeQuantitySchema from './schemas/changeQuantitySchema';
import organizationSchema from './schemas/organizationSchema';
import completeProfileSchema from './schemas/completeProfileSchema';
import { giftCardAmount, redeemCode, secretSymbol } from './schemas/redemptionsSchema';
import addSingleLocationSchema from './schemas/addSingleLocationSchema';
import addEditEmployeeSchema from './schemas/addEditEmployeeSchema';
import chooseAmountSchema from './schemas/chooseAmountSchema';
import createOrganizationSchema from './schemas/createOrganizationSchema';
import createCategorySchema from './schemas/createCategorySchema';
import createPromoGroupSchema from './schemas/createPromoGroupSchema';
import createSurveySchema from './schemas/createSurveySchema';
import resendEmailSchema from './schemas/resendEmailSchema';
import exportStatisticsSchema from './schemas/exportStatisticsSchema';
import createEditAdminSchema from './schemas/createEditAdminSchema';
import adminsVerifySchema from './schemas/adminsVerifySchema';
import support from './schemas/support';
import editWorkHours from './schemas/editWorkHours';
import downloadQrCode from './schemas/downloadQrCode';
import terms from './schemas/terms';
import privacy from './schemas/privacy';
import addBusinessLogo from './schemas/addBusinessLogo';
import editGiftCardSchema from './schemas/editGiftCardSchema';
import createMyOrganizationSchema from './schemas/createMyOrganizationSchema';
import addNotificationsForUsers from './schemas/addNotificationsForUsers';
import sentUniversalCardShame from './schemas/sentUniversalCardShame';
import timePickerDialogShame from './schemas/timePickerDialogShame';
import updateAdminSettings from '@/utility/validationSchema/schemas/updateAdminSettings';

const setYupValidationSchema = (schemaName, t, step, data = {}) => {
  let schema;

  switch (schemaName) {
    case 'SignInSchema':
      schema = signInSchema(t);
      break;
    case 'SignUpSchema':
      schema = signUpSchema(t)[step] ? signUpSchema(t)[step] : {};
      break;
    case 'ResetPasswordSchema':
      schema = resetPasswordSchema(t);
      break;
    case 'CreateGiftSchema':
      schema = createGiftSchema(t, data)[step] ? createGiftSchema(t, data)[step] : {};
      break;
    case 'editGiftCardSchema':
      schema = editGiftCardSchema(t)[step] ? editGiftCardSchema(t)[step] : {};
      break;
    case 'ProfileMainInfo':
      schema = profileMainInfo(t);
      break;
    case 'ProfileSecurity':
      schema = profileSecurity(t);
      break;
    case 'ProfileBilling':
      schema = profileBilling(t);
      break;
    case 'ProfileEmailNotifications':
      schema = profileEmailNotifications(t);
      break;
    case 'ChangeQuantitySchema':
      schema = changeQuantitySchema(t);
      break;
    case 'OrganizationSchema':
      schema = organizationSchema(t, data);
      break;
    case 'CompleteProfileSchema':
      schema = completeProfileSchema(t);
      break;
    case 'redemptionCodeSchema':
      schema = redeemCode(t);
      break;
    case 'redemptionAmountSchema':
      schema = giftCardAmount(t, step);
      break;
    case 'redemptionSecretSymbol':
      schema = secretSymbol(t);
      break;
    case 'AddSingleLocationSchema':
      schema = addSingleLocationSchema(t);
      break;
    case 'AddEditEmployeeSchema':
      schema = addEditEmployeeSchema(t, data);
      break;
    case 'ChooseAmountSchema':
      schema = chooseAmountSchema(t);
      break;
    case 'CreateOrganizationSchema':
      schema = createOrganizationSchema(t, data)[step]
        ? createOrganizationSchema(t, data)[step]
        : {};
      break;
    case 'CreateCategorySchema':
      schema = createCategorySchema(t);
      break;
    case 'CreatePromoGroupSchema':
      schema = createPromoGroupSchema(t);
      break;
    case 'CreateSurveySchema':
      schema = createSurveySchema(t);
      break;
    case 'ResendEmailSchema':
      schema = resendEmailSchema(t);
      break;
    case 'ExportStatisticsSchema':
      schema = exportStatisticsSchema(t);
      break;
    case 'CreateEditAdminSchema':
      schema = createEditAdminSchema(t);
      break;
    case 'AdminsVerifySchema':
      schema = adminsVerifySchema(t);
      break;
    case 'Support':
      schema = support(t);
      break;
    case 'editWorkHours':
      schema = editWorkHours(t);
      break;
    case 'downloadQrCode':
      schema = downloadQrCode(t);
      break;
    case 'terms':
      schema = terms(t);
      break;
    case 'privacy':
      schema = privacy(t);
      break;
    case 'addBusinessLogo':
      schema = addBusinessLogo(t);
      break;
    case 'updateAdminSettings':
      schema = updateAdminSettings(t);
      break;
    case 'createMyOrganizationSchema':
      schema = createMyOrganizationSchema(t);
      break;
    case 'addNotificationsForUsers':
      schema = addNotificationsForUsers(t);
      break;
    case 'timePickerDialogShame':
      schema = timePickerDialogShame(t, data);
      break;
    case 'sentUniversalCardShame':
      schema = sentUniversalCardShame(data);
      break;
    default:
      schema = {};
      break;
  }

  return Yup.object().shape(schema);
};

export default setYupValidationSchema;
