import * as actionTypes from './types/transactions';

export const getTransactionsList = params => {
  return {
    type: actionTypes.TRANSACTIONS_GET_LIST,
    payload: params,
  };
};

export const getLastTransactions = organizationId => {
  return {
    type: actionTypes.TRANSACTIONS_GET_LAST,
    payload: {
      organizationId,
    },
  };
};

export const getDetailsTransaction = transactionId => {
  return {
    type: actionTypes.GET_DETAIL_TRANSACTION,
    payload: {
      transactionId,
    },
  };
};

export const updateStatusTransaction = params => {
  return {
    type: actionTypes.UPDATE_STATUS_TRANSACTION,
    payload: params,
  };
};
