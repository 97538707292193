// GIFT actions
export const CATEGORIES_START = 'CATEGORIES_START';
// GET GIFT
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

// Admin categories
export const CATEGORIES_GET_LIST = 'CATEGORIES_GET_LIST';
export const CATEGORIES_GET_LIST_START = 'CATEGORIES_GET_LIST_START';
export const CATEGORIES_GET_LIST_SUCCESS = 'CATEGORIES_GET_LIST_SUCCESS';
export const CATEGORIES_GET_LIST_FAIL = 'CATEGORIES_GET_LIST_FAIL';

// Admin promo groups
export const PROMO_GROUPS_GET_LIST = 'PROMO_GROUPS_GET_LIST';
export const PROMO_GROUPS_GET_LIST_START = 'PROMO_GROUPS_GET_LIST_START';
export const PROMO_GROUPS_GET_LIST_SUCCESS = 'PROMO_GROUPS_GET_LIST_SUCCESS';
export const PROMO_GROUPS_GET_LIST_FAIL = 'PROMO_GROUPS_GET_LIST_FAIL';

// Admin categories select
export const CATEGORIES_GET_SELECT_SUCCESS = 'CATEGORIES_GET_SELECT_SUCCESS';

// Admin create category
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';

// Admin create promo group
export const CREATE_PROMO_GROUP = 'CREATE_PROMO_GROUP';
export const CREATE_PROMO_GROUP_START = 'CREATE_PROMO_GROUP_START';
export const CREATE_PROMO_GROUP_SUCCESS = 'CREATE_PROMO_GROUP_SUCCESS';
export const CREATE_PROMO_GROUP_FAIL = 'CREATE_PROMO_GROUP_FAIL';

// Admin update category
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL';

// Admin update promo group
export const UPDATE_PROMO_GROUP = 'UPDATE_PROMO_GROUP';
export const UPDATE_PROMO_GROUP_START = 'UPDATE_PROMO_GROUP_START';
export const UPDATE_PROMO_GROUP_SUCCESS = 'UPDATE_PROMO_GROUP_SUCCESS';
export const UPDATE_PROMO_GROUP_FAIL = 'UPDATE_PROMO_GROUP_FAIL';

// Admin get category
export const GET_CATEGORY_DETAIL = 'GET_CATEGORY_DETAIL';
export const GET_CATEGORY_DETAIL_START = 'GET_CATEGORY_DETAIL_START';
export const GET_CATEGORY_DETAIL_SUCCESS = 'GET_CATEGORY_DETAIL_SUCCESS';
export const GET_CATEGORY_DETAIL_FAIL = 'GET_CATEGORY_DETAIL_FAIL';

// Admin get promo group
export const GET_PROMO_GROUP_DETAIL = 'GET_PROMO_GROUP_DETAIL';
export const GET_PROMO_GROUP_DETAIL_START = 'GET_PROMO_GROUP_DETAIL_START';
export const GET_PROMO_GROUP_DETAIL_SUCCESS = 'GET_PROMO_GROUP_DETAIL_SUCCESS';
export const GET_PROMO_GROUP_DETAIL_FAIL = 'GET_PROMO_GROUP_DETAIL_FAIL';

// Admin delete category
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL';

// Admin delete promo group
export const DELETE_PROMO_GROUP = 'DELETE_PROMO_GROUP';
export const DELETE_PROMO_GROUP_START = 'DELETE_PROMO_GROUP_START';
export const DELETE_PROMO_GROUP_SUCCESS = 'DELETE_PROMO_GROUP_SUCCESS';
export const DELETE_PROMO_GROUP_FAIL = 'DELETE_PROMO_GROUP_FAIL';

// Admin subcategories
export const SUBCATEGORIES_GET_LIST = 'SUBCATEGORIES_GET_LIST';
export const SUBCATEGORIES_GET_LIST_START = 'SUBCATEGORIES_GET_LIST_START';
export const SUBCATEGORIES_GET_LIST_SUCCESS = 'SUBCATEGORIES_GET_LIST_SUCCESS';
export const SUBCATEGORIES_GET_LIST_FAIL = 'SUBCATEGORIES_GET_LIST_FAIL';
