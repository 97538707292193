import * as actionTypes from '@/store/actions/types/workHours';

const initialState = {
  loading: false,
  days: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_WORK_HOURS_START:
      return { ...state, loading: true };
    case actionTypes.GET_WORK_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        days: action.payload.data,
      };
    case actionTypes.GET_WORK_HOURS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.CHANGE_WORK_HOURS_START:
      return { ...state, loading: true };
    case actionTypes.CHANGE_WORK_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        days: action.payload.data,
      };
    case actionTypes.CHANGE_WORK_HOURS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
