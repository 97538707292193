import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import colors from '@/constants/colors';

import footerBg from '@/assets/images/sb_footer.png';
import { IconButton } from '@material-ui/core';

const ForwardRefNavLink = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />);

export const styles = theme => ({
  root: {
    display: 'flex',
    width: '240px',
  },
  drawerPaper: {
    border: 'none',
    background: colors.sideMenu,
    width: '240px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: '0px',
  },
  drawerClose: {
    background: colors.sideMenu,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 2,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  menuList: {
    marginTop: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  menuItem: {
    color: colors.navigationText,
    paddingLeft: '23px',
    height: '48px',
    '& i': {
      fontSize: '2.3rem',
      color: colors.navigationIconNotActive,
    },
    '&:hover': {
      backgroundColor: colors.navigationHover,
    },
    '&:hover i': {
      color: colors.navigationIconHover,
    },
    '&.active': {
      backgroundColor: colors.navigationActive,
    },
    '&.active i': {
      color: colors.navigationIconActive,
    },
  },
  utilityList: {
    height: '230px',
    marginTop: 'auto',
    background: `url(${footerBg}) 100% 100% no-repeat`,
    '&:focus': {
      outline: 'none',
    },
  },
  divider: {
    backgroundColor: colors.deviderBg,
    marginBottom: '20px',
  },
});

export const LogoWrapper = styled.div`
  width: 100%;
`;

export const Logo = styled.img`
  width: 89px;
  height: 39px;
  margin: 28px 0 0 28px;
`;

export const NavLinkS = styled(ForwardRefNavLink)`
  span {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &:focus {
    background: ${colors.navigationFocus};
  }
`;

export const MenuIcon = styled.i`
  font-size: ${p => p.fontSize};
`;

export const MenuText = styled.span`
  font-family: SofiaPro;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-left: 12px;
`;
export const BurgerButton = styled(IconButton)`
  && {
    position: fixed;
    top: 25px;
    left: 0;
    z-index: 1101; //AppBar + 1
    @media (max-width: 960px) {
      top: 8px;
    }
  }
`;
