import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, IconButton, Typography, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { t } from '@/locale/i18n';
import Dialog from '@/components/Dialogs';
import { StyledFiltersDialogTitle, StyledDialogContent, StyledDialogActions } from './styles';
import AutoCompleteSelect from '@/components/AutoCompleteSelect';

const switchAccountDialog = props => {
  const {
    dialogIsOpen,
    handleClose,
    accounts,
    activeAccount,
    switchAccount,
    loader,
    handleCreate,
    disabledChoose,
    title,
    isPaymentSwitcher,
  } = props;
  const [currentAccount, setCurrentAccount] = useState(activeAccount);
  const dialogTitle = isPaymentSwitcher
    ? t('switchAccountDialog.selectPaymentAccount')
    : t('switchAccountDialog.selectAccount');

  return (
    <Dialog
      PaperProps={{ style: { overflowY: 'visible' } }}
      open={dialogIsOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <StyledFiltersDialogTitle>
        <Typography variant="h3">{title || dialogTitle}</Typography>
        <IconButton aria-label="Close" onClick={handleClose}>
          <Close />
        </IconButton>
      </StyledFiltersDialogTitle>
      <StyledDialogContent style={{ overflowY: 'visible' }}>
        <p className="content-title p5">{dialogTitle}:</p>
        <Grid item md={12} xs={12} spacing={3}>
          <AutoCompleteSelect
            PaperProps={{ style: { marginTop: 0 } }}
            isLoading={false}
            isDisabled={false}
            name="business"
            inputId="business-input"
            inputStyles={{
              marginTop: 0,
              marginBottom: 0,
            }}
            options={accounts}
            onChange={setCurrentAccount}
            value={currentAccount}
            placeholder={dialogTitle}
            SelectProps={{
              displayEmpty: true,
            }}
            fullWidth
          />
        </Grid>
      </StyledDialogContent>
      <StyledDialogActions disableSpacing>
        <Grid container spacing={2} justify="space-between">
          <Grid style={{ display: 'flex' }} item direction="row" spacing={2}>
            <Grid item>
              <Button
                id="modal-apply-button"
                type="button"
                onClick={() => switchAccount(currentAccount)}
                disabled={disabledChoose}
              >
                {loader ? <CircularProgress size={25} /> : t('buttons.select')}
              </Button>
            </Grid>
          </Grid>
          {handleCreate && (
            <Grid item>
              <Button
                id="modal-create-button"
                onClick={handleCreate}
                color="secondary"
                variant="outlined"
              >
                {t('buttons.createOrganizations')}
              </Button>
            </Grid>
          )}
        </Grid>
      </StyledDialogActions>
    </Dialog>
  );
};

switchAccountDialog.defaultProps = {
  activeAccount: null,
  accounts: [],
  disabledChoose: false,
  isPaymentSwitcher: false,
  handleCreate: null,
  title: '',
};

switchAccountDialog.propTypes = {
  dialogIsOpen: PropTypes.bool.isRequired,
  applyFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  switchAccount: PropTypes.func.isRequired,
  handleCreate: PropTypes.func,
  activeAccount: PropTypes.object,
  accounts: PropTypes.array,
  loader: PropTypes.bool.isRequired,
  disabledChoose: PropTypes.bool,
  isPaymentSwitcher: PropTypes.bool,
  title: PropTypes.string,
};

export default switchAccountDialog;
