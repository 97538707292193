import * as actionTypes from '@/store/actions/types/giftsList';
import * as authActionTypes from '@/store/actions/types/auth';

const initialState = {
  list: [],
  searchList: [],
  loading: true,
  searchLoading: false,
  nextPage: null,
  current_page: 0,
  total_pages: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GIFTS_LIST_START:
      return {
        ...state,
        loading: !action.payload.isSearch,
        searchLoading: action.payload.isSearch,
      };
    case actionTypes.GET_GIFTS_LIST_SUCCESS: {
      return {
        ...state,
        list:
          action.payload.nextPage && !action.payload.reset
            ? state.list.concat(action.payload.data)
            : action.payload.data,
        searchList: [],
        current_page: action.payload.meta?.current_page,
        total_pages: action.payload.meta?.total_pages,
        loading: false,
      };
    }
    case actionTypes.GET_GIFTS_LIST_SUCCESS_SEARCH: {
      return {
        ...state,
        searchList:
          action.payload.nextPage && !action.payload.reset
            ? state.searchList.concat(action.payload.data)
            : action.payload.data,
        current_page: action.payload.meta?.current_page,
        total_pages: action.payload.meta?.total_pages,
        searchLoading: false,
      };
    }
    case actionTypes.GET_GIFTS_LIST_FAIL:
      return { ...state, loading: false, searchLoading: false };
    case authActionTypes.AUTH_LOGIN_AS_ORGANIZATION_SUCCESS:
    case authActionTypes.AUTH_LOGIN_AS_ADMIN:
    case authActionTypes.AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
