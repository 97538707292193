import styled from 'styled-components';
import colors from '@/constants/colors';
import ReactPlayer from 'react-player';

const { dropZone } = colors;

export const VideoPlayer = styled(ReactPlayer)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${dropZone.bg};
  border: 1px dashed ${dropZone.border};
  border-radius: 4px;
  margin-bottom: 15px;
`;

export const CropperTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  svg {
    cursor: pointer;
  }
`;

export const PreviewContainer = styled.div`
  &.video-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${dropZone.bg};
    border: 1px dashed ${dropZone.border};
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;

    video {
      max-width: 600px;
    }
  }

  &.image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${dropZone.bg};
    border: 1px dashed ${dropZone.border};
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;

    img {
      max-width: 100%;
    }

    .image-preview {
      &__btn {
        padding: 0 5px;
      }
      &__crop {
        max-height: 400px;
        max-width: 100%;
        margin-bottom: 15px;
      }
      &__crop-btn {
        margin-bottom: 10px;
      }
      &__remove-btn {
        line-height: 24px;
      }
    }

    .drop-filed {
      &__title {
        line-height: 1.8rem;
        opacity: 0.7;
        margin-bottom: 5px;
      }
      &__subtitle {
        line-height: 1.8rem;
        margin-bottom: 17px;
      }
    }

    &.m-b-0 {
      margin-bottom: 0;
    }
  }
`;
