import * as giftConst from '@/constants/gift';

export const getGiftData = data => {
  const step = data.step;
  const name = data.name ? { name: data.name } : {};
  const description = data.description ? { description: data.description } : {};
  const amount_from = data.amount_from ? { amount_from: data.amount_from.toString() } : {};
  const amount_to = data.amount_to ? { amount_to: data.amount_to.toString() } : {};
  const discount = data.discount ? { discount: data.discount } : { discount: 0 };
  const tags = data.tags ? data.tags.map(el => el.id) : [];
  const quantity_type = data.quantity_type ? { quantity_type: data.quantity_type } : {};
  const quantity = data.quantity ? { quantity: data.quantity } : {};
  const redemption_type = data.redemption_type ? { redemption_type: data.redemption_type } : {};
  const redemption_frequency = data.redemption_frequency
    ? { redemption_frequency: data.redemption_frequency }
    : {};
  const expiration_changed = data.expiration_changed;
  const expiration_type = data.expiration_type ? { expiration_type: data.expiration_type } : {};
  const expiration_value = data.expiration_value ? { expiration_value: data.expiration_value } : {};
  const spread = data.spread ? { spread: data.spread } : {};
  const locations = data.locations ? data.locations : [];
  let payment_type = {};
  let cost = {};

  if (step >= 4) {
    payment_type = data.payment_type ? { payment_type: data.payment_type } : {};

    if (data.type === giftConst.TYPE_GIFT) {
      if (data.payment_type === giftConst.GIFT_TYPE_PAID) {
        cost = { cost: +data.cost };
      } else {
        cost = { cost: null };
      }
    }
  }

  return {
    type: data.type,
    status: data.status,
    step: data.step,
    amounts: data.amounts,
    is_open: data.is_open,
    expiration_changed,
    locations,
    tags,
    ...expiration_type,
    ...expiration_value,
    ...spread,
    ...name,
    ...description,
    ...amount_from,
    ...amount_to,
    ...discount,
    ...cost,
    ...payment_type,
    ...quantity_type,
    ...quantity,
    ...redemption_type,
    ...redemption_frequency,
  };
};

export default getGiftData;
