import { createGlobalStyle } from 'styled-components';

import colors, { white, baliHai } from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';
import SofiaProLight from '@/assets/fonts/SofiaPro/SofiaPro-Light.ttf';
import SofiaProRegular from '@/assets/fonts/SofiaPro/SofiaPro-Regular.ttf';
import SofiaProMedium from '@/assets/fonts/SofiaPro/SofiaPro-Medium.ttf';
import SofiaProBlack from '@/assets/fonts/SofiaPro/SofiaPro-Black.ttf';
import OpenSansRegular from '@/assets/fonts/OpenSans/OpenSans-Regular.ttf';
import OpenSansSemiBold from '@/assets/fonts/OpenSans/OpenSans-SemiBold.ttf';

import '@/assets/fonts/icomoon/style.css';

const AppWrapper = createGlobalStyle`

  @font-face {
    font-family: 'SofiaPro';
    src: local('SofiaPro Light'),
       local('SofiaPro-Light'),
       url(${SofiaProLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SofiaPro';
    src: local('SofiaPro Regular'),
       local('SofiaPro-Regular'),
       url(${SofiaProRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'SofiaPro';
    src: local('SofiaPro Medium'),
       local('SofiaPro-Medium'),
       url(${SofiaProMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'SofiaPro';
    src: local('SofiaPro Black'),
       local('SofiaPro-Black'),
       url(${SofiaProBlack}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'),
       local('OpenSans'),
       url(${OpenSansRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'OpenSans';
    src: local('OpenSans SemiBold'),
       local('OpenSans-SemiBold'),
       url(${OpenSansSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  * {
    box-sizing: border-box;
  }

  html {
    min-height: 100%;
    font-size: 10px;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'SofiaPro', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 1.8rem;
    line-height: 3.1rem;
    font-weight: 300;
    background-color: ${colors.background};
    color: ${colors.primary};
    margin: 0;
    padding: 0;
  }

  .App {
    min-width: 320px;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Typography
  h1 {
    font-size: 4rem;
    line-height: 4.2rem;
    font-weight: 300;
  }

  h2 {
    font-size: 3.2rem;
    line-height: 3.6rem;
    font-weight: 300;
    margin-bottom: 3.8rem;
  }

  h3 {
    font-size: 2.4rem;
    line-height: 2.6rem;
    font-weight: 300;
  }

  h4 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    font-weight: 300;
  }

  h5 {
    font-size: 1.2rem;
    line-height: 2.4rem;
    font-weight: 300;
  }

  .p1, .p2, .p3, .p4, .p5 {
    margin: 0;
    &.textSecondary{
      color: ${colors.textSecondary}
    }
  }

  .p1 {
    font-size: 2.4rem;
    line-height: 3.5rem;
    font-weight: 400;
  }

  .p2 {
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 400;
  }

  .p3 {
    font-size: 1.8rem;
    line-height: 3.1rem;
    font-weight: 300;
  }

  .p4 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
  }

  .p5 {
    font-size: 1.4rem;
    line-height: 2.6rem;
    font-weight: 400;
  }

  .text-center {
    text-align: center;
    &__link{
    && {
    cursor: pointer;
    color: #6650EF;
    &:hover{
    text-decoration: underline;
    }
    }
    }
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .m-r-10 {
    margin-right: 10px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .pos-relative {
    position: relative;
  }

  // Form
  form {

    .label {

      &__parent {
        position: relative;
      }

      &--fullwidth {
        position: static;
      }

      &--expiration {

        .MuiInputLabel-formControl {
          top: 12px;
          left: 12px;
        }
      }
    }

    .buttons-field {
      margin-top: 50px;

      @media (max-width: 600px){
        margin-top: 25px;
      }
    }
    .loader-btn {
      margin-left: 10px;
    }
  }

  // Page Content
  .page-content {
    padding: 28px;
    height: 100%;

    @media screen and (max-width: ${breakpoints.sm}) {
      padding: 28px 16px 16px;
    }
  }

  // Static Pages
  .static-pages {
    h1, h2 {
      padding: 3.4rem 0 2.2rem;

      @media (max-width: ${breakpoints.xs}) {
        padding-top: 2.4rem;
      }
    }

    hr {
      margin: 8px 0;
    }

    p {
      padding: 0.6rem 0 1rem;
    }

    ul {
      padding: 3.6rem 0 3.6rem;
      margin: 0;
      list-style: none;

      @media (max-width: ${breakpoints.xs}) {
        padding: 2.4rem 0 2.4rem;
      }

      li {
        &:before {
          content: '\\2022';
          color: ${colors.listItemBulletColor};
          font-weight: bold;
          display: inline-block;
          padding-right: 2.7rem;

          @media (max-width: ${breakpoints.xs}) {
            padding-right: 1.6rem;
          }
        }
      }
    }
  }

  // Pac container
  .pac-container {
    font-family: 'SofiaPro', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    border-radius: .4rem;
    box-shadow: 0 2px 12px rgba(26, 75, 120, 0.07);
    padding: 10px 0;
    z-index: 1301;

    &.pac-logo {
      &:after {
        margin: 0 22px;
      }
    }

    .pac-item {
       font-size: 1.4rem;
       line-height: 2.4rem;
       font-weight: 400;
       padding: 11px 22px;
       color: ${colors.textSecondary};
       border-top: none;

       &:hover {
        background-color: ${colors.padItemHover};
       }
    }

    .pac-icon {
      display: none;
    }

    .pac-item-query {
      font-size: 1.6rem;
      color: ${colors.selectItem};
    }
  }

  // Text color
  .text-color {

    &--white {
      color: ${white};
    }

    &--grey {
      color: ${baliHai};
    }
  }

  // Grey Title
  .grey-title {
    text-transform: uppercase;
    color: ${baliHai};
    white-space: nowrap;
  }

  // Highcharts
  .highcharts-tooltip {
    box-shadow: 0 2px 12px rgba(26, 75, 120, 0.07);
  }
  .highcharts-tooltip span {
    font-family: 'SofiaPro', sans-serif;
    font-weight: 400;
    line-height: 24px;
    min-width: 150px;

    .highcharts-tooltip-header {
      font-size: 1.2rem;
      color: ${colors.highchart.tooltipHeaderText};
      text-transform: uppercase;
    }

    .highcharts-tooltip-subtitle {
      font-size: 1.6rem;
      color: ${colors.highchart.tooltipSubtitleText};
      text-transform: capitalize;
    }
  }

  // Stepper
  .MuiStepper-root {
    @media screen and (max-width: ${breakpoints.xs}) {
      .MuiStepLabel-label{
        display: none;
      }
    }
  }
`;

export default AppWrapper;
