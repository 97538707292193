import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/inbox';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { checkNotificationsSaga } from '@/store/saga/accounts';

export function* getInboxListSaga(action) {
  const { id, filter, ...params } = action.payload;
  yield put({ type: actionTypes.INBOX_START });
  const url = getLink(endpoints.GET_INBOX_LIST, { id, filter });
  try {
    const res = yield axios.get(url, { params: { ...params } });
    const { data } = res;

    yield put({
      type: actionTypes.INBOX_LIST_SUCCESS,
      payload: data,
    });

    yield call(checkNotificationsSaga, { payload: { id } });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.INBOX_STOP,
    });
  }
}

export default getInboxListSaga;
