// ACCOUNTS actions
export const ACCOUNTS_GET_START = 'ACCOUNTS_GET_START';
export const ACCOUNTS_PUT_START = 'ACCOUNTS_PUT_START';

export const ACCOUNTS_PUT_MAIN_INFO = 'ACCOUNTS_PUT_MAIN_INFO';
export const ACCOUNTS_PUT_MAIN_INFO_SUCCESS = 'ACCOUNTS_PUT_MAIN_INFO_SUCCESS';
export const ACCOUNTS_PUT_MAIN_INFO_FAIL = 'ACCOUNTS_PUT_MAIN_INFO_FAIL';

export const ACCOUNTS_PUT_SECURITY = 'ACCOUNTS_PUT_SECURITY';
export const ACCOUNTS_PUT_SECURITY_SUCCESS = 'ACCOUNTS_PUT_SECURITY_SUCCESS';
export const ACCOUNTS_PUT_SECURITY_FAIL = 'ACCOUNTS_PUT_SECURITY_FAIL';

export const ACCOUNTS_GET_EMAIL_NOTIFICATIONS = 'ACCOUNTS_GET_EMAIL_NOTIFICATIONS';
export const ACCOUNTS_GET_EMAIL_NOTIFICATIONS_SUCCESS = 'ACCOUNTS_GET_EMAIL_NOTIFICATIONS_SUCCESS';
export const ACCOUNTS_GET_EMAIL_NOTIFICATIONS_FAIL = 'ACCOUNTS_GET_EMAIL_NOTIFICATIONS_FAIL';

export const ACCOUNTS_PUT_EMAIL_NOTIFICATIONS = 'ACCOUNTS_PUT_EMAIL_NOTIFICATIONS';
export const ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_SUCCESS = 'ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_SUCCESS';
export const ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_FAIL = 'ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_FAIL';

export const ACCOUNTS_GET_BILLING_FAIL = 'ACCOUNTS_GET_BILLING_FAIL';
export const ACCOUNTS_GET_BILLING = 'ACCOUNTS_GET_BILLING';
export const ACCOUNTS_GET_BILLING_SUCCESS = 'ACCOUNTS_GET_BILLING_SUCCESS';

export const CHANGE_STRIPE_ACCOUNT = 'CHANGE_STRIPE_ACCOUNT';
export const CHANGE_STRIPE_ACCOUNT_START = 'CHANGE_STRIPE_ACCOUNT_START';
export const CHANGE_STRIPE_ACCOUNT_SUCCESS = 'CHANGE_STRIPE_ACCOUNT_SUCCESS';
export const CHANGE_STRIPE_ACCOUNT_FAIL = 'CHANGE_STRIPE_ACCOUNT_FAIL';

export const CHANGE_SQUARE_ACCOUNT = 'CHANGE_SQUARE_ACCOUNT';
export const CHANGE_SQUARE_ACCOUNT_START = 'CHANGE_SQUARE_ACCOUNT_START';
export const CHANGE_SQUARE_ACCOUNT_SUCCESS = 'CHANGE_SQUARE_ACCOUNT_SUCCESS';
export const CHANGE_SQUARE_ACCOUNT_FAIL = 'CHANGE_SQUARE_ACCOUNT_FAIL';

export const ACCOUNTS_PUT_BILLING_FAIL = 'ACCOUNTS_PUT_BILLING_FAIL';
export const ACCOUNTS_PUT_BILLING = 'ACCOUNTS_PUT_BILLING';
export const ACCOUNTS_PUT_BILLING_SUCCESS = 'ACCOUNTS_PUT_BILLING_SUCCESS';

export const CHECK_NOTIFICATIONS = 'CHECK_NOTIFICATIONS';
export const CHECK_NOTIFICATIONS_SUCCESS = 'CHECK_NOTIFICATIONS_SUCCESS';
