// Customers actions
export const CSV_START = 'CSV_START';
export const CSV_STOP = 'CSV_STOP';
export const CSV_CLEAR = 'CSV_CLEAR';

export const CSV_IMPORT_LOCATIONS = 'CSV_IMPORT_LOCATIONS';
export const CSV_IMPORT_LOCATIONS_SUCCESS = 'CSV_IMPORT_LOCATIONS_SUCCESS';

export const CSV_SAVE_LOCATIONS = 'CSV_SAVE_LOCATIONS';
export const CSV_SAVE_LOCATIONS_SUCCESS = 'CSV_SAVE_LOCATIONS_SUCCESS';

export const CSV_IMPORT_EMPLOYEES = 'CSV_IMPORT_EMPLOYEES';
export const CSV_IMPORT_EMPLOYEES_SUCCESS = 'CSV_IMPORT_EMPLOYEES_SUCCESS';

export const CSV_SAVE_EMPLOYEES = 'CSV_SAVE_EMPLOYEES';
export const CSV_SAVE_EMPLOYEES_SUCCESS = 'CSV_SAVE_EMPLOYEES_SUCCESS';

export const CSV_EXPORT_STATISTICS = 'CSV_EXPORT_STATISTICS';
export const CSV_EXPORT_STATISTICS_SUCCESS = 'CSV_EXPORT_STATISTICS_SUCCESS';
