import * as actionTypes from './types/sharing';

export const getSharingInfo = organizationId => {
  return {
    type: actionTypes.SHARING_GET_INFO,
    payload: {
      organizationId,
    },
  };
};

export const downloadQRCodeSharing = params => {
  return {
    type: actionTypes.DOWNLOAD_SHARING_FILE,
    payload: params,
  };
};

export default getSharingInfo;
