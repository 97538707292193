import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import * as imageSizes from '@/constants/images';
import DropZone from '@/components/DropZone';
import ImagePreview from './help/ImagePreview';
import FieldContainer from './styles';
import VideoPreview from '@/components/Form/DropZoneField/help/VideoPreview';
import { MAX_VIDEO_SIZE } from '@/constants/validation';
import { ORGANIZATION_VIDEO_HEIGHT } from '@/constants/videos';

const dropZoneField = props => {
  const {
    className,
    name,
    error,
    accept,
    title,
    subtitle,
    limit,
    dropPlaceholder,
    onDropAccepted,
    onImagesUpdated,
    rootProps,
    inputProps,
    multiple,
    images,
    cropAspect,
    cropHeight,
    cropWidth,
    onFileDialogCancel,
    isBothCropper,
    recommendWidth,
    recommendHeight,
    setRef,
    type,
    url,
    onVideoUpdated,
    onError,
    displayPreviewTitle,
  } = props;

  const handleCropImage = (croppedImage, index) => {
    const updatedImages = [...images];
    updatedImages[index] = croppedImage;
    URL.revokeObjectURL(images[index].src);
    onImagesUpdated(updatedImages);
  };

  const handleRemoveImage = index => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    URL.revokeObjectURL(images[index].src);
    onImagesUpdated(updatedImages);
    onFileDialogCancel();
  };

  const handleRemoveVideo = errorType => {
    onVideoUpdated([]);
    onError(errorType);
    onFileDialogCancel();
  };

  const container = () => {
    if (type === 'video') {
      const src = url[0] instanceof Object ? url[0].src : url[0];
      return url.length ? (
        <VideoPreview
          name={name}
          data={src}
          recommendWidth={recommendWidth}
          recommendHeight={recommendHeight}
          onRemoveVideo={handleRemoveVideo}
        />
      ) : (
        <DropZone
          accept="video/*"
          name={name}
          onDropAccepted={files => {
            onDropAccepted(files);
          }}
          onFileDialogCancel={onFileDialogCancel}
          multiple={multiple}
          inputProps={inputProps}
          rootProps={rootProps}
          limit={limit}
          error={error}
          maxSize={MAX_VIDEO_SIZE}
          height={ORGANIZATION_VIDEO_HEIGHT}
        >
          {dropPlaceholder}
        </DropZone>
      );
    }
    if (type === 'image') {
      return (
        <>
          {images?.map((imageData, index) => (
            <ImagePreview
              name={name}
              data={imageData}
              isBothCropper={isBothCropper}
              recommendWidth={recommendWidth}
              recommendHeight={recommendHeight}
              onCropImage={croppedImage => handleCropImage(croppedImage, index)}
              onRemoveImage={() => handleRemoveImage(index)}
              cropAspect={cropAspect}
              cropHeight={cropHeight}
              cropWidth={cropWidth}
              index={index}
              key={index}
              displayPreviewTitle={displayPreviewTitle}
            />
          ))}
          {images.length < limit && (
            <DropZone
              accept={accept}
              name={name}
              onDropAccepted={files => onDropAccepted([...images, ...files])}
              onFileDialogCancel={onFileDialogCancel}
              multiple={multiple}
              inputProps={inputProps}
              rootProps={rootProps}
              limit={limit}
              error={error}
            >
              {dropPlaceholder}
            </DropZone>
          )}
        </>
      );
    }
    return null;
  };

  return (
    <FieldContainer ref={setRef} className={`drop-filed ${className}`}>
      {title && (
        <Typography className="drop-filed__title" variant="body2">
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography className="drop-filed__subtitle" variant="body2">
          {subtitle}
        </Typography>
      )}
      {container()}
    </FieldContainer>
  );
};

dropZoneField.defaultProps = {
  isBothCropper: false,
  error: '',
  accept: 'image/*',
  className: '',
  name: '',
  title: '',
  subtitle: '',
  limit: 1,
  rootProps: {},
  inputProps: {},
  multiple: true,
  cropAspect: 16 / 9,
  onFileDialogCancel: () => {},
  onError: () => {},
  cropHeight: imageSizes.GIFT_DETAIL_HEIGHT,
  cropWidth: imageSizes.GIFT_DETAIL_WIDTH,
  type: 'image',
  url: [],
  images: [],
  displayPreviewTitle: true,
};

dropZoneField.propTypes = {
  recommendWidth: PropTypes.array,
  recommendHeight: PropTypes.array,
  isBothCropper: PropTypes.bool,
  error: PropTypes.string,
  setRef: PropTypes.object,
  onFileDialogCancel: PropTypes.func,
  accept: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  limit: PropTypes.number,
  rootProps: PropTypes.object,
  inputProps: PropTypes.object,
  multiple: PropTypes.bool,
  cropHeight: PropTypes.number,
  cropAspect: PropTypes.number,
  cropWidth: PropTypes.number,
  dropPlaceholder: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  images: PropTypes.array,
  onImagesUpdated: PropTypes.func,
  onDropAccepted: PropTypes.func.isRequired,
  onVideoUpdated: PropTypes.func,
  onError: PropTypes.func,
  type: PropTypes.string,
  url: PropTypes.array,
  displayPreviewTitle: PropTypes.bool,
};

export default React.memo(dropZoneField);
