import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';

import { t } from '@/locale/i18n';
import { getMe } from '@/store/saga/auth';
import * as actionTypes from '@/store/actions/types/privacyAndTerms';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* getPrivacySaga(action) {
  yield put({ type: actionTypes.GET_PRIVACY_START });
  const { organizationId } = action.payload;

  const url = getLink(endpoints.GET_PRIVACY, { organizationId });

  try {
    const { data } = yield axios.get(url, { params: action.payload });

    yield put({
      type: actionTypes.GET_PRIVACY_SUCCESS,
      payload: {
        data: data.date,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_PRIVACY_FAIL,
    });
  }
}

export function* getTermsSaga(action) {
  yield put({ type: actionTypes.GET_TERMS_START });
  const { organizationId } = action.payload;

  const url = getLink(endpoints.GET_TERMS, { organizationId });

  try {
    const { data } = yield axios.get(url, { params: action.payload });

    yield put({
      type: actionTypes.GET_TERMS_SUCCESS,
      payload: {
        data: data.date,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_TERMS_FAIL,
    });
  }
}

export function* sendPrivacyAndTermsSaga(action) {
  const { organizationId, privacy_policy, terms_of_use, type } = action.payload;

  // action type
  let actionStart;
  let actionSuccess;
  let actionFail;

  // request
  let requestType;
  let requestData;

  // notification
  let notification;

  if (type === 'privacy') {
    actionStart = actionTypes.SEND_PRIVACY_START;
    actionSuccess = actionTypes.SEND_PRIVACY_SUCCESS;
    actionFail = actionTypes.SEND_PRIVACY_FAIL;

    requestType = 'privacy-policy';
    requestData = { privacy_policy };

    notification = t('notification.privacy');
  } else {
    actionStart = actionTypes.SEND_TERMS_START;
    actionSuccess = actionTypes.SEND_TERMS_SUCCESS;
    actionFail = actionTypes.SEND_TERMS_FAIL;

    requestType = 'terms-of-use';
    requestData = { terms_of_use };

    notification = t('notification.terms');
  }

  yield put({ type: actionStart });

  const url = getLink(endpoints.SEND_PRIVACY_OR_TERMS, { organizationId, type: requestType });

  try {
    const { data } = yield axios.put(url, { ...requestData });

    let responseData = data;
    if (type === 'privacy') {
      responseData = responseData.privacy_policy;
    } else {
      responseData = responseData.terms_of_use;
    }

    yield put({
      type: actionSuccess,
      payload: { data: responseData },
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: notification,
        options: {
          variant: 'success',
        },
      },
    });
    yield call(getMe);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionFail,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}
