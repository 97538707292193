import React from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { InfoOutlined } from '@material-ui/icons';
import { Button, Link, Tooltip, Typography } from '@material-ui/core';

import { t } from '@/locale/i18n';
import { GIFT_DETAIL_WIDTH, GIFT_DETAIL_HEIGHT } from '@/constants/images';
import useImagePreview from '@/utility/hooks/useImagePreview';

import { PreviewContainer, CropperTitle } from './styles';

const ImagePreview = ({
  recommendWidth,
  recommendHeight,
  isBothCropper,
  index,
  cropAspect,
  cropHeight,
  cropWidth,
  name,
  data,
  onCropImage,
  onRemoveImage,
  displayPreviewTitle,
}) => {
  const {
    isCropper,
    isRecommendedSize,
    cropEl,
    cropImage,
    handleAspectRatio,
    isCropped,
  } = useImagePreview({
    data,
    recommendHeight,
    recommendWidth,
    cropAspect,
    cropWidth,
    cropHeight,
    onCropImage,
  });

  return (
    <PreviewContainer className="image-preview">
      {data.type && !isCropped && !isRecommendedSize && displayPreviewTitle && (
        <CropperTitle>
          <Typography className="image-preview__title" variant="body2">
            {t('giftCommon.cropYourPhoto')}
          </Typography>
          {isBothCropper && (
            <>
              <Link
                className="image-preview__btn"
                component="button"
                onClick={() => handleAspectRatio('rectangle')}
              >
                {t('buttons.rectangle')}
              </Link>
              <Typography variant="body2">{t('common.or')}</Typography>
              <Link
                className="image-preview__btn"
                component="button"
                onClick={() => handleAspectRatio('square')}
              >
                {t('buttons.square')}
              </Link>
              <Tooltip title={t('tooltip.cropper')}>
                <InfoOutlined />
              </Tooltip>
            </>
          )}
        </CropperTitle>
      )}
      {isCropper ? (
        <Cropper
          className="image-preview__crop"
          dragMode="move"
          viewMode={1}
          src={data.src}
          aspectRatio={cropAspect}
          /** at this point min crop breaks things */
          // minCropBoxHeight={cropHeight}
          // minCropBoxWidth={cropWidth}
          background={false}
          ref={cropEl}
        />
      ) : (
        <img className="image-preview__crop" src={data.src} alt="preview" />
      )}
      {isCropper && (
        <Button
          id={`${name}-${index}-crop-btn`}
          className="image-preview__crop-btn"
          type="button"
          onClick={cropImage}
        >
          {t('buttons.cropImage')}
        </Button>
      )}
      <Link
        id={`${name}-${index}-remove-btn`}
        className="btn-link btn-link--small btn-link--blue image-preview__remove-btn"
        type="button"
        color="secondary"
        component="button"
        underline="none"
        onClick={onRemoveImage}
      >
        {t('buttons.remove')}
      </Link>
    </PreviewContainer>
  );
};

ImagePreview.defaultProps = {
  isBothCropper: false,
  cropAspect: 16 / 9,
  cropHeight: GIFT_DETAIL_HEIGHT,
  cropWidth: GIFT_DETAIL_WIDTH,
  recommendWidth: [GIFT_DETAIL_WIDTH],
  recommendHeight: [GIFT_DETAIL_HEIGHT],
  displayPreviewTitle: true,
};

ImagePreview.propTypes = {
  recommendWidth: PropTypes.array,
  recommendHeight: PropTypes.array,
  isBothCropper: PropTypes.bool,
  index: PropTypes.number.isRequired,
  cropAspect: PropTypes.number,
  cropHeight: PropTypes.number,
  cropWidth: PropTypes.number,
  name: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onCropImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  displayPreviewTitle: PropTypes.bool,
};

export default ImagePreview;
