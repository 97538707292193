import * as actionTypes from '@/store/actions/types/sharing';

const initialState = {
  data: {},
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHARING_GET_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SHARING_GET_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case actionTypes.SHARING_GET_INFO_FAIL:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.DOWNLOAD_SHARING_FILE_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.DOWNLOAD_SHARING_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DOWNLOAD_SHARING_FILE_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
