import currenciesData from '../../constants/currency';

const getCurrencyInfo = currency => {
  const currencyData = currenciesData.find(data => data.currency === currency);

  return currencyData || { symbol: '' };
};

export const getCurrencyInfoByCountry = country => {
  const currencyData = currenciesData.find(data => data.country === country);

  return currencyData || {};
};

export default getCurrencyInfo;
