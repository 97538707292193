import axios from 'axios';
import { call, put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import * as actionTypes from '@/store/actions/types/multipleAccounts';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import getLink from '@/utility/common/routes';
import history from '@/utility/common/history';
import { ROUTE_REDEMPTIONS } from '@/constants/routes';
import { getMe, setAuth } from '@/store/saga/auth';
import { checkNotificationsSaga } from '@/store/saga/accounts';
import getDeviceId from '@/store/saga/common';

export function* chooseMyOrganizationSaga(action) {
  yield put({ type: actionTypes.CHOOSE_MY_ORGANIZATION_START });
  const { user_id, organization_id, callback } = action.payload;
  try {
    const login_as_organization = yield localStorage.getItem('login_as_organization');

    const deviceId = yield getDeviceId();
    const { data } = yield axios.put(endpoints.USER_ACTIVE_ORGANIZATIONS, {
      user_id,
      organization_id,
      device_id: deviceId,
    });
    yield call(setAuth, { ...data, login_as_organization });
    yield call(getMe);
    yield call(checkNotificationsSaga, { payload: { id: user_id } });
    yield call(history.push, ROUTE_REDEMPTIONS);

    yield put({
      type: actionTypes.CHOOSE_MY_ORGANIZATION_SUCCESS,
    });
    if (callback) {
      callback();
    }
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CHOOSE_MY_ORGANIZATION_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* getMyOrganizationsListSaga(action) {
  yield put({ type: actionTypes.GET_MY_ORGANIZATIONS_LIST_START });

  const { userId } = action.payload;
  const url = getLink(endpoints.USER_ORGANIZATIONS_BY_ID, { userId });

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.GET_MY_ORGANIZATIONS_LIST_SUCCESS,
      payload: {
        data: data || [],
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_MY_ORGANIZATIONS_LIST_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* createNewOrganizationSaga(action) {
  yield put({ type: actionTypes.CREATE_NEW_ORGANIZATION_START });
  const { user_id } = action.payload;
  try {
    const { data } = yield axios.post(endpoints.USER_ORGANIZATIONS, { ...action.payload });

    yield call(chooseMyOrganizationSaga, {
      payload: {
        user_id,
        organization_id: data.id,
      },
    });
    yield put({
      type: actionTypes.CREATE_NEW_ORGANIZATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CREATE_NEW_ORGANIZATION_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* deleteOrganizationSaga(action) {
  yield put({ type: actionTypes.DELETE_ORGANIZATION_START });

  try {
    yield axios.delete(endpoints.USER_ORGANIZATIONS, { ...action.payload });

    yield put({
      type: actionTypes.DELETE_ORGANIZATION_SUCCESS,
      data: action.payload,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.DELETE_ORGANIZATION_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}
