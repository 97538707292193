export const GET_NGC_STATUS = 'GET_NGC_STATUS';
export const GET_NGC_STATUS_START = 'GET_NGC_STATUS_START';
export const GET_NGC_STATUS_SUCCESS = 'GET_NGC_STATUS_SUCCESS';
export const GET_NGC_STATUS_FAILED = 'GET_NGC_STATUS_FAILED';

export const ARCHIVE_ALL_NGC = 'ARCHIVE_ALL_NGC';
export const ARCHIVE_ALL_NGC_START = 'ARCHIVE_ALL_NGC_START';
export const ARCHIVE_ALL_NGC_SUCCESS = 'ARCHIVE_ALL_NGC_SUCCESS';
export const ARCHIVE_ALL_NGC_FAILED = 'ARCHIVE_ALL_NGC_FAILED';

export const GET_ADMINS_SETTINGS = 'GET_ADMINS_SETTINGS';
export const GET_ADMINS_SETTINGS_START = 'GET_ADMINS_SETTINGS_START';
export const GET_ADMINS_SETTINGS_SUCCESS = 'GET_ADMINS_SETTINGS_SUCCESS';
export const GET_ADMINS_SETTINGS_FAILED = 'GET_ADMINS_SETTINGS_FAILED';

export const UPDATE_ADMINS_SETTINGS = 'UPDATE_ADMINS_SETTINGS';
export const UPDATE_ADMINS_SETTINGS_START = 'UPDATE_ADMINS_SETTINGS_START';
export const UPDATE_ADMINS_SETTINGS_SUCCESS = 'UPDATE_ADMINS_SETTINGS_SUCCESS';
export const UPDATE_ADMINS_SETTINGS_FAILED = 'UPDATE_ADMINS_SETTINGS_FAILED';

export const UPDATE_SETTINGS_TO_CURRENT_USERS = 'UPDATE_SETTINGS_TO_CURRENT_USERS';
export const UPDATE_SETTINGS_TO_CURRENT_USERS_START = 'UPDATE_SETTINGS_TO_CURRENT_USERS_START';
export const UPDATE_SETTINGS_TO_CURRENT_USERS_SUCCESS = 'UPDATE_SETTINGS_TO_CURRENT_USERS_SUCCESS';
export const UPDATE_SETTINGS_TO_CURRENT_USERS_FAILED = 'UPDATE_SETTINGS_TO_CURRENT_USERS_FAILED';

export const GET_ADMIN_NOTIFICATIONS = 'GET_ADMIN_NOTIFICATIONS';
export const GET_ADMIN_NOTIFICATIONS_START = 'GET_ADMIN_NOTIFICATIONS_START';
export const GET_ADMIN_NOTIFICATIONS_SUCCESS = 'GET_ADMIN_NOTIFICATIONS_SUCCESS';
export const GET_ADMIN_NOTIFICATIONS_FAILED = 'GET_ADMIN_NOTIFICATIONS_FAILED';

export const UPDATE_ADMIN_NOTIFICATIONS = 'UPDATE_ADMIN_NOTIFICATIONS';
export const UPDATE_ADMIN_NOTIFICATIONS_START = 'UPDATE_ADMIN_NOTIFICATIONS_START';
export const UPDATE_ADMIN_NOTIFICATIONS_SUCCESS = 'UPDATE_ADMIN_NOTIFICATIONS_SUCCESS';
export const UPDATE_ADMIN_NOTIFICATIONS_FAILED = 'UPDATE_ADMIN_NOTIFICATIONS_FAILED';

export const REINDEX_ALGOLIA = 'REINDEX_ALGOLIA';
export const REINDEX_ALGOLIA_START = 'REINDEX_ALGOLIA_START';
export const REINDEX_ALGOLIA_SUCCESS = 'REINDEX_ALGOLIA_SUCCESS';
export const REINDEX_ALGOLIA_FAILED = 'REINDEX_ALGOLIA_FAILED';
