import React from 'react';
import useClearCache from '@/utility/hooks/useClearCache';

import Routes from './routes';
import GlobalStyle from './styles';
import SnackbarNotify from '@/components/SnackbarNotify/SnackbarNotify';

function App() {
  useClearCache();

  return (
    <div className="App">
      <Routes />
      <GlobalStyle />
      <SnackbarNotify />
    </div>
  );
}

export default App;
