import * as actionTypes from './types/language';

export const setLanguage = (userId, language) => {
  return {
    type: actionTypes.SET_LANGUAGE,
    payload: {
      userId,
      language,
    },
  };
};

export const getEmailLanguage = organizationId => {
  return {
    type: actionTypes.GET_EMAIL_LANG,
    payload: { organizationId },
  };
};
