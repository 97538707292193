import axios from 'axios';
import { put } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/csv';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import moment from 'moment';

export function* importLocationsSaga(action) {
  yield put({ type: actionTypes.CSV_START });

  const { organizId, import_file } = action.payload;
  const url = getLink(endpoints.CSV_IMPORT_LOCATIONS, { id: organizId });
  const formData = new FormData();
  formData.append('import_file', import_file);

  try {
    const res = yield axios.post(url, formData, {
      'Content-Type': 'multipart/form-data',
    });
    const { data } = res;
    yield put({
      type: actionTypes.CSV_IMPORT_LOCATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CSV_STOP,
    });
  }
}

export function* saveLocationsSaga(action) {
  yield put({ type: actionTypes.CSV_START });

  const { organizId, ...rest } = action.payload;
  const url = getLink(endpoints.CSV_SAVE_LOCATIONS, { id: organizId });

  try {
    const res = yield axios.post(url, { ...rest });
    const { data } = res;
    yield put({
      type: actionTypes.CSV_SAVE_LOCATIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CSV_STOP,
    });
  }
}

export function* importEmployeesSaga(action) {
  yield put({ type: actionTypes.CSV_START });

  const { organizId, locationId, import_file } = action.payload;
  const url = getLink(endpoints.CSV_IMPORT_EMPLOYEES, { id: organizId, locationId });
  const formData = new FormData();
  formData.append('import_file', import_file);

  try {
    const res = yield axios.post(url, formData, {
      'Content-Type': 'multipart/form-data',
    });
    const { data } = res;
    yield put({
      type: actionTypes.CSV_IMPORT_EMPLOYEES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CSV_STOP,
    });
  }
}

export function* saveEmployeesSaga(action) {
  yield put({ type: actionTypes.CSV_START });

  const { organizId, locationId, users } = action.payload;
  const url = getLink(endpoints.CSV_SAVE_EMPLOYEES, { id: organizId, locationId });

  try {
    const res = yield axios.post(url, { users });
    const { data } = res;
    yield put({
      type: actionTypes.CSV_SAVE_EMPLOYEES_SUCCESS,
      payload: data,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.importEmployeesSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CSV_STOP,
    });
  }
}

export function* exportCSVStatisticsSaga(action) {
  yield put({ type: actionTypes.CSV_START });

  const { organizationId, date_range } = action.payload;
  const url = getLink(endpoints.CSV_EXPORT_STATISTICS, { id: organizationId });

  try {
    const { data } = yield axios.get(url, {
      responseType: 'blob',
      params: { date_range },
    });
    const date = `${moment(date_range.from, 'YYYY-MM-DD').format('YYYY_MM_DD')} - ${moment(
      date_range.to,
      'YYYY-MM-DD',
    ).format('YYYY_MM_DD')}`;
    const blob = new Blob([data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${t('common.statistics')}(${date}).csv`;
    link.click();

    yield put({
      type: actionTypes.CSV_EXPORT_STATISTICS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CSV_STOP,
    });
  }
}
