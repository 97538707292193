import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import {
  ROUTE_DETAIL_GIFT,
  ROUTE_GIFTS,
  ROUTE_EDIT_GIFT,
  ROUTE_CREATE_GIFT,
} from '@/constants/routes';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import getGiftData from '@/utility/gifts/gift';
import history from '@/utility/common/history';
import * as actionTypes from '@/store/actions/types/gift';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { TYPE_GIFT, TYPE_GIFT_CARD } from '@/constants/gift';
import giftMapper from '../mappers/gift';

export function* getGiftSaga(action) {
  yield put({ type: actionTypes.GET_GIFT_START });

  const { productId } = action.payload;
  const url = getLink(endpoints.GET_GIFT, { id: productId });

  try {
    const response = yield axios.get(url);
    const { data } = yield response;
    // console.log(data)
    // const isGiftCard = data.gift.type === TYPE_GIFT_CARD;
    // const isFilled = data.is_filled;
    // console.log(isFilled)
    const payloadData = giftMapper(data.gift);
    // console.log(payloadData);

    // if (isGiftCard) {
    //   payloadData = { ...payloadData, step: 5 };
    // }

    yield put({
      type: actionTypes.GET_GIFT_SUCCESS,
      payload: {
        data: payloadData,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_GIFT_FAIL,
    });
  }
}

export function* createGiftSaga(action) {
  yield put({ type: actionTypes.GIFT_START });

  const { type } = action.payload;
  const url = endpoints.CREATE_GIFT;

  try {
    const response = yield axios.post(url, { type });
    const { data } = yield response;

    yield localStorage.setItem('giftId', data.gift.id);

    yield call(history.push, ROUTE_CREATE_GIFT);

    yield put({
      type: actionTypes.CREATE_GIFT_SUCCESS,
      payload: {
        data: data.gift,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CREATE_GIFT_FAIL,
    });
  }
}

export function* updateGiftSaga(action) {
  yield put({ type: actionTypes.GIFT_START });

  const {
    productId,
    data: { cover_image, images, ...restData },
    setNextStepFunc,
  } = action.payload;
  const url = getLink(endpoints.UPDATE_GIFT, { id: productId });
  const uploadImageUrl = endpoints.UPLOAD_IMAGES;
  const updatedData = yield call(getGiftData, restData);

  const coverImage = [];
  const detailImages = [];
  let coverFormData;
  let imagesFormData;

  if (cover_image.length) {
    if (cover_image[0].type) {
      coverFormData = new FormData();
      coverFormData.append('images[]', cover_image[0]);
      coverFormData.append('type', TYPE_GIFT);
      coverFormData.append('id', productId);
    } else {
      updatedData.cover_image_id = cover_image[0].id;
      coverImage.push(cover_image[0]);
    }
  }

  if (images.length) {
    updatedData.images = [];
    let appendFormData = true;

    for (const image of images) {
      if (image.type) {
        if (appendFormData) {
          imagesFormData = new FormData();
          imagesFormData.append('type', TYPE_GIFT);
          imagesFormData.append('id', productId);
          appendFormData = false;
        }
        imagesFormData.append('images[]', image);
      } else {
        detailImages.push(image);
        updatedData.images.push(image.id);
      }
    }
  }

  try {
    let responseCoverData;
    let responseImagesData;

    if (coverFormData) {
      responseCoverData = yield axios.post(uploadImageUrl, coverFormData, {
        'Content-Type': 'multipart/form-data',
      });

      const uploadedImages = yield responseCoverData.data.images;

      yield coverImage.push(uploadedImages[0]);
      updatedData.cover_image_id = yield uploadedImages[0].id;
    }

    if (imagesFormData) {
      responseImagesData = yield axios.post(uploadImageUrl, imagesFormData, {
        'Content-Type': 'multipart/form-data',
      });

      for (const image of responseImagesData.data.images) {
        yield detailImages.push(image);
        yield updatedData.images.push(image.id);
      }
    }

    const { data: responseData } = yield axios.put(url, updatedData);

    if (responseCoverData || responseImagesData) {
      yield put({
        type: actionTypes.UPDATE_GIFT_IMAGES_SUCCESS,
        payload: {
          data: {
            ...responseData.gift,
            cover_image: coverImage,
            images: detailImages,
          },
          imagesWasUpdated: true,
        },
      });
    }

    const isGiftCard = responseData.gift.type === TYPE_GIFT_CARD;
    const isFilled = restData.is_filled;

    if (updatedData.step !== 5) {
      if (isFilled || isGiftCard) {
        yield call(setNextStepFunc, 5);
      } else {
        yield call(setNextStepFunc, updatedData.step + 1);
      }
    } else {
      yield localStorage.removeItem('giftId');
      yield put({
        type: PUSH_NOTIFY,
        payload: {
          message: t(`giftCommon.success_${restData.status}`),
          options: {
            variant: 'success',
          },
        },
      });
      history.push(ROUTE_GIFTS);
    }

    yield put({
      type: actionTypes.UPDATE_GIFT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_GIFT_FAIL,
    });
  }
}

export function* deleteGiftSaga(action) {
  yield put({ type: actionTypes.GIFT_START });

  const { productId } = action.payload;
  const url = getLink(endpoints.DELETE_GIFT, { id: productId });

  try {
    yield axios.delete(url);

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.deleteGiftSuccess'),
        options: {
          variant: 'success',
        },
      },
    });

    yield history.push(ROUTE_GIFTS);

    yield put({
      type: actionTypes.DELETE_GIFT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.DELETE_GIFT_FAIL,
    });
  }
}

export function* changeQuantitySaga(action) {
  yield put({ type: actionTypes.GIFT_START });

  const { productId, data } = action.payload;
  const url = getLink(endpoints.GIFT_CHANGE_QUANTITY, { id: productId });

  try {
    yield axios.put(url, data);

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.giftChangeQuantitySuccess'),
        options: {
          variant: 'success',
        },
      },
    });

    yield history.push(getLink(ROUTE_DETAIL_GIFT, { giftId: productId }));

    yield put({
      type: actionTypes.UPDATE_GIFT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_GIFT_FAIL,
    });
  }
}

export function* duplicateGiftSaga(action) {
  yield put({ type: actionTypes.GIFT_START });

  const url = getLink(endpoints.GIFT_DUPLICATE, { id: action.payload });

  try {
    const { data } = yield axios.post(url);

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.giftDuplicateSuccess'),
        options: {
          variant: 'success',
        },
      },
    });

    yield history.push(getLink(ROUTE_EDIT_GIFT, { giftId: data.gift.id }));

    yield put({
      type: actionTypes.UPDATE_GIFT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_GIFT_FAIL,
    });
  }
}

export function* getGiftChartSaga(action) {
  yield put({ type: actionTypes.GIFT_GET_CHART_START });

  const { id, dateRange, type } = action.payload;
  const url = getLink(endpoints.GET_GIFT_CHART, { id });

  try {
    const response = yield axios.get(url, {
      params: {
        date_range: dateRange,
        type,
      },
    });
    const { data } = yield response;

    yield put({
      type: actionTypes.GIFT_GET_CHART_SUCCESS,
      payload: {
        data: data.data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GIFT_GET_CHART_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* getGiftCardsSaga(action) {
  yield put({ type: actionTypes.GIFT_GET_CARDS_START });

  const { id, date_range } = action.payload;
  const url = getLink(endpoints.GET_GIFT_CARDS, { id });

  try {
    const response = yield axios.get(url, { params: { date_range } });
    const { data } = yield response;

    yield put({
      type: actionTypes.GIFT_GET_CARDS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GIFT_GET_CARDS_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* updateStatusGiftSaga(action) {
  yield put({ type: actionTypes.UPDATE_GIFT_STATUS_START });

  const { giftId } = action.payload;
  const url = getLink(endpoints.CHANGE_STATUS_GIFT, { giftId });

  try {
    const { data } = yield axios.put(url, action.payload);

    yield put({
      type: actionTypes.UPDATE_GIFT_STATUS_SUCCESS,
      payload: data,
    });
    history.push(ROUTE_GIFTS);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_GIFT_STATUS_FAIL,
    });
  }
}
