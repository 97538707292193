import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { checkRoles } from '@/utility/common/routes';
import { roleMainPage } from '@/constants/roles';

const PrivateRoute = ({ component, roles, role, isAuth, ...rest }) => {
  const isExistPage = checkRoles(roles, role);
  const redirectLink = roleMainPage[role];

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuth) {
          if (isExistPage) {
            return <>{component}</>;
          }
          return (
            <Redirect
              to={{
                pathname: redirectLink,
                state: { from: props.location },
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  roles: PropTypes.array,
  role: PropTypes.string,
  component: PropTypes.node,
  isAuth: PropTypes.bool,
  location: PropTypes.object,
};

export default PrivateRoute;
