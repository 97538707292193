import * as Yup from 'yup';

export default (/* t */) => {
  return {
    redeemGift: Yup.boolean(),
    getGift: Yup.boolean(),
    sentGif: Yup.boolean(),
    altruusUpdates: Yup.boolean(),
  };
};
