import React from 'react';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';
import { Link } from '@material-ui/core';

import { t } from '@/locale/i18n';

import { PreviewContainer, VideoPlayer } from './styles';
import {
  MAX_DURATION,
  ORGANIZATION_VIDEO_HEIGHT,
  ORGANIZATION_VIDEO_WIDTH,
} from '@/constants/videos';

const VideoPreview = ({
  name,
  data,
  onRemoveVideo,
  recommendHeight,
  recommendWidth,
  maxDuration,
}) => {
  return (
    <PreviewContainer className="video-preview">
      <VideoPlayer
        url={data}
        width={recommendWidth}
        height={recommendHeight}
        controls
        onDuration={duration => {
          if (maxDuration && duration > maxDuration + 1) {
            onRemoveVideo('duration');
          }
        }}
      />
      <Link
        id={`${name}-remove-btn`}
        className="btn-link btn-link--small btn-link--blue video-preview__remove-btn"
        type="button"
        color="secondary"
        component="button"
        underline="none"
        onClick={onRemoveVideo}
      >
        {t('buttons.remove')}
      </Link>
    </PreviewContainer>
  );
};

VideoPreview.defaultProps = {
  recommendWidth: ORGANIZATION_VIDEO_WIDTH,
  recommendHeight: ORGANIZATION_VIDEO_HEIGHT,
  maxDuration: MAX_DURATION,
};

VideoPreview.propTypes = {
  recommendWidth: PropTypes.array,
  recommendHeight: PropTypes.array,
  name: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  onRemoveVideo: PropTypes.func.isRequired,
  maxDuration: PropTypes.number,
};

export default VideoPreview;
