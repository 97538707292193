// Surveys action
export const GET_SURVEYS_LIST = 'GET_SURVEYS_LIST';
export const GET_SURVEYS_LIST_START = 'GET_SURVEYS_LIST_START';
export const GET_SURVEYS_LIST_SUCCESS = 'GET_SURVEYS_LIST_SUCCESS';
export const GET_SURVEYS_LIST_FAIL = 'GET_SURVEYS_LIST_FAIL';

export const GET_DETAIL_SURVEY = 'GET_DETAIL_SURVEY';
export const GET_DETAIL_SURVEY_START = 'GET_DETAIL_SURVEY_START';
export const GET_DETAIL_SURVEY_SUCCESS = 'GET_DETAIL_SURVEY_SUCCESS';
export const GET_DETAIL_SURVEY_FAIL = 'GET_DETAIL_SURVEY_FAIL';

export const CHANGE_STATUS_SURVEY = 'CHANGE_STATUS_SURVEY';
export const CHANGE_STATUS_SURVEY_START = 'CHANGE_STATUS_SURVEY_START';
export const CHANGE_STATUS_SURVEY_SUCCESS = 'CHANGE_STATUS_SURVEY_SUCCESS';
export const CHANGE_STATUS_SURVEY_FAIL = 'CHANGE_STATUS_SURVEY_FAIL';

export const CREATE_SURVEY = 'CREATE_SURVEY';
export const CREATE_SURVEY_START = 'CREATE_SURVEY_START';
export const CREATE_SURVEY_SUCCESS = 'CREATE_SURVEY_SUCCESS';
export const CREATE_SURVEY_FAIL = 'CREATE_SURVEY_FAIL';

export const EDIT_SURVEY = 'EDIT_SURVEY';
export const EDIT_SURVEY_START = 'EDIT_SURVEY_START';
export const EDIT_SURVEY_SUCCESS = 'EDIT_SURVEY_SUCCESS';
export const EDIT_SURVEY_FAIL = 'EDIT_SURVEY_FAIL';
