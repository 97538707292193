import * as actionTypes from '@/store/actions/types/gift';

const initialState = {
  gift: {},
  giftChart: [],
  giftCards: {},
  imagesWasUpdated: false,
  loading: {
    getGift: false,
    getChart: false,
    getCards: false,
    updateGift: false,
    updateStatusGift: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GIFT_START:
      return { ...state, loading: { ...state.loading, updateGift: true } };
    case actionTypes.GET_GIFT:
      return { ...state, gift: {} };
    case actionTypes.CREATE_GIFT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateGift: false },
      };
    case actionTypes.CREATE_GIFT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, updateGift: false },
      };
    case actionTypes.GIFT_GET_CHART_START:
      return {
        ...state,
        loading: { ...state.loading, getChart: true },
      };
    case actionTypes.GIFT_GET_CHART_SUCCESS:
      return {
        ...state,
        giftChart: action.payload.data,
        loading: { ...state.loading, getChart: false },
      };
    case actionTypes.GIFT_GET_CARDS_START:
      return {
        ...state,
        loading: { ...state.loading, getCards: true },
      };
    case actionTypes.GIFT_GET_CARDS_SUCCESS:
      return {
        ...state,
        giftCards: action.payload.data,
        loading: { ...state.loading, getCards: false },
      };
    case actionTypes.GET_GIFT_START:
      return {
        ...state,
        loading: { ...state.loading, getGift: true },
      };
    case actionTypes.GET_GIFT_SUCCESS:
      return {
        ...state,
        gift: action.payload.data,
        loading: { ...state.loading, getGift: false },
      };
    case actionTypes.GET_GIFT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getGift: false },
      };
    case actionTypes.UPDATE_GIFT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, updateGift: false },
      };
    case actionTypes.UPDATE_GIFT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, updateGift: false },
      };
    case actionTypes.UPDATE_GIFT_IMAGES_SUCCESS:
      return {
        ...state,
        gift: action.payload.data,
        imagesWasUpdated: action.payload.imagesWasUpdated,
      };
    case actionTypes.DELETE_GIFT_SUCCESS:
      return {
        ...state,
        gift: {},
        loading: { ...state.loading, updateGift: false },
      };
    case actionTypes.DELETE_GIFT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, updateGift: false },
      };
    case actionTypes.GIFT_GET_CHART_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getChart: false },
      };
    case actionTypes.GIFT_GET_CARDS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, getCards: false },
      };
    case actionTypes.UPDATE_GIFT_STATUS_START:
      return {
        ...state,
        loading: { ...state.loading, updateStatusGift: true },
      };
    case actionTypes.UPDATE_GIFT_STATUS_SUCCESS:
      return {
        ...state,
        gift: {
          status: action.payload.data,
        },
        loading: { ...state.loading, updateStatusGift: false },
      };
    case actionTypes.UPDATE_GIFT_STATUS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, updateStatusGift: false },
      };
    default:
      return state;
  }
};

export default reducer;
