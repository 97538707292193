import axios from 'axios';
import { put } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import { ROUTE_MY_PROFILE } from '@/constants/routes';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import history from '@/utility/common/history';
import * as authActionTypes from '@/store/actions/types/auth';
import * as actionTypes from '@/store/actions/types/plans';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* getPlansSaga(action) {
  yield put({ type: actionTypes.GET_PLANS_START });

  const url = endpoints.GET_PLANS;

  try {
    const { data } = yield axios.get(url, { params: action.payload });

    yield put({
      type: actionTypes.GET_PLANS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_PLANS_FAIL,
    });
  }
}

export function* changePlanSaga(action) {
  yield put({ type: actionTypes.CHANGE_PLAN_START });

  const { planName } = action.payload;
  const url = getLink(endpoints.CHANGE_PLAN, { planName });

  try {
    const { data } = yield axios.put(url);

    yield put({
      type: actionTypes.CHANGE_PLAN_SUCCESS,
    });

    if (data.plan_status) {
      yield put({
        type: authActionTypes.AUTH_UPDATE_PLAN_STATUS,
        payload: {
          plan_status: data.plan_status,
        },
      });
    }

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.changesSaved'),
        options: {
          variant: 'success',
        },
      },
    });

    yield history.replace(ROUTE_MY_PROFILE);
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CHANGE_PLAN_FAIL,
    });
  }
}

export default getPlansSaga;
