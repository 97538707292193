import * as actionTypes from './types/users';

export const getUsersList = params => {
  return {
    type: actionTypes.USERS_GET_LIST,
    payload: params,
  };
};

export const getUserDetail = params => {
  return {
    type: actionTypes.USERS_GET_DETAIL,
    payload: params,
  };
};

export const getInvitedUsers = params => {
  return {
    type: actionTypes.USERS_GET_INVITED_LIST,
    payload: params,
  };
};

export const blockUser = params => {
  return {
    type: actionTypes.GET_BLOCK,
    payload: params,
  };
};

export const downloadUserQrCode = params => {
  return {
    type: actionTypes.USER_DOWNLOAD_QRCODE,
    payload: params,
  };
};

export const generateUserQrCode = params => {
  return {
    type: actionTypes.USER_GENERATE_QRCODE,
    payload: params,
  };
};

export const verifyUser = params => {
  return {
    type: actionTypes.VERIFY_USER,
    payload: params,
  };
};

export const getOnlineUsersCount = params => {
  return {
    type: actionTypes.GET_ONLINE_USERS_COUNT,
    payload: params,
  };
};

export const notifyUsers = params => {
  return {
    type: actionTypes.NOTIFY_USERS,
    payload: params,
  };
};

export const changeBalanceStatus = params => {
  return {
    type: actionTypes.CHANGE_ALTRUUS_BALANCE_STATUS,
    payload: params,
  };
};
