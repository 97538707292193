import * as Yup from 'yup';

import * as validationConst from '@/constants/validation';
import * as giftConst from '@/constants/gift';
import { days } from '@/constants/regexp';

import getCurrencyInfo from '@/utility/common/currency';

export default (t, data) => {
  const { currency } = data;
  const currencyInfo = getCurrencyInfo(currency);

  return {
    giftType: {},
    mainInfo: {
      name: Yup.string()
        .required(t('validation.nameRequired'))
        .min(validationConst.MIN_NAME_LENGTH, t('validation.nameLength'))
        .test('name', t('validation.emoji'), name => {
          // eslint-disable-next-line
          const regEx = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
          return !regEx.test(name);
        }),
      description: Yup.string().max(
        validationConst.MAX_DESCRIPTION_LENGTH,
        t('validation.descriptionMaxLength'),
      ),
    },
    giftDetails: {
      tags: Yup.array()
        .max(5, t('validation.tagsMaxLength'))
        .nullable(),
      cover_image: Yup.array().required(),
    },
    costAndQuantity: {
      payment_type: Yup.string().required(t('validation.giftTypeRequired')),
      redemption_frequency: Yup.string().when('payment_type', {
        is: val => val === giftConst.GIFT_TYPE_FREE || val === giftConst.GIFT_TYPE_GEO_GIFT,
        then: Yup.string().required(t('validation.giftTypeRequired')),
        otherwise: Yup.string(),
      }),
      expiration_value: Yup.string().when('payment_type', {
        is: val => val === giftConst.GIFT_TYPE_FREE_EXPIRATION,
        then: Yup.string()
          .required(t('validation.expirationDayRequired'))
          .matches(days, t('validation.expirationDays')),
        otherwise: Yup.string(),
      }),
      spread: Yup.string().required(t('validation.distributeGiftRequired')),
      locations: Yup.array().when('spread', {
        is: spread => spread === giftConst.GIFT_BY_CITY,
        then: Yup.array().required(t('validation.locationsRequired')),
        otherwise: Yup.array(),
      }),
      cost: Yup.number()
        .min(
          currencyInfo.minStripePrice,
          t('validation.costLength', { minPrice: currencyInfo.minStripePrice }),
        )
        .max(validationConst.MAX_GIFT_COST, t('validation.costMaxLength'))
        .when(['type', 'payment_type'], {
          is: (type, payment_type) => {
            return type === giftConst.TYPE_GIFT && payment_type === giftConst.GIFT_TYPE_PAID;
          },
          then: Yup.number().required(t('validation.costRequired')),
          otherwise: Yup.number(),
        }),
      redemption_type: Yup.string().required(t('validation.redeemCodeTypeRequired')),
      discount: Yup.string().when(['type', 'payment_type'], {
        is: (type, payment_type) => {
          return type === giftConst.TYPE_GIFT && payment_type === giftConst.GIFT_TYPE_PAID;
        },
        then: Yup.string()
          .nullable()
          .test('discount', t('validation.emailsAlreadyTaken'), discount => {
            const schema = Yup.number();
            const discountParsed = parseInt(discount);

            return !discount || schema.isValidSync(discountParsed);
          }),
        otherwise: Yup.string().nullable(),
      }),
      quantity_type: Yup.string().required(t('validation.quantityRequired')),
      quantity: Yup.number()
        .min(validationConst.MIN_GIFT_SPECIFIC_QUANTITY, t('validation.specificQuantityLength'))
        .when('quantity_type', {
          is: quantity_type => quantity_type === giftConst.QUANTITY_TYPE_SPECIFIC,
          then: Yup.number().required(t('validation.specificQuantityRequired')),
          otherwise: Yup.number(),
        }),
    },
    overview: {},
  };
};
