import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { Grid, IconButton, Typography, Link, Hidden, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import queryString from 'query-string';

import { t } from '@/locale/i18n';
import * as actions from '@/store/actions';
import { ROUTE_PRIVACY_POLICY } from '@/constants/routes';

import CustomStepper from '@/components/CustomStepper';
import Dialog from '@/components/Dialogs';
import { Number } from '@/components/CustomStepper/help/styles';
import { LoaderWrap } from '@/containers/Redemptions/styles';

import AddBusinessLogo from './help/AddBusinessLogo';

import {
  LeftSide,
  RightSide,
  FormWrapper,
  MainTitle,
  LeftSideFooter,
  WrapSteps,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from './styles';
import SwitchAccountDialog from '@/components/Dialogs/SwitchAccountDialog';
import { activeAccount, getAccounts } from '@/utility/switchAccountDialog';
import PaymentConnect from '@/components/PaymentConnect/PaymentConnect';
import EXTERNAL_SERVICE from '@/constants/externalService';
import usePreviousProps from '@/utility/hooks/usePreviousProps';

const CompleteProfileDialog = ({
  loadingLogo,
  organization_logo,
  external_service,
  setStripeConnect,
  country,
  dialogIsOpen,
  closeDialogProfile,
  check_info,
  location,
  plan_status,
  changeAccountLoader,
  changeStripeAccount,
  changeSquareAccount,
  organization_id,
  history,
}) => {
  const query = queryString.parse(location.search);

  const [loading, setLoading] = useState(!!query.code || !!query.square);
  const [activeStep, setActiveStep] = useState(0);
  const [complete, setComplete] = useState([]);
  const [switchDialogIsOpened, setSwitchDialogIsOpened] = useState({
    display: false,
    paymentType: null,
    accountId: null,
  });

  const getActiveStep = step => {
    if (!step) return 1;
    if (organization_logo !== null || query.code || query.square) {
      return 1;
    }

    return 0;
  };

  const getCompleteSteps = () => {
    let updatedCheckInfo = { ...check_info };
    const business_logo = organization_logo !== null;

    if (plan_status && plan_status.is_free) {
      updatedCheckInfo = {
        business_logo,
        payment_connect: updatedCheckInfo.stripe_connect || updatedCheckInfo.stripe_connect,
      };
    }

    return (
      updatedCheckInfo &&
      Object.keys(updatedCheckInfo)
        .filter(value => updatedCheckInfo[value] === true)
        .map((el, index) => index)
    );
  };

  const setInitialStepParam = () => {
    const completedSteps = [].concat(getCompleteSteps());

    setComplete(completedSteps);
    setActiveStep(getActiveStep(completedSteps));
  };

  useEffect(() => {
    setInitialStepParam();
  }, [check_info]);

  useEffect(() => {
    if (query.code && !!organization_id) {
      const cleanSearch = () => {
        history.push(location.pathname);
      };
      setStripeConnect({ code: query.code, organization_id, cleanSearch });
    }
  }, [organization_id]);

  const prevStripeConnect = usePreviousProps(check_info.stripe_connect);
  const prevSquareConnect = usePreviousProps(check_info.square_connect);

  useEffect(() => {
    if (
      (!prevStripeConnect && check_info.stripe_connect) ||
      (!prevSquareConnect && check_info.square_connect)
    ) {
      setLoading(false);
    }
  }, [check_info.square_connect, check_info.stripe_connect]);

  const handleComplete = completedStep => {
    setComplete([...complete, completedStep]);
  };

  const isMexico = country === 'Mexico';
  const isNGC = external_service === EXTERNAL_SERVICE;

  let title = t('completeProfileDialog.steps.step2Title');

  if (isMexico) {
    title = t('completeProfileDialog.stripe');
  }
  if (isNGC) {
    title = t('completeProfileDialog.square');
  }

  const steps = [
    {
      title: t('completeProfileDialog.steps.step1Title'),
      subtitle: t('completeProfileDialog.steps.step1Subtitle'),
    },
    {
      title,
      subtitle: t('completeProfileDialog.steps.step2Subtitle'),
    },
  ];

  return (
    <Dialog
      open={dialogIsOpen}
      onClose={closeDialogProfile}
      fullWidth
      maxWidth="lg"
      fullScreenKey="sm"
    >
      <Grid container spacing={0}>
        <LeftSide item xs={12} md={6} sm={12}>
          <MainTitle>
            <Typography className="text-color text-color--white" variant="h1" component="h3">
              {t('completeProfileDialog.title')}
            </Typography>
            <Hidden mdUp>
              <IconButton
                id="close-modal-button"
                className="close-button"
                aria-label="Close"
                onClick={closeDialogProfile}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
            </Hidden>
          </MainTitle>
          <Hidden smDown>
            <CustomStepper
              activeStep={activeStep}
              complete={complete}
              handleStep={setActiveStep}
              data={steps}
            />
            <LeftSideFooter>
              <Typography color="textSecondary" variant="body2">
                {t('common.poweredByAltruus')}&nbsp;
                <Link
                  id="privacy-policy-link"
                  component={RouterLink}
                  to={ROUTE_PRIVACY_POLICY}
                  target="_blank"
                >
                  {t('common.privacyPolicyLink')}
                </Link>
              </Typography>
            </LeftSideFooter>
          </Hidden>
        </LeftSide>
        <RightSide item xs={12} md={6} sm={12}>
          <FormWrapper>
            <Hidden smDown>
              <IconButton
                id="close-modal-button"
                className="close-button"
                aria-label="Close"
                onClick={closeDialogProfile}
              >
                <CloseIcon />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              {!loading && !loadingLogo ? (
                <WrapSteps>
                  {activeStep === 0 && (
                    <AddBusinessLogo handleStep={setActiveStep} handleComplete={handleComplete} />
                  )}
                  {activeStep === 1 ? (
                    <PaymentConnect
                      isDesktop
                      squareDisplay={activeStep === 1}
                      stripeDisplay={activeStep === 1}
                      setSwitchDialogIsOpened={setSwitchDialogIsOpened}
                    />
                  ) : null}
                </WrapSteps>
              ) : (
                <LoaderWrap>
                  <CircularProgress />
                </LoaderWrap>
              )}
            </Hidden>
            <Hidden mdUp>
              {/* FIRST STEP */}
              <ExpansionPanel
                square
                expanded={activeStep === 0}
                onChange={complete.includes(0) ? () => setActiveStep(0) : null}
                disabled={complete.includes(0) && activeStep !== 0}
              >
                <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Number className="stepper-number">1</Number>
                    </Grid>
                    <Grid item>
                      <Typography variant="h3" component="h5">
                        {t('completeProfileDialog.steps.step1Title')}
                      </Typography>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <AddBusinessLogo handleStep={setActiveStep} handleComplete={handleComplete} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              {/* SECOND STEP */}
              <ExpansionPanel
                square
                expanded={activeStep === 1}
                onChange={complete.includes(0) ? () => setActiveStep(1) : null}
                disabled={complete.includes(0) && activeStep !== 1}
              >
                <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Grid container spacing={2} alignItems="center" wrap="nowrap">
                    <Grid item>
                      <Number className="stepper-number">2</Number>
                    </Grid>

                    <Grid item>
                      <Typography variant="h3" component="h5">
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  {
                    <PaymentConnect
                      isMobile
                      squareDisplay={activeStep === 1}
                      stripeDisplay={activeStep === 1}
                      setSwitchDialogIsOpened={setSwitchDialogIsOpened}
                    />
                  }
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Hidden>
          </FormWrapper>
        </RightSide>
      </Grid>
      {switchDialogIsOpened.display && (
        <SwitchAccountDialog
          accounts={getAccounts(switchDialogIsOpened.paymentType, check_info)}
          activeAccount={activeAccount(switchDialogIsOpened, check_info)}
          switchAccount={item => {
            if (switchDialogIsOpened.paymentType === 'stripe') {
              changeStripeAccount({
                stripe_user_id: item.value,
                organization_id,
              });
            }
            if (switchDialogIsOpened.paymentType === 'square') {
              changeSquareAccount({
                square_user_id: item.value,
                organization_id,
              });
            }
            closeDialogProfile();
          }}
          dialogIsOpen={switchDialogIsOpened.display}
          handleClose={() => {
            setSwitchDialogIsOpened({ display: false, paymentType: null });
          }}
          loader={changeAccountLoader}
          isPaymentSwitcher
        />
      )}
    </Dialog>
  );
};

CompleteProfileDialog.propTypes = {
  loadingLogo: PropTypes.bool,
  organization_logo: PropTypes.string,
  external_service: PropTypes.string,
  setStripeConnect: PropTypes.func,
  country: PropTypes.string,
  dialogIsOpen: PropTypes.bool.isRequired,
  closeDialogProfile: PropTypes.func.isRequired,
  check_info: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  plan_status: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  changeAccountLoader: PropTypes.bool.isRequired,
  changeStripeAccount: PropTypes.func.isRequired,
  changeSquareAccount: PropTypes.func.isRequired,
  organization_id: PropTypes.number,
};

const mapStateToProps = ({ auth, profileDialog, accounts }) => {
  return {
    external_service: auth.external_service,
    dialogIsOpen: profileDialog.dialogIsOpen,
    check_info: auth.check_info,
    plan_status: auth.plan_status,
    country: auth.organization_country,
    organization_id: auth.organization_id,
    organization_logo: auth.organization_logo,
    loadingLogo: auth.loadingLogo,
    changeAccountLoader: accounts.loading.change,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setStripeConnect: params => dispatch(actions.setStripeConnect(params)),
    closeDialogProfile: () => dispatch(actions.closeDialogProfile()),
    changeStripeAccount: params => dispatch(actions.changeStripeAccount(params)),
    changeSquareAccount: params => dispatch(actions.changeSquareAccount(params)),
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CompleteProfileDialog);
