import styled from 'styled-components';
import { DialogContent, DialogActions } from '@material-ui/core';

import colors from '@/constants/colors';
import breakpoints from '@/constants/breakpoints';
import StyledDialogTitle from '../styles';

export const StyledFiltersDialogTitle = styled(StyledDialogTitle)`
  && {
    padding: 44px 52px 30px;
    @media (max-width: ${breakpoints.xs}) {
      padding: 16px;
    }
  }
  border: 1px solid ${colors.dialogHeaderBorder};
  .MuiButtonBase-root {
    top: calc(50% - 15px);
    @media (max-width: ${breakpoints.xs}) {
      top: calc(50% - 20px);
    }
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 32px 52px 38px;
    @media (max-width: ${breakpoints.xs}) {
      padding: 16px;
    }
  }
  p {
    margin: 0;
  }
  .content-title {
    margin-bottom: 15px;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    padding: 0 52px 47px;
    @media (max-width: ${breakpoints.xs}) {
      padding: 0 16px 16px;
    }
  }
`;
