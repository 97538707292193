import * as actionTypes from './types/disputes';

export const getDisputesList = params => {
  return {
    type: actionTypes.DISPUTES_GET_LIST,
    payload: params,
  };
};

export default getDisputesList;
