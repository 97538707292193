const isDevelop = process.env.NODE_ENV === 'develop' || process.env.NODE_ENV === 'local';

const getErrorMessage = error => {
  let errorMessage = '';
  if (error.response && error.response.data.errors) {
    const handledErrors = error.response.data.errors;
    const errorKeys = Object.keys(handledErrors);

    errorMessage = handledErrors[errorKeys[0]][0];
  } else if (error.response && error.response.data.error) {
    errorMessage = error.response.data.error;
  } else if (error.response && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message === 'Network Error') {
    errorMessage = isDevelop ? 'Network Error' : '';
  } else {
    errorMessage = error.message;
  }

  return errorMessage;
};

export default getErrorMessage;
