import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/es';

import en from './languages/en';
import es from './languages/es';

const resources = { en, es };

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    load: 'unspecific',
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: true,
    },
  });

export function t(name, params = {}) {
  return i18n.t(name, params);
}

export function getLanguages() {
  return Object.keys(resources);
}

export function getCurrentLang() {
  const languages = getLanguages();
  const lng = i18n.language;
  const curLang = lng.split('-')[0];

  return languages.includes(curLang) ? curLang : 'en';
}

export function changeLang(lang) {
  i18n.changeLanguage(lang);
}

const currentLang = getCurrentLang();

moment.locale(currentLang);

export default i18n;
