import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import * as actionTypes from '@/store/actions/types/redemptions';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import getLink from '@/utility/common/routes';
import { getOrganizationInfo } from '@/store/saga/organizations';
import { TYPE_GIFT } from '@/constants/gift';
import { TYPE_ORGANIZATION } from '@/constants/organizations';

export function* redeemInfoSaga(action) {
  yield put({ type: actionTypes.REDEMPTIONS_START });
  const { code, formik } = action.payload;
  const url = endpoints.REDEEM_INFO;
  try {
    const { data } = yield axios.post(url, { code });
    yield put({
      type: actionTypes.REDEMPTIONS_INFO_SUCCESS,
      payload: {
        data,
      },
    });
    if (data.type === TYPE_GIFT) {
      formik.setSchema('redemptionCodeSchema');
    } else {
      formik.setSchema('redemptionAmountSchema');
      yield put({
        type: actionTypes.REDEMPTIONS_GET_HISTORY,
        payload: { code },
      });
    }
    if (formik.resetForm) {
      formik.resetForm(formik.values);
    }
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_FAIL,
    });
  }
}

export function* redeemSubmitSaga(action) {
  yield put({ type: actionTypes.REDEMPTIONS_START });
  const { responseData, formik } = action.payload;
  const url = endpoints.REDEEM_SUBMIT;
  try {
    yield axios.post(url, responseData);

    let amount = 0;
    if (responseData.value) {
      amount = responseData.value;
    }

    yield put({
      type: actionTypes.REDEMPTIONS_SUBMIT_SUCCESS,
      payload: amount,
    });

    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('redemptions.sucessfullyRedeemed'),
        options: {
          variant: 'success',
        },
      },
    });
    formik.setSchema(null);
    formik.resetForm();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_FAIL,
    });
  }
}

export function* getRedemptionHistorySaga(action) {
  yield put({ type: actionTypes.REDEMPTIONS_START });
  const { code } = action.payload;

  const url = endpoints.REDEEM_HISTORY;
  try {
    const { data } = yield axios.post(url, { code });

    yield put({
      type: actionTypes.REDEMPTIONS_GET_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_FAIL,
    });
  }
}

export function* refundRedemptionSaga(action) {
  yield put({ type: actionTypes.REDEMPTIONS_START });
  const { id, transaction_id, code, setSchema } = action.payload;
  const url = getLink(endpoints.REDEEM_REFUND, { id, transaction_id });
  try {
    const { data } = yield axios.post(url, { id, transaction_id });

    yield put({
      type: actionTypes.REDEMPTIONS_REFUND_SUCCESS,
      payload: data,
    });
    yield put({
      type: actionTypes.REDEMPTIONS_GET_HISTORY,
      payload: { code },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_INFO,
      payload: { code, formik: { setSchema } },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_FAIL,
    });
  }
}

export function* generateRedemptionCodeSaga(action) {
  const { type, id } = action.payload;
  const isOrganization = type === TYPE_ORGANIZATION;

  let start;
  let success;
  let fail;

  if (isOrganization) {
    start = actionTypes.GENERATE_REDEMPTION_ORGANIZ_CODE_START;
    success = actionTypes.GENERATE_REDEMPTION_ORGANIZ_CODE_SUCCESS;
    fail = actionTypes.GENERATE_REDEMPTION_ORGANIZ_CODE_FAIL;
  } else {
    start = actionTypes.GENERATE_REDEMPTION_CODE_USER_START;
    success = actionTypes.GENERATE_REDEMPTION_CODE_USER_SUCCESS;
    fail = actionTypes.GENERATE_REDEMPTION_CODE_USER_FAIL;
  }

  yield put({ type: start });

  const url = endpoints.GENERATE_REDEMPTION_CODE;

  try {
    const { data } = yield axios.post(url, { type, id });

    if (isOrganization) {
      yield put({
        type: success,
        payload: data.redemption_code,
      });
    } else {
      yield put({
        type: success,
        payload: { redemption_code: data.redemption_code, employeeId: id },
      });
    }
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });

    yield put({
      type: fail,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* redemptionDownloadQrCodeSaga(action) {
  yield put({ type: actionTypes.REDEMPTION_DOWNLOAD_QR_CODE_START });

  const { id, download_type, isMulti, name, callback } = action.payload;

  const url = isMulti
    ? getLink(endpoints.REDEMPTION_DOWNLOAD_CODES, { id })
    : getLink(endpoints.REDEMPTION_DOWNLOAD_QR_CODE, { id });

  try {
    const { data } = yield axios.get(url, {
      responseType: 'blob',
      params: { download_type },
    });

    const timestamp = Math.floor(Date.now() / 1000);
    const fileName = isMulti ? `${timestamp}` : `${name}${timestamp}`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = `${fileName}.${download_type}`;
    link.click();

    yield put({
      type: actionTypes.REDEMPTION_DOWNLOAD_QR_CODE_SUCCESS,
    });
    callback();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTION_DOWNLOAD_QR_CODE_FAIL,
    });
  }
}

export function* redemptionGetSecretSymbolsSaga() {
  yield put({ type: actionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS_START });

  const url = getLink(endpoints.REDEMPTION_GET_SECRET_SYMBOLS);

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS_SUCCESS,
      payload: { data },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_GET_SECRET_SYMBOLS_FAIL,
    });
  }
}

export function* redemptionSendSecretSymbolSaga(action) {
  yield put({ type: actionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL_START });

  const { period, secret_symbol_code, organizationId } = action.payload;

  const url = getLink(endpoints.REDEMPTION_SEND_SECRET_SYMBOL, { organizationId });

  const formData = new FormData();
  formData.append('period', period);
  formData.append('secret_symbol_code', secret_symbol_code);
  formData.append('_method', 'put');

  try {
    yield axios.post(url, formData, {
      'Content-Type': 'multipart/form-data',
    });
    yield put({
      type: actionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL_SUCCESS,
    });
    yield call(getOrganizationInfo, { payload: { organizationId } });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.generateSecretSymbol'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_SEND_SECRET_SYMBOL_FAIL,
    });
  }
}

export function* redemptionDownloadСosterSaga(action) {
  yield put({ type: actionTypes.REDEMPTION_DOWNLOAD_COSTER_START });

  const { organizationId, name } = action.payload;

  const url = getLink(endpoints.REDEMPTION_DOWNLOAD_COSTER, { id: organizationId });

  try {
    const { data } = yield axios.get(url, { responseType: 'blob' });

    const timestamp = Math.floor(Date.now() / 1000);
    const fileName = `${name}${timestamp}`;
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(data);
    link.download = `${fileName}.pdf`;
    link.click();

    yield put({
      type: actionTypes.REDEMPTION_DOWNLOAD_COSTER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTION_DOWNLOAD_COSTER_FAIL,
    });
  }
}

export function* getRedemptionsSaga(action) {
  yield put({ type: actionTypes.GET_REDEMPTIONS_START });

  const { organizationId, ...params } = action.payload;
  const url = organizationId
    ? getLink(endpoints.ORGANIZATION_REDEMPTIONS, { organizationId })
    : endpoints.REDEMPTIONS;

  try {
    const { data } = yield axios.get(url, { params });

    yield put({
      type: actionTypes.GET_REDEMPTIONS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_REDEMPTIONS_FAIL,
    });
  }
}

export function* getRedemptionsCodeInfoSaga(action) {
  yield put({ type: actionTypes.REDEMPTIONS_CODE_INFO_START });

  const { id } = action.payload;
  const url = getLink(endpoints.CODE_REDEMPTIONS, { id });

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.REDEMPTIONS_CODE_INFO_SUCCESS,
      payload: { data: data.data, total_redeemed: data.total_redeemed },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.REDEMPTIONS_CODE_INFO_FAIL,
    });
  }
}
