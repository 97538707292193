import React, { lazy } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';

import LayoutRouter from '@/hoc/Layout';

// CONSTANTS
import * as routePaths from '@/constants/routes';
import { ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN } from '@/constants/roles';

// UTILITY
import { retry } from '@/utility/common/routes';

const Support = lazy(() => retry(() => import('@/containers/Auth/Support')));
const ProfileSupport = lazy(() => retry(() => import('@/containers/ProfileSupport')));
const SignIn = lazy(() => retry(() => import('@/containers/Auth/SignIn')));
const SignUp = lazy(() => retry(() => import('@/containers/Auth/SignUp')));
const ResetPassword = lazy(() => retry(() => import('@/containers/Auth/ResetPassword')));
const UpdatePassword = lazy(() => retry(() => import('@/containers/Auth/UpdatePassword')));
const StyleGuide = lazy(() => retry(() => import('@/containers/StyleGuide')));
const Overview = lazy(() => retry(() => import('@/containers/Overview')));
const Gifts = lazy(() => retry(() => import('@/containers/Gifts')));
const CreateEditGift = lazy(() => retry(() => import('@/containers/Gifts/CreateEditGift')));
const EditGiftCard = lazy(() => retry(() => import('@/containers/Gifts/EditGiftCard')));
const DetailGift = lazy(() => retry(() => import('@/containers/Gifts/DetailGift')));
const ChangeQuantity = lazy(() => retry(() => import('@/containers/Gifts/ChangeQuantity')));
const Balance = lazy(() => retry(() => import('@/containers/Balance')));
const TransactionsHistory = lazy(() => {
  return retry(() => import('@/containers/Balance/TransactionsHistory'));
});
const Customers = lazy(() => retry(() => import('@/containers/Customers')));
const DetailsCustomer = lazy(() => retry(() => import('@/containers/Customers/DetailsCustomer')));
const Users = lazy(() => retry(() => import('@/containers/Users')));
const DetailsUser = lazy(() => retry(() => import('@/containers/Users/DetailsUser')));
const Inbox = lazy(() => retry(() => import('@/containers/Inbox')));
const Disputes = lazy(() => retry(() => import('@/containers/Disputes')));
const MyProfile = lazy(() => retry(() => import('@/containers/MyProfile')));
const Help = lazy(() => retry(() => import('@/containers/Help')));
const Redemptions = lazy(() => retry(() => import('@/containers/Redemptions/Organization')));
const AdminRedemptions = lazy(() => retry(() => import('@/containers/Redemptions/Admin')));
const Settings = lazy(() => retry(() => import('@/containers/Settings')));
const Organization = lazy(() => retry(() => import('@/containers/Organization')));
const CreateOrganization = lazy(() => {
  return retry(() => import('@/containers/Organizations/createOrganization'));
});
const CreateMyOrganization = lazy(() => {
  return retry(() => import('@/containers/Organization/help/MyBussinesses/CreateMyOrganization'));
});
const Organizations = lazy(() => retry(() => import('@/containers/Organizations')));
const DetailLocation = lazy(() => retry(() => import('@/containers/Organization/DetailLocation')));
const LocationsImportCSV = lazy(() => {
  return retry(() => import('@/containers/Organization/LocationsImportCSV'));
});
const EmployeesImportCSV = lazy(() => {
  return retry(() => import('@/containers/Organization/EmployeesImportCSV'));
});
const Categories = lazy(() => retry(() => import('@/containers/Categories')));
const SubCategories = lazy(() => retry(() => import('@/containers/Categories/help/SubCategories')));
const CreateEditSubCategory = lazy(() => {
  return retry(() => import('@/containers/Categories/CreateEditSubCategory'));
});
const CreateEditCategory = lazy(() => {
  return retry(() => import('@/containers/Categories/CreateEditCategory'));
});
const CreateEditPromoGroup = lazy(() => {
  return retry(() => import('@/containers/Categories/CreateEditPromoGroup'));
});
const Surveys = lazy(() => retry(() => import('@/containers/Surveys')));
const DetailSurveys = lazy(() => retry(() => import('@/containers/Surveys/DetailSurvey')));
const CreateEditSurvey = lazy(() => retry(() => import('@/containers/Surveys/CreateEditSurvey')));
const TransactionsList = lazy(() => retry(() => import('@/containers/Transactions')));
const TransactionDetails = lazy(() => {
  return retry(() => import('@/containers/Transactions/help/TransactionDetails'));
});
const NotFound = lazy(() => retry(() => import('@/containers/NotFound')));
const GiftInfo = lazy(() => retry(() => import('@/containers/Gifts/GiftInfo/GiftInfo')));
const Communications = lazy(() => retry(() => import('@/containers/CommonContent/Communications')));
const CommunicationsPR = lazy(() => {
  return retry(() => import('@/containers/CommonContent/CommunicationsPR'));
});

const About = lazy(() => retry(() => import('@/containers/CommonContent/About')));
const HowItWorks = lazy(() => retry(() => import('@/containers/CommonContent/HowItWorks')));
const UserTerms = lazy(() => retry(() => import('@/containers/CommonContent/UserTerms')));
const UserTermsPR = lazy(() => retry(() => import('@/containers/CommonContent/UserTermsPR')));

const MerchantAgreement = lazy(() => {
  return retry(() => import('@/containers/CommonContent/MerchantAgreement'));
});
const PrivacyAndTerms = lazy(() => {
  return retry(() => import('@/containers/CommonContent/PrivacyAndTerms'));
});
const ChangePlan = lazy(() => retry(() => import('@/containers/MyProfile/ChangePlan')));
const Download = lazy(() => retry(() => import('@/containers/Download')));
const Admins = lazy(() => retry(() => import('@/containers/Admins')));
const Analytics = lazy(() => retry(() => import('@/containers/Analytics')));
const CreateEditAdmin = lazy(() => {
  return retry(() => import('@/containers/Admins/CreateEditAdmin'));
});
const AdminsVerify = lazy(() => {
  return retry(() => import('@/containers/Auth/AdminsVerify'));
});
const DetailAdmin = lazy(() => {
  return retry(() => import('@/containers/Admins/DetailsAdmin'));
});
const Sharing = lazy(() => retry(() => import('@/containers/Sharing')));
const UniversalCards = lazy(() => retry(() => import('@/containers/UniversalCards')));
const NotifyUsers = lazy(() => retry(() => import('@/containers/NotifyUsers')));

const routes = () => {
  return (
    <Switch>
      <LayoutRouter type="auth" path={routePaths.ROUTE_SUPPORT} component={Support} exact />
      <LayoutRouter
        type="auth"
        path={routePaths.ROUTE_ADMINS_VERIFY}
        component={AdminsVerify}
        exact
      />
      <LayoutRouter type="auth" path={routePaths.ROUTE_ROOT} component={SignIn} exact />
      <LayoutRouter type="auth" path={routePaths.ROUTE_SIGN_UP} component={SignUp} />
      <LayoutRouter
        type="auth"
        path={routePaths.ROUTE_RESET_PASSWORD}
        component={ResetPassword}
        exact
      />
      <LayoutRouter
        type="auth"
        path={routePaths.ROUTE_UPDATE_PASSWORD}
        component={UpdatePassword}
      />
      <LayoutRouter
        type="auth"
        path={routePaths.ROUTE_CREATE_PASSWORD}
        component={UpdatePassword}
      />
      <LayoutRouter
        type="document"
        path={routePaths.ROUTE_PRIVACY_AND_TERMS}
        component={PrivacyAndTerms}
      />
      <LayoutRouter type="document" path={routePaths.ROUTE_ABOUT} component={About} />
      <LayoutRouter type="document" path={routePaths.ROUTE_HOW_IT_WORKS} component={HowItWorks} />
      <LayoutRouter type="document" path={routePaths.ROUTE_TERMS} component={UserTerms} />
      <LayoutRouter type="document" path={routePaths.ROUTE_TERMS_PR} component={UserTermsPR} />
      <LayoutRouter
        type="document"
        path={routePaths.ROUTE_MERCH_AGREEMENT}
        component={MerchantAgreement}
      />
      <LayoutRouter
        type="document"
        path={routePaths.ROUTE_PRIVACY_POLICY}
        component={Communications}
      />
      <LayoutRouter
        type="document"
        path={routePaths.ROUTE_PRIVACY_POLICY_PR}
        component={CommunicationsPR}
      />
      <LayoutRouter type="document" path={routePaths.ROUTE_DOWNLOAD} component={Download} />
      <LayoutRouter
        roles={[ROLE_ADMIN]}
        path={routePaths.SETTINGS}
        component={Settings}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_REDEMPTIONS}
        component={Redemptions}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_ADMIN_REDEMPTIONS}
        component={AdminRedemptions}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_OVERVIEW}
        component={Overview}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_GIFTS}
        component={Gifts}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DIRECT_GIFTS}
        component={Gifts}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_CREATE_GIFT}
        component={CreateEditGift}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EDIT_GIFT}
        component={CreateEditGift}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DETAIL_GIFT}
        component={DetailGift}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EDIT_GIFT_CARD}
        component={EditGiftCard}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_CHANGE_QUANTITY}
        component={ChangeQuantity}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DETAIL_DIRECT_GIFT}
        component={DetailGift}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_BALANCE}
        component={Balance}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN, ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_TRANSACTIONS_HISTORY}
        component={TransactionsHistory}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_CUSTOMERS}
        component={Customers}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_DETAILS_CUSTOMER}
        component={DetailsCustomer}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_USERS}
        component={Users}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DETAILS_USER}
        component={DetailsUser}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_ORGANIZATION}
        component={Organization}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_ORGANIZATIONS}
        component={Organizations}
        exact
        protection
      />
      {/* <LayoutRouter */}
      {/*  roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]} */}
      {/*  path={routePaths.ROUTE_CREATE_ORGANIZATION} */}
      {/*  component={CreateOrganization} */}
      {/*  exact */}
      {/*  protection */}
      {/* /> */}
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_CREATE_MY_ORGANIZATION}
        component={CreateMyOrganization}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DRAFT_ORGANIZATION}
        component={CreateOrganization}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_LOCATIONS_IMPORT_CSV}
        component={LocationsImportCSV}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_LOCATIONS_CSV}
        component={LocationsImportCSV}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EMPLOYEES_IMPORT_CSV}
        component={EmployeesImportCSV}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EMPLOYEES_CSV}
        component={EmployeesImportCSV}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DETAIL_ORGANIZATION}
        component={Organization}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_ORGANIZATION_LOCATION_DETAIL}
        component={DetailLocation}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_ANALYTICS}
        component={Analytics}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_LOCATION_DETAIL}
        component={DetailLocation}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_CATEGORIES}
        component={Categories}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_CREATE_CATEGORY}
        component={CreateEditCategory}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_CREATE_PROMO_GROUP}
        component={CreateEditPromoGroup}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EDIT_PROMO_GROUP}
        component={CreateEditPromoGroup}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EDIT_CATEGORY}
        component={CreateEditCategory}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_SUBCATEGORIES}
        component={SubCategories}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_CREATE_SUBCATEGORY}
        component={CreateEditSubCategory}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EDIT_SUBCATEGORY}
        component={CreateEditSubCategory}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_SURVEYS}
        component={Surveys}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_GIFT_INFO}
        component={GiftInfo}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_CREATE_SURVEYS}
        component={CreateEditSurvey}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_EDIT_SURVEYS}
        component={CreateEditSurvey}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DETAIL_SURVEYS}
        component={DetailSurveys}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_NOTIFY_USERS}
        component={NotifyUsers}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_TRANSACTIONS_LIST}
        component={TransactionsList}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_DETAIL_TRANSACTION}
        component={TransactionDetails}
        exact
        protection
      />
      <LayoutRouter
        path={routePaths.ROUTE_HELP}
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN, ROLE_MERCHANDISER]}
        component={Help}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN]}
        path={routePaths.ROUTE_ADMINS}
        component={Admins}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN]}
        path={routePaths.ROUTE_CREATE_ADMIN}
        component={CreateEditAdmin}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN]}
        path={routePaths.ROUTE_EDIT_ADMIN}
        component={CreateEditAdmin}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN]}
        path={routePaths.ROUTE_ADMINS_DETAIL}
        component={DetailAdmin}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_INBOX}
        component={Inbox}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN]}
        path={routePaths.ROUTE_DISPUTES}
        component={Disputes}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_MY_PROFILE}
        component={MyProfile}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_PROFILE_SUPPORT}
        component={ProfileSupport}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_MERCHANDISER]}
        path={routePaths.ROUTE_CHANGE_PLAN}
        component={ChangePlan}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_SHARING}
        component={Sharing}
        exact
        protection
      />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_SUB_ADMIN]}
        path={routePaths.ROUTE_UNIVERSAL_CARDS}
        component={UniversalCards}
        exact
        protection
      />
      <Route path={routePaths.ROUTE_STYLEGUIDE} component={StyleGuide} exact />
      <LayoutRouter
        roles={[ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN]}
        type="static"
        component={NotFound}
        protection
      />
    </Switch>
  );
};

export default withRouter(routes);
