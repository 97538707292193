import styled from 'styled-components';
import colors from '@/constants/colors';

const NotificationContainer = styled.p`
  margin: 0;
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 400;
  text-transform: uppercase;
  color: ${colors.notificationDefault};
  ${({ type }) => {
    if (type === 'success') {
      return `color: ${colors.notificationSuccess};`;
    }
    if (type === 'error') {
      return `color: ${colors.notificationError};`;
    }
    if (type === 'warning') {
      return `color: ${colors.notificationWarning};`;
    }
  }};
`;

export default NotificationContainer;
