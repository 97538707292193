// Work Hours actions
export const GET_WORK_HOURS = 'GET_WORK_HOURS';
export const GET_WORK_HOURS_START = 'GET_WORK_HOURS_START';
export const GET_WORK_HOURS_SUCCESS = 'GET_WORK_HOURS_SUCCESS';
export const GET_WORK_HOURS_FAIL = 'GET_WORK_HOURS_FAIL';

export const CHANGE_WORK_HOURS = 'CHANGE_WORK_HOURS';
export const CHANGE_WORK_HOURS_START = 'CHANGE_WORK_HOURS_START';
export const CHANGE_WORK_HOURS_SUCCESS = 'CHANGE_WORK_HOURS_SUCCESS';
export const CHANGE_WORK_HOURS_FAIL = 'CHANGE_WORK_HOURS_FAIL';
