// REDEMPTIONS actions
export const REDEMPTIONS_START = 'REDEMPTIONS_START';
export const REDEMPTIONS_FAIL = 'REDEMPTIONS_FAIL';

export const REDEMPTIONS_INFO = 'REDEMPTIONS_INFO';
export const REDEMPTIONS_INFO_SUCCESS = 'REDEMPTIONS_INFO_SUCCESS';

export const REDEMPTIONS_SUBMIT = 'REDEMPTIONS_SUBMIT';
export const REDEMPTIONS_SUBMIT_SUCCESS = 'REDEMPTIONS_SUBMIT_SUCCESS';

export const REDEMPTIONS_REDEEM_OTHER = 'REDEMPTIONS_REDEEM_OTHER';

export const REDEMPTIONS_GET_HISTORY = 'REDEMPTIONS_GET_HISTORY';
export const REDEMPTIONS_GET_HISTORY_SUCCESS = 'REDEMPTIONS_GET_HISTORY_SUCCESS';

export const REDEMPTIONS_REFUND = 'REDEMPTIONS_REFUND';
export const REDEMPTIONS_REFUND_SUCCESS = 'REDEMPTIONS_REFUND_SUCCESS';

export const REDEMPTIONS_SET_INIT_STATE = 'REDEMPTIONS_SET_INIT_STATE';

export const GENERATE_REDEMPTION_CODE = 'GENERATE_REDEMPTION_CODE';
export const GENERATE_REDEMPTION_ORGANIZ_CODE_START = 'GENERATE_REDEMPTION_ORGANIZ_CODE_START';
export const GENERATE_REDEMPTION_ORGANIZ_CODE_SUCCESS = 'GENERATE_REDEMPTION_ORGANIZ_CODE_SUCCESS';
export const GENERATE_REDEMPTION_ORGANIZ_CODE_FAIL = 'GENERATE_REDEMPTION_ORGANIZ_CODE_FAIL';

export const GENERATE_REDEMPTION_CODE_USER_START = 'GENERATE_REDEMPTION_CODE_USER_START';
export const GENERATE_REDEMPTION_CODE_USER_SUCCESS = 'GENERATE_REDEMPTION_CODE_USER_SUCCESS';
export const GENERATE_REDEMPTION_CODE_USER_FAIL = 'GENERATE_REDEMPTION_CODE_USER_FAIL';

export const REDEMPTION_DOWNLOAD_QR_CODE = 'REDEMPTION_DOWNLOAD_QR_CODE';
export const REDEMPTION_DOWNLOAD_QR_CODE_START = 'REDEMPTION_DOWNLOAD_QR_CODE_START';
export const REDEMPTION_DOWNLOAD_QR_CODE_SUCCESS = 'REDEMPTION_DOWNLOAD_QR_CODE_SUCCESS';
export const REDEMPTION_DOWNLOAD_QR_CODE_FAIL = 'REDEMPTION_DOWNLOAD_QR_CODE_FAIL';

export const REDEMPTIONS_GET_SECRET_SYMBOLS = 'REDEMPTIONS_GET_SECRET_SYMBOLS';
export const REDEMPTIONS_GET_SECRET_SYMBOLS_START = 'REDEMPTIONS_GET_SECRET_SYMBOLS_START';
export const REDEMPTIONS_GET_SECRET_SYMBOLS_SUCCESS = 'REDEMPTIONS_GET_SECRET_SYMBOLS_SUCCESS';
export const REDEMPTIONS_GET_SECRET_SYMBOLS_FAIL = 'REDEMPTIONS_GET_SECRET_SYMBOLS_FAIL';

export const REDEMPTIONS_SEND_SECRET_SYMBOL = 'REDEMPTIONS_SEND_SECRET_SYMBOL';
export const REDEMPTIONS_SEND_SECRET_SYMBOL_START = 'REDEMPTIONS_SEND_SECRET_SYMBOL_START';
export const REDEMPTIONS_SEND_SECRET_SYMBOL_SUCCESS = 'REDEMPTIONS_SEND_SECRET_SYMBOL_SUCCESS';
export const REDEMPTIONS_SEND_SECRET_SYMBOL_FAIL = 'REDEMPTIONS_SEND_SECRET_SYMBOL_FAIL';

export const REDEMPTION_DOWNLOAD_COSTER = 'REDEMPTION_DOWNLOAD_COSTER';
export const REDEMPTION_DOWNLOAD_COSTER_START = 'REDEMPTION_DOWNLOAD_COSTER_START';
export const REDEMPTION_DOWNLOAD_COSTER_SUCCESS = 'REDEMPTION_DOWNLOAD_COSTER_SUCCESS';
export const REDEMPTION_DOWNLOAD_COSTER_FAIL = 'REDEMPTION_DOWNLOAD_COSTER_FAIL';

export const GET_REDEMPTIONS = 'GET_REDEMPTIONS';
export const GET_REDEMPTIONS_START = 'GET_REDEMPTIONS_START';
export const GET_REDEMPTIONS_FAIL = 'GET_REDEMPTIONS_FAIL';
export const GET_REDEMPTIONS_SUCCESS = 'GET_REDEMPTIONS_SUCCESS';

export const REDEMPTIONS_CODE_INFO = 'REDEMPTIONS_CODE_INFO';
export const REDEMPTIONS_CODE_INFO_START = 'REDEMPTIONS_CODE_INFO_START';
export const REDEMPTIONS_CODE_INFO_FAIL = 'REDEMPTIONS_CODE_INFO_FAIL';
export const REDEMPTIONS_CODE_INFO_SUCCESS = 'REDEMPTIONS_CODE_INFO_SUCCESS';
