import * as actionTypes from './types/privacyAndTerms';

export const sendPrivacyAndTerms = params => {
  return {
    type: actionTypes.SEND_PRIVACY_AND_TERMS,
    payload: params,
  };
};

export const getPrivacy = (params = {}) => {
  return {
    type: actionTypes.GET_PRIVACY,
    payload: params,
  };
};

export const getTerms = (params = {}) => {
  return {
    type: actionTypes.GET_TERMS,
    payload: params,
  };
};
