// PRIVACY AND TERMS actions
export const GET_PRIVACY = 'GET_PRIVACY';
export const GET_PRIVACY_START = 'GET_PRIVACY_START';
export const GET_PRIVACY_SUCCESS = 'GET_PRIVACY_SUCCESS';
export const GET_PRIVACY_FAIL = 'GET_PRIVACY_FAIL';

export const GET_TERMS = 'GET_TERMS';
export const GET_TERMS_START = 'GET_TERMS_START';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAIL = 'GET_TERMS_FAIL';

export const SEND_PRIVACY_AND_TERMS = 'SEND_PRIVACY_AND_TERMS';

export const SEND_PRIVACY_START = 'SEND_PRIVACY_START';
export const SEND_PRIVACY_SUCCESS = 'SEND_PRIVACY_SUCCESS';
export const SEND_PRIVACY_FAIL = 'SEND_PRIVACY_FAIL';

export const SEND_TERMS_START = 'SEND_TERMS_START';
export const SEND_TERMS_SUCCESS = 'SEND_TERMS_SUCCESS';
export const SEND_TERMS_FAIL = 'SEND_TERMS_FAIL';
