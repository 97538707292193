import * as actionTypes from '@/store/actions/types/analytics';
import * as authActionTypes from '@/store/actions/types/auth';

const initialState = {
  data: {
    counts: {},
    screens: {
      viewData: [],
      avgTimeData: [],
    },
    tops: {
      businesses: [],
      gifts: [],
      users: [],
      categories: [],
    },
    search: [],
    businessDetails: {
      registrations: [],
      detailedTop: {
        data: [],
        current_page: 0,
        per_page: 5,
        total: 0,
      },
    },
    brands: {
      data: [],
      current_page: 0,
      per_page: 5,
      total: 0,
    },
    giftsSum: {
      data: [],
      current_page: 0,
      per_page: 5,
      total: 0,
    },
  },
  loading: {
    general: false,
    users: false,
    businesses: false,
    brands: false,
    gifts: false,
    giftsSum: false,
  },
  errors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ANALYTICS_GET_BUSINESS_DETAILS_START:
      return {
        ...state,
        loading: { ...state.loading, businesses: true },
      };
    case actionTypes.ANALYTICS_GET_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          businessDetails: {
            ...state.data.businessDetails,
            registrations: action.payload.registrations,
          },
        },
        loading: { ...state.loading, businesses: false },
      };
    case actionTypes.ANALYTICS_GET_BUSINESS_DETAILS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, businesses: false },
      };
    case actionTypes.ANALYTICS_GENERAL_START:
      return {
        ...state,
        loading: { ...state.loading, general: true },
      };
    case actionTypes.ANALYTICS_GENERAL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          counts: {
            ...state.data.counts,
            ...action.payload.counts,
          },
          screens: action.payload.screens,
          tops: {
            ...state.data.tops,
            ...action.payload.tops,
          },
          search: action.payload.search,
        },
        loading: { ...state.loading, general: false },
      };
    case actionTypes.ANALYTICS_GENERAL_FAIL:
      return {
        ...state,
        loading: { ...state.loading, general: false },
      };
    case actionTypes.ANALYTICS_USERS_START:
      return {
        ...state,
        loading: { ...state.loading, users: true },
      };
    case actionTypes.ANALYTICS_USERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          counts: {
            ...state.data.counts,
            ...action.payload.counts,
          },
          tops: {
            ...state.data.tops,
            ...action.payload.tops,
          },
        },
        loading: { ...state.loading, users: false },
      };
    case actionTypes.ANALYTICS_USERS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, users: false },
      };
    case actionTypes.ANALYTICS_GIFTS_START:
      return {
        ...state,
        loading: { ...state.loading, gifts: true },
      };
    case actionTypes.ANALYTICS_GIFTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          counts: {
            ...state.data.counts,
            ...action.payload.counts,
          },
          tops: {
            ...state.data.tops,
            ...action.payload.tops,
          },
        },
        loading: { ...state.loading, gifts: false },
      };
    case actionTypes.ANALYTICS_GIFTS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, gifts: false },
      };
    case actionTypes.ANALYTICS_GET_BRANDS_START:
      return {
        ...state,
        loading: { ...state.loading, brands: true },
      };
    case actionTypes.ANALYTICS_GET_BRANDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          brands: {
            data: action.payload.data,
            current_page: action.payload.meta.current_page - 1,
            per_page: action.payload.meta.per_page,
            total: action.payload.meta.total,
          },
        },
        loading: { ...state.loading, brands: false },
      };
    case actionTypes.ANALYTICS_GET_BRANDS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, brands: false },
      };

    case actionTypes.ANALYTICS_GET_SUM_START:
      return {
        ...state,
        loading: { ...state.loading, giftsSum: true },
      };
    case actionTypes.ANALYTICS_GET_SUM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          giftsSum: {
            data: action.payload.data,
            current_page: action.payload.meta.current_page - 1,
            per_page: action.payload.meta.per_page,
            total: action.payload.meta.total,
          },
        },
        loading: { ...state.loading, giftsSum: false },
      };
    case actionTypes.ANALYTICS_GET_SUM_FAIL:
      return {
        ...state,
        loading: { ...state.loading, giftsSum: false },
      };

    case actionTypes.GET_TOP_BRANDS_START:
      return {
        ...state,
        loading: { ...state.loading, topBrands: true },
      };
    case actionTypes.GET_TOP_BRANDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          businessDetails: {
            ...state.data.businessDetails,
            detailedTop: {
              data: action.payload.data,
              current_page: action.payload.meta?.current_page
                ? action.payload.meta?.current_page - 1
                : 0,
              per_page: action.payload.meta?.per_page || 0,
              total: action.payload.meta?.total || 0,
            },
          },
        },
        loading: { ...state.loading, topBrands: false },
      };
    case actionTypes.GET_TOP_BRANDS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, topBrands: false },
      };
    case authActionTypes.AUTH_LOGOUT: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
