import React, { Suspense, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

// COMMON
import PublicRoute from '@/routes/help/PublicRoute';
import PrivateRoute from '@/routes/help/PrivateRoute';
import CompleteProfileDialog from '@/components/Dialogs/CompleteProfileDialog';
import SideMenu from '@/components/SideMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Wrapper, AuthWrapper, ProgressWrap } from './styles';
import * as actions from '@/store/actions';

const loader = (
  <ProgressWrap>
    <CircularProgress />
  </ProgressWrap>
);

const layout = props => {
  const {
    role,
    roles,
    type,
    component: Component,
    isAuth,
    loading,
    protection,
    user,
    checkNotifications,
    id,
    ...rest
  } = props;

  let Structure;
  const [authPicSrc, setPicSrc] = useState('');
  const authPicCb = src => setPicSrc(src);

  useEffect(() => {
    if (protection && id) {
      checkNotifications({ id });
      const checkInbox = setInterval(() => {
        checkNotifications({ id });
      }, 1000 * 60 * 5);
      return () => {
        clearInterval(checkInbox);
      };
    }
  }, [id]);

  switch (type) {
    case 'dashboard':
      Structure = (
        <Grid container wrap="nowrap" direction="row">
          <SideMenu role={role} />
          <Wrapper item>
            {loading ? (
              loader
            ) : (
              <Suspense fallback={loader}>{!!user && <Component role={role} />}</Suspense>
            )}
          </Wrapper>
          <CompleteProfileDialog />
        </Grid>
      );
      break;
    case 'auth':
      Structure = (
        <AuthWrapper container>
          <Grid className="right-side" item xs={12} md={6}>
            <Suspense fallback={loader}>
              <Component authPicCallBack={authPicCb} />
            </Suspense>
          </Grid>
          <Grid className="left-side" item md={6}>
            <Grid className="image-wrapp" container justify="center" alignItems="center">
              {authPicSrc && <img src={authPicSrc} alt="auth-bg" />}
            </Grid>
          </Grid>
        </AuthWrapper>
      );
      break;
    case 'document':
      Structure = (
        <Suspense fallback={loader}>
          <Component authPicCallBack={authPicCb} />
        </Suspense>
      );
      break;
    default:
      Structure = (
        <Suspense fallback={loader}>
          <Component />
        </Suspense>
      );
  }

  return protection ? (
    <PrivateRoute {...rest} role={role} roles={roles} isAuth={isAuth} component={Structure} />
  ) : (
    <PublicRoute {...rest} type={type} role={role} isAuth={isAuth} component={Structure} />
  );
};

layout.defaultProps = {
  type: 'dashboard',
  protection: false,
};

layout.propTypes = {
  roles: PropTypes.array,
  role: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  authPicture: PropTypes.node,
  protection: PropTypes.bool,
  loading: PropTypes.bool,
  isAuth: PropTypes.bool,
  id: PropTypes.number,
  component: PropTypes.object,
  checkNotifications: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

const mapStateToProps = ({ auth }) => {
  return {
    loading: auth.loading,
    isAuth: auth.isAuth,
    id: auth.user && auth.user.id,
    user: auth.user,
    role: auth.role,
  };
};

const mapDispatchToProps = dispatch => ({
  checkNotifications: params => dispatch(actions.checkNotifications(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(layout);
