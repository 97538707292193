import * as Yup from 'yup';
import axios from 'axios';
import * as endpoints from '@/constants/api';

export default t => {
  return {
    old_email: Yup.string().required(t('validation.emailRequired')),
    new_email: Yup.string()
      .required(t('validation.emailRequired'))
      .email(t('validation.emailNotValid'))
      .test('email', t('validation.emailsAlreadyTaken'), email => {
        if (email) {
          const schema = Yup.string().email();
          if (schema.isValidSync(email)) {
            return axios
              .get(`${endpoints.USER_CHECK_EMAIL}/${email}?role_id=3`)
              .then(res => !res.data.exist)
              .catch(() => true);
          }
        }
        return true;
      }),
  };
};
