import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const SingleValue = ({ selectProps, innerProps, children }) => {
  return (
    <Typography className={selectProps.classes.singleValue} {...innerProps}>
      {children}
    </Typography>
  );
};

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any,
  selectProps: PropTypes.object.isRequired,
};

export default SingleValue;
