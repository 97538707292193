import * as actionTypes from './types/auth';

export const getMe = () => {
  return {
    type: actionTypes.AUTH_GET_ME,
  };
};

export const auth = (email, password) => {
  return {
    type: actionTypes.AUTH_USER,
    payload: {
      email,
      password,
    },
  };
};

export const logout = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const registerBusiness = (data, onSuccess) => {
  return {
    type: actionTypes.REGISTER_BUSINESS,
    payload: { data, onSuccess },
  };
};

export const changePass = data => {
  return {
    type: actionTypes.AUTH_CHANGE_PASS,
    payload: {
      data,
    },
  };
};

export const resetPass = email => {
  return {
    type: actionTypes.AUTH_RESET_PASS,
    payload: {
      email,
    },
  };
};

export const createPass = data => {
  return {
    type: actionTypes.AUTH_CREATE_PASS,
    payload: {
      data,
    },
  };
};

export const verifyBusiness = token => {
  return {
    type: actionTypes.BUSINESS_VERIFY,
    payload: {
      token,
    },
  };
};

export const updateFirstLogin = () => {
  return {
    type: actionTypes.AUTH_UPDATE_FIRST_LOGIN,
  };
};

export const loginAsOrganization = params => {
  return {
    type: actionTypes.AUTH_LOGIN_AS_ORGANIZATION,
    payload: params,
  };
};

export const loginAsAdmin = params => {
  return {
    type: actionTypes.AUTH_LOGIN_AS_ADMIN,
    payload: params,
  };
};

export const resendEmail = params => {
  return {
    type: actionTypes.AUTH_RESEND_EMAIL,
    payload: params,
  };
};

export const verifyAdmin = params => {
  return {
    type: actionTypes.ADMIN_VERIFY,
    payload: params,
  };
};
