import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const NoOptionsMessage = ({ innerProps, selectProps, children }) => {
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
};

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

export default NoOptionsMessage;
