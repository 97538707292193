import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import colors from '@/constants/colors';

import breakpoints from '@/constants/breakpoints';

export const ErrorWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  margin-top: 5px;
  width: 100%;
  color: ${colors.dropZone.error};
`;

export const WrapperLoader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled(Paper)`
  && {
    margin-top: 32px;
    margin-bottom: 20px;
    padding: 0 60px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: ${breakpoints.md}) {
      padding: 0px 30px;
    }

    @media screen and (max-width: ${breakpoints.xs}) {
      padding: 0px 16px;
    }
  }
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 500px;
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const StyledForm = styled.form`
  width: 100%;
  padding-top: 45px;
  padding-bottom: 56px;

  @media screen and (max-width: ${breakpoints.md}) {
    padding-top: 35px;
    padding-bottom: 45px;
  }

  @media screen and (max-width: ${breakpoints.xs}) {
    padding: 16px 0;
  }
`;

export const ButtonsField = styled.div`
  margin-top: 38px;
  @media screen and (max-width: ${breakpoints.xs}) {
    margin-top: 30px;
  }
`;

export default Container;
