import * as actionTypes from '@/store/actions/types/credits';

export const getUsersUniversalCards = params => {
  return {
    type: actionTypes.GET_USERS_UNIVERSAL_CARDS,
    payload: params,
  };
};

export const getUniversalCards = params => {
  return {
    type: actionTypes.GET_UNIVERSAL_CARDS,
    payload: params,
  };
};

export const getUserUniversalCard = params => {
  return {
    type: actionTypes.GET_USER_UNIVERSAL_CARD,
    payload: params,
  };
};

export const addUniversalCardToUser = params => {
  return {
    type: actionTypes.ADD_UNIVERSAL_CARD_TO_USER,
    payload: params,
  };
};
