import * as actionTypes from '@/store/actions/types/language';

const initialState = {
  loading: false,
  emailLang: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE_START:
      return { ...state, loading: true };
    case actionTypes.SET_LANGUAGE_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.SET_LANGUAGE_FAIL:
      return { ...state, loading: false };

    case actionTypes.GET_EMAIL_LANG_START:
      return { ...state, loading: true };
    case actionTypes.GET_EMAIL_LANG_SUCCESS:
      return { ...state, emailLang: action.payload.email_lang, loading: false };
    case actionTypes.GET_EMAIL_LANG_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
