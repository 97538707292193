import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { InfoOutlined } from '@material-ui/icons';
import { Typography, Link } from '@material-ui/core/index';

import queryString from 'query-string/index';

import { t } from '@/locale/i18n';
// CONSTANTS
import {
  checkIsPaymentNeedDocuments,
  checkIsPaymentVerified,
  checkPaymentAccountProblem,
} from '@/utility/orgonizations/organization';
import TitleContainer from '@/components/Form/styles';
import Notification from '@/components/Notification/Notification';
import { WarningContainer } from './styles';
import { stripeAccountProblem } from '@/components/Warnings/PaymentAccountWarning/PaymentAccountWarning';
import { ROLE_ADMIN, ROLE_SUB_ADMIN } from '@/constants/roles';
import StripeBtn from '../StripeBtn';

const stripeConnect = ({
  match,
  history,
  location,
  check_info,
  organizationStatus,
  role,
  stripeAccountName,
  stripeAccountId,
}) => {
  const { stripe_connect, stripe_connect_requirements: requirements } = check_info;
  const [code, setCode] = useState(null);
  const query = queryString.parse(location.search);
  const isStripeVerified = checkIsPaymentVerified(check_info, organizationStatus);
  const isStripeAccountProblem = checkPaymentAccountProblem(
    check_info,
    role === ROLE_ADMIN || role === ROLE_SUB_ADMIN,
  );
  const isStripeNeedDocuments = checkIsPaymentNeedDocuments(check_info, organizationStatus);
  const activeAccountName = stripeAccountName || stripeAccountId;

  useEffect(() => {
    if (query.code && !stripe_connect) {
      setCode(query.code);
    }
  }, [query.code]);

  const renderSubtitle = () => {
    if (!isStripeVerified || isStripeAccountProblem) {
      return null;
    }

    return code || stripe_connect ? (
      <Notification type="success" text={t('notification.paymentConnectSuccess')} />
    ) : (
      <Typography variant="subtitle2" color="textSecondary">
        {t('completeProfileDialog.paymentConnectSubtitle', { payment: 'Stripe' })}
      </Typography>
    );
  };

  const warningText = () => {
    let formatText = '';

    if (!isStripeVerified) {
      formatText = `${t('stripe.messages.disabled')}${'\n'}`;
      if (requirements?.disabled_reason) {
        formatText += `${t('stripe.messages.reason')} ${t(
          `stripe.${requirements?.disabled_reason}`,
        )} ${'\n'}`;
      }
    }

    if (!!requirements?.currently_due?.length && !isStripeAccountProblem) {
      formatText += `${t('stripe.messages.notAllFields')} ${'\n'}`;
    }

    if (isStripeAccountProblem) {
      formatText += stripeAccountProblem(check_info);
    }

    return formatText;
  };

  return (
    <>
      <TitleContainer>
        <Typography variant="h2" component="h4">
          {t('completeProfileDialog.paymentConnectTitle', { payment: 'Stripe' })}
        </Typography>
        {renderSubtitle()}
        {(!isStripeVerified || isStripeNeedDocuments || isStripeAccountProblem) && (
          <WarningContainer>
            <Typography variant="body2">
              {warningText()}
              {'\n'}
              {`${t('stripe.messages.checkIssue')}`}{' '}
              <Link
                id="stripe-connect-dashboard"
                href="https://dashboard.stripe.com/login"
                target="_blank"
              >
                {t('stripe.messages.stripeDashboard')}
              </Link>
            </Typography>
            <InfoOutlined style={{ marginRight: 10 }} />
          </WarningContainer>
        )}
      </TitleContainer>
      {(code || stripe_connect) && (
        <Typography variant="subtitle2" color="textSecondary">
          {t('completeProfileDialog.paymentToManaging', { payment: 'Stripe' })}{' '}
          <Link
            id="stripe-connect-dashboard"
            href="https://dashboard.stripe.com/login"
            target="_blank"
            style={{ fontSize: 16 }}
          >
            {t('common.link')}
          </Link>
        </Typography>
      )}
      <StripeBtn history={history} match={match} />
      {activeAccountName && (
        <Typography variant="subtitle2" color="textSecondary">
          Active account: {activeAccountName}
        </Typography>
      )}
    </>
  );
};

stripeConnect.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  check_info: PropTypes.object,
  organizationStatus: PropTypes.string,
  stripeAccountName: PropTypes.string,
  stripeAccountId: PropTypes.string,
  role: PropTypes.string.isRequired,
};

const mapStateToProps = ({ stripe, auth }) => {
  return {
    loading: stripe.loading,
    check_info: auth.check_info,
    organizationStatus: auth.organization_status,
    stripeAccountName: auth.organization_stripe_account_name,
    stripeAccountId: auth.organization_stripe_account,
    role: auth.role,
  };
};

export default React.memo(compose(withRouter, connect(mapStateToProps, null))(stripeConnect));
