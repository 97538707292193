import * as actionTypes from './types/tags';

export const getTags = params => {
  return {
    type: actionTypes.GET_TAGS,
    payload: params,
  };
};

export default getTags;
