import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';

import * as actionTypes from '@/store/actions/types/sharing';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import getLink from '@/utility/common/routes';
import { t } from '@/locale/i18n';

export function* getSharingInfoSaga(action) {
  yield put({ type: actionTypes.SHARING_GET_INFO_START });

  const { organizationId } = action.payload;
  const url = organizationId
    ? getLink(endpoints.GET_SHARING_INFO_BY_ID, { organizationId })
    : endpoints.GET_SHARING_INFO;

  try {
    const { data } = yield axios.get(url);

    yield put({
      type: actionTypes.SHARING_GET_INFO_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.SHARING_GET_INFO_FAIL,
    });
  }
}

export function* downloadQRCodeSharingSaga(action) {
  yield put({ type: actionTypes.DOWNLOAD_SHARING_FILE_START });
  const { download_type, callback } = action.payload;

  const url = getLink(endpoints.SHARING_DOWNLOAD);

  try {
    const { data } = yield axios.get(url, {
      responseType: 'blob',
      params: { download_type },
    });

    const timestamp = Math.floor(Date.now() / 1000);
    const fileName = `${t('sharing.download')}${timestamp}`;
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(data);
    link.download = `${fileName}.${download_type}`;
    link.click();

    yield put({
      type: actionTypes.DOWNLOAD_SHARING_FILE_SUCCESS,
    });

    callback();
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.DOWNLOAD_SHARING_FILE_FAIL,
    });
  }
}
