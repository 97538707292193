import styled from 'styled-components';

const FieldContainer = styled.div`
  &.drop-filed {
    margin-bottom: 30px;

    .drop-filed {
      &__title {
        line-height: 1.8rem;
        opacity: 0.7;
        margin-bottom: 5px;
      }
      &__subtitle {
        line-height: 1.8rem;
        margin-bottom: 17px;
      }
    }

    &.m-b-0 {
      margin-bottom: 0;
    }
  }
`;

export default FieldContainer;
