import * as actionTypes from './types/gift';

/**
 * Create gift
 * @param {string} type
 * @param {Function} setNextStepFunc
 */
export const createGift = (type, setNextStepFunc) => {
  return {
    type: actionTypes.CREATE_GIFT,
    payload: {
      type,
      setNextStepFunc,
    },
  };
};

export const getGift = productId => {
  return {
    type: actionTypes.GET_GIFT,
    payload: {
      productId,
    },
  };
};

/**
 * Update gift
 * @param {number} productId
 * @param {Object} data
 * @param {Function} setNextStepFunc
 */
export const updateGift = (productId, data, setNextStepFunc) => {
  return {
    type: actionTypes.UPDATE_GIFT,
    payload: {
      productId,
      data,
      setNextStepFunc,
    },
  };
};

export const deleteGift = productId => {
  return {
    type: actionTypes.DELETE_GIFT,
    payload: {
      productId,
    },
  };
};

export const changeQuantity = (productId, data) => {
  return {
    type: actionTypes.CHANGE_QUANTITY,
    payload: {
      productId,
      data,
    },
  };
};

export const duplicateGift = id => {
  return {
    type: actionTypes.GIFT_DUPLICATE,
    payload: id,
  };
};

export const getGiftChart = (id, dateRange, type) => {
  return {
    type: actionTypes.GIFT_GET_CHART,
    payload: { id, dateRange, type },
  };
};

export const getGiftCards = (id, date_range) => {
  return {
    type: actionTypes.GIFT_GET_CARDS,
    payload: { id, date_range },
  };
};

export const updateGiftStatus = data => {
  return {
    type: actionTypes.UPDATE_GIFT_STATUS,
    payload: data,
  };
};
