import * as Yup from 'yup';

import * as validationConst from '@/constants/validation';

export default t => {
  return {
    amount: Yup.string().required(t('validation.amountRequired')),
    otherAmount: Yup.number()
      .min(validationConst.MIN_GIFT_CARD_AMOUNT, t('validation.amountLength'))
      .when('amount', {
        is: amount => amount === 'other',
        then: Yup.number().required(t('validation.amountRequired')),
        otherwise: Yup.number(),
      })
      .test('otherAmount', t('validation.amountMustBeInRange'), function testAmount(value) {
        const { amount, amountFrom, amountTo } = this.parent;

        if (amount === 'other') {
          return amount === 'other' && value >= amountFrom && value <= amountTo;
        }
        return true;
      }),
  };
};
