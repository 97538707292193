import { t } from '@/locale/i18n';

// Convert a Base64-encoded string to a File object
export function base64StringToFile(base64String, filename) {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const getCroppedImg = (image, crop, fileName, type = 'image/jpeg') => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
  );

  const base64 = canvas.toDataURL(type);
  const imageFile = base64StringToFile(base64, fileName);

  return Object.assign(imageFile, {
    src: URL.createObjectURL(imageFile),
  });
};

export const getCroppedImgV2 = (imageBase64, fileName) => {
  const imageFile = base64StringToFile(imageBase64, fileName);

  return Object.assign(imageFile, {
    src: URL.createObjectURL(imageFile),
  });
};

export const getVideoUploadError = type => {
  switch (type) {
    case 'duration':
      return t('validation.promotionVideoDuration');
    default:
      return '';
  }
};

export default getCroppedImg;
