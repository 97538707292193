import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/gifts';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN } from '@/constants/roles';

export function* getTotalChartSaga(action) {
  yield put({ type: actionTypes.GIFTS_CHART_START });
  const { organizationId, dateRange, type, role, country, to, from } = action.payload;
  let url;

  if (role === ROLE_MERCHANDISER) {
    url = getLink(endpoints.GIFTS_CHART, { id: organizationId });
  } else if (role === ROLE_ADMIN || role === ROLE_SUB_ADMIN) {
    url = endpoints.GIFTS_CHART_ADMIN;
  }

  try {
    const response = yield axios.get(url, {
      params: {
        date_range: dateRange,
        type,
        country,
        from,
        to,
      },
    });
    const { data } = yield response;

    yield put({
      type: actionTypes.GIFTS_CHART_SUCCESS,
      payload: {
        data: data.data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GIFTS_CHART_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}

export function* getTotalStatisticCardsSaga(action) {
  yield put({ type: actionTypes.GIFTS_CARDS_START });
  const { organizationId, date_range, role } = action.payload;
  let url;

  if (role === ROLE_MERCHANDISER) {
    url = getLink(endpoints.GIFTS_CARDS, { id: organizationId });
  } else if (role === ROLE_ADMIN || role === ROLE_SUB_ADMIN) {
    url = endpoints.GIFTS_CARDS_ADMIN;
  }

  try {
    const response = yield axios.get(url, { params: { date_range } });
    const { data } = yield response;

    yield put({
      type: actionTypes.GIFTS_CARDS_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GIFTS_CARDS_FAIL,
      payload: { errorMessage: getErrorMessage(error) },
    });
  }
}
