import * as Yup from 'yup';

export default () => {
  return {
    date_range: Yup.object().shape({
      from: Yup.string(),
      to: Yup.string(),
    }),
  };
};
