import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

import { ItemContainer, Number, Subtitle } from './styles';

const customStepperItem = ({ activeStep, complete, handleStep, data, index }) => {
  const isCompleted = complete.includes(index);
  const isActive = activeStep === index;
  const isDisabled = !isActive && !isCompleted;
  let itemClass = '';

  if (isCompleted) {
    itemClass += 'complete disabled';
  } else if (activeStep === index) {
    itemClass += 'active';
  } else if (isDisabled) {
    itemClass += 'disabled';
  }

  return (
    <ItemContainer
      className={itemClass}
      onClick={!(isCompleted || isActive || isDisabled) ? () => handleStep(index) : null}
    >
      <Grid container spacing={2} alignItems="center" wrap="nowrap">
        <Grid item>
          <Number className="stepper-number">{index + 1}</Number>
        </Grid>
        <Grid item>
          <Typography variant="h3" component="h5">
            {data.title}
          </Typography>
          {data.subtitle && <Subtitle>{data.subtitle}</Subtitle>}
        </Grid>
      </Grid>
    </ItemContainer>
  );
};

customStepperItem.propTypes = {
  disabled: PropTypes.bool,
  activeStep: PropTypes.number.isRequired,
  complete: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  handleStep: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default React.memo(customStepperItem);
