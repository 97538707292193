import React from 'react';
import PropTypes from 'prop-types';

import CustomStepperItem from './help/CustomStepperItem';
import StepperContainer from './styles';

const customStepper = ({ activeStep, handleStep, data, complete }) => {
  return (
    <StepperContainer>
      {data.map((item, index) => (
        <CustomStepperItem
          data={item}
          activeStep={activeStep}
          complete={complete}
          handleStep={handleStep}
          index={index}
          key={index}
        />
      ))}
    </StepperContainer>
  );
};

customStepper.defaultProps = {
  activeStep: 0,
  complete: [],
};

customStepper.propTypes = {
  activeStep: PropTypes.number,
  complete: PropTypes.array,
  handleStep: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
};

export default React.memo(customStepper);
