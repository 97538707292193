import * as actionTypes from './types/csv';

export const importLocation = params => {
  return {
    type: actionTypes.CSV_IMPORT_LOCATIONS,
    payload: params,
  };
};

export const saveLocations = params => {
  return {
    type: actionTypes.CSV_SAVE_LOCATIONS,
    payload: params,
  };
};

export const importEmployees = params => {
  return {
    type: actionTypes.CSV_IMPORT_EMPLOYEES,
    payload: params,
  };
};

export const saveEmployees = params => {
  return {
    type: actionTypes.CSV_SAVE_EMPLOYEES,
    payload: params,
  };
};

export const clearCSV = () => {
  return {
    type: actionTypes.CSV_CLEAR,
  };
};

export const exportStatistics = params => {
  return {
    type: actionTypes.CSV_EXPORT_STATISTICS,
    payload: params,
  };
};
