import udidv4 from '@/utility/common/udidv4';

export function* getDeviceId() {
  let device_id = yield localStorage.getItem('device_id');

  if (!device_id) {
    device_id = udidv4();
    yield localStorage.setItem('device_id', device_id);
  }

  return device_id;
}

export default getDeviceId;
