import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography, Button, Link } from '@material-ui/core';

import { t } from '@/locale/i18n';
import * as actions from '@/store/actions';

import setYupValidationSchema from '@/utility/validationSchema/validationSchema';

import TitleContainer from '@/components/Form/styles';
import * as imageSizes from '@/constants/images';
import DropZoneField from '@/components/Form/DropZoneField';

const addBusinessLogo = props => {
  const { handleStep, uploadLogo, organization_id, handleComplete } = props;
  const [initialFormData] = useState({ logo: [] });

  const handleState = () => {
    handleStep(1);
    handleComplete(0);
  };

  const onSubmit = values => {
    uploadLogo({ id: organization_id, logo: values.logo, callback: () => handleState() });
  };

  const logoDropPlaceholder = (
    <Typography color="textSecondary" variant="body2">
      {t('dropZoneField.dropTextPart1')}&nbsp;
      <Typography color="primary" variant="body2" component="span">
        {t('organization.logoImage')}&nbsp;
      </Typography>
      {t('dropZoneField.dropTextPart2')}&nbsp;
      <Link id="select-logo-button" component="button">
        {t('dropZoneField.dropTextPart3')}
      </Link>
      &nbsp;{t('dropZoneField.dropTextPart4')}
    </Typography>
  );

  return (
    <>
      <TitleContainer>
        <Typography variant="h2" component="h4">
          {t('completeProfileDialog.businessLogo')}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {t('completeProfileDialog.logoDescription')}
        </Typography>
      </TitleContainer>
      <Formik
        initialValues={initialFormData}
        validationSchema={setYupValidationSchema('addBusinessLogo', t)}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, isValid, setFieldValue, setFieldTouched }) => {
          return (
            <form onSubmit={e => e.preventDefault()} noValidate autoComplete="off">
              <DropZoneField
                name="logo"
                isBothCropper
                title={t('organization.inputs.organizationLogo')}
                subtitle={t('organization.inputs.organizationLogoSubtitle')}
                limit={1}
                multiple={false}
                images={values.logo}
                onImagesUpdated={files => setFieldValue('logo', files)}
                onDropAccepted={files => setFieldValue('logo', files)}
                onFileDialogCancel={() => setFieldTouched('logo', true)}
                dropPlaceholder={logoDropPlaceholder}
                cropAspect={16 / 9}
                cropHeight={imageSizes.ORGANIZATION_LOGO_HEIGHT}
                cropWidth={imageSizes.ORGANIZATION_LOGO_WIDTH}
                recommendWidth={[
                  imageSizes.ORGANIZATION_LOGO_WIDTH,
                  imageSizes.ORGANIZATION_LOGO_WIDTH_RECTANGLE,
                ]}
                recommendHeight={[imageSizes.ORGANIZATION_LOGO_HEIGHT]}
                error={errors.logo && touched.logo && errors.logo}
              />
              <Button
                size="medium"
                color="secondary"
                variant="outlined"
                type="submit"
                id="upload-business-logo"
                disabled={!isValid}
                onClick={() => onSubmit(values)}
              >
                {t('buttons.save')}
              </Button>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

addBusinessLogo.propTypes = {
  handleComplete: PropTypes.func,
  handleStep: PropTypes.func,
  organization_id: PropTypes.number,
  match: PropTypes.object,
  location: PropTypes.object,
  check_info: PropTypes.object,
  uploadLogo: PropTypes.func,
};

const mapStateToProps = ({ stripe, auth }) => {
  return {
    organization_id: auth.organization_id,
    loading: stripe.loading,
    check_info: auth.check_info,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    uploadLogo: params => dispatch(actions.uploadBusinessLogo(params)),
  };
};

export default React.memo(
  compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(addBusinessLogo),
);
