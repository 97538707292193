import * as Yup from 'yup';
import * as validationConst from '@/constants/validation';
import axios from 'axios';
import { BUSINESS_CHECK_EMAIL, BUSINESS_CHECK_NAME } from '@/constants/api';

export default t => {
  let prevBusinessEmail;
  let prevName;

  return {
    mainInformation: {
      email: Yup.string()
        .required(t('validation.emailRequired'))
        .email(t('validation.emailNotValid'))
        .test('email', t('validation.emailsAlreadyTaken'), function testEmail(email) {
          const { organization_id } = this.parent;
          if (email) {
            const schema = Yup.string().email();
            if (schema.isValidSync(email) && prevBusinessEmail !== email) {
              prevBusinessEmail = email;
              return axios
                .get(`${BUSINESS_CHECK_EMAIL}/${email}`, { params: { organization_id } })
                .then(res => !res.data.exist)
                .catch(() => true);
            }
          }
          return true;
        }),
      organization_email: Yup.string()
        .required(t('validation.emailRequired'))
        .email(t('validation.emailNotValid'))
        .test('email', t('validation.emailsAlreadyTaken'), function testEmail(email) {
          const { organization_id } = this.parent;
          if (email) {
            const schema = Yup.string().email();
            if (schema.isValidSync(email) && prevBusinessEmail !== email) {
              prevBusinessEmail = email;
              return axios
                .get(`${BUSINESS_CHECK_EMAIL}/${email}`, { params: { organization_id } })
                .then(res => !res.data.exist)
                .catch(() => true);
            }
          }
          return true;
        }),
      percent: Yup.number()
        .required(t('validation.percent'))
        .typeError('Enter the number')
        .min(0)
        .max(99),
      business_name: Yup.string()
        .required(t('validation.businessNameRequired'))
        .min(validationConst.MIN_BUSINESS_NAME_LENGTH, t('validation.businessNameLength'))
        .test('name', t('validation.businessNameAlreadyTaken'), function testName(name) {
          const { organization_id } = this.parent;

          if (
            name &&
            name.trim() &&
            name.length >= validationConst.MIN_BUSINESS_NAME_LENGTH &&
            prevName !== name
          ) {
            prevName = name;
            return axios
              .get(`${BUSINESS_CHECK_NAME}`, { params: { name, organization_id } })
              .then(res => !res.data.exist)
              .catch(() => true);
          }
          return true;
        }),
      location: Yup.string().required(t('validation.locationRequired')),
      zip: Yup.string()
        .required(t('validation.zipRequired'))
        .matches(/^[0-9]{4,6}(?:-[0-9]{4})?$/, t('validation.zipRequired')),
    },
    contractDetails: {
      contract_email: Yup.string()
        .required(t('validation.emailRequired'))
        .email(t('validation.emailNotValid')),
      template_id: Yup.string().required(t('validation.template')),
    },
  };
};
