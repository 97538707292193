import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useTranslation } from 'react-i18next';

// MATERIAL UI
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Drawer, MenuList, MenuItem, Divider, Hidden } from '@material-ui/core';
import BurgerIcon from '@material-ui/icons/Menu';

// COMMON
import { menuList, utilityList, menuListAdmin, menuListSubAdmin } from '@/constants/sidebarLabels';
import { ROLE_ADMIN, ROLE_MERCHANDISER, ROLE_SUB_ADMIN } from '@/constants/roles';
import { ROUTE_HELP, ROUTE_OVERVIEW, ROUTE_REDEMPTIONS } from '@/constants/routes';
import logo from '@/assets/images/logo_white_text.svg';

import { LogoWrapper, Logo, styles, NavLinkS, MenuIcon, MenuText, BurgerButton } from './styles';

const sideMenu = props => {
  const { classes, history, role } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [mobileOpen, setMobileOpen] = useState(false);
  let logoLink = ROUTE_OVERVIEW;
  let menuItemList;

  function handleDrawerToggle() {
    if (!matches) {
      setMobileOpen(!mobileOpen);
    }
  }

  switch (role) {
    case ROLE_ADMIN:
      menuItemList = menuListAdmin;
      logoLink = ROUTE_OVERVIEW;
      break;
    case ROLE_SUB_ADMIN:
      menuItemList = menuListSubAdmin;
      logoLink = ROUTE_OVERVIEW;
      break;
    case ROLE_MERCHANDISER:
      menuItemList = menuList;
      logoLink = ROUTE_REDEMPTIONS;
      break;
    default:
      menuItemList = [];
  }

  const drawer = (
    <>
      <LogoWrapper>
        <Link to={logoLink}>
          <Logo src={logo} />
        </Link>
      </LogoWrapper>
      <MenuList classes={{ root: classes.menuList }}>
        {menuItemList.map(el => {
          return el.route ? (
            <MenuItem
              exact
              classes={{ root: classes.menuItem }}
              className={history.location.pathname.includes(el.route) ? 'active' : ''}
              component={NavLinkS}
              onClick={handleDrawerToggle}
              to={el.route}
              key={el.label}
            >
              <MenuIcon className={el.icon} />
              <MenuText>{t(el.label)}</MenuText>
            </MenuItem>
          ) : (
            <MenuItem
              component="a"
              exact
              classes={{ root: classes.menuItem }}
              href={el.url}
              key={el.label}
              target="_blank"
            >
              <MenuIcon className={el.icon} />
              <MenuText>{t(el.label)}</MenuText>
            </MenuItem>
          );
        })}
      </MenuList>
      {role === ROLE_MERCHANDISER ? (
        <MenuList classes={{ root: classes.utilityList }}>
          <Divider classes={{ root: classes.divider }} />
          {utilityList
            .filter(el => el.route !== ROUTE_HELP)
            .map(el => {
              return (
                <MenuItem
                  exact
                  classes={{ root: classes.menuItem }}
                  component={NavLinkS}
                  onClick={handleDrawerToggle}
                  to={el.route}
                  key={el.label}
                  target={el.target}
                >
                  {el.icon && <MenuIcon className={el.icon} />}
                  <MenuText>{t(el.label)}</MenuText>
                </MenuItem>
              );
            })}
        </MenuList>
      ) : null}
    </>
  );

  return (
    <>
      {!mobileOpen && (
        <Hidden lgUp>
          <BurgerButton id="side-menu-burger-button" onClick={handleDrawerToggle}>
            <BurgerIcon fontSize="large" />
          </BurgerButton>
        </Hidden>
      )}
      <Hidden lgUp>
        <Drawer
          classes={{ root: classes.root, paper: classes.drawerPaper }}
          variant="temporary"
          onClose={handleDrawerToggle}
          open={mobileOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          classes={{ root: classes.root, paper: classes.drawerPaper }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

sideMenu.propTypes = {
  role: PropTypes.string,
  classes: PropTypes.object,
  history: PropTypes.object.isRequired,
};

export default compose(withRouter, withStyles(styles, { withTheme: true }))(sideMenu);
