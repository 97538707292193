import axios from 'axios';
import { put } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/customers';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* getCustomersListSaga(action) {
  const { id, search, ...params } = action.payload;
  yield put({ type: actionTypes.CUSTOMERS_START });
  const url = search
    ? getLink(endpoints.GET_CUSTOMERS_SEARCH, { id, search })
    : getLink(endpoints.GET_CUSTOMERS_LIST, { id });
  try {
    const res = yield axios.get(url, { params });
    const { data } = res;
    yield put({
      type: actionTypes.CUSTOMERS_LIST_SUCCESS,
      payload: params.all ? { data } : data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CUSTOMERS_STOP,
    });
  }
}

export function* getCustomersDetailSaga(action) {
  const { ...params } = action.payload;
  yield put({ type: actionTypes.CUSTOMERS_START });
  const url = getLink(endpoints.GET_CUSTOMERS_DETAIL, { ...params });
  try {
    const res = yield axios.get(url, { ...params });
    const { data } = res;
    yield put({
      type: actionTypes.CUSTOMERS_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.CUSTOMERS_STOP,
    });
  }
}
