import * as actionTypes from './types/customers';

export const getCustomersList = params => {
  return {
    type: actionTypes.CUSTOMERS_GET_LIST,
    payload: params,
  };
};

export const getCustomerDetail = params => {
  return {
    type: actionTypes.CUSTOMERS_GET_DETAIL,
    payload: params,
  };
};
