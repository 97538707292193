import axios from 'axios';
import { put } from 'redux-saga/effects';

import { t } from '@/locale/i18n';
import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/directGift';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';

export function* sendDirectGiftSaga(action) {
  const { id, customerId, gift_id, amount } = action.payload;
  yield put({ type: actionTypes.DIRECT_GIFT_START });
  const url = getLink(endpoints.DIRECT_GIFT_SEND, { id, customerId });
  const amountData = amount ? { amount } : {};

  try {
    const res = yield axios.post(url, { gift_id, ...amountData });
    const { data } = res;
    yield put({
      type: actionTypes.DIRECT_GIFT_SEND_SUCCESS,
      payload: data,
    });
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: t('notification.sendDirectSuccess'),
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.DIRECT_GIFT_STOP,
    });
  }
}

export default sendDirectGiftSaga;
