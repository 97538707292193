import * as actionTypes from '@/store/actions/types/accounts';
import * as authActionTypes from '@/store/actions/types/auth';

const initialState = {
  emailNotifications: [],
  billing: null,
  loading: {
    data: false,
    update: false,
    change: false,
  },
  errors: {
    data: null,
    update: null,
    change: null,
  },
  unread: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNTS_GET_START:
      return {
        ...state,
        loading: { ...state.loading, data: true },
        error: null,
      };
    case actionTypes.ACCOUNTS_PUT_START:
      return {
        ...state,
        loading: { ...state.loading, update: true },
        error: null,
      };
    case actionTypes.ACCOUNTS_PUT_MAIN_INFO_SUCCESS:
    case actionTypes.ACCOUNTS_PUT_SECURITY_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, update: false },
      };
    case actionTypes.ACCOUNTS_GET_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        emailNotifications: action.payload.emailNotifications,
        loading: { ...state.loading, data: false },
      };
    case actionTypes.ACCOUNTS_GET_BILLING_SUCCESS:
      return {
        ...state,
        billing: action.payload,
        loading: { ...state.loading, data: false },
      };
    case actionTypes.ACCOUNTS_PUT_BILLING_SUCCESS:
      return {
        ...state,
        billing: action.payload,
        loading: { ...state.loading, update: false },
      };
    case actionTypes.ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        emailNotifications: action.payload.emailNotifications,
        loading: { ...state.loading, update: false },
      };
    case actionTypes.ACCOUNTS_GET_BILLING_FAIL:
    case actionTypes.ACCOUNTS_GET_EMAIL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, data: false },
        errors: { ...state.errors, data: action.payload.error },
      };
    case actionTypes.ACCOUNTS_PUT_BILLING_FAIL:
    case actionTypes.ACCOUNTS_PUT_MAIN_INFO_FAIL:
    case actionTypes.ACCOUNTS_PUT_SECURITY_FAIL:
    case actionTypes.ACCOUNTS_PUT_EMAIL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: { ...state.loading, update: false },
        errors: { ...state.errors, update: action.payload.error },
      };
    case authActionTypes.AUTH_LOGOUT: {
      return initialState;
    }
    case actionTypes.CHECK_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unread: action.payload.quantity,
      };

    case actionTypes.CHANGE_STRIPE_ACCOUNT_START:
      return {
        ...state,
        loading: { ...state.loading, change: true },
      };
    case actionTypes.CHANGE_STRIPE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, change: false },
      };
    case actionTypes.CHANGE_STRIPE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, change: false },
        errors: { ...state.errors, change: action.payload.error },
      };

    case actionTypes.CHANGE_SQUARE_ACCOUNT_START:
      return {
        ...state,
        loading: { ...state.loading, change: true },
      };
    case actionTypes.CHANGE_SQUARE_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, change: false },
      };
    case actionTypes.CHANGE_SQUARE_ACCOUNT_FAIL:
      return {
        ...state,
        loading: { ...state.loading, change: false },
        errors: { ...state.errors, change: action.payload.error },
      };
    default:
      return state;
  }
};

export default reducer;
