import * as actionTypes from '@/store/actions/types/surveys';

const initialState = {
  list: [],
  loading: false,
  current_page: 0,
  per_page: 10,
  total: 0,
  detail: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SURVEYS_LIST_START:
      return { ...state, loading: true };
    case actionTypes.GET_SURVEYS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data,
        current_page: action.payload.current_page - 1,
        per_page: action.payload.per_page,
        total: action.payload.total,
      };
    case actionTypes.GET_SURVEYS_LIST_FAIL:
      return { ...state, loading: false };
    case actionTypes.GET_DETAIL_SURVEY_START:
      return { ...state, loading: true };
    case actionTypes.GET_DETAIL_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: action.payload,
      };
    case actionTypes.GET_DETAIL_SURVEY_FAIL:
      return { ...state, loading: false };
    case actionTypes.CHANGE_STATUS_SURVEY_START:
      return { ...state, loading: true };
    case actionTypes.CHANGE_STATUS_SURVEY_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: {
          status: action.payload.data,
        },
      };
    case actionTypes.CHANGE_STATUS_SURVEY_FAIL:
      return { ...state, loading: false };
    case actionTypes.CREATE_SURVEY_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_SURVEY_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.CREATE_SURVEY_FAIL:
      return { ...state, loading: false };
    case actionTypes.EDIT_SURVEY_START:
      return { ...state, loading: true };
    case actionTypes.EDIT_SURVEY_SUCCESS:
      return { ...state, loading: false };
    case actionTypes.EDIT_SURVEY_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
