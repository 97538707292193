// TRANSACTIONS actions
export const TRANSACTIONS_START = 'TRANSACTIONS_START';
export const TRANSACTIONS_STOP = 'TRANSACTIONS_STOP';

export const TRANSACTIONS_GET_LIST = 'TRANSACTIONS_GET_LIST';
export const TRANSACTIONS_GET_LIST_SUCCESS = 'TRANSACTIONS_GET_LIST_SUCCESS';

export const TRANSACTIONS_GET_LAST = 'TRANSACTIONS_GET_LAST';
export const TRANSACTIONS_GET_LAST_SUCCESS = 'TRANSACTIONS_GET_LAST_SUCCESS';

export const GET_DETAIL_TRANSACTION = 'GET_DETAIL_TRANSACTION';
export const GET_DETAIL_TRANSACTION_START = 'GET_DETAIL_TRANSACTION_START';
export const GET_DETAIL_TRANSACTION_SUCCESS = 'GET_DETAIL_TRANSACTION_SUCCESS';
export const GET_DETAIL_TRANSACTION_FAIL = 'GET_DETAIL_TRANSACTION_FAIL';

export const UPDATE_STATUS_TRANSACTION = 'UPDATE_STATUS_TRANSACTION';
export const UPDATE_STATUS_TRANSACTION_START = 'UPDATE_STATUS_TRANSACTION_START';
export const UPDATE_STATUS_TRANSACTION_SUCCESS = 'UPDATE_STATUS_TRANSACTION_SUCCESS';
export const UPDATE_STATUS_TRANSACTION_FAIL = 'UPDATE_STATUS_TRANSACTION_FAIL';
