import * as Yup from 'yup';
import * as validation from '@/constants/validation';
import moment from 'moment';
import { getCurrentLang } from '@/locale/i18n';

export default t => {
  return {
    title: Yup.string()
      .required(t('validation.textRequired'))
      .max(validation.MAX_TITLE_DISCOUNT, t('validation.titleMaxLength')),
    body: Yup.string()
      .required(t('validation.bodyRequired'))
      .max(validation.MAX_DESCRIPTIONS_DISCOUNT, t('validation.bodyMaxLength')),
    country: Yup.string().required(t('validation.countryRequired')),
    notification_date: Yup.string()
      .nullable()
      .test('notification_date', t('validation.notificationDate'), notification_date => {
        if (!notification_date) return true;

        const format = getCurrentLang() === 'es' ? 'HH:mm:ss' : 'HH:mm:ss A';
        const formatDate = moment.utc(notification_date, `YYYY-MM-DD ${format}`);
        return moment.utc(formatDate).isAfter(moment.utc());
      }),
    to_ids: Yup.array()
      .nullable()
      .when('is_to_all_users', {
        is: true,
        otherwise: Yup.array().required(t('validation.usersOrCheckboxRequired')),
      }),
  };
};
