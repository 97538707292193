import * as actionTypes from '@/store/actions/types/stripe';

const initialState = {
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STRIPE_START:
      return { loading: true };
    case actionTypes.STRIPE_SUCCESS:
      return { loading: false };
    case actionTypes.STRIPE_FAIL:
      return { loading: false };
    default:
      return state;
  }
};

export default reducer;
