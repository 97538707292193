import axios from 'axios';
import { put, call } from 'redux-saga/effects';

import * as endpoints from '@/constants/api';
import getErrorMessage from '@/utility/common/errors';
import getLink from '@/utility/common/routes';
import * as actionTypes from '@/store/actions/types/transactions';
import { PUSH_NOTIFY } from '@/store/actions/types/notifications';
import { t } from '@/locale/i18n';
import { TYPE_GIFT } from '@/constants/gift';
import {
  TRANSACTION_REQUEST_TYPE_ROLE,
  TRANSACTION_REQUEST_TYPE_STATUS,
} from '@/constants/transactions';

export function* getTransactionsListSaga(action) {
  const {
    id,
    type,
    search,
    userId,
    customer_id: customerId,
    giftId,
    pendingType,
    ...params
  } = action.payload;

  yield put({ type: actionTypes.TRANSACTIONS_START });
  const url = () => {
    switch (type) {
      case 'customers':
        return getLink(endpoints.GET_TRANSACTION_CUSTOMER_LIST, {
          id,
          customer_id: customerId,
          ...params,
        });
      case 'users':
        return getLink(endpoints.GET_TRANSACTION_USER_LIST, {
          id,
          userId,
          ...params,
        });
      case TRANSACTION_REQUEST_TYPE_ROLE:
        if (search) {
          return getLink(endpoints.GET_TRANSACTION_LIST_SEARCH, {
            search,
            ...params,
          });
        }
        return endpoints.GET_TRANSACTION_LIST;
      case TRANSACTION_REQUEST_TYPE_STATUS:
        return getLink(endpoints.GET_TRANSACTION_PENDING_LIST, { type: pendingType, search });
      case 'balance':
        return getLink(endpoints.GET_TRANSACTION_BALANCE_LIST, { id, ...params });

      case TYPE_GIFT:
        return getLink(endpoints.GET_TRANSACTION_GIFT_LIST, { gift_id: giftId, ...params });
      default:
        return endpoints.GET_TRANSACTION_LIST;
    }
  };

  try {
    const res = yield axios.get(url(), { params });
    const { data: response } = res;
    const meta = response.meta || {};

    yield put({
      type: actionTypes.TRANSACTIONS_GET_LIST_SUCCESS,
      payload: { ...response, ...meta },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.TRANSACTIONS_STOP,
    });
  }
}

export function* getLastTransactionsSaga(action) {
  yield put({ type: actionTypes.TRANSACTIONS_START });

  const { organizationId } = action.payload;
  const url = getLink(endpoints.GET_TRANSACTION_LAST_TRANSACTIONS, { id: organizationId });

  try {
    const res = yield axios.get(url);
    const { data } = res;

    yield put({
      type: actionTypes.TRANSACTIONS_GET_LAST_SUCCESS,
      payload: {
        lastTransactions: data.transactions,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.TRANSACTIONS_STOP,
    });
  }
}

export function* getDetailsTransactionSaga(action) {
  yield put({ type: actionTypes.GET_DETAIL_TRANSACTION_START });

  const { transactionId } = action.payload;
  const url = getLink(endpoints.TRANSACTION_DETAIL, { transactionId });

  try {
    const res = yield axios.get(url);
    const { data } = res;

    yield put({
      type: actionTypes.GET_DETAIL_TRANSACTION_SUCCESS,
      payload: {
        data,
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.GET_DETAIL_TRANSACTION_FAIL,
    });
  }
}

export function* updateTransactionStatusSaga(action) {
  yield put({ type: actionTypes.UPDATE_STATUS_TRANSACTION_START });

  const { transactionId, ...params } = action.payload;

  const blockTransactionUrl = getLink(endpoints.TRANSACTION_BLOCK, { transactionId });
  const unblockTransactionUrl = getLink(endpoints.TRANSACTION_UN_BLOCK, { transactionId });
  const isWaiting = params.pendingType === 'waiting';
  try {
    const res = yield axios.put(isWaiting ? blockTransactionUrl : unblockTransactionUrl);
    const { data } = res;

    yield call(getTransactionsListSaga, { payload: params });
    yield put({
      type: actionTypes.UPDATE_STATUS_TRANSACTION_SUCCESS,
      payload: {
        data,
      },
    });

    const message = isWaiting
      ? t('transactionsList.successBlocked')
      : t('transactionsList.successUnblocked');
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message,
        options: {
          variant: 'success',
        },
      },
    });
  } catch (error) {
    yield put({
      type: PUSH_NOTIFY,
      payload: {
        message: getErrorMessage(error),
        options: {
          variant: 'error',
        },
      },
    });
    yield put({
      type: actionTypes.UPDATE_STATUS_TRANSACTION_FAIL,
    });
  }
}
