import * as actionTypes from './types/accounts';

export const putMainInfo = (data, formik) => {
  return {
    type: actionTypes.ACCOUNTS_PUT_MAIN_INFO,
    payload: {
      data,
      formik,
    },
  };
};

export const putSecurity = (data, formik) => {
  return {
    type: actionTypes.ACCOUNTS_PUT_SECURITY,
    payload: {
      data,
      formik,
    },
  };
};

export const getEmailNotifications = id => {
  return {
    type: actionTypes.ACCOUNTS_GET_EMAIL_NOTIFICATIONS,
    payload: {
      id,
    },
  };
};

export const putEmailNotifications = (data, formik) => {
  return {
    type: actionTypes.ACCOUNTS_PUT_EMAIL_NOTIFICATIONS,
    payload: {
      data,
      formik,
    },
  };
};

export const getBillingCard = id => {
  return {
    type: actionTypes.ACCOUNTS_GET_BILLING,
    payload: id,
  };
};

export const changeBillingCard = params => {
  return {
    type: actionTypes.ACCOUNTS_PUT_BILLING,
    payload: params,
  };
};

export const checkNotifications = params => {
  return {
    type: actionTypes.CHECK_NOTIFICATIONS,
    payload: params,
  };
};

export const changeStripeAccount = params => {
  return {
    type: actionTypes.CHANGE_STRIPE_ACCOUNT,
    payload: params,
  };
};

export const changeSquareAccount = params => {
  return {
    type: actionTypes.CHANGE_SQUARE_ACCOUNT,
    payload: params,
  };
};
